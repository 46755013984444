import integration from '@/api/integrations/integration';
import { copyToClipboard } from '@/utils/Integrations';
import i18n from '../../i18n';

// State object
const state = {
    creatorOpen: false,
    // stepsCompleted: [], // might add this to enable more intelligent progress tracking - Teo
    currentStep: null,
    totalSteps: null,
    creatorIntegrationObject: null,
    creatorKey: 0,
    creatorFormValidity: {
        name: false,
        behavior: false,
        variables: false,
        summary: false,
    },
    recommendedVariables: [],
    // billing
    articles: [],
    // system integrations
    systemIntegrations: null,
    externalData: null,
    search: '',
    // I2I
    policyId: null,
    partyId: null,
};
// Getter functions
const getters = {
    integrationSections: (state) => {
        if (!state.systemIntegrations) return null;

        const sections = state.search
            ? state.systemIntegrations.filter(
                  (integration) =>
                      integration.name.toLowerCase().includes(state.search.toLowerCase()) ||
                      integration.type.toLowerCase().includes(state.search.toLowerCase()),
              )
            : state.systemIntegrations;
        return sections.reduce((acc, integration) => {
            if (!acc[integration.type]) {
                acc[integration.type] = [integration];
            } else {
                acc[integration.type].push(integration);
            }
            return acc;
        }, {});
    },
};

// Actions
const actions = {
    incrementCreatorKey({ commit }) {
        commit('INCREMENT_CREATOR_KEY');
    },
    setIntegrationCreationObject({ commit }, payload) {
        commit('SET_INTEGRATION_CREATION_OBJECT', payload);
    },
    setTotalSteps({ commit }, payload) {
        commit('SET_TOTAL_STEPS', payload);
    },
    setCurrentStep({ commit }, payload) {
        commit('SET_CURRENT_STEP', payload);
    },
    setCreatorModal({ commit }, payload) {
        commit('SET_CREATOR_MODAL', payload);
    },
    setPreviousStep({ commit }) {
        if (state.currentStep > 1) {
            commit('SET_CURRENT_STEP', state.currentStep - 1);
        }
    },
    setNextStep({ commit }) {
        if (state.currentStep < state.totalSteps) {
            commit('SET_CURRENT_STEP', state.currentStep + 1);
        } else {
            commit('SET_CURRENT_STEP', 1);
        }
    },
    cancelCreation({ commit }) {
        commit('SET_CREATOR_MODAL', false);
        commit('SET_CURRENT_STEP', 1);
        commit('SET_INTEGRATION_CREATION_OBJECT', {
            name: { value: '', text: i18n.t('stepperActions.integrName') },
            type: { value: '', text: i18n.t('stepperActions.integrType') },
            variables: { value: [], text: i18n.t('stepperActions.variables') },
        });
        commit('SET_VALIDITY', {
            name: false,
            behavior: false,
            variables: false,
            summary: false,
        });
        commit('INCREMENT_CREATOR_KEY');
    },

    async openIntegration({ dispatch }, payload) {
        try {
            switch (payload.integration.name.toLowerCase()) {
                case 'mrm': {
                    dispatch('integrationMrm', payload.identificationNumber);
                    break;
                }
                default: {
                    await copyToClipboard(payload.identificationNumber);
                    window.location.href = payload.integration.link;
                }
            }
        } catch (error) {
            console.error('Failed to open integration: ' + integration.name);
        }
    },

    async integrationMrm({ dispatch }, identificationNumber) {
        const res = await dispatch('Cases/soapGetUserLink', { personNr: identificationNumber }, { root: true });
        const url = res.URL;
        window.open(url, '_blank');
    },
    // TODO: change from try catch to async await in integrations.store.js
    getExternalData(_, payload) {
        return new Promise((resolve, reject) => {
            integration
                .getExternalData(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    deploySync(_, payload) {
        return new Promise((resolve, reject) => {
            integration
                .deploySync(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getHistory(_, payload) {
        return new Promise((resolve, reject) => {
            integration
                .getHistory(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    async bankIdAuthenticate(_, pnr) {
        const result = await integration.bankidAuthenticate(pnr);
        return result.data;
    },

    async isBankIdActive() {
        const result = await integration.isBankIdActive();
        return result.data;
    },
    async getActiveStates() {
        const res = await integration.getActiveStates();
        return res.data;
    },

    async getDeduTasks(_, payload) {
        const result = await integration.getDeduTasks(payload);
        return result.data;
    },
    async getDeduTask(_, id) {
        const result = await integration.getDeduTask(id);
        return result.data;
    },
    createDeduTask(_, payload) {
        return integration.createDeduTask(payload);
    },
    updateDeduTask(_, payload) {
        return integration.updateDeduTask(payload);
    },
    async getDeduBuildingEntityItems(_, payload) {
        const result = await integration.getDeduBuildingEntityItems(payload);
        return result.data;
    },
    async getDeduProfessions(_, payload) {
        const result = await integration.getDeduProfessions(payload);
        return result.data;
    },
    async getDeduTaskTypes() {
        const result = await integration.getDeduTaskTypes();
        return result.data;
    },
    async getDeduTaskCategories() {
        const result = await integration.getDeduTaskCategories();
        return result.data;
    },
    async getDeduTaskSubCategories() {
        const result = await integration.getDeduTaskSubCategories();
        return result.data;
    },
    async getDeduTaskPriorities() {
        const result = await integration.getDeduTaskPriorities();
        return result.data;
    },
    async getDeduTaskSources() {
        const result = await integration.getDeduTaskSources();
        return result.data;
    },

    async getQueues() {
        const result = await integration.getQueues();
        return result.data;
    },
    async setSelectedQueue(_, payload) {
        const result = await integration.setSelectedQueue(payload);
        return result.data;
    },
    async createCaseInIntegration(_, payload) {
        const result = await integration.createCaseInIntegration(payload);
        return result.data;
    },

    // MADHAT

    async getOrders(_, payload) {
        const result = await integration.getOrders(payload);
        return result.data;
    },
    async getOrder(_, payload) {
        const result = await integration.getOrder(payload);
        return result.data;
    },

    async getPortals(_, type) {
        const result = await integration.getPortals(type);
        return result.data;
    },

    addPortal(_, payload) {
        return integration.addPortal(payload);
    },
    updatePortal(_, payload) {
        return integration.updatePortal(payload);
    },

    deletePortal(_, id) {
        return integration.deletePortal(id);
    },

    // END OF MADHAT

    // PINDELIVERY

    async getPindeliveryReports(_, orderId) {
        const { data } = await integration.getPindeliveryReports(orderId);
        return data.status_reports;
    },

    // END OF PINDELIVERY

    async getOrderIntegrations() {
        try {
            const { data } = await integration.getOrderIntegrations();
            return data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('casesStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },

    getSystemIntegrations({ commit }) {
        return new Promise((resolve, reject) => {
            integration
                .getSystemIntegrations()
                .then((res) => {
                    commit('SET_SYSTEM_INTEGRATIONS', res.data);
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getCaseButtonIntegrations() {
        return new Promise((resolve, reject) => {
            integration
                .getCaseButtonIntegrations()
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    getSystemIntegrationData({ commit }, id) {
        return new Promise((resolve, reject) => {
            integration
                .getSystemIntegrationData(id)
                .then((res) => {
                    commit('SET_EXTERNAL_DATA', res.data);
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    addKeyToIntegration(_, payload) {
        return new Promise((resolve, reject) => {
            integration
                .addKeyToIntegration(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    updateSystemIntegration({ commit }, payload) {
        return new Promise((resolve, reject) => {
            integration
                .updateSystemIntegration(payload)
                .then((res) => {
                    if (payload.active !== undefined) {
                        commit('UPDATE_SYSTEM_INTEGRATION_ACTIVE', {
                            active: payload.active,
                            id: payload.integrationId,
                        });
                    }
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    updateSystemIntegrationInstance(_, payload) {
        return new Promise((resolve, reject) => {
            integration
                .updateSystemIntegrationInstance(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    async updateSystemIntegrationInstanceSettings(_, payload) {
        const result = await integration.saveSystemIntegrationInstanceSettings(payload);
        return result.data;
    },

    async deleteInstance(_, payload) {
        const result = await integration.deleteInstance(payload);
        return result.data;
    },
    async createInstance(_, payload) {
        const result = await integration.createInstance(payload);
        return result.data;
    },

    // Sveriges A-kassor
    async sverigesAkassorGetMember(_, ssn) {
        try {
            const result = await integration.sverigesAkassorGetMember(ssn);
            return result.data;
        } catch (error) {
            return this._vm.$toasted.error(error.response.data);
        }
    },
    async sverigesAkassorOpenMember(_, ssn) {
        try {
            const result = await integration.sverigesAkassorOpenMember(ssn);
            return result.data;
        } catch (error) {
            return this._vm.$toasted.error(error.response.data);
        }
    },
    // END OF SVERIGES A-kassor
    setSearch({ commit }, payload) {
        commit('SET_SEARCH', payload);
    },
};
const mutations = {
    SET_CREATOR_MODAL(state, payload) {
        state.creatorOpen = payload;
    },
    SET_CURRENT_STEP(state, payload) {
        state.currentStep = payload;
    },
    SET_TOTAL_STEPS(state, payload) {
        state.totalSteps = payload;
    },
    SET_INTEGRATION_CREATION_OBJECT(state, payload) {
        state.creatorIntegrationObject = payload;
    },
    SET_INTEGRATION_CREATION_OBJECT_KEY(state, data) {
        const [key, value] = Object.entries(data)[0];
        state.creatorIntegrationObject[key].value = value;
    },
    SET_VALIDITY(state, data) {
        const [key, value] = Object.entries(data)[0];
        state.creatorFormValidity[key] = value;
    },
    USER_MODAL_NEXT_STEP(state) {
        if (state.currentStep < state.totalSteps) state.currentStep++;
        else state.currentStep = 1;
    },
    USER_MODAL_PREVIOUS_STEP(state) {
        if (state.currentStep > 1) state.currentStep--;
    },
    INCREMENT_CREATOR_KEY(state) {
        state.creatorKey++;
    },
    SET_RECOMMENDED_VARIABLES(state, data) {
        state.recommendedVariables = data;
    },
    SET_SYSTEM_INTEGRATIONS(state, data) {
        state.systemIntegrations = data;
    },
    UPDATE_SYSTEM_INTEGRATION_ACTIVE(state, data) {
        const index = state.systemIntegrations.findIndex((integration) => integration.id === data.id);
        if (index === -1) return;
        state.systemIntegrations[index].active = data.active;
    },
    SET_EXTERNAL_DATA(state, data) {
        if (data.externalData) {
            state.externalData = data.externalData;
        }
    },
    SET_SEARCH(state, data) {
        state.search = data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
