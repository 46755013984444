<template>
    <section :class="{ disabled: commentsState.disabled }" class="create-comments-functions">
        <CreateCommentsRoundButton
            v-if="commentsState.isFullScreen"
            :activeColor="commentsState.inCase ? 'var(--v-primary-base)' : 'var(--v-gray3-base)'"
            :active="Boolean(commentsState.inCase)"
            :iconOnly="Boolean(commentsState.collapseEditor)"
            :icon="commentsState.inCase ? 'mdi-tab-plus' : 'mdi-tab-minus'"
            :tooltip="commentsState.inCase ? $t('createComments.working') : $t('createComments.notWorking')"
            :clickable="false"
            cy-data="create-comments-in-case"
        />

        <CreateCommentsRoundButton
            :active="commentsState.autoComplete"
            icon="mdi-lightbulb-auto-outline"
            :iconOnly="Boolean(commentsState.collapseEditor)"
            toggle
            :tooltip="$t('createComments.toggleAutoComplete')"
            @update:active="toggleAutoComplete"
            cy-data="create-comments-auto-complete"
        />

        <Template
            :templateLanguage="commentsState.currentQueueLanguage"
            :templateTypes="['EMAIL_TEMPLATE_SELECT']"
            :queueId="commentsState.queueId"
            :closeMenuOnClick="true"
            :emitGlobalMergeTags="false"
            @input="applyTemplate"
            cy-data="create-comments-email-template"
        >
            <CreateCommentsRoundButton
                :iconOnly="Boolean(commentsState.collapseEditor)"
                icon="mdi-card-account-mail"
                :tooltip="$t('createComments.insertTemplate')"
            />
        </Template>

        <Template
            :class="{ disabled: !commentsState.external }"
            :templateLanguage="commentsState.currentQueueLanguage"
            :templateTypes="['SIGNATURE_TEMPLATE_SELECT']"
            :queueId="commentsState.queueId"
            :closeMenuOnClick="true"
            @input="applySignature"
            cy-data="create-comments-signature-template"
        >
            <CreateCommentsRoundButton
                :iconOnly="Boolean(commentsState.collapseEditor)"
                icon="mdi-file-sign"
                :tooltip="$t('createComments.insertSignature')"
            />
        </Template>

        <CreateCommentsRoundButton
            icon="mdi-file-pdf-box"
            :iconOnly="Boolean(commentsState.collapseEditor)"
            :tooltip="$t('createComments.createPDF')"
            @click="commentsState.emitCreatePDF"
            cy-data="create-comments-create-pdf"
        />

        <ChatGPT
            :iconOnly="Boolean(commentsState.collapseEditor)"
            :context="'cases'"
            :caseId="commentsState.caseId"
            :clientId="commentsState.clientId"
            :small="true"
            :textInput="commentsState.content"
            cy-data="create-comments-chat-gpt"
        />
    </section>
</template>

<script lang="ts">
    import { defineComponent, inject } from 'vue';
    import CreateCommentsRoundButton from '@/components/Cases/CreateComments/CreateCommentsRoundButton.vue';
    import { CreateCommentsState, Template as ITemplate } from '@/types/createComments.state';
    import Template from '@/components/Main/Selects/Template.vue';
    import ChatGPT from '@/components/Global/ChatGPT/ChatGPT.vue';
    import { templateTypes } from '../../../enums/templates.enums';

    export default defineComponent({
        name: 'CreateCommentsFunctions',

        components: {
            CreateCommentsRoundButton,
            Template,
            ChatGPT,
        },

        setup() {
            const commentsState = inject<CreateCommentsState>('commentsState');

            if (!commentsState) {
                throw new Error('commentsState is not provided');
            }

            const toggleAutoComplete = (value: boolean) => {
                commentsState.autoComplete = value;
            };

            const applyTemplate = (template: ITemplate) => {
                commentsState.template = template;
            };

            const applySignature = (signature: ITemplate) => {
                commentsState.signature = signature.id;
            };

            return {
                applyTemplate,
                applySignature,
                commentsState,
                toggleAutoComplete,
                templateTypes,
            };
        },
    });
</script>

<style scoped>
    .create-comments-functions {
        display: flex;
        gap: 8px;
    }

    .disabled {
        pointer-events: none;
        opacity: 0.5;
    }
</style>
