<template>
    <tooltip :content="templateTooltipText">
        <div :style="containerStyle" @click="click">
            <div v-if="brandId" class="logo-inner-container" @click="click">
                <img :src="logo" alt="Brand Logo" :style="imageStyle" />
            </div>

            <div v-else :style="noLogoStyle">No logo</div>
        </div>
    </tooltip>
</template>

<script>
    import { mapActions } from 'vuex';

    export default {
        name: 'BrandLogoTransparent',
        components: {
            SimpleTooltip: () => import('@/components/Global/SimpleTooltip.vue'),
        },
        props: {
            size: {
                type: String,
                default: '50px', // Default size if no size prop is passed
            },
            brandId: {
                type: Number,
                default: null,
            },
            color: {
                type: String,
                default: 'var(--v-gray3-base)',
            },
            borderWidth: {
                type: String,
                default: '2px',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            tooltip: {
                type: Boolean,
                default: false,
            },
            tooltipText: {
                type: String,
                default: '', // Default tooltip text
            },
            resolution: {
                type: String,
                default: '128',
                validator(value) {
                    const resolutions = ['128', '256', '512'];
                    return resolutions.includes(value);
                },
            },
        },
        data() {
            return {
                logo: null,
                brand: null,
            };
        },
        computed: {
            imageStyle() {
                return {
                    width: this.size,
                    height: this.size,
                    objectFit: 'cover',
                };
            },
            containerStyle() {
                return {
                    opacity: this.disabled ? 0.4 : 1, // Lower opacity when disabled
                    transition: 'all 0.1s', // Smooth transition
                    display: 'flex',
                    alignItems: 'center',
                    width: this.size,
                    height: this.size,
                };
            },
            noLogoStyle() {
                return {
                    width: this.size,
                    height: this.size,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'var(--v-gray3-base)',
                    color: 'var(--v-gray7-base)',
                    fontSize: '60%',
                };
            },
            templateTooltipText() {
                return this.tooltipText || this.brand?.name || '';
            },
        },

        created() {
            this.init();
        },
        methods: {
            ...mapActions({
                getBrandLogo: 'Brands/getBrandLogo',
                getBrand: 'Brands/getBrand',
            }),
            async init() {
                if (!this.brandId) return;
                this.logo = await this.getBrandLogo({ brandId: this.brandId, resolution: this.resolution });
                if (this.tooltip && !this.tooltipText) {
                    this.brand = await this.getBrand(this.brandId);
                }
            },
            click(event) {
                this.$emit('click', event);
            },
        },
    };
</script>

<style scoped>
    .logo-inner-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>
