import axios from 'axios';

function getUserNotices() {
    return axios.get('/system/user/notifications');
}

export interface CloseUserNoticePayload {
    notificationId: number;
}

function closeUserNotice(payload: CloseUserNoticePayload) {
    return axios.put('/system/user/notifications', payload);
}

function getSettings(configKeys: string) {
    return axios.get(`/system/configurations/?configKeys=${configKeys}`);
}

export interface UpdateDocPayload {
    name: string;
    keys: Record<
        string,
        {
            DisplayText: string;
            dataType: string;
            includedInSeparateFetch?: boolean;
            isRingable: boolean;
            showInListView: boolean;
            sortingOrder: number;
            userEditable: boolean;
            userLevel: string | number;
            visible?: boolean;
        }
    >;
}

function updateDoc(payload: UpdateDocPayload) {
    return axios.put('/system/settings', payload);
}

function getServerTZOffset() {
    return axios.get('/system/timezone');
}

function getSupportedTimezones() {
    return axios.get('/system/supported-timezones');
}

export interface SaveTimezonePayload {
    timezone: string;
}

function saveTimezone(payload: SaveTimezonePayload) {
    return axios.put('/system/timezone', payload);
}

function getSentryEnabled() {
    return axios.get('/system/sentry-enabled');
}

export default {
    getUserNotices,
    closeUserNotice,
    getSettings,
    updateDoc,
    getServerTZOffset,
    getSupportedTimezones,
    saveTimezone,
    getSentryEnabled,
};
