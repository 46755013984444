<template>
    <div class="relative" :class="dynamic ? 'dynamic' : ''" @mouseenter="showTooltip" @mouseleave="hideTooltip">
        <slot></slot>
    </div>
</template>

<script lang="ts">
    import { defineComponent, onBeforeUnmount, watch, ref } from 'vue';

    export default defineComponent({
        name: 'Tooltip',

        props: {
            content: {
                type: String,
                required: false,
                default: '',
            },
            dynamic: {
                type: Boolean,
                required: false,
                default: false,
            },
            value: {
                type: Boolean,
                required: false,
                default: false,
            },
        },

        data() {
            return {
                position: { x: 0, y: 0 },
                tooltipEl: null as HTMLElement | null,
                timeout: null as number | null,
            };
        },

        methods: {
            createTooltip() {
                if (!this.tooltipEl) {
                    this.tooltipEl = document.createElement('div');
                    this.tooltipEl.className = 'custom-tooltip';
                    this.tooltipEl.innerHTML = this.content;
                    const app = document.getElementById('app');
                    if (app) {
                        app.appendChild(this.tooltipEl);
                    }
                }
            },

            updateTooltipPosition(event: MouseEvent) {
                const tooltip = this.tooltipEl;
                if (tooltip) {
                    const { clientWidth: tooltipWidth, clientHeight: tooltipHeight } = tooltip;
                    const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
                    const { pageX: x, pageY: y } = event;

                    let top = y + 10;
                    let left = x + 10;

                    // Ensure tooltip stays within bounds
                    if (left + tooltipWidth > windowWidth) {
                        left = windowWidth - tooltipWidth - 10;
                    }
                    if (top + tooltipHeight > windowHeight) {
                        top = windowHeight - tooltipHeight - 10;
                    }
                    if (left < 0) {
                        left = 10;
                    }
                    if (top < 0) {
                        top = 10;
                    }

                    tooltip.style.top = `${top}px`;
                    tooltip.style.left = `${left}px`;
                }
            },

            showTooltip(event: MouseEvent) {
                this.$emit('input', true);

                if (this.content === '') {
                    return;
                }
                this.createTooltip();
                this.updateTooltipPosition(event);

                if (this.timeout) {
                    clearTimeout(this.timeout);
                }

                this.timeout = window.setTimeout(() => {
                    if (this.tooltipEl) {
                        this.tooltipEl.style.visibility = 'visible';
                        this.tooltipEl.style.opacity = '1';
                    }
                }, 500);

                window.addEventListener('mousemove', this.updateTooltipPosition);
            },

            hideTooltip() {
                if (this.timeout) {
                    clearTimeout(this.timeout);
                }

                if (this.tooltipEl) {
                    this.tooltipEl.style.visibility = 'hidden';
                    this.tooltipEl.style.opacity = '0';
                }
                this.$emit('input', false);

                window.removeEventListener('mousemove', this.updateTooltipPosition);
            },
        },

        watch: {
            value(newVal) {
                if (!newVal && this.tooltipEl) {
                    this.tooltipEl.style.visibility = 'hidden';
                    this.tooltipEl.style.opacity = '0';
                }
            },

            content(newVal) {
                if (this.tooltipEl) {
                    this.tooltipEl.innerHTML = newVal;
                }
            },
        },

        setup() {
            onBeforeUnmount(() => {
                const tooltipEl = document.querySelector('.custom-tooltip');
                if (tooltipEl && tooltipEl.parentNode) {
                    tooltipEl.parentNode.removeChild(tooltipEl);
                }
            });
        },
    });
</script>

<style>
    .custom-tooltip {
        position: absolute;
        z-index: 9999999;
        background-color: white !important;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 8px 16px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        white-space: normal; /* Change this to normal */
        word-wrap: break-word; /* Allow long words to break and wrap */
        font-size: 12px;
        pointer-events: none;
        opacity: 0;
        visibility: hidden;
        transition:
            opacity 0.2s,
            visibility 0.2s;
        max-width: 200px;
    }

    .dynamic {
        flex: 1;
        width: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
