<template>
    <section class="infinite-list-container">
        <header v-if="items.length !== 0 && headers.length" class="infinite-list-headers" :style="headerStyles">
            <slot name="header">
                <section v-for="header in headers" :key="header.value" class="header-item">
                    {{ header.text }}
                </section>
            </slot>
        </header>

        <section ref="list" class="infinite-list" :class="listClass" @scroll="onScroll">
            <div ref="listContent">
                <TransitionGroup name="move" tag="div" :css="false">
                    <div v-for="item in orderedItems" :key="item.id">
                        <slot name="divider" :item="item"></slot>
                        <slot name="item" :item="item"></slot>
                    </div>
                </TransitionGroup>
            </div>

            <section v-if="orderedItems.length === 0 && !loading" class="infinite-list-empty">
                <EmptyState :text="emptyMessage" :header="emptyHeader" :icon="emptyIcon" />
            </section>
        </section>
    </section>
</template>

<script>
    import { debouncer } from '@/utils';
    import EmptyState from '@/components/Main/List/EmptyState.vue';

    export default {
        name: 'InfiniteList',

        components: {
            EmptyState,
        },

        props: {
            headers: {
                type: Array,
                required: false,
                default: () => [],
            },

            fetchItems: {
                type: Function,
                required: true,
            },

            fetchProps: {
                type: Object,
                default: () => ({}),
            },

            limit: {
                type: Number,
                default: 30,
            },

            emptyMessage: {
                type: String,
                default: 'There are no items to display',
            },

            emptyHeader: {
                type: String,
                default: 'No data found',
            },

            emptyIcon: {
                type: String,
                default: 'mdi-information',
            },

            items: {
                type: Array,
                default: () => [],
            },

            search: {
                type: String,
                default: '',
            },

            size: {
                type: String,
                default: 'medium',
            },

            reverse: {
                type: Boolean,
                default: false,
            },

            fixed: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                page: 1,
                fetchDebouncer: null,
                loading: false,
                loaded: false,
            };
        },

        computed: {
            headerStyles() {
                return {
                    'grid-template-columns': `${this.headers.map((header) => header.width).join(' ')}`,
                };
            },

            listClass() {
                return {
                    'infinite-list-fixed': this.fixed,
                };
            },

            orderedItems() {
                return this.reverse ? this.items.slice().reverse() : this.items;
            },
        },

        watch: {
            search() {
                this.page = 1;
                this.loadItems();
            },
        },

        async mounted() {
            this.fetchDebouncer = debouncer(this.loadItems, 500);
            await this.loadItems();

            if (this.reverse) {
                this.$nextTick(() => {
                    const { list } = this.$refs;
                    list.scrollTop = list.scrollHeight;
                });
            }

            const { list } = this.$refs;
            const { listContent } = this.$refs;

            let count = 0;
            const MAX_ITERATIONS = 5;

            if (!list || !listContent) return;

            while (list.clientHeight >= listContent.clientHeight && !this.loading) {
                count += 1;
                if (count > MAX_ITERATIONS) {
                    console.error('InfiniteList: Too many iterations to fill the list');
                    break;
                }
                const numberOfItems = this.items.length;
                await this.loadItems();
                if (numberOfItems === this.items.length) break;
                await this.$nextTick();

                if (!list || !listContent) return;
            }
        },

        methods: {
            scrollToItemId(id) {
                const index = this.orderedItems.findIndex((item) => item.id === id);
                this.scrollToIndex(index);
            },

            scrollToIndex(index) {
                const section = this.$refs.list;
                const outerDiv = section.querySelector('div');

                if (!outerDiv) return;

                const item = outerDiv.children[index];

                if (!item) return;

                if (this.reverse) {
                    section.scrollTop = item.offsetTop - section.offsetTop - item.clientHeight;
                } else {
                    section.scrollTop = item.offsetTop - section.offsetTop;
                }
            },

            scrollToPosition(position) {
                const { list } = this.$refs;
                list.scrollTop = position;
            },

            async loadItems() {
                if (this.loading) return;
                this.loading = true;
                this.page += 1;
                await this.fetchItems({
                    page: this.page - 1,
                    limit: this.limit,
                    search: this.search,
                    ...this.fetchProps,
                });
                this.loading = false;

                if (this.reverse && !this.loaded) {
                    this.loaded = true;
                    this.$nextTick(() => {
                        const { list } = this.$refs;
                        list.scrollTop = list.scrollHeight;
                    });
                }
            },
            onScroll(e) {
                const { scrollTop, scrollHeight, clientHeight } = e.target;

                if (this.reverse) {
                    if (scrollTop <= 50 && !this.loading) {
                        this.fetchDebouncer();
                    }
                } else {
                    const scrollPercentage = (scrollTop + clientHeight) / scrollHeight;
                    if (scrollPercentage >= 0.8 && !this.loading) {
                        this.fetchDebouncer();
                    }
                }
            },
        },
    };
</script>

<style scoped>
    .infinite-list-container {
        display: flex;
        flex-direction: column;
        height: 0;
        flex: 1;
    }

    .infinite-list {
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        flex: 1;
    }

    .infinite-list-headers {
        display: grid;
        gap: 16px;
        border-bottom: 1px solid #e0e0e0;
        padding: 16px 32px;
        position: relative;
        padding-right: 36px;
    }

    .header-item {
        color: #666;
    }

    .header-item:last-child {
        justify-self: end;
    }

    .infinite-list-empty {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-size: 0.8em;
        color: #666;
        padding: 16px 0;
        flex: 1;
    }

    .move-enter-active,
    .move-leave-active,
    .move-move {
        transition: opacity 0.5s;
    }

    .move-enter,
    .move-leave-to {
        opacity: 0;
    }

    .infinite-list-fixed {
        overflow-y: auto;
        scroll-behavior: smooth;
    }

    .infinite-list-fixed::-webkit-scrollbar {
        display: none;
    }
</style>
