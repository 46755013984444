<template>
    <action-button v-bind="extendedAttrs" class="outlined-btn" @click="handleClick">
        <slot></slot>
    </action-button>
</template>

<script>
    export default {
        name: 'TertiaryActionButton',
        computed: {
            extendedAttrs() {
                return {
                    color: 'var(--v-gray3-base)',
                    ...this.$attrs,
                };
            },
        },

        methods: {
            handleClick(event) {
                this.$emit('click', event);
            },
        },
    };
</script>

<style scoped>
    .outlined-btn {
        background-color: transparent;
        border: 1px solid #ccc;
        color: var(--v-gray2-base);
        flex: 1;
    }
</style>
