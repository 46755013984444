<template>
    <standard-select
        v-bind="selectPropsComputed"
        :items="items"
        :boolean="boolean"
        :dense="dense"
        :value="preSelected"
        :single="true"
        :searchable="true"
        @click="handleClick"
        @change="([item]) => handleChange(item)"
    />
</template>
<script>
    import { mapState, mapActions } from 'vuex';
    import { templateTypes } from '../../../enums/templates.enums';

    export default {
        name: 'SignatureSelect',

        props: {
            boolean: {
                type: Boolean,
                default: false,
            },
            dense: {
                type: Boolean,
                default: true,
            },
            value: {
                type: Object,
                default: () => {},
            },
            queueId: {
                type: Number,
                default: 0,
            },
        },

        data() {
            return {
                selectProps: {},
                queueSignature: null,
            };
        },

        computed: {
            ...mapState('Admin', { signatures: 'signatureTemplates', globalSignatureRaw: 'globalSignature' }),

            selectPropsComputed() {
                return {
                    ...this.selectProps,
                    ...this.$attrs,
                };
            },

            preSelected() {
                // If queueSignature is set, return queueSignature
                if (this.queueSignature) {
                    return this.queueSignature;
                }

                // If globalSignature is set, return globalSignature
                if (this.globalSignature) {
                    return this.globalSignature;
                }

                // If no queueSignature or globalSignature is set, return first signature
                return this.items[0];
            },

            items() {
                const signatures = this.signatures.map((signature) => ({
                    text: signature.Name,
                    value: signature.ID,
                    icon: 'mdi-file-sign',
                    color: 'var(--v-gray2-base)',
                }));
                const noSignature = {
                    text: this.$t('signatureSelect.noSignature'),
                    value: 0,
                    icon: 'mdi-minus-circle-outline',
                    color: 'var(--v-gray2-base)',
                };
                return [noSignature, ...signatures];
            },
            globalSignature() {
                if (!this.globalSignatureRaw?.ID) return null;

                return this.items.find((signature) => this.globalSignatureRaw.ID === signature.value);
            },
        },

        watch: {
            queueId: {
                immediate: true,
                handler() {
                    this.firePreSelect();
                },
            },
        },
        async created() {
            await this.getTemplates(templateTypes.SIGNATURE);
            await this.getGlobalSignature({});
            await this.firePreSelect();
        },

        methods: {
            ...mapActions('Admin', ['getTemplates', 'getGlobalSignature']),
            ...mapActions('QueueManager', ['getQueueDefaultSignature']),

            handleClick(event) {
                this.$emit('click', event);
            },

            handleChange(value) {
                this.$emit('input', value);
                this.$emit('change', value);
            },
            async firePreSelect() {
                await this.getQueueSignature();
                this.handleChange(this.preSelected);
            },

            async getQueueSignature() {
                // Reset queueSignature if queueId is 0
                if (!this.queueId) {
                    this.queueSignature = null;
                    return;
                }

                // Reset queueSignature if no queueSignature is found
                const [queueSignature] = await this.getQueueDefaultSignature(this.queueId);
                if (!queueSignature.ID) {
                    this.queueSignature = null;
                    return;
                }

                this.queueSignature = this.items.find((signature) => queueSignature.ID === signature.value);
            },
        },
    };
</script>

<style scoped></style>

<i18n lang="json">
{
    "en": {
        "signatureSelect": {
            "noSignature": "No signature"
        }
    },
    "sv": {
        "signatureSelect": {
            "noSignature": "Ingen signatur"
        }
    }
}
</i18n>
