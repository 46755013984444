import axios from 'axios';

//= ===================================================//
//                 Main user functions                //
//= ===================================================//

export interface CreateUserPayload {
    type: string;
    swyxCreationState: boolean;
    oldSwyxCreationState: boolean;
    login: string;
    password: string;
    image: string;
    title: string;
    description: string;
    name: string;
    email: string;
    internalNumber: number;
    publicNumber: string;
    roles: number[];
    groups: number[];
    templateUserId: number;
    userId: number;
    credentialChange: boolean;
}

function addUser(payload: CreateUserPayload) {
    return axios.post('/users', payload);
}
function deleteUser(userId: number) {
    return axios.delete(`/users/${userId}`);
}

//= ===================================================//
//                    Get user data                   //
//= ===================================================//

function getUserDataToEdit(userId: number) {
    return axios.get(`/users/${userId}/edit`);
}
function getNextInternalNumber() {
    return axios.get('/swyx/internalnumbers/next');
}

function getAssignedInternalNumbers() {
    return axios.get('/swyx/internalnumbers');
}

function getPublicNumberSuggestions(number: number) {
    return axios.get(`/swyx/publicnumbers/suggestions?internalNumber=${number}
        `);
}

function getSwyxTrunks() {
    return axios.get('/swyx/trunks');
}

function getSwyxUsers() {
    return axios.get('/swyx/users');
}

function getNumberRanges(trunkIds: number[]) {
    return axios.get(`/swyx/trunk/numberranges?ids=${trunkIds}`);
}

function getSwyxSystemSettings() {
    return axios.get('/swyx/systemsettings');
}

//= ===================================================//
//                 User validations                   //
//= ===================================================//

function checkSwyxInternalNumberValidation(number: number) {
    return axios.get(`/swyx/validate/internalnumber?internalNumber=${number}`);
}
function checkSwyxPublicNumberValidation(number: number) {
    return axios.get(`/swyx/publicnumber/validate?publicNumber=${encodeURIComponent(number)}`);
}

function checkUserNameValidation(name: string) {
    return axios.get(`/swyx/validate/name?name=${name}`);
}

function checkEmailValidation(name: string) {
    return axios.get(`/swyx/validate/email?email=${name}`);
}

//= ===================================================//
//                        ROLES                       //
//= ===================================================//

function getRoles() {
    return axios.get('/roles');
}

export interface SaveRoleChangePayload {
    name: string;
    userId: number;
    roleId: number;
    roleValue: boolean;
}

function saveRoleChange(payload: SaveRoleChangePayload) {
    return axios.put('/roles', payload);
}

//= ===================================================//
//                     Day Calling                     //
//= ===================================================//

function getUserDayCalling(userId: number) {
    return axios.get(`/users/${userId}/daycalling`);
}

function saveUserDayCalling(userId: number, days: number[]) {
    return axios.put(`/users/${userId}/daycalling`, days);
}

export default {
    addUser,
    deleteUser,
    checkSwyxPublicNumberValidation,
    getAssignedInternalNumbers,
    getNextInternalNumber,
    getSwyxTrunks,
    getNumberRanges,
    getPublicNumberSuggestions,
    checkSwyxInternalNumberValidation,
    getUserDataToEdit,
    checkUserNameValidation,
    checkEmailValidation,
    saveRoleChange,
    getRoles,
    getSwyxUsers,
    getSwyxSystemSettings,
    getUserDayCalling,
    saveUserDayCalling,
};
