<template>
    <div id="language-container" :class="{ disabled: disabled }">
        <standard-select v-model="modelValue" :items="filteredLanguages" v-bind="$attrs" outlined searchable right />
    </div>
</template>
<script>
    import { languages } from '@/helpers/countries/countries.helper';

    export default {
        name: 'LanguageSelect',
        props: {
            value: {
                type: String,
                default: null,
            },
            filter: {
                type: Array,
                default: null,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['input'],
        data() {
            return {
                languages,
            };
        },
        computed: {
            modelValue: {
                get() {
                    return {
                        id: this.value,
                        value: this.value,
                        text: this.value ? this.$t(`language.${this.value}`) : this.$t('languageSelect.noLanguage'),
                        icon: this.value ? 'mdi-flag' : 'mdi-close',
                    };
                },
                set(value) {
                    this.$emit('input', value);
                },
            },

            filterSet() {
                return new Set(this.filter);
            },

            filteredLanguages() {
                const languages = [{ code: null }, ...this.languages];

                if (!this.filter) {
                    return languages.map((lang) => {
                        return {
                            id: lang.code,
                            value: lang.code,
                            text: lang.code ? this.$t(`language.${lang.code}`) : this.$t('languageSelect.noLanguage'),
                            icon: lang.code ? 'mdi-flag' : 'mdi-close',
                        };
                    });
                }

                return languages
                    .filter((lang) => this.filterSet.has(lang.code))
                    .map((lang) => {
                        return {
                            id: lang.code,
                            value: lang.code,
                            text: lang.code ? this.$t(`language.${lang.code}`) : this.$t('languageSelect.noLanguage'),
                            icon: lang.code ? 'mdi-flag' : 'mdi-close',
                        };
                    });
            },
        },
    };
</script>
<style scoped>
    .disabled {
        pointer-events: none;
        opacity: 0.5;
    }
</style>
<i18n lang="json">
{
    "sv": {
        "languageSelect": {
            "noLanguage": "Inget språk",
            "noLanguageFound": "Inga språk hittades"
        }
    },
    "en": {
        "languageSelect": {
            "noLanguage": "No language",
            "noLanguageFound": "No language found"
        }
    }
}
</i18n>
