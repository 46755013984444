<template>
    <v-menu v-model="menuOpen" :close-on-content-click="false" transition="scale-transition" offset-y>
        <template #activator="{}">
            <tertiary-action-button
                :dense="false"
                :disabled="selectPropsComputed?.disabled"
                @click="menuOpen = !menuOpen"
            >
                <article v-if="selectedItems.length > 0" class="item-text item">
                    <div class="avatar-item">
                        <CommonAvatar :key="selectedItem.value" :userId="selectedItem.value" :size="20" class="mr-3" />
                        <v-list-item-title class="item-text">{{ selectedItem.Name }}</v-list-item-title>
                    </div>

                    <v-icon size="18" class="ml-2" color="var(--v-gray2-base)">mdi-chevron-down</v-icon>
                </article>
                <article v-else class="display-item item">
                    <div class="select-item">
                        {{ $t('selects.selectItems') }}
                    </div>
                    <v-icon size="18" class="ml-2" color="var(--v-gray2-base)">mdi-chevron-down</v-icon>
                </article>
            </tertiary-action-button>
        </template>

        <div>
            <header>
                <search-input ref="search-input" :value="input" @input="setSearch" :autofocus="true" />
            </header>

            <section :key="input" ref="scrollContainer" class="user-list" @scroll="checkScroll">
                <button
                    v-for="(item, index) in users.data"
                    :key="index"
                    :class="['list-item', { 'selected-item': isSelected(item) }]"
                    @click="selectItem(item)"
                >
                    <div class="list-item-icon">
                        <CommonAvatar :key="item.UserID" :userId="item.UserID" :size="20" />
                    </div>
                    <div>
                        <div :class="isSelected(item) ? 'item-text' : 'item-text-not-selected'">
                            {{ item.Name }}
                        </div>
                    </div>
                </button>
            </section>
        </div>
    </v-menu>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import { debouncer } from '@/utils';
    import CommonAvatar from '@/components/Global/CommonAvatar.vue';

    export default {
        name: 'UserSelect',

        components: {
            CommonAvatar,
        },
        props: {
            showExtra: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                selectProps: {
                    rounded: true,
                    color: 'var(--v-gray3-base)',
                    filled: true,
                    dense: true,
                    hideDetails: true,
                },

                loading: false,
                input: '',
                page: 1,
                users: {},
                menuOpen: false,
                selectedItems: [],
                hasMore: false,
                children: [],
            };
        },

        computed: {
            ...mapState('Auth', ['userObject']),

            selectPropsComputed() {
                return {
                    ...this.selectProps,
                    ...this.$attrs,
                };
            },

            selectedItem() {
                return this.selectedItems.at(0);
            },
        },

        watch: {
            menuOpen() {
                if (this.menuOpen) {
                    this.setSearch('');
                    this.addEventListeners();
                    return;
                }
                this.removeEventListeners();
            },
        },

        created() {
            this.init();
            this.setSelectedItems();
        },

        methods: {
            ...mapActions('Users', {
                getUsersAndRoles: 'getUsersAndRoles',
            }),

            setSelectedItems() {
                try {
                    this.selectedItems = [
                        {
                            UserID: this.userObject.userId,
                            Name: this.userObject.userName,
                            value: this.userObject.userId,
                        },
                    ];

                    this.handleChange({ value: this.userObject.userId });
                } catch (error) {
                    console.error('Error setting selected user:', error);
                }
            },

            setSearch(input) {
                this.input = input;
                this.fetchUsers(1).then((users) => {
                    this.users = users;
                });
            },

            async init() {
                // eslint-disable-next-line new-cap
                this.debouncedSearch = new debouncer(async () => {
                    this.page = 1;
                    await this.getData();
                }, 500);

                this.users = await this.fetchUsers();
            },

            checkScroll() {
                const container = this.$refs.scrollContainer;
                const { scrollHeight } = container;
                const { scrollTop } = container;
                const { clientHeight } = container;

                const PAGINATION_OFFSET = 5;

                if (Math.floor(scrollHeight - scrollTop) - PAGINATION_OFFSET > clientHeight || !this.hasMore) {
                    return;
                }

                this.page++;
                this.fetchUsers(this.page).then((users) => {
                    this.users.data = [...this.users.data, ...users.data];
                    this.hasMore = users.hasMore;
                });
            },

            async getData() {
                this.loading = true;
                const { data } = await this.fetchUsers(1);
                this.users = data;
                this.loading = false;
            },

            async fetchUsers(page = 1) {
                const users = await this.getUsersAndRoles({
                    search: this.input,
                    page,
                    selfFirst: true,
                });

                if (page === 1 && !this.input && this.$attrs.hideUnassigned === undefined) {
                    users.data.unshift({ UserID: 0, Name: this.$t('users.unassigned') });
                }

                this.hasMore = users.hasMore;
                this.$nextTick(() => {
                    this.updateChildren();
                });
                return {
                    hasMore: users.hasMore,
                    data: users.data.map((user) => {
                        return {
                            ...user,
                            value: user.UserID,
                        };
                    }),
                };
            },

            selectItem(item) {
                this.selectedItems = [item];
                this.handleChange(item);
                // Close menu on selecting a user
                this.menuOpen = false;
            },

            isSelected(item) {
                return this.selectedItems.some((selectedItem) => selectedItem.value === item.value);
            },

            handleChange(user) {
                this.$emit('input', user.value);
                this.$emit('change', user.value);
            },
            addEventListeners() {
                document.addEventListener('keydown', this.handleKeyDown);
                this.$nextTick(() => {
                    this.updateChildren();
                });
            },
            removeEventListeners() {
                document.removeEventListener('keydown', this.handleKeyDown);
                this.children = [];
            },
            updateChildren() {
                const ref = this.$refs.scrollContainer;
                if (!ref) return;
                this.children = ref.children;
            },
            handleKeyDown(event) {
                const data = this.children;
                const focused = document.activeElement;
                const index = Array.from(data).indexOf(focused);

                const keys = ['ArrowDown', 'ArrowUp', 'Tab'];

                if (!keys.includes(event.key) || !data.length) return;

                if (event.key === 'ArrowDown' || event.key === 'Tab') {
                    event.preventDefault();
                    if (index < data.length - 1) {
                        data[index + 1].focus();
                    } else {
                        data[0].focus();
                    }
                }

                if (event.key === 'ArrowUp' || (event.key === 'Tab' && event.shiftKey)) {
                    event.preventDefault();
                    if (index > 0) {
                        data[index - 1].focus();
                    } else {
                        data[data.length - 1].focus();
                    }
                }
                this.checkScroll();
            },
        },
    };
</script>

<style scoped>
    :deep(.v-input__prepend-inner) {
        align-self: center;
        margin: 0px !important;
        padding: 0px !important;
        white-space: nowrap !important;
    }

    div[role='menu'] {
        border-radius: 8px;
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2) !important;
        border: 1px solid #eee;
        padding: 4px;
        background-color: var(--v-gray3-base);
        min-width: 240px !important;
        max-width: 240px !important;
    }

    .select-item {
        display: flex;
        align-items: center;
        flex-direction: row;
    }

    .avatar-item {
        display: flex;
        align-items: center;
        flex-direction: row;
    }

    .user-list {
        max-height: 295px;
        overflow-y: auto;
    }

    .item {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        flex: 1;
    }
    .item-text {
        font-size: 0.9rem !important;
        font-weight: 400 !important;
        color: var(--v-gray2-base);
    }

    .item-text-not-selected {
        font-size: 0.9rem !important;
        font-weight: 400 !important;
        color: var(--v-gray2-base);
    }
    .list-item {
        display: flex;
        padding: 0;
        padding-left: 8px;
        padding-right: 24px;
        margin-top: 4px;
        height: 48px;
        background-color: var(--v-gray3-base);
        margin-bottom: 1px;
        width: 100%;
        text-align: left;
        cursor: pointer;
        align-items: center;
        outline: none;
    }
    .list-item:hover {
        background-color: var(--v-gray3-darken1);
    }
    .list-item:focus {
        background-color: var(--v-gray3-darken1);
        border: none !important;
    }
    .list-item-icon {
        margin-right: 8px;
        align-self: center;
        justify-content: flex-start;
    }
    .selected-item {
        background-color: white;
    }

    .display-item {
        display: flex;
        align-items: center;
        flex-direction: row;
        font-size: 0.9rem !important;
        font-weight: 400 !important;
        color: var(--v-gray2-base);
    }

    :focus {
        border: 1px solid var(--v-primary-base) !important;
    }
</style>

<i18n lang="json">
{
    "en": {
        "users": {
            "all": "All",
            "unassigned": "Unassigned"
        },

        "selects": {
            "selectItems": "Select",
            "allItemsSelected": "All",
            "itemsSelected": "selected"
        }
    },
    "sv": {
        "users": {
            "all": "Alla",
            "unassigned": "Ej tilldelade"
        },

        "selects": {
            "selectItems": "Välj",
            "allItemsSelected": "Alla",
            "itemsSelected": " valda"
        }
    }
}
</i18n>
