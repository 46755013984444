<template>
    <span class="date">{{ formatDateToText(date) }}</span>
</template>
<script lang="ts">
    import { formatDateToText } from '@/utils/DateFormatter';
    import { defineComponent } from 'vue';

    export default defineComponent({
        props: {
            date: {
                type: String,
                required: true,
            },
        },
        methods: {
            formatDateToText,
        },
    });
</script>
<style scoped>
    .date {
        font-size: 12px;
        font-weight: 400;
        color: var(--v-gray1-darken4);
    }
</style>
