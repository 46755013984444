<template>
    <main>
        <v-menu
            class="client-tooltip-menu"
            v-model="menuOpen"
            :nudge-top="100"
            open-on-hover
            :nudge-right="100"
            :close-delay="200"
        >
            <template #activator="{ on, attrs }">
                <v-btn class="client-tooltip-button" width="30" height="30" elevation="0" fab v-bind="attrs" v-on="on">
                    <v-icon color="var(--v-gray2-base)" size="18">mdi-menu</v-icon>
                </v-btn>
            </template>
            <template #default>
                <v-card class="client-tooltip-card" elevation="0">
                    <ClientFamilySearch v-if="menuOpen && item.clientId" :clientId="item.clientId" />

                    <section class="client-tooltip-grid">
                        <article
                            v-for="article in filteredFields"
                            :key="article"
                            :style="articleStyle(article)"
                            class="client-tooltip-article"
                        >
                            <label for="article">{{ labelTranslation(article) }}</label>
                            <div id="article" class="article-truncate" :style="articleBoxStyle(article)">
                                {{ fieldValue(article) }}
                                <v-icon
                                    v-if="isMergeClientForm && isAdded(article)"
                                    color="var(--v-gray4-base)"
                                    size="14"
                                >
                                    mdi-plus
                                </v-icon>
                            </div>
                        </article>
                    </section>
                </v-card>
            </template>
        </v-menu>
    </main>
</template>

<script>
    import ClientFamilySearch from '@/components/Search/Clients/ClientFamilySearch.vue';
    import { mapState } from 'vuex';
    import { formTypes } from '@/enums/creator.enums';

    export default {
        name: 'ClientTooltip',
        components: {
            ClientFamilySearch,
        },
        props: {
            item: {
                type: Object,
                default: () => ({}),
            },
            visibleFields: {
                type: Array,
                default: () => [],
            },
        },
        data() {
            return {
                menuOpen: false,
                formTypes,
            };
        },
        computed: {
            ...mapState({
                currentSelectedClient: (state) => state.Client.currentSelectedClient,
                currentForm: (state) => state.Creator.currentForm.id,
            }),
            visibleFieldsSet() {
                return new Set(this.visibleFields);
            },
            filteredFields() {
                if (this.visibleFields.length === 0) {
                    return Object.keys(this.item);
                }
                return Object.keys(this.item).filter((key) => this.visibleFieldsSet.has(key));
            },
            isMergeClientForm() {
                return this.currentForm === formTypes.MERGE_CLIENT;
            },
        },
        methods: {
            labelTranslation(key) {
                return this.$t(`label.${key}`);
            },
            isAdded(key) {
                return this.currentSelectedClient[key] && !this.item[key];
            },
            fieldValue(article) {
                return this.isMergeClientForm
                    ? this.item[article] || this.currentSelectedClient[article]
                    : this.item[article];
            },
            articleBoxStyle(article) {
                return {
                    backgroundColor: this.isAdded(article) ? 'var(--v-gray3-base)' : 'transparent',
                };
            },
            articleStyle(key) {
                if (this.currentSelectedClient[key] && !this.item[key]) {
                    return {
                        color: 'var(--v-gray4-base) !important',
                    };
                }

                if (this.item[key] === this.currentSelectedClient[key]) {
                    return {
                        color: 'var(--v-gray4-base) !important',
                    };
                }

                return {
                    color: 'var(--v-gray1-base) !important',
                };
            },
        },
    };
</script>

<style scoped>
    .client-tooltip-menu {
        top: 371px !important;
    }

    .client-tooltip-card {
        gap: 16px;
    }

    .client-tooltip-button {
        background-color: transparent !important;
    }

    .client-tooltip-grid {
        padding: 1em;
        display: grid;
        grid-template-columns: 200px 200px;
        gap: 8px;
    }

    .client-tooltip-grid > * {
        min-height: 32px;
    }

    .client-tooltip-article {
        display: grid;
        grid-template-columns: 1fr;
        gap: 4px;
        font-size: 0.8rem;
    }

    .client-tooltip-article label {
        color: var(--v-gray4-base);
    }

    .conflicted-fields {
        font-size: 0.8rem;
        color: black;
        font-weight: bold;
        padding-bottom: 8px;
    }

    .article-truncate {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border: 1px solid var(--v-gray3-base);
        padding: 8px;
        border-radius: 4px;
        min-height: 36px;
        max-height: 36px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1;
    }

    .client-tooltip-information {
        font-size: 0.8rem;
        color: var(--v-gray4-base);
        padding-top: 16px;
        padding-bottom: 0px;
        max-width: 200px;
    }
</style>
