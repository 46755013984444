<template>
    <div class="comments" :style="commentStyle" :class="commentClass">
        <!-- Call Header -->
        <header class="comment-section">
            <div class="avatar-wrapper">
                <div class="comment-avatar">
                    <InternalType :absolute="false" :item="{ internalType: 'call' }" />
                    <v-tooltip bottom>
                        <template #activator="{ on }">
                            <div v-on="on">
                                <CommonAvatar v-if="item.userId" :userId="item.userId" :size="32" />
                                <SystemAvatar v-else :size="32" />
                            </div>
                        </template>
                        <span>{{ username }}</span>
                    </v-tooltip>
                </div>
            </div>

            <div class="comment-from text-truncate">{{ item.from }}</div>

            <div class="comment-top-field" :class="item.outgoing ? 'outgoing-chip' : 'incoming-chip'">
                <v-progress-circular
                    v-if="!item.sent"
                    class="comment-top-field"
                    color="var(-v-gray2-base)"
                    indeterminate
                    size="10"
                    width="2"
                />
                {{ commentDirection }}
            </div>

            <div class="top-field">{{ nationalIdentityNumberText }}</div>
            <div class="date">{{ formatDate(item.dateTime) }}</div>
        </header>

        <CallRecording :item="item" />
    </div>
</template>
<script>
    import { format } from 'date-fns';
    import { mapState, mapActions } from 'vuex';
    import { getDay } from '@/utils/DateFormatter';
    import { httpStatusCode } from '@/enums/response.enums';

    import config from '@/app/config';
    import InternalType from '@/components/Cases/List/InternalType.vue';
    import SystemAvatar from '@/components/Global/SystemAvatar.vue';
    import CommonAvatar from '../../Global/CommonAvatar.vue';
    import CallRecording from './CallRecording.vue';

    export default {
        components: {
            InternalType,
            SystemAvatar,
            CommonAvatar,
            CallRecording,
        },

        props: {
            item: {
                type: Object,
                default: () => {},
            },
        },

        data() {
            return {
                config,
                loaded: false,
                key: 'value',
            };
        },
        computed: {
            ...mapState({
                userSettings: (state) => state.System.userSettings,
            }),
            username() {
                if (!this.item.userId) {
                    return this.$t('comment.system');
                }
                if (this.item?.agentDeleted) {
                    return this.$t('global.deletedUser');
                }
                return this.item.userName;
            },
            commentStyle() {
                return {
                    justifySelf: this.item.outgoing ? 'end' : 'start',
                };
            },

            commentClass() {
                return {
                    outgoing: this.item.outgoing,
                    incoming: !this.item.outgoing,
                };
            },

            commentDirection() {
                // handle sent
                if (!this.item.sent) {
                    return this.$t('comment.sending');
                }
                return this.item.outgoing ? this.$t('comment.outgoing') : this.$t('comment.incoming');
            },

            nationalIdentityNumberText() {
                const [flag, type, value] = this.item.comment.split(':');
                if (flag !== 'NATIONAL_IDENTITY_NUMBER' || !type || !value) return this.item.comment;
                return this.$t(`calls.nationalIdentityNumbers.${type}`, [value]);
            },
        },

        methods: {
            formatDate(dateTime) {
                if (!this.userSettings.cases.showDate.active) {
                    return getDay(dateTime);
                }

                return getDay(dateTime) + ' (' + format(new Date(dateTime), 'yyyy-MM-dd') + ')';
            },

            getDay,
            format,
        },
    };
</script>
<style scoped lang="scss">
    .comment-transcript {
        font-size: 0.8rem;
        color: var(--v-gray2-base);
    }

    .comment-section {
        display: grid;
        padding-bottom: 16px;
        grid-template-areas:
            'avatar comment-from comment-top-field'
            'avatar top-field date';

        gap: 8px;
        grid-template-columns: 0.5fr 2fr 1fr;
    }

    .comment-avatar {
        grid-area: avatar;
        display: flex;
        flex-direction: row;
        gap: 8px;
    }

    .comment-from {
        grid-area: comment-from;
        max-height: 25px;
        display: flex;
        padding-left: 14%;
    }

    .comment-top-field.incoming-chip {
        grid-area: comment-top-field;
        max-height: 20px;
        max-width: 87px;
        display: flex;
        justify-self: flex-end;
    }

    .top-field {
        grid-area: top-field;
        font-size: 12px;
        color: var(--v-gray2-base);
        margin-top: -10px;
        padding-left: 14%;
    }

    .date {
        grid-area: date;
        display: flex;
        justify-self: flex-end;
        align-self: flex-end;
        justify-content: flex-end;
        font-size: 12px;
        color: var(--v-gray2-base);
        margin-top: -10px;
    }

    .comments {
        padding: 32px;
        padding-bottom: 16px;
        border-radius: 12px;
        width: 100%;
        max-width: 800px;
        gap: 4px;
        background-color: rgba(245, 245, 247, 0.53);
        font-size: 1rem;
        border: 1px solid rgba(0, 0, 0, 0.05);
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
    }
    .comment-header {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 8px;
    }

    .comment-info {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr auto;
        font-size: 0.8rem;
        color: var(--v-gray2-base);
    }

    .comment-date {
        justify-self: right;
    }

    @media (max-width: 1349px) {
        .comment-section {
            grid-template-areas:
                'avatar date'
                'top-field .'
                'comment-from .'
                'comment-top-field comment-top-field';
            display: grid;
            gap: 8px;
            grid-template-columns: 1fr 1fr;
        }

        .comment-from {
            grid-area: comment-from;
            grid-column: 1 / span 2;
            font-size: 12px;
            display: flex;
            justify-self: center;
            justify-content: center;
            width: 150px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: 14%;
        }

        .date {
            grid-area: date;
            min-width: 100px;
            display: flex;
            font-size: 12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .comment-top-field {
            grid-area: comment-top-field;
            display: flex;
            justify-self: center !important;
        }

        .top-field {
            grid-area: top-field;
            grid-column: 1 / span 2;
            font-size: 12px;
            display: flex;
            justify-self: center;
            margin-top: 5%;
            padding-right: 14%;
        }

        .date-from {
            margin-left: 0;
        }

        .comment-avatar {
            color: white;
            border: 0px solid var(--v-accent2-base);
            margin-right: 8px;
            gap: 8px;
            grid-area: avatar;
        }
    }
    @media (min-width: 1350px) and (max-width: 1550px) {
        .comment-section {
            display: grid;
            grid-template-areas:
                'avatar date'
                '. top-field'
                '. comment-top-field'
                '. comment-from';
            grid-template-columns: 1fr 3fr;
        }

        .date {
            grid-area: date;
            display: flex !important;
            align-items: flex-end !important;
            font-size: 12px;
            padding-bottom: 10px;
        }

        .comment-top-field {
            grid-area: comment-top-field;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        .comment-avatar {
            color: white;
            border: 0px solid var(--v-accent2-base);
            margin-right: 8px;
            max-height: 40px;
            gap: 8px;
            grid-area: avatar;
        }
        .top-field {
            min-width: 90%;
            grid-area: top-field;
            font-size: 12px;
            display: flex;
            justify-content: flex-end;
            padding-bottom: -10px;
        }

        .comment-from {
            grid-area: comment-from;
            font-size: 12px;
            display: flex;
            justify-content: flex-end;
        }
    }
</style>

<i18n lang="json">
{
    "en": {
        "comment": {
            "to": "To:",
            "outgoing": "Outgoing",
            "incoming": "Incoming",
            "sending": "Sending"
        },
        "calls": {
            "actions": "Actions",
            "call": "Call",
            "recordingStartedAt": "Recording started at: {created}",
            "inbound": "Inbound call",
            "outbound": "Outbound call",
            "nationalIdentityNumbers": {
                "swedishPersonalIdentityNumber": "Swedish personal identity number: {0}",
                "norwegianNationalIdentityNumber": "Norwegian national identity number: {0}"
            }
        }
    },
    "sv": {
        "comment": {
            "to": "Till:",
            "outgoing": "Utgående",
            "incoming": "Inkommande",
            "sending": "Skickar"
        },
        "calls": {
            "actions": "Funktioner",
            "call": "Telefonsamtal",
            "recordingStartedAt": "Inspelning startade: {created}",
            "inbound": "Inkommande samtal",
            "outbound": "Utgående samtal",
            "nationalIdentityNumbers": {
                "swedishPersonalIdentityNumber": "Svenskt personnummer: {0}",
                "norwegianNationalIdentityNumber": "Norskt födelsenummer: {0}"
            }
        }
    }
}
</i18n>
