<template>
    <div class="c1-list" :style="{ maxHeight: maxHeight, overflowY: 'auto' }">
        <template v-if="items.length">
            <c1-menu-item
                v-for="(item, index) in items"
                :key="index"
                :active="item.active"
                @click="() => $emit('item-click', item)"
            >
                <template v-if="item.icon" #icon>
                    <span class="c1-list__icon">
                        <i class="mdi" :class="item.icon"></i>
                    </span>
                </template>
                {{ item.text }}
            </c1-menu-item>
        </template>
        <slot v-else></slot>
    </div>
</template>

<script lang="ts">
    import { defineComponent, PropType } from 'vue';

    export default defineComponent({
        name: 'C1List',

        props: {
            items: {
                type: Array as PropType<{ text: string; icon?: any; active?: boolean }[]>,
                default: () => [],
            },
            maxHeight: {
                type: String,
                default: '264px',
            },
        },
        emits: ['item-click'],
    });
</script>

<style scoped>
    .c1-list {
        display: flex;
        flex-direction: column;
    }

    .c1-list__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        font-size: 16px;
    }
</style>
