<template>
    <section>
        <transition name="fade">
            <div v-if="show" class="loader-overlay">
                <v-progress-circular indeterminate color="var(--v-gray2-base)" />
            </div>
        </transition>

        <section>
            <slot></slot>
        </section>
    </section>
</template>

<script>
    export default {
        name: 'LoaderOverlay',

        props: {
            show: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>

<style scoped>
    .loader-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.5);
        z-index: 1000;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }
</style>
