<template>
    <standard-input v-bind="extendedAttrs" @input="handleInput" @change="handleChange" @blur="handleBlur">
        <slot></slot>
    </standard-input>
</template>

<script>
    export default {
        name: 'SecondaryInput',

        data() {
            return {
                buttonProps: {
                    outlined: true,
                    color: 'var(--v-gray2-base)',
                },
            };
        },

        computed: {
            extendedAttrs() {
                return {
                    ...this.buttonProps,
                    ...this.$attrs,
                };
            },
        },

        methods: {
            handleClick() {
                this.$emit('click');
            },
            handleInput(value) {
                this.$emit('input', value);
            },
            handleChange(value) {
                this.$emit('change', value);
            },
            handleBlur(value) {
                this.$emit('blur', value);
            },
        },
    };
</script>

<style scoped>
    .search-input {
        border: 1px solid #eee;
        border-radius: 8px;
        padding: 0px;
        margin-bottom: 4px;
    }

    :deep(.v-input__control) {
        min-height: 40px !important;
    }
</style>
