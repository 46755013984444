export const tiptapStates = {
    CLOSED: 'closed',
    OPEN: 'open',
    FULL: 'full',
} as const;

export type TiptapStates = (typeof tiptapStates)[keyof typeof tiptapStates];

export const contentTypes = {
    TEXT: 'text',
    HTML: 'html',
} as const;

export type ContentTypes = (typeof contentTypes)[keyof typeof contentTypes];
