<template>
    <section class="empty-container">
        <i v-if="icon" class="mdi" :class="icon"></i>
        <span class="empty-text-header">{{ header }}</span>
        <span class="empty-text">{{ text }}</span>
    </section>
</template>

<script lang="ts">
    import type { PropType } from 'vue';
    import { TranslateResult } from 'vue-i18n';

    export default {
        name: 'EmptyState',

        props: {
            text: {
                type: String as PropType<string | TranslateResult>,
                required: true,
            },

            header: {
                type: String as PropType<string | TranslateResult>,
                default: '',
            },

            icon: {
                type: String,
                default: 'mdi-information',
            },
        },
    };
</script>

<style scoped>
    .empty-text {
        color: #6c6c6c;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
    }

    .empty-text-header {
        color: black;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
    }

    .empty-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4px;
        padding-top: 16px;
        padding-bottom: 32px;
    }

    .mdi {
        font-size: 40px;
        color: #aaa;
    }
</style>
