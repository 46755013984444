export const countries = [
    { code: 'AF' },
    { code: 'AX' },
    { code: 'AL' },
    { code: 'DZ' },
    { code: 'AS' },
    { code: 'AD' },
    { code: 'AO' },
    { code: 'AI' },
    { code: 'AQ' },
    { code: 'AG' },
    { code: 'AR' },
    { code: 'AM' },
    { code: 'AW' },
    { code: 'AU' },
    { code: 'AT' },
    { code: 'AZ' },
    { code: 'BS' },
    { code: 'BH' },
    { code: 'BD' },
    { code: 'BB' },
    { code: 'BY' },
    { code: 'BE' },
    { code: 'BZ' },
    { code: 'BJ' },
    { code: 'BM' },
    { code: 'BT' },
    { code: 'BO' },
    { code: 'BQ' },
    { code: 'BA' },
    { code: 'BW' },
    { code: 'BV' },
    { code: 'BR' },
    { code: 'IO' },
    { code: 'BN' },
    { code: 'BG' },
    { code: 'BF' },
    { code: 'BI' },
    { code: 'CV' },
    { code: 'KH' },
    { code: 'CM' },
    { code: 'CA' },
    { code: 'KY' },
    { code: 'CF' },
    { code: 'TD' },
    { code: 'CL' },
    { code: 'CN' },
    { code: 'CX' },
    { code: 'CC' },
    { code: 'CO' },
    { code: 'KM' },
    { code: 'CG' },
    { code: 'CD' },
    { code: 'CK' },
    { code: 'CR' },
    { code: 'HR' },
    { code: 'CU' },
    { code: 'CW' },
    { code: 'CY' },
    { code: 'CZ' },
    { code: 'DK' },
    { code: 'DJ' },
    { code: 'DM' },
    { code: 'DO' },
    { code: 'EC' },
    { code: 'EG' },
    { code: 'SV' },
    { code: 'GQ' },
    { code: 'ER' },
    { code: 'EE' },
    { code: 'SZ' },
    { code: 'ET' },
    { code: 'FK' },
    { code: 'FO' },
    { code: 'FJ' },
    { code: 'FI' },
    { code: 'FR' },
    { code: 'GF' },
    { code: 'PF' },
    { code: 'TF' },
    { code: 'GA' },
    { code: 'GM' },
    { code: 'GE' },
    { code: 'DE' },
    { code: 'GH' },
    { code: 'GI' },
    { code: 'GR' },
    { code: 'GL' },
    { code: 'GD' },
    { code: 'GP' },
    { code: 'GU' },
    { code: 'GT' },
    { code: 'GG' },
    { code: 'GN' },
    { code: 'GW' },
    { code: 'GY' },
    { code: 'HT' },
    { code: 'HM' },
    { code: 'VA' },
    { code: 'HN' },
    { code: 'HK' },
    { code: 'HU' },
    { code: 'IS' },
    { code: 'IN' },
    { code: 'ID' },
    { code: 'IR' },
    { code: 'IQ' },
    { code: 'IE' },
    { code: 'IM' },
    { code: 'IL' },
    { code: 'IT' },
    { code: 'JM' },
    { code: 'JP' },
    { code: 'JE' },
    { code: 'JO' },
    { code: 'KZ' },
    { code: 'KE' },
    { code: 'KI' },
    { code: 'KP' },
    { code: 'KR' },
    { code: 'KW' },
    { code: 'KG' },
    { code: 'LA' },
    { code: 'LV' },
    { code: 'LB' },
    { code: 'LS' },
    { code: 'LR' },
    { code: 'LY' },
    { code: 'LI' },
    { code: 'LT' },
    { code: 'LU' },
    { code: 'MO' },
    { code: 'MG' },
    { code: 'MW' },
    { code: 'MY' },
    { code: 'MV' },
    { code: 'ML' },
    { code: 'MT' },
    { code: 'MH' },
    { code: 'MQ' },
    { code: 'MR' },
    { code: 'MU' },
    { code: 'YT' },
    { code: 'MX' },
    { code: 'FM' },
    { code: 'MD' },
    { code: 'MC' },
    { code: 'MN' },
    { code: 'ME' },
    { code: 'MS' },
    { code: 'MA' },
    { code: 'MZ' },
    { code: 'MM' },
    { code: 'NA' },
    { code: 'NR' },
    { code: 'NP' },
    { code: 'NL' },
    { code: 'NC' },
    { code: 'NZ' },
    { code: 'NI' },
    { code: 'NE' },
    { code: 'NG' },
    { code: 'NU' },
    { code: 'NF' },
    { code: 'MK' },
    { code: 'MP' },
    { code: 'NO' },
    { code: 'OM' },
    { code: 'PK' },
    { code: 'PW' },
    { code: 'PS' },
    { code: 'PA' },
    { code: 'PG' },
    { code: 'PY' },
    { code: 'PE' },
    { code: 'PH' },
    { code: 'PN' },
    { code: 'PL' },
    { code: 'PT' },
    { code: 'PR' },
    { code: 'QA' },
    { code: 'RE' },
    { code: 'RO' },
    { code: 'RU' },
    { code: 'RW' },
    { code: 'BL' },
    { code: 'SH' },
    { code: 'KN' },
    { code: 'LC' },
    { code: 'MF' },
    { code: 'PM' },
    { code: 'VC' },
    { code: 'WS' },
    { code: 'SM' },
    { code: 'ST' },
    { code: 'SA' },
    { code: 'SN' },
    { code: 'RS' },
    { code: 'SC' },
    { code: 'SL' },
    { code: 'SG' },
    { code: 'SX' },
    { code: 'SK' },
    { code: 'SI' },
    { code: 'SB' },
    { code: 'SO' },
    { code: 'ZA' },
    { code: 'GS' },
    { code: 'SS' },
    { code: 'ES' },
    { code: 'LK' },
    { code: 'SD' },
    { code: 'SR' },
    { code: 'SJ' },
    { code: 'SE' },
    { code: 'CH' },
    { code: 'SY' },
    { code: 'TW' },
    { code: 'TJ' },
    { code: 'TZ' },
    { code: 'TH' },
    { code: 'TL' },
    { code: 'TG' },
    { code: 'TK' },
    { code: 'TO' },
    { code: 'TT' },
    { code: 'TN' },
    { code: 'TR' },
    { code: 'TM' },
    { code: 'TC' },
    { code: 'TV' },
    { code: 'UG' },
    { code: 'UA' },
    { code: 'AE' },
    { code: 'GB' },
    { code: 'US' },
    { code: 'UM' },
    { code: 'UY' },
    { code: 'UZ' },
    { code: 'VU' },
    { code: 'VE' },
    { code: 'VN' },
    { code: 'VG' },
    { code: 'VI' },
    { code: 'WF' },
    { code: 'EH' },
    { code: 'YE' },
    { code: 'ZM' },
    { code: 'ZW' },
];

export const languages = [
    { code: 'af' }, // Afrikaans
    { code: 'sq' }, // Albanian
    { code: 'am' }, // Amharic
    { code: 'ar' }, // Arabic
    { code: 'hy' }, // Armenian
    { code: 'az' }, // Azerbaijani
    { code: 'eu' }, // Basque
    { code: 'be' }, // Belarusian
    { code: 'bn' }, // Bengali
    { code: 'bs' }, // Bosnian
    { code: 'bg' }, // Bulgarian
    { code: 'ca' }, // Catalan
    { code: 'ceb' }, // Cebuano
    { code: 'zh' }, // Chinese
    { code: 'co' }, // Corsican
    { code: 'hr' }, // Croatian
    { code: 'cs' }, // Czech
    { code: 'da' }, // Danish
    { code: 'nl' }, // Dutch
    { code: 'en' }, // English
    { code: 'eo' }, // Esperanto
    { code: 'et' }, // Estonian
    { code: 'fi' }, // Finnish
    { code: 'fr' }, // French
    { code: 'fy' }, // Frisian
    { code: 'gl' }, // Galician
    { code: 'ka' }, // Georgian
    { code: 'de' }, // German
    { code: 'el' }, // Greek
    { code: 'gu' }, // Gujarati
    { code: 'ht' }, // Haitian Creole
    { code: 'ha' }, // Hausa
    { code: 'haw' }, // Hawaiian
    { code: 'he' }, // Hebrew
    { code: 'hi' }, // Hindi
    { code: 'hmn' }, // Hmong
    { code: 'hu' }, // Hungarian
    { code: 'is' }, // Icelandic
    { code: 'ig' }, // Igbo
    { code: 'id' }, // Indonesian
    { code: 'ga' }, // Irish
    { code: 'it' }, // Italian
    { code: 'ja' }, // Japanese
    { code: 'jv' }, // Javanese
    { code: 'kn' }, // Kannada
    { code: 'kk' }, // Kazakh
    { code: 'km' }, // Khmer
    { code: 'rw' }, // Kinyarwanda
    { code: 'ko' }, // Korean
    { code: 'ku' }, // Kurdish (Kurmanji)
    { code: 'ky' }, // Kyrgyz
    { code: 'lo' }, // Lao
    { code: 'la' }, // Latin
    { code: 'lv' }, // Latvian
    { code: 'lt' }, // Lithuanian
    { code: 'lb' }, // Luxembourgish
    { code: 'mk' }, // Macedonian
    { code: 'mg' }, // Malagasy
    { code: 'ms' }, // Malay
    { code: 'ml' }, // Malayalam
    { code: 'mt' }, // Maltese
    { code: 'mi' }, // Maori
    { code: 'mr' }, // Marathi
    { code: 'mn' }, // Mongolian
    { code: 'my' }, // Myanmar (Burmese)
    { code: 'ne' }, // Nepali
    { code: 'no' }, // Norwegian
    { code: 'ny' }, // Nyanja (Chichewa)
    { code: 'or' }, // Odia (Oriya)
    { code: 'ps' }, // Pashto
    { code: 'fa' }, // Persian
    { code: 'pl' }, // Polish
    { code: 'pt' }, // Portuguese
    { code: 'pa' }, // Punjabi
    { code: 'ro' }, // Romanian
    { code: 'ru' }, // Russian
    { code: 'sm' }, // Samoan
    { code: 'gd' }, // Scots Gaelic
    { code: 'sr' }, // Serbian
    { code: 'st' }, // Sesotho
    { code: 'sn' }, // Shona
    { code: 'sd' }, // Sindhi
    { code: 'si' }, // Sinhala
    { code: 'sk' }, // Slovak
    { code: 'sl' }, // Slovenian
    { code: 'so' }, // Somali
    { code: 'es' }, // Spanish
    { code: 'su' }, // Sundanese
    { code: 'sw' }, // Swahili
    { code: 'sv' }, // Swedish
    { code: 'tl' }, // Tagalog (Filipino)
    { code: 'tg' }, // Tajik
    { code: 'ta' }, // Tamil
    { code: 'tt' }, // Tatar
    { code: 'te' }, // Telugu
    { code: 'th' }, // Thai
    { code: 'tr' }, // Turkish
    { code: 'tk' }, // Turkmen
    { code: 'uk' }, // Ukrainian
    { code: 'ur' }, // Urdu
    { code: 'ug' }, // Uyghur
    { code: 'uz' }, // Uzbek
    { code: 'vi' }, // Vietnamese
    { code: 'cy' }, // Welsh
    { code: 'xh' }, // Xhosa
    { code: 'yi' }, // Yiddish
    { code: 'yo' }, // Yoruba
    { code: 'zu' }, // Zulu
];
