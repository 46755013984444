import axios from 'axios';

export interface SearchInDBPayload {
    type: string;
    searchValue: string;
    page: number;
}

function searchInDB(payload: SearchInDBPayload) {
    const { type, searchValue, page } = payload;
    return axios.get(`/contacts/search?type=${type}&searchValue=${searchValue}&page=${page}`);
}

export interface GetCollectionItemsPayload {
    collectionTypeId: number;
    collectionId: number;
    page: number;
}

function getCollectionItems(payload: GetCollectionItemsPayload) {
    return axios.get(
        `/contacts/collection-items?collectionTypeId=${payload.collectionTypeId}&collectionId=${payload.collectionId}&page=${payload.page}`,
    );
}

export interface GetAllCollectionItemsPayload {
    collectionTypeId: number;
    collectionId: number;
}

function getAllCollectionItems(payload: GetAllCollectionItemsPayload) {
    return axios.get(
        `/contacts/collection-items-all?collectionTypeId=${payload.collectionTypeId}&collectionId=${payload.collectionId}`,
    );
}

export interface GetSearchIdsPayload {
    collectionTypeId: number;
    collectionId: number;
}

function getSearchIds(payload: GetSearchIdsPayload) {
    return axios.get(
        `/contacts/search-ids?collectionTypeId=${payload.collectionTypeId}&collectionId=${payload.collectionId}`,
    );
}

function getCollectionsWithItems(page: number) {
    const query = page ? `?page=${page}` : '';
    return axios.get(`/contacts/collections-with-items${query}`);
}

function getAllCollectionsWithItems() {
    return axios.get('/contacts/all-collections-with-items');
}

function searchCollections(searchValue: string) {
    return axios.get(`/contacts/collections-with-items/search?searchValue=${searchValue}`);
}

export interface SearchItemsPayload {
    searchValue: string;
    collectionId: number;
    collectionTypeId: number;
}

function searchItems(payload: SearchItemsPayload) {
    const { searchValue, collectionId, collectionTypeId } = payload;

    return axios.get(
        `/contacts/items/search?collectionId=${collectionId}&collectionTypeId=${collectionTypeId}&searchValue=${searchValue}`,
    );
}

export interface CreateCollectionPayload {
    CollectionIcon: string;
    CollectionName: string;
    OrderBy: number;
    Type: number;
}

function createCollection(payload: CreateCollectionPayload) {
    return axios.post('contacts/collections', payload);
}

export interface AddCollectionItemPayload {
    CollectionID: number;
    OrderBy: number;
    SearchID: number;
}

function addCollectionItem(payload: AddCollectionItemPayload) {
    return axios.post('contacts/collection-items/', payload);
}

export interface ChangeOrderPayload {
    id: number;
    index: number;
    table: string;
}

function changeOrder(i: ChangeOrderPayload) {
    return axios.put('contacts/collections/order', i);
}

export interface EditCollectionNamePayload {
    ID: number;
    Name: string;
}

function editCollectionName(payload: EditCollectionNamePayload) {
    return axios.put('contacts/collection-name', payload);
}

export interface DeleteCollectionItemPayload {
    collectionId: number;
    itemId: number;
}

function deleteCollectionItem(payload: DeleteCollectionItemPayload) {
    return axios.delete(`/contacts/collection-item?collectionId=${payload.collectionId}&itemId=${payload.itemId}`);
}

export interface DeleteSelectedCollectionsPayload {
    selectedCollections: number[];
}

function deleteSelectedCollections(selectedCollections: DeleteSelectedCollectionsPayload) {
    return axios.delete('contacts/collections/', { data: selectedCollections });
}

function deleteAllCollections() {
    return axios.delete('/contacts/collections-all');
}

export {
    searchInDB,
    searchCollections,
    searchItems,
    changeOrder,
    editCollectionName,
    deleteSelectedCollections,
    deleteAllCollections,
    getCollectionItems,
    getAllCollectionItems,
    getSearchIds,
    getAllCollectionsWithItems,
    getCollectionsWithItems,
    createCollection,
    addCollectionItem,
    deleteCollectionItem,
};
