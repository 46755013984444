<template>
    <div>
        <v-dialog v-model="open" width="500px">
            <v-card v-if="taskId" class="single-item-view">
                <VitecSelectedTask :key="taskId" :taskId="taskId" @exit="() => (taskId = null)" />
            </v-card>
            <div v-else class="list-view">
                <v-card>
                    <v-card-title>
                        <div class="d-flex">
                            <v-img :src="getImage(integration.name)" width="60"></v-img>
                            <span>{{ integration.name.charAt(0).toUpperCase() + integration.name.slice(1) }}</span>
                        </div>
                        <v-spacer />
                        <v-btn icon @click="open = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-divider />
                    <div v-if="false" class="d-flex justify-end my-2 mr-2">
                        <v-sheet width="200px">
                            <v-text-field
                                v-model="search"
                                placeholder="Search"
                                outlined
                                dense
                                hide-details
                                prepend-inner-icon="mdi-magnify"
                            />
                        </v-sheet>
                    </div>

                    <v-sheet height="500px" class="overflow-y-auto">
                        <v-list class="py-0">
                            <v-alert
                                v-if="state === 'error'"
                                type="error"
                                prominent
                                class="my-4"
                                icon="mdi-alert-circle-outline"
                            >
                                <div>{{ $t('error.title') }}</div>
                                <p>{{ $t('error.message') }}</p>
                                <v-btn color="error" @click="getTasks()">{{ $t('error.retry') }}</v-btn>
                            </v-alert>
                            <template v-else-if="state === 'loading'">
                                <v-skeleton-loader v-for="_ in 5" type="list-item-two-line" />
                            </template>
                            <template v-else-if="state === 'success' && tasks.length">
                                <VitecTaskListItem
                                    v-if="tasks.length"
                                    v-for="task in tasks"
                                    :key="task.id"
                                    :data="task"
                                    @selected="selectTaskId"
                                />
                            </template>
                            <template v-else>No tasks found</template>
                        </v-list>
                    </v-sheet>
                </v-card>
            </div>
        </v-dialog>
        <IntegrationButton :integrationName="integration.name" :active="active" @click="clicked" />
    </div>
</template>
<script lang="ts">
    import IntegrationButton from '../IntegrationButton.vue';
    import VitecTaskListItem from './VitecTaskListItem.vue';
    import VitecSelectedTask from './VitecSelectedTask.vue';
    import api from '@/api/integrations/integrationV2';
    import clientApi from '@/api/client/client';

    import { defineComponent } from 'vue';
    import { getImage } from '@/utils/Integrations';

    export default defineComponent({
        components: {
            IntegrationButton,
            VitecTaskListItem,
            VitecSelectedTask,
        },
        props: {
            integration: {
                type: Object as () => { name: string },
                default: () => ({}),
            },
            clientId: {
                type: String,
                default: null,
            },
        },
        data(): {
            open: boolean;
            state: 'loading' | 'error' | 'success';
            tasks: any[];
            clientRelations: { source: string; externalId: string }[];
            taskId: number | null;
            search: string;
        } {
            return {
                open: false,
                state: 'loading',
                tasks: [],
                clientRelations: [],
                taskId: null,
                search: '',
            };
        },
        mounted() {
            this.getClientExternalIds();
        },
        computed: {
            active() {
                return Boolean(this.clientRelations.find((item) => item.source === 'vitec'));
            },
        },
        methods: {
            getImage,
            selectTaskId(taskId: number) {
                this.taskId = taskId;
            },

            async clicked() {
                this.open = true;
                await this.getTasks();
            },
            async getTasks() {
                try {
                    this.state = 'loading';
                    const response = await api.getVitecTasks(this.clientId);
                    this.tasks = response.data;
                    this.state = 'success';
                } catch (error) {
                    console.error(error);
                    this.state = 'error';
                }
            },
            async getClientExternalIds() {
                try {
                    const { data } = await clientApi.getClientRelations(this.clientId);

                    this.clientRelations = data.map(
                        (
                            item: {
                                externalId: string;
                                source: string;
                            },
                            index: number
                        ) => ({
                            ...item,
                            id: index,
                        })
                    );
                } catch (error) {
                    console.error(error);
                }
            },
        },
    });
</script>

<i18n lang="json">
{
    "en": {
        "error": {
            "title": "Error",
            "message": "An error occurred while fetching vitec cases",
            "retry": "Retry",
            "noTasks": "No tasks found"
        }
    },
    "sv": {
        "error": {
            "title": "Fel",
            "message": "Ett fel uppstod vid hämtning av vitec ärenden",
            "retry": "Försök igen",
            "noTasks": "Inga uppgifter hittades"
        }
    }
}
</i18n>
