import axios from 'axios';
import DedupeRequest from '../../entity/DedupeRequest';

export interface GetBrandsParams {
    search: string;
    users: number[];
    offset: number;
    limit: number;
}

function getBrands(params: GetBrandsParams) {
    return axios.get('brands', { params });
}

function getBrandsCount() {
    return axios.get('brands/count');
}

const getBrandDedupe = new DedupeRequest((id) => {
    return axios.get(`brands/${id}`);
}, 1000);

function getBrand(id: number) {
    // @ts-ignore <Why do dedupe only accept objects but we are passing a number here?>
    return getBrandDedupe.send(id);
}

export interface GetBrandLogoParams {
    resolution: number;
}

// @ts-ignore <Why do we need to pass a number here?>
const getBrandLogoDedupe = new DedupeRequest((id: number, params: GetBrandLogoParams) => {
    return axios.get(`brands/${id}/logo`, { params });
}, 1000);

function getBrandLogo(id: number, params: GetBrandLogoParams) {
    // @ts-ignore <Why do dedupe only accept objects but we are passing a number here?>
    return getBrandLogoDedupe.send(id, params);
}

export interface CreateBrandPayload {
    name: string;
    logo: string;
    queues: number[];
}

function createBrand(payload: CreateBrandPayload) {
    return axios.post('brands', payload);
}

export interface UpdateBrandPayload {
    brandId: number;
    fields: {
        name: string;
        logo: string;
        queues: number[];
    };
}

function updateBrand(id: number, payload: UpdateBrandPayload) {
    return axios.patch(`brands/${id}`, payload);
}

function deleteBrand(id: number, toBrandId: number) {
    return axios.delete(`brands/${id}`, { params: { toBrandId } });
}

function getUserSelectedBrands(userId: number) {
    return axios.get(`brands/users/${userId}`);
}

function updateUserSelectedBrands(userId: number, brandIds: number[]) {
    return axios.patch(`brands/users/${userId}`, { brandIds: brandIds });
}

function fillBrandWithQueues(brandId: number) {
    return axios.post(`brands/${brandId}/fill-queues`);
}

export default {
    getBrands,
    getBrandsCount,
    getBrand,
    getBrandLogo,
    createBrand,
    updateBrand,
    deleteBrand,
    getUserSelectedBrands,
    updateUserSelectedBrands,
    fillBrandWithQueues,
};
