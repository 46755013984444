<template>
    <v-menu v-model="menuOpen" :close-on-content-click="false" transition="scale-transition" offset-y>
        <template #activator="{}">
            <tertiary-action-button
                v-bind="styleForCase ? caseProps : creatorProps"
                :dense="dense"
                @click="handleClick"
            >
                <article class="item-text item" cy-data="manualSLA">
                    <div class="select-item">
                        <v-icon size="16" :class="iconClass" color="var(--v-gray2-base)">
                            mdi-calendar-clock-outline
                        </v-icon>
                        <span v-if="!$attrs.minimal">{{ dateStart }} ({{ timeStart }})</span>
                    </div>

                    <v-icon size="16" class="ml-3" color="var(--v-gray2-base)">mdi-chevron-down</v-icon>
                </article>
            </tertiary-action-button>
        </template>

        <DateAndTimeSelect v-model="localDateTime"></DateAndTimeSelect>
    </v-menu>
</template>

<script>
    import { format } from 'date-fns';
    import { mapState } from 'vuex';
    import DateAndTimeSelect from './DateAndTimeSelect.vue';

    export default {
        name: 'TimeSelect',

        components: {
            DateAndTimeSelect,
        },

        props: {
            items: {
                type: Array,
                default: () => [],
            },

            boolean: {
                type: Boolean,
                default: false,
            },

            dense: {
                type: Boolean,
                default: true,
            },

            styleForCase: {
                type: Boolean,
                default: false,
            },
            dateTime: {
                type: Date,
                default: null,
            },
        },

        data() {
            return {
                creatorProps: {
                    rounded: true,
                    filled: true,
                    dense: true,
                    items: [],
                    hideDetails: true,
                },
                caseProps: {
                    rounded: false,
                    filled: true,
                    dense: true,
                    items: [],
                    'background-color': '#ffffff',
                    width: '100%',
                },
                menuOpen: false,
                localSavedDateTime: null,
            };
        },

        computed: {
            ...mapState('Cases', ['caseFilters', 'caseFiltersState', 'selectedCase']),
            dateStart: {
                get() {
                    if (this.localSavedDateTime) {
                        return format(this.localSavedDateTime, 'yyyy-MM-dd');
                    }
                    return format(this.localDateTime, 'yyyy-MM-dd');
                },
            },
            timeStart: {
                get() {
                    if (this.localSavedDateTime) {
                        return format(this.localSavedDateTime, 'HH:mm');
                    }
                    return format(this.localDateTime, 'HH:mm');
                },
            },
            localDateTime: {
                get() {
                    if (this.dateTime) {
                        return this.dateTime;
                    }
                    return new Date();
                },
                set(value) {
                    this.localSavedDateTime = value;
                    this.$emit('input', value);
                    this.menuOpen = false;
                },
            },
            itemStyle() {
                return { height: this.dense ? '40px' : '50px' };
            },

            iconClass() {
                return this.$attrs.minimal ? '' : 'mr-3';
            },

            selectPropsComputed() {
                return {
                    ...this.selectProps,
                    ...this.$attrs,
                };
            },
        },
        methods: {
            handleClick(event) {
                this.menuOpen = !this.menuOpen;
                this.$emit('click', event);
            },

            emitUpdate(input) {
                this.$emit('input', input);
                this.menuOpen = false;
            },
        },
    };
</script>

<style scoped>
    .window {
        min-height: 400px;
    }

    :deep(.v-input__prepend-inner) {
        align-self: center;
        margin: 0px !important;
        padding: 0px !important;
        white-space: nowrap !important;
    }

    div[role='menu'] {
        border-radius: 8px;
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2) !important;
        border: 1px solid #eee;
        padding: 4px;
        background-color: white;
        min-width: 300px !important;
        max-width: 300px !important;
    }

    .item {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        flex: 1;
    }

    .item-list {
        overflow-y: auto;
    }

    .select-item {
        display: flex;
        align-items: center;
        flex-direction: row;
    }

    .display-item {
        display: flex;
        align-items: center;
        flex-direction: row;
        font-size: 0.9rem !important;
        font-weight: 400 !important;
        color: var(--v-gray2-base);
    }

    .item-text {
        font-size: 0.9rem !important;
        font-weight: 400 !important;
        color: var(--v-gray2-base);
    }

    .item-text-not-selected {
        font-size: 0.9rem !important;
        font-weight: 400 !important;
        color: var(--v-gray2-base);
    }

    .list-item {
        padding: 0 !important;
        padding-left: 8px !important;
        padding-right: 24px !important;
        height: 48px !important;
        background-color: var(--v-gray3-base);
        margin-bottom: 1px;
    }

    .icon-container {
        padding-right: 16px;
    }

    .selected-item {
        background-color: white;
    }

    .footer-buttons {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    .time-buttons-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        gap: 4px;
    }

    .save-button {
        flex: 1;
    }

    :focus {
        border: 1px solid var(--v-primary-base) !important;
    }
</style>

<i18n lang="json">
{
    "en": {
        "selects": {
            "date": "Date",
            "time": "Time"
        }
    },
    "sv": {
        "selects": {
            "date": "Datum",
            "time": "Tid"
        }
    }
}
</i18n>
