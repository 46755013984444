<template>
    <article class="chip" :style="computedStyle">
        <i :class="iconClass"></i>
        {{ category.name }}
    </article>
</template>
<script lang="ts">
    import { Category } from '@/types/cases.types';
    import { defineComponent, PropType } from 'vue';

    export default defineComponent({
        name: 'CategoryChipSmall',
        props: {
            category: {
                type: Object as PropType<Category>,
                required: true,
            },
        },
        computed: {
            iconClass(): string {
                return `mdi ${this.category.icon}`;
            },
            computedStyle() {
                return {
                    backgroundColor: this.category.color,
                };
            },
        },
    });
</script>
<style scoped>
    .chip {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        height: 24px;
        user-select: none;
        padding: 2px 6px;
        border-radius: 4px;
        font-size: 12px;
        color: white;
        text-transform: capitalize;
    }

    .chip i {
        margin-right: 0.25rem;
    }
</style>
