<template>
    <section :class="{ disabled: disabled }" :style="backgroundStyle">
        <InfiniteMenu
            :key="render"
            :headers="[]"
            :fetchFunction="fetchFunction"
            :items="items"
            :limit="30"
            :emptyHeader="$t('emptyHeader')"
            :emptyMessage="$t('emptyMessage')"
            :isSearchable="isSearchable"
            @click-item="selectItem"
        >
            <section v-if="!error" class="select-box" :class="selectClass">
                <i v-if="displayIcon" class="mdi icon-container" :class="displayIcon"></i>
                <span>{{ display || label }}</span>
            </section>

            <section v-else class="select-box" :class="selectClass">
                <i class="mdi icon-container mdi-alert-circle-outline"></i>
                <span>{{ $t('error') }}</span>
            </section>

            <template #item="{ item }">
                <section :key="item.id" class="dynamic-item" :class="selectedItemClass(item.value)">
                    <i v-if="item.icon" class="mdi icon-container" :class="item.icon"></i>
                    <span>{{ item.name }}</span>
                </section>
            </template>

            <template #footer>
                <section v-if="createFunction">
                    <standard-input
                        v-model="createValue"
                        :dense="true"
                        :placeholder="$t('create')"
                        @enter="create(createValue)"
                    />
                </section>
            </template>
        </InfiniteMenu>
    </section>
</template>

<script>
    import InfiniteMenu from '@/components/Main/List/InfiniteMenu.vue';

    export default {
        name: 'DynamicSelect',

        components: {
            InfiniteMenu,
        },

        props: {
            error: {
                type: Boolean,
                default: false,
            },

            background: {
                type: String,
                default: 'transparent',
            },

            label: {
                type: String,
                default: '',
            },

            disabled: {
                type: Boolean,
                default: false,
            },

            displayIcon: {
                type: String,
                default: '',
            },

            displayValue: {
                type: String,
                default: '',
            },

            value: {
                type: [String, Number],
                default: null,
            },

            fetchFunction: {
                type: Function,
                default: () => {},
            },

            createFunction: {
                type: Function,
                default: () => {},
            },

            items: {
                type: Array,
                default: () => [],
            },

            isSearchable: {
                type: Boolean,
                default: true,
            },

            dense: {
                type: Boolean,
                default: true,
            },
        },

        data() {
            return {
                createValue: '',
                render: 0,
            };
        },

        computed: {
            display() {
                return this.displayValue || this.value || '';
            },

            selected() {
                return this.items.find((item) => item.value === this.value);
            },

            selectClass() {
                return {
                    dense: this.dense,
                };
            },

            backgroundStyle() {
                return {
                    backgroundColor: this.background,
                };
            },
        },

        methods: {
            selectedItemClass(value) {
                return {
                    selectedItemClass: this.selected && this.selected.value === value,
                };
            },

            isSelected(item) {
                return this.selected && this.selected.value === item.value;
            },

            selectItem(item) {
                if (this.selected && this.selected.value === item.value) {
                    this.$emit('input', null);
                } else {
                    this.$emit('input', item.value);
                }

                this.render += 1;
            },

            async create() {
                const result = await this.createFunction(this.createValue);

                if (result) {
                    this.createValue = '';
                    this.render += 1;
                }
            },
        },
    };
</script>

<style scoped>
    .dense {
        min-height: 40px !important;
        height: 40px;
    }

    .selectedItemClass {
        background-color: #f5f5f5;
    }

    .icon-container {
        margin-right: 8px;
    }

    .dynamic-item {
        padding: 8px 16px;
        border-bottom: 1px solid #ececec;
        font-size: 1em;
        min-height: 48px;
        transition: background-color 0.2s;
        display: flex;
        align-items: center;
    }

    .dynamic-item:hover {
        background-color: #f5f5f5;
        cursor: pointer;
    }

    .select-box {
        display: flex;
        justify-content: stretch;
        align-items: center;
        padding: 0 12px;
        color: black;
        min-height: 56px;
        border: 1px solid #ececec;
        border-radius: 4px;
        font-size: 1.1em;
        transition: background-color 0.2s;
        user-select: none;
    }

    .select-box span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 0px;
        flex: 1;
    }

    .select-box:hover {
        background-color: #f5f5f5;
        cursor: pointer;
    }

    .disabled {
        pointer-events: none;
    }
</style>

<i18n lang="json">
{
    "sv": {
        "emptyHeader": "Inga fält att visa",
        "emptyMessage": "Det finns inga fält att visa i denna lista.",
        "create": "Skapa nytt fält",
        "error": "Fel vid hämtning av data"
    },
    "en": {
        "emptyHeader": "No fields to show",
        "emptyMessage": "There are no fields to show in this list.",
        "create": "Create new field",
        "error": "Error fetching data"
    }
}
</i18n>
