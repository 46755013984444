import axios from 'axios';

export interface GetWidgetsListPayload {
    page: number;
}

function getWidgetsList(q: GetWidgetsListPayload) {
    const params = {
        page: q.page,
    };

    return axios.get('/widgets-builder', {
        params,
    });
}

function getSpecificWidget(id: string, preview: number) {
    const params = {
        ID: id,
        preview,
    };

    return axios.get('/widgets-builder/widget', {
        params,
    });
}

function getWidgetQueues() {
    return axios.get('/widgets-builder/queues');
}

export interface CreateWidgetPayload {
    name: string;
    url: string;
    deployUrl: string;
    id: string;
}

function createWidget(q: CreateWidgetPayload) {
    return axios.post('/widgets-builder', {
        name: q.name,
        url: q.url,
        deployUrl: q.deployUrl,
        type: q.id,
    });
}

function deleteWidget(id: string) {
    return axios.delete('/widgets-builder', {
        params: {
            id,
        },
    });
}

function duplicateWidget(id: string) {
    return axios.post('/widgets-builder/duplicate', {
        id,
    });
}

function getTemplates() {
    return axios.get('/widgets-builder/templates');
}

export interface UpdateWidgetPayload {
    id: string;
    name: string;
    url: string;
    type: string;
    deployUrl: string;
}

function updateWidget(q: UpdateWidgetPayload) {
    return axios.put('/widgets-builder', {
        id: q.id,
        name: q.name,
        url: q.url,
        type: q.type,
        deployUrl: q.deployUrl,
    });
}

export interface UpdateWidgetObjectPayload {
    id: string;
    object: unknown;
    preview: number;
}

function updateWidgetObject(q: UpdateWidgetObjectPayload) {
    return axios.put('/widgets-builder/object', {
        id: q.id,
        object: q.object,
        preview: q.preview,
    });
}

export interface ResetWidgetObjectPayload {
    id: string;
}

function resetWidgetObject(q: ResetWidgetObjectPayload) {
    return axios.put('/widgets-builder/reset', {
        id: q.id,
    });
}

export interface UpdateWidgetStatePayload {
    uniqueId: string;
    state: string;
}

function updateWidgetState(data: UpdateWidgetStatePayload) {
    return axios.put('/widgets-builder/state', {
        uniqueId: data.uniqueId,
        state: data.state,
    });
}

export interface BookingBboxPayload {
    target: string;
    lang: string;
}

function getBookingBbox(data: BookingBboxPayload) {
    return axios.get('/widgets-builder/booking/bbox', {
        params: {
            target: data.target,
            lang: data.lang,
        },
    });
}

function getBookingIcon(icon: string) {
    const endpoint = '/static/public/widgets/chat/build/assets/images/icons/booking';
    return axios.get(`${endpoint}/${icon}.svg`);
}

export interface SetConnectedFaqsPayload {
    uniqueId: string;
    faqs: number[];
}

function setConnectedFaqs(data: SetConnectedFaqsPayload) {
    return axios.put('/widgets-builder/faq', {
        id: data.uniqueId,
        faqs: data.faqs,
    });
}

export interface LoadSelectedFaqsPayload {
    uniqueId: string;
}

function loadSelectedFaqs(data: LoadSelectedFaqsPayload) {
    return axios.get('/widgets-builder/faq', {
        params: {
            id: data.uniqueId,
        },
    });
}

export interface UpdateEmailSettingsPayload {
    id: string;
    emailSender: string;
    emailConversationOnClose: boolean;
}

function updateEmailSettings(data: UpdateEmailSettingsPayload) {
    return axios.put('/widgets-builder/settings/email', data);
}

export default {
    getWidgetsList,
    getSpecificWidget,
    createWidget,
    getTemplates,
    deleteWidget,
    duplicateWidget,
    updateWidget,
    updateWidgetObject,
    resetWidgetObject,
    updateWidgetState,
    getWidgetQueues,
    getBookingBbox,
    getBookingIcon,
    setConnectedFaqs,
    loadSelectedFaqs,
    updateEmailSettings,
};
