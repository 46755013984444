import axios from 'axios';

function getActiveCases(limit: number, offset: number) {
    return axios.get('/users/cases/active', {
        params: {
            limit,
            offset,
        },
    });
}

function getActiveDialogs(limit: number, offset: number) {
    return axios.get('/users/dialogs/active', {
        params: {
            limit,
            offset,
        },
    });
}

function getActiveQueues(limit: number, offset: number) {
    return axios.get('/users/queues/active', {
        params: {
            limit,
            offset,
        },
    });
}

function getActiveUnreadMessages() {
    return axios.get('/users/unreadmessages');
}

function getActiveCapacity() {
    return axios.get('/users/capacity');
}

export const channelType = {
    EMAIL: 'email',
    SMS: 'sms',
    CALL: 'call',
    CHAT: 'chat',
    SOCIAL: 'social',
    FORM: 'form',
    CALLBACK: 'callback',
} as const;

export type ChannelType = (typeof channelType)[keyof typeof channelType];

function getActiveCapacityLeft(channelType: ChannelType) {
    return axios.get('/users/capacity/amount', {
        params: {
            channelType,
        },
    });
}

export default {
    getActiveCases,
    getActiveDialogs,
    getActiveQueues,
    getActiveUnreadMessages,
    getActiveCapacity,
    getActiveCapacityLeft,
};
