<template>
    <section class="wrapper">
        <section class="categories" :style="gridStyle">
            <CategoryChipSmall v-for="category in visibleCategoriesList" :category="category" :key="category.name" />
        </section>
        <SimpleTooltip v-if="showMoreButton" :tooltipText="showMoreTooltip" top>
            <button v-if="showMoreButton" class="show-more-button" @click="showAll = !showAll">
                <i class="mdi mdi-dots-horizontal"></i>
            </button>
        </SimpleTooltip>
    </section>
</template>
<script lang="ts">
    import { defineComponent, PropType } from 'vue';

    import type { Category } from '@/types/cases.types';
    import CategoryChipSmall from '@/components/Cases/BigTimeLineCaseItem/CategoryChipSmall.vue';
    import SimpleTooltip from '@/components/Global/SimpleTooltip.vue';

    export default defineComponent({
        components: { CategoryChipSmall, SimpleTooltip },
        props: {
            categories: {
                type: Array as PropType<Category[]>,
                required: true,
            },
            visibleCategories: {
                type: Number,
                default: 3,
            },
        },
        data() {
            return {
                showAll: false,
            };
        },
        computed: {
            visibleCategoriesList(): Category[] {
                return this.showAll ? this.categories : this.categories.slice(0, this.visibleCategories);
            },
            showMoreButton() {
                return this.categories.length > this.visibleCategories && !this.showAll;
            },
            showMoreTooltip(): string {
                return this.showAll ? String(this.$t('global.showLess')) : String(this.$t('global.showMore'));
            },
            gridStyle() {
                return {
                    gridTemplateColumns: `repeat(${Math.min(this.categories.length, 3)}, 1fr)`,
                };
            },
        },
    });
</script>
<style scoped lang="scss">
    .wrapper {
        display: flex;
        justify-content: space-between;
    }

    .categories {
        display: grid;
        gap: 4px;
    }

    .show-more-button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        cursor: pointer;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        transition: all 0.2s;
        margin-left: 4px;

        &:hover {
            background-color: var(--v-gray1-base);
        }
    }
</style>
