import axios from 'axios';

interface ClientPayload {
    name?: string | null;
    clientName?: string | null;
    clientId?: string | null;
    primaryTelephone?: string | null;
    secondaryTelephone?: string | null;
    primaryEmail?: string | null;
    secondaryEmail?: string | null;
    address?: string | null;
    post?: string | null;
    tags?: string | null;
    information?: string;
    contactContract?: string | null;
    contactSupport?: string | null;
    confirmationEmail?: string | number;
    /** @deprecated Use swedishPersonalIdentityNumber instead */
    socialSecurityNumber?: string | null;
    gdprCleaned?: string | number;
    includedInSearch?: string | number;
    priorityPoints?: number | null;
    type?: 'client' | 'shadow';
    parent?: string | null;
    customerType?: string | null;
    market?: string | null;
    country?: string | null;
    city?: string | null;
    language?: string | null;
    locationInfo?: string | null;
    clientType?: number | null;
    clientTypeName?: string | null;
    clientTypeIcon?: string | null;
    active?: boolean;
    swedishPersonalIdentityNumber?: string | null;
    norwegianNationalIdentityNumber?: string | null;
}

export interface UpdateClientPayload {
    clientId: string;
    type: string;
    [key: string]: ClientPayload[keyof ClientPayload];
}

function updateClient(payload: UpdateClientPayload) {
    // * extract the clientId from the payload
    const { clientId, ...data } = payload;
    return axios.put(`/client/${clientId}`, data);
}

export interface UpdateClientFieldsPayload {
    clientId: string;
    [key: string]: ClientPayload[keyof ClientPayload];
}

function updateClientFields(payload: UpdateClientFieldsPayload) {
    const { clientId, ...data } = payload;
    return axios.patch(`/client/${clientId}/fields`, data);
}

export interface CreateClientPayload {
    type: string;
    // biome-ignore lint/suspicious/noExplicitAny: <use client v2+ instead>
    [key: string]: any;
}
function createClient(payload: CreateClientPayload) {
    return axios.post('/client', payload);
}

function getClient(id: string) {
    return axios.get(`/client/${id}`);
}

function getClientV2(id: string) {
    return axios.get(`/v2/clients/${id}`);
}

function getClientByCaseId(id: string) {
    return axios.get(`/client/case/${id}`);
}

function deleteClient(id: string) {
    return axios.delete(`/client/${id}`);
}

const searchFields = Object.freeze({
    NAME: 'name',
    EMAIL: 'email',
    TELEPHONE: 'telephone',
    ADRESS: 'adress',
});

export type searchFields = (typeof searchFields)[keyof typeof searchFields];

export interface GetClientsPayload {
    search?: string | null;
    searchFields?: searchFields[];
    page?: number | null;
    limit?: number | null;
    clientId?: string | null;
    types?: 'client' | 'shadow';
    alwaysSearch?: boolean;
}

function getClients(params: GetClientsPayload) {
    return axios.get('/clients', { params });
}

export interface GetAddressesPayload {
    search: string;
}
function getAddresses(payload: GetAddressesPayload) {
    return axios.get(`/client/address/${payload.search}`);
}

export interface GetCustomerTypesPayload {
    offset: number;
    limit: number;
}
function getCustomerTypes(payload: GetCustomerTypesPayload) {
    return axios.get('/clients/fields/customerTypes', { params: payload });
}

function getClientTypes() {
    return axios.get('/v1/client-types/types');
}

function createCustomerType(name: string) {
    return axios.post('/clients/fields/customerTypes', { name });
}

function getClientsFamily(id: string, limit: number, offset: number) {
    return axios.get(`/clients/${id}/parents`, { params: { limit, offset } });
}

function getClientsChildren(id: string, limit: number, offset: number, clientTypeIds = []) {
    return axios.get(`/clients/${id}/children`, { params: { limit, offset, clientTypeIds } });
}

function getTimeline(id: string, limit: number, offset: number) {
    return axios.get(`/clients/${id}/timeline`, { params: { limit, offset } });
}

function getClientRelations(id: string) {
    return axios.get(`/v2/clients/${id}/external-relations`);
}

export default {
    // Basic
    updateClient,
    updateClientFields,
    createClient,
    getClient,
    deleteClient,

    // Case specific
    getClientByCaseId,

    // Paginated search
    getClients,

    // Get addresses
    getAddresses,

    getCustomerTypes,
    getClientTypes,
    createCustomerType,

    getClientsFamily,
    getClientsChildren,

    getClientV2,
    getTimeline,
    getClientRelations,
};
