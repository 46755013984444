<template>
    <i class="icon mdi" :class="badgeIcon"></i>
</template>
<script lang="ts">
    import { defineComponent, PropType } from 'vue';
    import UnreadMessagesIndicator from '@/components/UnreadMessagesIndicator.vue';
    import { formatDateToText } from '@/utils/DateFormatter';
    import { filterObjectsByKeys, getActiveFromSettings } from '@/utils';
    import { channelTypes } from '@/templates/cases.template';

    export default defineComponent({
        components: {},
        props: {
            subType: {
                type: String,
                required: true,
            },
            source: {
                type: String,
                required: true,
            },
        },
        data() {
            return {};
        },
        computed: {
            channelConfig(): object {
                // @ts-ignore <This is going to be converted to Pinia store in the future>
                return this.$store.state.System.userSettings.system.channels as object;
            },

            facebookMessengerIcon(): string | undefined {
                // @ts-ignore <This is going to be converted to Pinia store in the future>
                return this.$store.state.Social.social.facebookmessenger?.icon as string | undefined;
            },

            filteredChannelTypes() {
                const channelBooleans = getActiveFromSettings(this.channelConfig);
                return filterObjectsByKeys(channelBooleans, channelTypes, 'value') as { value: string; icon: string }[];
            },

            badgeIcon(): string {
                if (this.subType === 'message' && this.source === 'facebook') {
                    return this.facebookMessengerIcon as string;
                }
                return this.getChannelIcon(this.source) as string;
            },
        },

        methods: {
            formatDateToText,

            getChannelIcon(channel: string): string {
                // @ts-ignore <This is going to be converted to Pinia store in the future>
                return (this.$store.state.Social.social[channel]?.icon || 'mdi-help-circle') as string;
            },
        },
    });
</script>
<style scoped></style>
<i18n lang="json">
{
    "en": {},
    "sv": {}
}
</i18n>
