<template>
    <v-card class="case-detail">
        <v-toolbar flat>
            <v-btn :disabled="state === 'loading'" icon small @click="$emit('exit')">
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <div style="width: 100%">
                <v-card-title>
                    <v-skeleton-loader v-if="state === 'loading'" width="100" height="20" />
                    <truncate v-if="task">{{ $t('case') }}: {{ task.id }}</truncate>
                </v-card-title>
            </div>
        </v-toolbar>
        <v-divider />

        <v-sheet height="500px" class="overflow-y-auto">
            <v-alert v-if="state === 'error'" type="error" prominent class="my-4" icon="mdi-alert-circle-outline">
                <div>{{ $t('error') }}</div>
                <p>{{ $t('errorMessage') }}</p>
                <v-btn text @click="getTask(Number(taskId))">{{ $t('retry') }}</v-btn>
            </v-alert>
            <template v-else-if="state === 'loading'">
                <v-skeleton-loader v-for="_ in 3" type="article" />
            </template>
            <template v-else-if="state === 'success' && task">
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="6">
                            <h3>{{ $t('registered') }}</h3>
                            <p>{{ formatDate(task.registrationDate) }}</p>
                        </v-col>

                        <v-col cols="12" md="6">
                            <h3>{{ $t('category') }}</h3>
                            <p>{{ $t(task.category) }}</p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <h3>{{ $t('description') }}</h3>
                            <p>{{ task.description }}</p>
                        </v-col>

                        <v-col cols="12">
                            <h3>{{ $t('notifier') }}</h3>
                            <p>
                                {{ task.notifier.name }}
                                <br />
                                {{ task.notifier.email }}
                                {{ task.notifier.phone }}
                            </p>
                        </v-col>

                        <v-col cols="12">
                            <v-expansion-panels flat>
                                <v-expansion-panel>
                                    <v-expansion-panel-header>
                                        <h3>{{ $t('parentObject') }}</h3>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-card-title>{{ task.parentObject.fullname }}</v-card-title>
                                        <v-card-subtitle>
                                            {{ $t(task.parentObject.classCode) }} - {{ task.parentObject.number }}
                                        </v-card-subtitle>

                                        <v-card-text>
                                            <v-row>
                                                <v-col cols="12" md="6">
                                                    <p>
                                                        <strong>{{ $t('description') }}:</strong>
                                                        {{ task.parentObject.description }}
                                                    </p>
                                                    <p>
                                                        <strong>{{ $t('address') }}:</strong>
                                                        {{ task.parentObject.address || $t('na') }}
                                                    </p>
                                                    <p>
                                                        <strong>{{ $t('location') }}:</strong>
                                                        {{ task.parentObject.city }}, {{ task.parentObject.zipcode }},
                                                        {{ task.parentObject.country }}
                                                    </p>
                                                    <p>
                                                        <strong>{{ $t('area') }}:</strong>
                                                        {{ task.parentObject.area }} m²
                                                    </p>
                                                </v-col>

                                                <v-col cols="12" md="6">
                                                    <p>
                                                        <strong>{{ $t('created') }}:</strong>
                                                        {{ formatDate(task.parentObject.createdDate) }} {{ $t('by') }}
                                                        {{ task.parentObject.createdBy }}
                                                    </p>
                                                    <p>
                                                        <strong>{{ $t('lastChanged') }}:</strong>
                                                        {{ formatDate(task.parentObject.changedDate) }} {{ $t('by') }}
                                                        {{ task.parentObject.changedBy }}
                                                    </p>
                                                    <p>
                                                        <strong>{{ $t('startDate') }}:</strong>
                                                        {{ formatDate(task.parentObject.startDate) }}
                                                    </p>
                                                    <p>
                                                        <strong>{{ $t('endDate') }}:</strong>
                                                        {{ formatDate(task.parentObject.endDate) }}
                                                    </p>
                                                    <p>
                                                        <strong>{{ $t('blocked') }}:</strong>
                                                        {{ task.parentObject.blocked ? $t('yes') : $t('no') }}
                                                    </p>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-col>
                    </v-row>
                </v-card-text>
            </template>
            <template v-else>No tasks found</template>
        </v-sheet>
    </v-card>
</template>

<script lang="ts">
    import Vue from 'vue';
    import api from '@/api/integrations/integrationV2';
    export default Vue.extend({
        props: {
            taskId: {
                type: [String, Number],
                required: true,
            },
        },
        data(): {
            state: 'loading' | 'error' | 'success';
            task: any;
        } {
            return {
                state: 'loading',
                task: null,
            };
        },
        mounted() {
            this.getTask(Number(this.taskId));
        },
        methods: {
            formatDate(date: string) {
                return new Date(date).toLocaleDateString();
            },
            async getTask(taskId: number) {
                try {
                    this.state = 'loading';
                    const response = await api.getVitecTask(taskId);
                    this.task = response.data;
                    this.state = 'success';
                } catch (error) {
                    console.error(error);
                    this.state = 'error';
                }
            },
        },
    });
</script>

<style scoped>
    .case-detail {
        padding: 16px;
    }
</style>

<i18n lang="json">
{
    "en": {
        "case": "Case",
        "registered": "Registered",
        "category": "Category",
        "description": "Description",
        "notifier": "Notifier",
        "parentObject": "Parent Object",
        "address": "Address",
        "location": "Location",
        "area": "Area",
        "created": "Created",
        "lastChanged": "Last Changed",
        "startDate": "Start Date",
        "endDate": "End Date",
        "blocked": "Blocked",
        "yes": "Yes",
        "no": "No",
        "close": "Close",
        "by": "by",
        "na": "N/A",
        "apartment": "Apartment",
        "garage": "Garage",
        "parking": "Parking",
        "carport": "Carport",
        "storage": "Storage",
        "premises": "Premises",
        "floor": "Floor",
        "stairwell": "Stairwell",
        "error": "Error",
        "errorMessage": "An error occurred while fetching the task. Please try again. If problem persists, contact support.",
        "retry": "Retry"
    },
    "sv": {
        "case": "Ärende",
        "registered": "Registrerad",
        "category": "Kategori",
        "description": "Beskrivning",
        "notifier": "Anmälare",
        "parentObject": "Föräldraobjekt",
        "address": "Adress",
        "location": "Plats",
        "area": "Yta",
        "created": "Skapad",
        "lastChanged": "Senast ändrad",
        "startDate": "Startdatum",
        "endDate": "Slutdatum",
        "blocked": "Blockerad",
        "yes": "Ja",
        "no": "Nej",
        "close": "Stäng",
        "by": "av",
        "na": "N/A",
        "apartment": "Lägenhet",
        "garage": "Garage",
        "parking": "Parkering",
        "carport": "Carport",
        "storage": "Förråd",
        "premises": "Lokal",
        "floor": "Våning",
        "stairwell": "Uppgång",
        "error": "Fel",
        "errorMessage": "Ett fel uppstod vid hämtning av ärendet. Försök igen. Om problemet kvarstår, kontakta support.",
        "retry": "Försök igen"
    }
}
</i18n>
