<template>
    <standard-button v-bind="extendedAttrs" class="btn" @click="handleClick">
        <slot></slot>
    </standard-button>
</template>

<script>
    export default {
        name: 'ActionButton',
        computed: {
            extendedAttrs() {
                return {
                    rounded: true,
                    ...this.$attrs,
                };
            },
        },

        methods: {
            handleClick(event) {
                this.$emit('click', event);
            },
        },
    };
</script>

<style scoped>
    .btn {
        color: var(--v-gray2-base);
    }
</style>
