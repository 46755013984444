<template>
    <section
        class="timeline-item-container"
        :selected="isSelected"
        :expanded="isExpanded"
        :disabled="!item.allowed"
        @click="$emit('click')"
    >
        <!-- Line indicating selected item -->
        <BusyLine :item="item" :isBusy="isBusy" :isSelected="isSelected" class="busy-line" />
        <section class="case-data">
            <!-- Top row with categories-->
            <CaseCategoriesWithShowMore v-if="item.categories.length > 0" :categories="item.categories" />

            <!-- Mid section with title -->
            <section class="timeline-item-title">
                <!-- "Normal" icon or social icon -->
                <CaseIcon v-if="item.internalType !== 'social'" :internalType="item.internalType" />
                <CaseIconSocial v-else :source="item.source" :subType="item.internalSubType" />
                <!-- The title -->
                <TimeLineItemTitle :title="item.topic" />
            </section>
            <section class="bottom-row">
                <!-- Left -->
                <CaseDate :date="item.dateTime" class="date-time" />

                <!-- Right -->
                <QueueChipWithIconAndName v-if="caseQueue" :queue="caseQueue" class="queue-chip" />
                <CaseStatus :item="item" />
                <AgentAvatar :item="item" />
            </section>
        </section>
        <section class="expand-button">
            <ExpandButton v-if="item.subCasesCount > 0" :active="isExpanded" @click="$emit('expand')" />
        </section>
    </section>
</template>
½

<script lang="ts">
    import { defineComponent, PropType } from 'vue';
    import type { TranslateResult } from 'vue-i18n';
    import TimelineItemHeader from '@/components/TimelineItemHeader.vue';
    import TimelineItemAvatar from '@/components/Cases/TimelineItemAvatar.vue';
    import TimelineItemCard from '@/components/Cases/TimelineItemCard.vue';
    import TimelineItemChevron from '@/components/TimelineItemChevron.vue';
    import BusyLine from '@/components/BusyLine.vue';
    import CaseIcon from '@/components/Cases/BigTimeLineCaseItem/CaseIcon.vue';
    import CaseIconSocial from '@/components/Cases/BigTimeLineCaseItem/CaseIconSocial.vue';
    import TimeLineItemTitle from './TimeLineItemTitle.vue';
    import CaseDate from './CaseDate.vue';
    import QueueChipWithIconAndName from '@/components/Cases/BigTimeLineCaseItem/QueueChipWithIconAndName.vue';
    import { ClientTimelineItem } from '@/types/cases.types';
    import CaseCategoriesWithShowMore from './CaseCategoriesWithShowMore.vue';

    export default defineComponent({
        name: 'BigTimelineItemSubCases',
        components: {
            TimelineItemHeader,
            AgentAvatar: TimelineItemAvatar,
            CaseStatus: TimelineItemCard,
            ExpandButton: TimelineItemChevron,
            BusyLine,
            CaseIcon,
            CaseIconSocial,
            CaseDate,
            QueueChipWithIconAndName,
            TimeLineItemTitle,
            CaseCategoriesWithShowMore,
        },
        props: {
            item: {
                type: Object as PropType<ClientTimelineItem>,
                required: true,
            },
            isExpanded: {
                type: Boolean,
                default: false,
            },
            isSelected: {
                type: Boolean,
                default: false,
            },
            isBusy: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            caseQueue(): { name: string | TranslateResult; icon: string } | undefined {
                if (this.item.queue && this.item.queueIcon) {
                    return {
                        name: this.item.queue,
                        icon: this.item.queueIcon,
                    };
                }
                return undefined;
            },
        },
    });
</script>

<style scoped lang="scss">
    .timeline-item-container {
        overflow: hidden;
        position: relative;
        display: grid;
        grid-template-columns: 1fr 40px;
        align-items: center;
        gap: 4px;
        padding: 12px 0px 12px 20px;
        border-radius: var(--Corner-Radius-Corner-Radius-Medium, 8px);

        &:hover {
            background-color: var(--v-gray3-base);
            cursor: pointer;
        }

        &[disabled] {
            opacity: 0.5;
            pointer-events: none;
        }

        &[selected] {
            background-color: var(--v-gray3-base);
        }

        &[selected][expanded] {
            background-color: var(--v-gray5-darken1) !important;
        }

        & > * {
            align-self: stretch;
        }

        .busy-line {
            height: 67%;
            top: 50%;
            transform: translateY(-50%);
            width: 4px;
            overflow: hidden;
            border-radius: 0px 2px 2px 0px;
        }

        .case-data {
            display: grid;
            grid-template-rows: auto 1fr auto;
            gap: 4px;
        }

        .expand-button {
            display: grid;
            place-content: center;
        }

        .bottom-row {
            display: flex;
            align-items: center;
            gap: 8px;
            font-size: 12px;
            color: var(--v-gray1-darken4);

            .date-time {
                flex: 1;
            }
        }

        .queue-chip {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            text-align: right;
        }
    }

    .timeline-item-title {
        display: grid;
        grid-template-columns: auto 1fr;
        height: 30px;
        gap: 4px;
    }
</style>
