import axios from 'axios';
import { searchFields } from '../../enums/client.enums';

function searchClients(payload: string) {
    // '+' symbol == empty space in url-queries, thus we must do this replace to maintain it (phone numbers can have + symbols) -Teo 2022-06-14
    const formattedPayload = payload.replace('+', '%2B');
    return axios.get(`/clients?search=${formattedPayload}`);
}

export interface GetAllClientsDataPayload {
    page: number;
    search: string;
}

function getAllClientsData(payload: GetAllClientsDataPayload) {
    return axios.get('/clients', {
        params: {
            limit: 25,
            page: payload.page,
            search: payload.search,
            searchFields: [searchFields.NAME, searchFields.EMAIL, searchFields.TELEPHONE, searchFields.ADDRESS],
        },
    });
}

export interface GetAllContactsDataPayload {
    clientId: string;
    limitOffSet: number;
    limitAmount: number;
    search: string;
    fields: typeof searchFields;
}

function getAllContactsData(payload: GetAllContactsDataPayload) {
    return axios.get(
        `/contacts?clientId=${payload.clientId}&limit=${payload.limitOffSet || 0},${payload.limitAmount || 0}&search=${
            payload.search
        }&fields=${payload.fields}`,
    );
}

export interface AddNewClientPayload {
    name: string;
    parent: string | null;
    template: {
        fields: {
            name: string;
            primaryTelephone: string | null;
            secondaryTelephone: string | null;
            primaryEmail: string | null;
            secondaryEmail: string | null;
            address: string | null;
            post: string | null;
            tags: string | null;
            information: string | null;
            swedishPersonalIdentityNumber: string | null;
            contactContract: string | null;
            contactSupport: string | null;
            priorityPoints: number | null;
        };
        toggles: {
            confirmationEmail: number;
            includedInSearch: number;
        };
    };
    visibleExtraData: Record<string, unknown>;
    hiddenExtraData: Record<string, unknown>;
    clientType: number | null;
}

function addNewClient(payload: AddNewClientPayload) {
    return axios.post('/customer-management/clients', payload);
}

// @deprecated - Contact is not used in the system anymore
function addNewContact(payload: unknown) {
    return axios.post('/contact', payload);
}

function deleteClient(id: string) {
    return axios.delete(`/clients/${id}`);
}

function getClientTemplate() {
    return axios.get('/client/manifest/template');
}

function getContactTemplate() {
    return axios.get('/contact/manifest/template');
}

export interface UpdateContactInfoPayload {
    id: number;
    body: {
        nameField: string;
        value: string;
        includeInSearch: number;
        loggedInUserId: number;
        name: string;
        userLevel: number;
        visible: boolean;
    };
}

function updateContactInfo(payload: UpdateContactInfoPayload) {
    return axios.put(`/contact/${payload.id}`, payload);
}

export interface SetDefaultCreateClientPayload {
    queue: number;
    topic: string;
    description: string;
    status: string;
    SLA: number;
    id: number;
}

function setDefaultCreateClient(payload: SetDefaultCreateClientPayload) {
    return axios.post('/clients/defaultData', payload);
}
function getDefaultCreateClient() {
    return axios.get('/clients/defaultData/all');
}
function generateClientId() {
    return axios.get('/clients/generate/id');
}

function getContactPersonExtraData(id: string) {
    return axios.get(`/contact/${id}`);
}

function getClientExtraData(id: string) {
    return axios.get(`/customer/${id}`);
}

export interface GetAvailableUsersPayload {
    objectId: string;
    page: number;
    search: string;
}

function getAvailableUsers(payload: GetAvailableUsersPayload) {
    return axios.get(
        `/customer-management/users/available/${payload.objectId}?page=${payload.page}&search=${payload.search}`,
    );
}

export interface AddUsersToClientPayload {
    objectId: string;
    selectedUsers: number[];
    excludedUsers: number[];
    selectAll: boolean;
}

function addUsersToClient(payload: AddUsersToClientPayload) {
    return axios.post('/customer-management/users', payload);
}

export interface GetMembersPayload {
    objectId: string;
    page: number;
    search: string;
}

function getMembers(payload: GetMembersPayload) {
    return axios.get(
        `/customer-management/users/members/${payload.objectId}?page=${payload.page}&search=${payload.search}`,
    );
}

export interface RemoveAllMembersPayload {
    objectId: string;
}

function removeAllMembers(payload: RemoveAllMembersPayload) {
    return axios.delete(`/customer-management/users/${payload.objectId}`);
}

export interface RemoveMemberPayload {
    objectId: string;
    userId: number;
}

function removeMember(payload: RemoveMemberPayload) {
    return axios.delete(`/customer-management/user/${payload.objectId}/${payload.userId}`);
}

export interface UpdateClientPayload {
    id: string;
    body: {
        type: string;
        field: Record<string, unknown>;
    };
}

function updateClient(payload: UpdateClientPayload) {
    return axios.put(`/customer-management/clients/${payload.id}`, payload.body);
}

export interface CheckClientPayload {
    fields: unknown[];
}

function checkClient(payload: CheckClientPayload) {
    return axios.post('/customer-management/clients/check', payload);
}

export default {
    searchClients,
    getAllClientsData,
    getAllContactsData,
    addNewClient,
    addNewContact,
    deleteClient,
    getClientTemplate,
    getContactTemplate,
    updateContactInfo,
    setDefaultCreateClient,
    getDefaultCreateClient,
    generateClientId,
    getClientExtraData,
    getContactPersonExtraData,
    getAvailableUsers,
    addUsersToClient,
    getMembers,
    removeAllMembers,
    removeMember,
    updateClient,
    checkClient,
};
