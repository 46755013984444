import axios from 'axios';

export interface CreateTemplatePayload {
    categoryId: number;
    hidden: number;
    name: string;
    content: string;
    isActive: number;
    identifier: string;
    language: string;
    subject: string;
    attachments: {
        file: {
            id: number;
            contentBytes: string;
            name: string;
            contentType: string;
            contentId: string;
            size: number;
            msgId: string;
            isInline: boolean;
            context: {
                id: number | string | null;
                resource: 'comment' | 'template';
            };
        };
        id: string;
        type: string;
    }[];
    queues?: number[];
}

function createTemplate(template: CreateTemplatePayload) {
    return axios.post('/v2/templates', template);
}

export interface UpdateTemplatePayload {
    id: number;
    categoryId: number;
    hidden: number;
    name: string;
    content: string;
    isActive: number;
    identifier: string;
    language: string;
    subject: string;
    attachments: {
        file: {
            id: number;
            contentBytes: string;
            name: string;
            contentType: string;
            contentId: string;
            size: number;
            msgId: string;
            isInline: boolean;
            context: {
                id: number | string | null;
                resource: 'comment' | 'template';
            };
        };
        id: string;
        type: string;
    }[];
    queues?: number[];
}

function updateTemplate(template: UpdateTemplatePayload) {
    return axios.put(`/v2/templates/${template.id}`, template);
}

function removeTemplate(templateId: number) {
    return axios.delete(`/v2/templates/${templateId}`);
}

/**
 * Retrieves the categories of system templates.
 *
 * @returns {Promise} A promise that resolves with the categories.
 */
function categories() {
    return axios.get('/v2/templates/categories');
}

export interface TemplateByCategoryPagination {
    offset: number;
    limit: number;
    search: string;
    language: string;
    queueIds: number[];
}

/**
 * Retrieves templates by category.
 */
function templateByCategory(categoryId: number, pagination: TemplateByCategoryPagination) {
    const { offset, limit, search, language, queueIds } = pagination;

    return axios.get('/v2/templates', {
        params: {
            categoryId,
            offset,
            limit,
            query: search,
            language,
            queues: queueIds,
        },
    });
}

/**
 * Retrieves a template by its ID.
 */
function getTemplateById(templateId: number) {
    return axios.get(`/v2/templates/${templateId}`);
}

export default {
    categories,
    templateByCategory,
    getTemplateById,

    removeTemplate,
    updateTemplate,
    createTemplate,
};
