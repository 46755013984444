<template>
    <action-button v-bind="extendedAttrs" class="outlined-btn" @click="handleClick"><slot></slot></action-button>
</template>

<script>
    export default {
        name: 'SecondaryAction',
        computed: {
            extendedAttrs() {
                return {
                    outlined: true,
                    color: 'var(--v-gray2-base)',
                    ...this.$attrs,
                };
            },
        },

        methods: {
            handleClick() {
                this.$emit('click');
            },
        },
    };
</script>

<style scoped>
    .outlined-btn {
        background-color: transparent;
        border: 1px solid #ccc;
        color: var(--v-gray2-base);
        transition: all 0.2s;
    }

    .outlined-btn:hover {
        background-color: rgba(0, 0, 0, 0.1);
        color: black;
        border: 1px solid transparent;
    }
</style>
