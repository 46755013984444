export const creator = {
    OPEN: 'OPEN',
    CLOSED: 'CLOSED',
} as const;

export type Creator = (typeof creator)[keyof typeof creator];

export const inputTypes = {
    INPUT: 'secondary-input',
    MULTI_SELECT: 'multi-select',
    SINGLE_SELECT: 'standard-select',
    TEXT: 'standard-text',
    EMAIL_INPUT: 'email-input',
    STATUS_SELECT: 'status-select',
    QUEUE_SELECT: 'queue-select',
    EMAIL_QUEUE_SELECT: 'queue-select',
    SMS_QUEUE_SELECT: 'queue-select',
    CALL_QUEUE_SELECT: 'queue-select',
    TEMPLATE_SELECT: 'template-select',
    SMS_TEMPLATE_SELECT: 'template-select',
    SIGNATURE_SELECT: 'signature-select',
    TIME_SELECT: 'time-select',
    SLA_SELECT: 'sla-select',
    USER_SELECT: 'user-select',
    CLIENT_SELECT: 'client-select',
    TIP_TAP: 'tip-tap',
    SMS_TIP_TAP: 'sms-tip-tap',
    SOCIAL_SECURITY_NUMBER_INPUT: 'secondary-input',
    EMAIL_INPUT_SINGLE: 'email-input-single',
    EMAIL_INPUT_MULTI: 'email-input-multi',
    EMAIL_INPUT_SYSTEM: 'email-input-system',
    ADDRESS_SINGLE: 'address-single',
    SMS_PREVIEW: 'sms-preview',
    SENDER_NUMBER_SELECT: 'sender-number-select',
} as const;

export type InputTypes = (typeof inputTypes)[keyof typeof inputTypes];

export const inputTypesKeys = {
    TELEPHONE_INPUT: 'TELEPHONE_INPUT',
    SMS_INPUT: 'SMS_INPUT',
    SOCIAL_SECURITY_NUMBER_INPUT: 'SOCIAL_SECURITY_NUMBER_INPUT',
    EMAIL_QUEUE_SELECT: 'EMAIL_QUEUE_SELECT',
} as const;

export type InputTypesKeys = (typeof inputTypesKeys)[keyof typeof inputTypesKeys];

export const formTypes = {
    CREATE_INCOMING_EMAIL: 1,
    CREATE_OUTGOING_EMAIL: 2,
    CREATE_CLIENT: 3,
    BIND_CLIENT: 4,
    MERGE_CLIENT: 5,
    BIND_CONTACT: 6,
    CREATE_SMS: 7,
    CREATE_SMS_TO_CASE: 8,
    CREATE_EMAIL_TO_CASE: 9,
    CREATE_CALL: 10,
    CONNECT_CLIENT_TO_CASE: 11,
    CREATE_SUBCASE: 12,
} as const;

export type FormTypes = (typeof formTypes)[keyof typeof formTypes];
