import axios from 'axios';

//= ===================================================//
//                     Notes                          //
//= ===================================================//

function getNotes(id: number) {
    return axios.get(`/board/group/${id}/notes`);
}

export interface AddNewNotePayload {
    Ordinal: number;
    Title: string;
    Description: string;
    Tags: {
        ID: number;
        Name: string;
    };
    GroupId: number;
}

function addNewNote(payload: AddNewNotePayload) {
    return axios.post('/board/notes', payload);
}

export interface UpdateNotePayload {
    ID: number;
    Ordinal: number;
    Title: string;
    Description: string;
    Tags: {
        ID: number;
        Name: string;
    };
}

function updateNote(payload: UpdateNotePayload) {
    return axios.put(`/board/notes/${payload.ID}`, payload);
}

export interface UpdateOrdinalOnNotesPayload {
    items: {
        ID: number;
        Ordinal: number;
    }[];
}

function updateOrdinalOnNotes(items: UpdateOrdinalOnNotesPayload) {
    return axios.put('/board/notes/', items);
}

function deleteNote(id: number) {
    return axios.delete(`/board/notes/${id}`);
}

//= ===================================================//
//                      Tags                          //
//= ===================================================//

export interface AddNewTagPayload {
    Name: string;
}
function addNewTag(payload: AddNewTagPayload) {
    return axios.post('/board/tags', payload);
}

function getTags() {
    return axios.get('/board/tags');
}

function deleteTag(id: number) {
    return axios.delete(`/board/tags/${id}`);
}

export interface DeleteTagOnNotePayload {
    noteID: number;
    tagID: number;
}

function deleteTagOnNote(payload: DeleteTagOnNotePayload) {
    return axios.delete(`/board/notes/${payload.noteID}/tags/${payload.tagID}`);
}

export default {
    getNotes,
    addNewNote,
    deleteNote,
    addNewTag,
    updateNote,
    updateOrdinalOnNotes,
    getTags,
    deleteTag,
    deleteTagOnNote,
};
