import DedupeRequest from '@/entity/DedupeRequest';
import { jwtParser } from '@/utils';
import axios from 'axios';

// change url to /users/quick for faster result
function allUsers() {
    return axios.get('/users?type=extended');
}

function getUserNotification(userId: number) {
    return axios.get(`/users/${userId}/notification`);
}

async function getUsers(payload: string) {
    const result = await axios.get(`/userslist/${payload}`);
    const roles = jwtParser(result.config.headers.Authorization).Roles;
    // sort roles by largest to smallest
    roles.sort((a: number, b: number) => b - a);
    result.data.push(roles[0]);
    return result;
}

export interface GetSpecificUserInfoPayload {
    userId: number;
    data: string;
    searchType?: string;
}

function getSpecificUserInfo(payload: GetSpecificUserInfoPayload) {
    return axios.get(`/users/${payload.userId}/info/${payload.data}/${payload.searchType || '*'}`);
}

export interface UpdateUserPresencePayload {
    userId: number;
    data: {
        table: 'presence';
        value: boolean;
    };
}

function updateUserPresence(payload: UpdateUserPresencePayload) {
    return axios.put(`/users/${payload.userId}/presence`, payload.data);
}
function getIntegrationLink(payload: unknown) {
    return axios.get(`/soap/user/${payload}`);
}
function setIntegrationLink(payload: unknown) {
    return axios.put('/soap/user', payload);
}
function setLastHangup(query: string, userId: number, lastHangup: string) {
    return axios.put(`/users/${userId}/lastHangup?${query}`, { lastHangup });
}

export interface GetUsersAndRolesPayload {
    page?: number;
    search?: string;
    roleIdFilter?: string;
    selfFirst?: boolean;
}

function getUsersAndRoles(payload: GetUsersAndRolesPayload) {
    return axios.get(
        `/user-role-list?page=${payload?.page || 1}&search=${payload?.search || ''}&roles=${
            payload?.roleIdFilter || ''
        }&selfFirst=${payload?.selfFirst || false}`,
    );
}

function getUserCount() {
    return axios.get('/users/info/count');
}

//= ===================================================//
//                     Swyx   Users                    //
//= ===================================================//

export interface TogglePasswordChangePayload {
    userId: number;
    value: 0 | 1;
}

function togglePasswordChange(data: TogglePasswordChangePayload) {
    return axios.put(`/users/${data.userId}/credential/change`, { value: data.value });
}

// Store profile pictures for 30 seconds
const getUserProfileImageByUserIdDedupe = new DedupeRequest(
    ({ userId, resolution }: { userId: number; resolution: number }) => {
        return axios.get(`/users/${userId}/profileimage?resolution=${resolution}`);
    },
    30 * 1000,
);

function getUserProfileImageByUserId(userId: number, resolution: number) {
    return getUserProfileImageByUserIdDedupe.send({ userId, resolution });
}
function setUserProfileImage(userId: number, image: string) {
    return axios.post(`/users/${userId}/profileimage`, { image });
}

function getCurrentCommunications() {
    return axios.get('/queues/communications/user');
}

function getUser(userId: number) {
    return axios.get(`/users/${userId}`);
}

export interface UpdateUserFieldsPayload {
    userId: number;
    fields: {
        name?: string;
        email?: string;
        quarantine?: number;
        credentialChange?: number;
        title?: string;
        description?: string;
        internalNumber?: string;
        publicNumber?: string;
        mobileNumber?: string;
        extendedWrapup?: number;
    };
}

function updateUserFields({ userId, fields }: UpdateUserFieldsPayload) {
    return axios.patch(`/users/${userId}`, fields);
}

function getUserSettings(userId: number) {
    return axios.get(`/users/${userId}/settings`);
}

export interface SetUserSettingsSettings {
    showSystemNotifications?: boolean;
    autocompleteInComment?: boolean;
}

function setUserSettings(userId: number, settings: SetUserSettingsSettings) {
    return axios.put(`/users/${userId}/settings`, settings);
}

export interface GetAllUsersV2Payload {
    page: number;
    itemsPerPage: number;
    search: string;
    selfFirst: boolean;
    allowedUserStates: ('Enabled' | 'Disabled' | 'Deleted')[];
}

function getAllUsersV2(payload: GetAllUsersV2Payload) {
    return axios.get('/v2/users', { params: payload });
}

export interface UpdateUserPayload {
    activationStatus?: 'Enabled' | 'Disabled' | 'Deleted';
}

function updateUser(userId: number, data: UpdateUserPayload) {
    return axios.patch(`/v2/users/${userId}`, data);
}

export default {
    allUsers,
    getUserNotification,
    getUsers,
    getSpecificUserInfo,
    getUsersAndRoles,
    updateUserPresence,
    setIntegrationLink,
    getIntegrationLink,
    togglePasswordChange,
    getUserProfileImageByUserId,
    setUserProfileImage,
    getCurrentCommunications,
    setLastHangup,
    getUser,
    updateUserFields,
    getUserCount,
    getUserSettings,
    setUserSettings,
    getAllUsersV2,
    updateUser,
};
