<template>
    <div>
        <v-combobox
            v-bind="buttonPropsComputed"
            :items="availableAddresses"
            :search-input.sync="search"
            :loading="loading"
            @input="handleChange"
        />
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import { debouncer } from '@/utils';

    export default {
        name: 'AdressSingle',

        props: {
            icon: {
                type: String,
                default: '',
            },
            dense: {
                type: Boolean,
                default: true,
            },
        },

        data() {
            return {
                buttonProps: {
                    elevation: 0,
                    ripple: false,
                    color: 'white',
                    height: this.dense ? 40 : 50,
                    flat: true,
                    solo: true,
                    'hide-details': true,
                    outlined: true,
                    chips: true,
                },

                search: null,
                ruleStatus: '',
                loading: false,
                searchData: [],
                debounceGetData: null,
            };
        },

        computed: {
            ...mapState({
                currentFormData: (state) => state.Creator.currentFormData,
            }),

            availableAddresses() {
                return this.searchData;
            },

            buttonPropsComputed() {
                return {
                    ...this.buttonProps,
                    ...this.$attrs,
                    rules: [],
                };
            },
        },

        watch: {
            search: {
                handler() {
                    this.debounceGetData();
                },
            },
        },

        mounted() {
            const DEBOUNCE_TIME = 500;
            this.debounceGetData = debouncer(this.getData, DEBOUNCE_TIME);
        },

        methods: {
            ...mapActions({
                getClientContactPersons: 'Cases/getClientContactPersons',
                searchAddresses: 'Client/getAddresses',
            }),

            async getData() {
                if (!this.search) return;

                this.loading = true;

                const response = await this.searchAddresses({ search: this.search });

                const { data } = response;

                this.searchData = data;
                this.loading = false;
            },

            handleChange(value) {
                if (value?.length === 0) {
                    this.$emit('change', '');
                    return;
                }

                this.input = value;

                this.$emit('change', this.input);
            },

            handleClick(event) {
                this.$emit('click', event);
            },

            onSearch() {},
        },
    };
</script>

<style scoped>
    :deep(.v-btn__content) {
        letter-spacing: 0px !important;
        text-transform: none !important;
        font-weight: 400 !important;
    }

    .btn {
        color: var(--v-gray2-base);
        font-size: 1rem;
    }
</style>
