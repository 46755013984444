<template>
    <div class="integration" :style="active ? activeStyle : deactiveStyle" @click="$emit('click')">
        <v-progress-circular v-if="loading" color="primary" indeterminate />

        <template v-else>
            <img class="image" :src="getImage(integrationName)" />
            <div class="name text-truncate text-capitalize">{{ integrationName.toLowerCase() }}</div>
        </template>
    </div>
</template>
<script lang="ts">
    import { getImage } from '@/utils/Integrations';

    export default {
        props: {
            integrationName: {
                type: String,
                required: true,
            },
            active: {
                type: [Boolean, Number],
                default: true,
            },
            loading: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            activeStyle() {
                return {
                    cursor: 'pointer',
                };
            },

            deactiveStyle() {
                return {
                    filter: 'grayscale(100%)',
                    pointerEvents: 'none',
                    opacity: '0.5',
                };
            },
        },

        methods: {
            getImage,
        },
    };
</script>
<style scoped lang="scss">
    .integration {
        display: grid;
        grid-template-rows: 1fr auto;
        background-color: #fff;
        flex: 1;
        height: 100%;
        overflow: hidden;
        transition: background-color 0.2s ease-in-out;
        position: relative;
    }

    .integration > :first-child {
        margin-top: 16px;
        justify-self: center;
        padding-top: 0px;
    }

    .integration > :nth-child(2) {
        padding-bottom: 12px;
    }

    .integration:hover {
        background-color: var(--v-gray3-base);
        cursor: pointer;
    }

    .integration:focus {
        background-color: var(--v-gray3-base);
    }

    .integration:active {
        background-color: var(--v-gray2-base);
    }

    .name {
        font-size: 12px;
        font-weight: 400;
        color: var(--v-gray1-base);
        flex: 1;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        user-select: none;
    }

    .image {
        width: 26px;
        height: 26px;
        user-select: none;
    }
</style>
<i18n lang="json">
{
    "en": {},
    "sv": {}
}
</i18n>
