import axios from 'axios';

function getQueues() {
    return axios.get('/openinghours/queues');
}

function getAccessUsers() {
    return axios.get('/openinghours/access/users');
}

function getUserAccess(userId: number) {
    return axios.get(`/openinghours/access/users/${userId}/list`);
}

export interface AddUserAccessPayload {
    userId: number;
    access: {
        queueId: number;
        queueType: string;
    }[];
}

function addUserAccess(payload: AddUserAccessPayload) {
    return axios.post('/openinghours/access/users', payload);
}

function removeUserAccess(userId: number) {
    return axios.delete(`/openinghours/access/users/${userId}`);
}

export default {
    getQueues,
    getAccessUsers,
    getUserAccess,
    addUserAccess,
    removeUserAccess,
};
