import axios from 'axios';

export interface GetDynamicStatisticsPayload {
    userId: number;
    filter: {
        statuses: string[];
        items: number;
        page: number;
        userIds: string[];
        queues: string[];
        channels: string[];
        categories: string[];
        sortBy: string;
        direction: string;
        query: string;
        searchType: string;
        showUnansweredOnly: boolean;
        includeSubCases: boolean;
    };
    dataPoint: string;
}

function getDynamicStatistics(q: GetDynamicStatisticsPayload) {
    const params = {
        Statuses: q.filter.statuses.join(','),
        Limit: q.filter.items,
        Page: q.filter.page,
        UserIds: q.filter.userIds.join(','),
        GroupId: q.filter.queues.join(','),
        InternalType: q.filter.channels.join(','),
        CategoryIds: q.filter.categories.join(','),
        SortBy: q.filter.sortBy,
        Direction: q.filter.direction,
        Query: encodeURIComponent(q.filter.query),
        SearchType: q.filter.searchType,
        ID: q.userId,
        IncludeSubCases: q.filter.includeSubCases ? 1 : 0,
    } as Partial<Record<string, string | number>>;

    if (q.filter.showUnansweredOnly) {
        params.Answered = 0;
    }

    return axios.get('/dynamic-statistics', {
        params,
    });
}

export interface GetDynamicQueuesPayload {
    userId: number;
    filter: {
        statuses: string[];
        items: number;
        page: number;
        userId: number[];
        queues: number[];
        channels: string[];
        categories: number[];
        sortBy: string;
        direction: string;
        query: string;
        searchType: number;
        showUnansweredOnly: boolean;
        includeSubCases: boolean;
    };
    dataPoint: string;
}

function getDynamicQueues(q: GetDynamicQueuesPayload) {
    const params = {
        Statuses: q.filter.statuses.join(','),
        Limit: q.filter.items,
        Page: q.filter.page,
        UserId: q.filter.userId,
        GroupId: q.filter.queues.join(','),
        InternalType: q.filter.channels.join(','),
        CategoryIds: q.filter.categories.join(','),
        SortBy: q.filter.sortBy,
        Direction: q.filter.direction,
        Query: encodeURIComponent(q.filter.query),
        SearchType: q.filter.searchType,
        ID: q.userId,
        DataPoint: q.dataPoint,
        IncludeSubCases: q.filter.includeSubCases ? 1 : 0,
    } as Partial<Record<string, string | number | number[]>>;

    if (q.filter.showUnansweredOnly) {
        params.Answered = 0;
    }

    if (q.filter.includeSubCases) {
        params.IncludeSubCases = 1;
    }

    return axios.get('/dynamic-statistics/queues', {
        params,
    });
}

function getDynamicChoices(id: number) {
    return axios.get('/dynamic-statistics/choices', {
        params: {
            id,
        },
    });
}

export interface DeleteDynamicStatisticsPayload {
    id: number;
    userId: number;
}

function deleteDynamicStatistics(q: DeleteDynamicStatisticsPayload) {
    return axios.delete('/dynamic-statistics', {
        params: {
            id: q.id,
            userId: q.userId,
        },
    });
}

export interface UpdateDynamicStatisticsPayload {
    userId: number;
    dataPoint: number;
    listOrder: number;
}

function updateDynamicStatistics(q: UpdateDynamicStatisticsPayload) {
    return axios.put('/dynamic-statistics', {
        userId: q.userId,
        dataPoint: q.dataPoint,
        listOrder: q.listOrder,
    });
}

export interface AddDynamicStatisticsPayload {
    userId: number;
    dataPoint: number;
    listOrder: number;
}

function addDynamicStatistics(q: AddDynamicStatisticsPayload) {
    return axios.post('/dynamic-statistics', {
        userId: q.userId,
        dataPoint: q.dataPoint,
        listOrder: q.listOrder,
    });
}

export interface GetDynamicStatisticsItemPayload {
    id: number;
}

function getDynamicStatisticsItem(q: GetDynamicStatisticsItemPayload) {
    return axios.get('/dynamic-statistics/item', {
        params: {
            id: q,
        },
    });
}

export interface UpdateDynamicOrderPayload {
    userId: number;
    listOrder: number;
}

export interface UpdateDynamicOrderPayload {
    userId: number;
    order: number;
    id: number;
}

function updateDynamicOrder(q: UpdateDynamicOrderPayload, userId: number) {
    return axios.post(`/dynamic-statistics/order?&userId=${userId}`, q);
}

export interface GetCurveStatisticsPayload {
    filter: {
        statuses: string[];
        items: number;
        page: number;
        userIds: number[];
        queues: number[];
        channels: string[];
        categories: number[];
        sortBy: string;
        direction: string;
        query: string;
        searchType: number;
        showUnansweredOnly: boolean;
        includeSubCases: boolean;
    };
    dataPoint: number;
}

function getCurveStatistics(q: GetCurveStatisticsPayload) {
    const params = {
        Statuses: q.filter.statuses.join(','),
        Limit: q.filter.items,
        Page: q.filter.page,
        UserIds: q.filter.userIds,
        GroupId: q.filter.queues.join(','),
        InternalType: q.filter.channels.join(','),
        CategoryIds: q.filter.categories.join(','),
        SortBy: q.filter.sortBy,
        Direction: q.filter.direction,
        Query: encodeURIComponent(q.filter.query),
        SearchType: q.filter.searchType,
        DataPoint: q.dataPoint,
        IncludeSubCases: q.filter.includeSubCases ? 1 : 0,
    } as Partial<Record<string, string | number | number[]>>;

    if (q.filter.showUnansweredOnly) {
        params.Answered = 0;
    }

    if (q.filter.includeSubCases) {
        params.IncludeSubCases = 1;
    }

    return axios.get('/dynamic-statistics/curve', {
        params,
    });
}

export default {
    getDynamicStatistics,
    getDynamicChoices,
    deleteDynamicStatistics,
    updateDynamicStatistics,
    addDynamicStatistics,
    getDynamicStatisticsItem,
    updateDynamicOrder,
    getCurveStatistics,
    getDynamicQueues,
};
