import type {
    CreateCommentsData,
    CaseData,
    Comment,
    UploadAttachment,
    FileData,
    AttachmentObject,
    TemplateResponse,
    CommentPayload,
    UserData,
    SendCommentResponse,
    Global,
    CreateCommentsStateRaw,
    CommentData,
    StoreDispatch,
} from '@/types/createComments.state';

import type { useLocalStorage, useLocalStorageContent } from '@/composables/useLocalStorage';
import type { changeImgSrcToDataSrc } from '../../helpers/comments/comments.helper';
import type i18n from '../../i18n';
import type { Socket } from 'socket.io-client';
import type { Toasted } from 'vue-toasted';
import type * as api from '@/api/createComments/createComments.api';
import type * as shadowDom from '@/directives/shadowDom';
import type { Ref } from 'vue';

export interface ICreateCommentsService {
    uploadAttachment(files: UploadAttachment[]): Promise<AttachmentObject[] | null>;
    getCreateCommentsData(caseId: string): Promise<CreateCommentsData | null>;
    getSignatureTemplate(id: number): Promise<TemplateResponse | null>;
    useCreateCommentState(caseId: string): {
        autoComplete: Ref<{ value: boolean }>;
        subject: Ref<{ value: string }>;
        content: Ref<{ value: string }>;
        external: Ref<{ value: boolean }>;
        from: Ref<{ value: string }>;
        toRecipients: Ref<{ value: string[] }>;
        ccRecipients: Ref<{ value: string[] }>;
        bccRecipients: Ref<{ value: string[] }>;
        showCcAndBcc: Ref<{ value: boolean }>;
        minHeight: Ref<{ value: number }>;
        signature: Ref<{ value: CreateCommentsData['signature'] }>;
        files: Ref<{ value: CreateCommentsData['files'] }>;
        cursorPosition: Ref<{ value: number }>;
        resetSubject: () => void;
        resetContent: () => void;
        resetExternal: () => void;
        resetFrom: () => void;
        resetToRecipients: () => void;
        resetCcRecipients: () => void;
        resetBccRecipients: () => void;
        resetShowCcAndBcc: () => void;
        resetSignature: () => void;
        resetRepliedComment: () => void;
        resetAttachConversation: () => void;
        resetMinHeight: () => void;
        resetCursorPosition: () => void;
        resetAll: () => void;
    };
    getCaseData(caseId: string): Promise<CaseData>;
    generatePDF(caseId: string, title?: string): Promise<string>;
    processComments(comments: CaseData['comments']): CaseData['comments'];
    generateCommentHtml(filteredComments: CaseData['comments']): string;
    replaceTemplateValues(template: string, comment: Comment): string;
    generateFinalHtmlContent(commentHtmlContent: string, title: string): Promise<string>;
    printContent(finalContent: string): void;
    uploadNewFiles(files: FileData[], existingFileIds: number[]): Promise<number[]>;
    dockWindow(caseId: string, width: number, height: number, top: number, left: number, window: Window): void;
    closeWindow(window: Window): void;
    subscribeToCase(
        caseId: string,
        userData: { userId: number; userName: string },
        busyCaseList: { id: string }[],
    ): void;
    unsubscribeFromCase(caseId: string): void;
    setDefaultCommentState(
        commentsState: Partial<CreateCommentsStateRaw>,
        result: CreateCommentsData,
    ): Partial<CreateCommentsStateRaw>;
    resetCommentState(commentsState: Partial<CreateCommentsStateRaw>): Partial<CreateCommentsStateRaw>;
    postComment(
        commentsState: Partial<CreateCommentsStateRaw>,
        caseId: string,
        userData: UserData,
        closeCaseInDB: boolean,
    ): Promise<SendCommentResponse | { validationFailed: boolean } | { confirmed: boolean } | null>;
    sendComment(payload: CommentPayload): Promise<SendCommentResponse | null>;
    closeCase(window: Window): void;
    showToast(type: keyof Toasted, messageKey: string, toasted: Toasted): void;
    getAnswerEmailAdresses(
        item: CommentData,
        systemEmails: string[],
        allOrOne: 'all' | 'one',
    ): { toRecipients: string[]; ccRecipients: string[]; bccRecipients: string[]; from: string };

    forwardCase(
        commentsState: Partial<CreateCommentsStateRaw>,
        caseId: string,
    ): Promise<{ files: string[]; content: string }>;

    handleKeyDown(
        commentsState: Partial<CreateCommentsStateRaw>,
        saveFunction: (closeCaseInDB: boolean) => void,
        event: KeyboardEvent,
    ): void;

    getFilesSize(files: number[]): Promise<number | null>;
    getThreadSize(commentIds: number[]): Promise<{ threadSize: number; attachedFilesSize: number } | null>;
}

export class CreateCommentsService implements ICreateCommentsService {
    constructor(
        private readonly _global: Global,
        private readonly _toasted: Toasted,
        private readonly _i18n: typeof i18n,
        private readonly _socket: Socket,
        private readonly _api: typeof api,
        private readonly _shadowDom: typeof shadowDom,
        private readonly _document: Document,
        private readonly _localStorage: Storage,
        private readonly _window: Window,

        private readonly _changeImgSrcToDataSrc: typeof changeImgSrcToDataSrc,
        private readonly _useLocalStorage: typeof useLocalStorage,
        private readonly _useLocalStorageContent: typeof useLocalStorageContent,
        private readonly _storeDispatch: StoreDispatch,
    ) {}

    async getThreadSize(commentIds: number[]): Promise<{ threadSize: number; attachedFilesSize: number } | null> {
        try {
            const result = await this._api.getThreadSize(commentIds);
            return { threadSize: result.data.threadSize, attachedFilesSize: result.data.attachedFilesSize };
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    async getFilesSize(files: number[]): Promise<number | null> {
        try {
            const result = await this._api.getFilesSize(files);
            return result.data.fileSize;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    async sendComment(payload: CommentPayload) {
        try {
            const result = await this._api.sendComment(payload);
            return result.data;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    async uploadAttachment(files: UploadAttachment[]) {
        try {
            const result = await this._api.uploadAttachment(files);
            return result.data;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    async getCreateCommentsData(caseId: string): Promise<CreateCommentsData | null> {
        try {
            const result = await this._api.getCreateCommentsData(caseId);
            return result.data;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    async getSignatureTemplate(id: number) {
        try {
            const result = await this._api.getSignatureTemplate(id);
            return result.data;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    useCreateCommentState(caseId: string) {
        const { data: content, reset: resetContent } = this._useLocalStorageContent<string>(
            caseId,
            'content',
            '',
            this._shadowDom,
            this._localStorage,
        );

        const { data: autoComplete } = this._useLocalStorage<boolean>(null, 'autoComplete', true, this._localStorage);
        const { data: subject, reset: resetSubject } = this._useLocalStorage<string>(
            caseId,
            'subject',
            '',
            this._localStorage,
        );

        const { data: external, reset: resetExternal } = this._useLocalStorage<boolean>(
            caseId,
            'external',
            true,
            this._localStorage,
        );
        const { data: from, reset: resetFrom } = this._useLocalStorage<string>(caseId, 'from', '', this._localStorage);
        const { data: toRecipients, reset: resetToRecipients } = this._useLocalStorage<string[]>(
            caseId,
            'toRecipients',
            [],
            this._localStorage,
        );
        const { data: ccRecipients, reset: resetCcRecipients } = this._useLocalStorage<string[]>(
            caseId,
            'ccRecipients',
            [],
            this._localStorage,
        );
        const { data: bccRecipients, reset: resetBccRecipients } = this._useLocalStorage<string[]>(
            caseId,
            'bccRecipients',
            [],
            this._localStorage,
        );
        const { data: showCcAndBcc, reset: resetShowCcAndBcc } = this._useLocalStorage<boolean>(
            caseId,
            'showCcAndBcc',
            false,
            this._localStorage,
        );
        const { data: signature, reset: resetSignature } = this._useLocalStorage<CreateCommentsData['signature']>(
            caseId,
            'signature',
            null,
            this._localStorage,
        );
        const { data: files, reset: resetFiles } = this._useLocalStorage<CreateCommentsData['files']>(
            caseId,
            'files',
            [],
            this._localStorage,
        );

        const { data: repliedComment, reset: resetRepliedComment } = this._useLocalStorage<number | null>(
            caseId,
            'repliedComment',
            null,
            this._localStorage,
        );

        const { data: attachConversation, reset: resetAttachConversation } = this._useLocalStorage<boolean | null>(
            caseId,
            'attachConversation',
            null,
            this._localStorage,
        );

        const { data: minHeight, reset: resetMinHeight } = this._useLocalStorage<number>(
            null,
            'minHeight',
            500,
            this._localStorage,
        );

        const { data: cursorPosition, reset: resetCursorPosition } = this._useLocalStorage<number>(
            caseId,
            'cursorPosition',
            0,
            this._localStorage,
        );

        const resetAll = () => {
            resetSubject();
            resetContent();
            resetExternal();
            resetFrom();
            resetToRecipients();
            resetCcRecipients();
            resetBccRecipients();
            resetShowCcAndBcc();
            resetSignature();
            resetRepliedComment();
            resetAttachConversation();
            resetFiles();
            resetMinHeight();
            resetCursorPosition();
        };

        if (!content.value) {
            resetAll();
        }

        return {
            autoComplete,
            subject,
            content,
            external,
            from,
            toRecipients,
            ccRecipients,
            bccRecipients,
            showCcAndBcc,
            signature,
            files,
            repliedComment,
            attachConversation,
            minHeight,
            cursorPosition,
            resetSubject,
            resetContent,
            resetExternal,
            resetFrom,
            resetToRecipients,
            resetCcRecipients,
            resetBccRecipients,
            resetShowCcAndBcc,
            resetSignature,
            resetRepliedComment,
            resetAttachConversation,
            resetMinHeight,
            resetCursorPosition,
            resetAll,
        };
    }

    async getCaseData(caseId: string): Promise<CaseData> {
        try {
            const result = await this._api.getCaseData(caseId);
            return result.data;
        } catch (error) {
            console.error(error);
            return { caseId, topic: '', comments: [] };
        }
    }

    async generatePDF(caseId: string, title = 'Print Title') {
        const caseData = await this.getCaseData(caseId);

        if (!caseData) {
            this.showToast('error', 'createComments.errorFetchingCase');
            return '';
        }

        const comments = this.processComments(caseData.comments);
        const filteredComments = comments.filter((comment) => comment.typeOfMessage === 'email');
        const commentHtmlContent = this.generateCommentHtml(filteredComments);
        const finalContent = await this.generateFinalHtmlContent(commentHtmlContent, title);
        this.printContent(finalContent);
        return finalContent;
    }

    processComments(comments: CaseData['comments']) {
        if (Symbol.iterator in Object(comments)) {
            for (const comment of comments) {
                if (comment.comment) {
                    comment.comment = this._changeImgSrcToDataSrc(comment.comment);
                }
                if (comment.appendedComment) {
                    comment.appendedComment = this._changeImgSrcToDataSrc(comment.appendedComment);
                }
            }
        }
        return comments;
    }

    generateCommentHtml(filteredComments: CaseData['comments']) {
        const commentStructure = [
            '<br><hr>',
            '<br>',
            '<p><strong>{fromTitle}:</strong> {from}</p>',
            '<p><strong>{sendTitle}:</strong> {dateTime}</p>',
            '<p><strong>{toTitle}:</strong> {to}</p>',
            '<p><strong>{subjectTitle}:</strong> {subject}</p>',
            '<br>',
            `<p style="padding: 10px 0px">{comment}</p>`,
        ];

        let commentHtmlContent = '';
        let isFirstComment = true;

        for (const comment of filteredComments) {
            let commentHtml = `<div style="page-break-inside: avoid; break-inside: avoid">`;

            for (const template of commentStructure) {
                if (!isFirstComment && template === '<br><hr>') {
                    commentHtml += '<hr>';
                    continue;
                }

                commentHtml += this.replaceTemplateValues(template, comment);
            }

            commentHtml += '</div>';
            commentHtmlContent += commentHtml;
            isFirstComment = false;
        }

        return commentHtmlContent;
    }

    replaceTemplateValues(template: string, comment: Comment) {
        const commentDate = new Date(comment.dateTime);
        const readableDate = new Intl.DateTimeFormat('sv-SE', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
            timeZone: 'UTC',
        }).format(commentDate);

        return template
            .replace('{from}', comment.from)
            .replace('{dateTime}', readableDate)
            .replace(
                '{to}',
                `${comment.to || ''}${comment.to && (comment.ccTo || comment.bccTo) ? ', ' : ''}${comment.ccTo || ''}${comment.ccTo && comment.bccTo ? ', ' : ''}${comment.bccTo || ''}`,
            )
            .replace('{subject}', comment.subject)
            .replace('{comment}', comment.comment)
            .replace('{fromTitle}', this._i18n.t('comment.from').toString())
            .replace('{sendTitle}', this._i18n.t('comment.send').toString())
            .replace('{toTitle}', this._i18n.t('comment.to').toString())
            .replace('{subjectTitle}', this._i18n.t('comment.subject').toString());
    }

    async generateFinalHtmlContent(commentHtmlContent: string, title: string) {
        const div = this._document.createElement('div');
        div.innerHTML = commentHtmlContent;

        const hydratedElement = await this._shadowDom.hydrateImages(div);

        return `<html><head><title>${title}</title></head><body>${hydratedElement.innerHTML}</body></html>`;
    }

    printContent(finalContent: string) {
        const printFrame = this._document.createElement('iframe');
        printFrame.setAttribute('cy-data', 'pdfIframe');
        printFrame.name = 'printFrame';
        printFrame.style.position = 'absolute';
        printFrame.style.top = '-1000000px';
        printFrame.srcdoc = finalContent;

        this._document.body.append(printFrame);

        printFrame.contentWindow?.print();

        printFrame.contentWindow?.addEventListener('afterprint', () => {
            printFrame.remove();
        });
    }

    async uploadNewFiles(files: FileData[], existingFileIds: number[]): Promise<number[]> {
        const filesToUpload = files.filter((file) => !existingFileIds.includes(Number(file.id)));

        if (!filesToUpload.length) return [];

        const mappedFiles = filesToUpload.map((file) => ({
            contentBytes: file.file.contentBytes,
            contentType: file.file.contentType,
            name: file.file.name,
            size: file.file.size,
            isInline: file.file.isInline,
        }));

        const response = await this.uploadAttachment(mappedFiles);

        if (!response) return [];

        return response.filter((file) => file.id !== null && file.id !== undefined).map((file) => Number(file.id));
    }

    dockWindow(caseId: string, width: number, height: number, top: number, left: number) {
        const url = `${this._window.location.origin}/#/cases/${caseId}/tab`;
        this._window.open(url, '_blank', `width=${width},height=${height},top=${top},left=${left}`);
    }

    closeWindow() {
        this._window.close();
    }

    closeCase() {
        const url = `${this._window.location.origin}/#/cases`;
        this._window.location.href = url;
    }

    subscribeToCase(caseId: string, userData: { userId: number; userName: string }, busyCaseList: { id: string }[]) {
        const anyUserInCase = busyCaseList.some((item) => item.id === caseId);

        this._socket.emit('currentCaseSubscription', {
            id: caseId,
            userId: userData.userId,
            userName: userData.userName,
            socketId: this._socket.id,
            activeUser: anyUserInCase,
            joinRoom: false,
        });
    }

    unsubscribeFromCase(caseId: string) {
        this._socket.emit('currentCaseUnsubscription', {
            id: caseId,
            joinRoom: false,
        });
    }

    setDefaultCommentState(commentsState: Partial<CreateCommentsStateRaw>, result: CreateCommentsData) {
        // Check if the from email is a system email othervise use the from email from the result
        const isFromInSystemEmails = commentsState.from ? result.systemEmails.includes(commentsState.from) : false;

        commentsState.systemEmails = result.systemEmails;
        commentsState.from = isFromInSystemEmails ? commentsState.from : result.from;
        commentsState.signature ||= result.signature;
        commentsState.queueId = result.queueId;
        commentsState.currentQueueLanguage = result.currentQueueLanguage;
        commentsState.subject ||= result.subject;
        commentsState.toRecipients = commentsState.toRecipients?.length
            ? commentsState.toRecipients
            : result.toRecipients;
        commentsState.ccRecipients = commentsState.ccRecipients?.length ? commentsState.ccRecipients : [];
        commentsState.bccRecipients = commentsState.bccRecipients?.length ? commentsState.bccRecipients : [];
        commentsState.status = result.status;
        commentsState.clientId = result.clientId;
        commentsState.repliedComment ||= result.repliedComment;
        commentsState.content ||= result.content;
        commentsState.isSubcase ||= result.isSubcase;

        return commentsState;
    }

    resetCommentState(commentsState: Partial<CreateCommentsStateRaw>) {
        if (commentsState.resetAll) {
            commentsState.resetAll();
        }

        commentsState.systemEmails = [];
        commentsState.external = true;
        commentsState.showCcAndBcc = false;
        commentsState.from = '';
        commentsState.toRecipients = [];
        commentsState.ccRecipients = [];
        commentsState.bccRecipients = [];
        commentsState.queueId = null;
        commentsState.signature = null;
        commentsState.attachments = [];
        commentsState.subject = '';
        commentsState.files = [];
        commentsState.currentQueueLanguage = 'swe';
        commentsState.template = null;
        commentsState.status = null;
        commentsState.content = '';
        commentsState.sending = false;
        commentsState.sendingClose = false;
        commentsState.disabled = false;
        commentsState.clientId = null;
        commentsState.repliedComment = null;
        commentsState.attachConversation = null;
        commentsState.attachedConversations = [];

        return commentsState;
    }

    private async confirmAttachConversationFiles(commentsState: Partial<CreateCommentsStateRaw>) {
        const attachedConversations = commentsState.attachedConversations || [];

        let attachedConversationSize = 0;

        const result = await this.getThreadSize(attachedConversations);
        const threadSize = result?.threadSize ?? null;
        const attachedFilesSize = result?.attachedFilesSize ?? null;

        if (threadSize !== null) {
            attachedConversationSize = threadSize;
        } else {
            throw new Error('Error getting attached conversation size');
        }

        if (attachedConversationSize === 0 || attachedFilesSize === 0) {
            return true;
        }

        const { confirmed } = await this._global.dialogs.showConfirmationDialog({
            title: this._i18n.t('createComments.attachConversationFilesTitle').toString(),
            message: this._i18n.t('createComments.attachConversationFilesMessage').toString(),
            confirmText: this._i18n.t('global.yes').toString(),
            declineText: this._i18n.t('global.no').toString(),
        });

        return confirmed;
    }

    private async checkFilesSizeAndConfirm(commentsState: Partial<CreateCommentsStateRaw>) {
        const files = commentsState.files || [];
        const attachedConversations = commentsState.attachedConversations || [];

        let attachedConversationSize = 0;
        let embeddedFilesSize = 0;

        if (attachedConversations.length) {
            const result = await this.getThreadSize(attachedConversations);
            const threadSize = result?.threadSize ?? null;

            if (threadSize !== null) {
                attachedConversationSize = threadSize;
            } else {
                throw new Error('Error getting attached conversation size');
            }
        }

        if (files.length) {
            const filesSize = await this.getFilesSize(files);
            if (filesSize !== null) {
                embeddedFilesSize = filesSize;
            } else {
                throw new Error('Error getting files size');
            }
        }

        if (attachedConversationSize === null || embeddedFilesSize === null) {
            return false;
        }

        const maxFileSize = commentsState.maxFileSize || 0;

        if (attachedConversationSize + embeddedFilesSize > maxFileSize) {
            /**
             * Ask if we want to send the email without the attached conversation anyways
             */
            const { confirmed } = await this._global.dialogs.showConfirmationDialog({
                title: this._i18n.t('createComments.filesSizeTitle').toString(),
                message: this._i18n.t('createComments.filesSizeMessage').toString(),
                confirmText: this._i18n.t('global.yes').toString(),
                declineText: this._i18n.t('global.no').toString(),
            });

            return confirmed;
        }

        return true;
    }

    private async closeSubCases(caseId: string) {
        const confirmed = await this.handleSubCaseStatusChange(caseId);

        if (!confirmed) {
            return { confirmed: false };
        }

        try {
            const result = await this._api.updateCase({ caseId, closeSubCases: true, changed: {} });
            if (result.data.status !== 200) {
                console.error('Error closing subcases', result.data.message);
                return null;
            }
        } catch (error) {
            console.error('Error closing subcases', error);
            return null;
        }
    }

    async postComment(
        commentsState: Partial<CreateCommentsStateRaw>,
        caseId: string,
        userData: UserData,
        closeCaseInDB: boolean,
    ) {
        const validation = this.isValidComment(commentsState);

        if (validation.validationFailed) {
            this.showToast('info', `createComments.${validation.reason}`);
            return { validationFailed: true };
        }

        let attachThreadedFiles = commentsState.external ?? true;

        if (commentsState.attachConversation && commentsState.external) {
            const confirmed = commentsState.allwaysAttachThreadedFiles
                ? await this.checkFilesSizeAndConfirm(commentsState)
                : (await this.confirmAttachConversationFiles(commentsState)) &&
                  (await this.checkFilesSizeAndConfirm(commentsState));

            attachThreadedFiles = confirmed;
        }

        if (closeCaseInDB) {
            await this.closeSubCases(caseId);
        }
        const payload = this.createCommentPayload(commentsState, caseId, userData, closeCaseInDB, attachThreadedFiles);

        return this.sendComment(payload);
    }

    private async handleSubCaseStatusChange(caseId: string) {
        const subCasesWithOpenInProgress = await this._api.getSubCasesWithOpenInProgress(caseId);

        if (subCasesWithOpenInProgress.data.subcases === 0) {
            return true;
        }

        const { confirmed } = await this._global.dialogs.showConfirmationDialog({
            title: this._i18n.t('cases.changeSubCasesTitle').toString(),
            message: this._i18n.t('cases.changeSubCasesStatusMessage').toString(),
            confirmText: this._i18n.t('global.yes').toString(),
            declineText: this._i18n.t('global.no').toString(),
        });

        return confirmed;
    }

    private isValidComment(commentsState: Partial<CreateCommentsStateRaw>): {
        validationFailed: boolean;
        reason: string;
    } {
        if (!commentsState.external && (commentsState.content?.length ?? 0) > 0) {
            return { validationFailed: false, reason: 'internalComment' };
        }
        if (!commentsState.content?.length) {
            return { validationFailed: true, reason: 'contentRequired' };
        }
        if (!commentsState.from?.length) {
            return { validationFailed: true, reason: 'fromFieldRequired' };
        }
        if (!commentsState.toRecipients?.length) {
            return { validationFailed: true, reason: 'toRecipientsRequired' };
        }

        return { validationFailed: false, reason: 'none' };
    }

    private createCommentPayload(
        commentsState: Partial<CreateCommentsStateRaw>,
        caseId: string,
        userData: UserData,
        close: boolean,
        attachThreadedFiles: boolean,
    ): CommentPayload {
        return {
            receiver: this.getReceiver(commentsState),
            comment: commentsState.content || '',
            caseId,
            from: this.getSender(commentsState, userData),
            outgoing: this.getOutgoingStatus(commentsState),
            attachments: commentsState.files || [],
            typeOfMessage: this.getMessageType(commentsState),
            type: this.getMessageTypeCode(commentsState),
            subject: commentsState.subject || '',
            to: this.getRecipients(commentsState),
            ccTo: this.getCcRecipients(commentsState),
            bccTo: this.getBccRecipients(commentsState),
            attachConversation: commentsState.attachConversation ? 1 : 0,
            threadedAttaches: attachThreadedFiles ? 1 : 0,
            userId: userData.userId,
            signature: this.getSignature(commentsState),
            attachComments: commentsState.attachConversation ? commentsState.attachedConversations || [] : [],
            closeCase: close,
            repliedComment: commentsState.repliedComment || null,
        };
    }

    private getReceiver(commentsState: Partial<CreateCommentsStateRaw>): string | null {
        return commentsState.external ? commentsState.toRecipients?.[0] ?? null : null;
    }

    private getSender(commentsState: Partial<CreateCommentsStateRaw>, userData: UserData): string {
        return commentsState.external ? commentsState.from || '' : userData.userName;
    }

    private getOutgoingStatus(commentsState: Partial<CreateCommentsStateRaw>): number {
        return commentsState.external ? 1 : 0;
    }

    private getMessageType(commentsState: Partial<CreateCommentsStateRaw>): string {
        return commentsState.external ? 'email' : 'internal';
    }

    private getMessageTypeCode(commentsState: Partial<CreateCommentsStateRaw>): number {
        return commentsState.external ? 1 : 0;
    }

    private getRecipients(commentsState: Partial<CreateCommentsStateRaw>): string[] {
        return commentsState.external ? commentsState.toRecipients ?? [] : [];
    }

    private getCcRecipients(commentsState: Partial<CreateCommentsStateRaw>): string[] {
        return commentsState.external && commentsState.ccRecipients ? commentsState.ccRecipients : [];
    }

    private getBccRecipients(commentsState: Partial<CreateCommentsStateRaw>): string[] {
        return commentsState.external && commentsState.bccRecipients ? commentsState.bccRecipients : [];
    }

    private getSignature(commentsState: Partial<CreateCommentsStateRaw>): number | null {
        return commentsState.external && commentsState.signature ? commentsState.signature : null;
    }

    showToast(type: keyof Toasted, messageKey: string) {
        this._toasted[type](this._i18n.t(messageKey).toString(), {
            duration: 3000,
            icon: type === 'success' ? 'mdi-check' : 'mdi-alert',
        });
    }

    // biome-ignore lint/complexity/noExcessiveCognitiveComplexity: <hotfix will clean this up in another PR>
    getAnswerEmailAdresses(
        item: CommentData,
        systemEmails: string[] = [],
        allOrOne: 'all' | 'one' = 'one',
    ): {
        toRecipients: string[];
        ccRecipients: string[];
        bccRecipients: string[];
        from: string;
    } {
        if (!systemEmails.length) throw new Error('No system email available.');

        const isIncoming = item.outgoing === 0;
        const { from, to, ccTo, bccTo, replyTo } = item;

        let toRecipients: string[] = [];
        let ccRecipients: string[] = [];
        let bccRecipients: string[] = [];

        const onlyOneTo = to.length === 1;

        if (allOrOne === 'one') {
            if (replyTo) {
                toRecipients = [replyTo];
            } else {
                toRecipients = isIncoming ? [from] : onlyOneTo ? [to[0]] : [from];
            }
        }

        if (allOrOne === 'all') {
            if (replyTo) {
                toRecipients = isIncoming
                    ? [...to, replyTo].map((email) => email.trim()).filter((email) => !systemEmails.includes(email))
                    : [...to, from].map((email) => email.trim()).filter((email) => !systemEmails.includes(email));
            } else {
                toRecipients = [...to, from]
                    .map((email) => email.trim())
                    .filter((email) => !systemEmails.includes(email));
            }

            toRecipients = Array.from(new Set(toRecipients));
        }

        if (allOrOne === 'all') {
            ccRecipients = (Array.isArray(ccTo) ? ccTo : [ccTo]).filter((email): email is string => email !== null);
            bccRecipients = (Array.isArray(bccTo) ? bccTo : [bccTo]).filter((email): email is string => email !== null);
        } else {
            ccRecipients = [];
            bccRecipients = [];
        }

        const fromAddress = this.getFromAddress(item, systemEmails);

        return {
            toRecipients,
            ccRecipients,
            bccRecipients,
            from: fromAddress,
        };
    }

    private getFromAddress(item: CommentData, systemEmails: string[]): string {
        const isOutgoing = item.outgoing === 1;
        const { from, to } = item;

        return isOutgoing
            ? systemEmails.find((email) => from === email) || systemEmails[0]
            : systemEmails.find((email) => [...to, ...(item.ccTo || []), ...(item.bccTo || [])].includes(email)) ||
                  systemEmails[0];
    }

    async forwardCase(
        commentsState: Partial<CreateCommentsStateRaw>,
        caseId: string,
    ): Promise<{ files: string[]; content: string }> {
        const caseData = await this.getCaseData(caseId);

        if (!caseData) {
            this.showToast('error', 'createComments.errorFetchingCase');
            throw new Error('Error fetching case');
        }

        const filteredComments = caseData.comments.filter((comment) => comment.typeOfMessage === 'email');

        let commentContent = commentsState.content + '<br/>' || '';

        if (filteredComments.length > 0) {
            const commentHtmlContent = this.generateCommentHtml(filteredComments);
            commentContent += commentHtmlContent;
        }

        const files = filteredComments
            .flatMap((comment) => comment.attachments)
            .map((attachment) => {
                return {
                    file: {
                        contentBytes: null,
                        name: '',
                        contentId: null,
                        contentType: '',
                        size: 0,
                        isInline: false,
                        lastModifiedDateTime: '',
                        id: attachment?.id,
                    },
                    caseId,
                    id: attachment?.id,
                    type: 'reference',
                    loading: true,
                };
            });

        const filesAttached = (commentsState.files ?? []).map((id) => {
            return {
                file: {
                    contentBytes: null,
                    name: '',
                    contentId: null,
                    contentType: '',
                    size: 0,
                    isInline: false,
                    lastModifiedDateTime: '',
                    id: id,
                },
                caseId,
                id: id,
                type: 'reference',
                loading: true,
            };
        });

        const allFiles = [...files, ...filesAttached];

        await this._storeDispatch(
            'Creator/openForm',
            {
                id: 12, // CREATE_SUBCASE
                keys: {
                    caseId: { value: caseId },
                    status: { value: 'Closed' },
                    text: { existingContent: commentContent, referenceFiles: allFiles },
                    topic: { value: caseData.topic },
                    from: commentsState.from ? { value: commentsState.from } : undefined,
                },
            },
            { root: true },
        );

        return {
            files: files.map((file) => file.id).filter((id) => id !== null) as string[],
            content: commentContent,
        };
    }

    handleKeyDown(
        commentsState: Partial<CreateCommentsStateRaw>,
        saveFunction: (closeCaseInDb: boolean) => void,
        event: KeyboardEvent,
    ) {
        const ctrlShiftEnter = event.ctrlKey && event.shiftKey && event.key === 'Enter';
        if (ctrlShiftEnter && !commentsState.disabled && commentsState.shortCutsSendClose) {
            saveFunction(true);
            return;
        }
        const ctrlEnter = event.ctrlKey && event.key === 'Enter';
        if (ctrlEnter && !commentsState.disabled && commentsState.shortCutsSend) {
            saveFunction(false);
        }
    }
}
