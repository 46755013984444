import axios from 'axios';

function getRecordingsMetaData(msgId: string) {
    return axios.get(`/comments/recording/${msgId}/meta-data`);
}
function getRecording(msgId: string) {
    return axios.get(`/comments/recording/${msgId}/file`);
}

function getCommentIds(caseId: string) {
    return axios.get(`/comments/${caseId}/ids`);
}

export function resetCommentTries(commentId: number, from: string) {
    return axios.post(`/comments/${commentId}/reset-tries`, { newFrom: from });
}

export function deleteFailedComment(commentId: number) {
    return axios.delete(`/comments/${commentId}`);
}

export function deleteComment(commentId: number) {
    return axios.delete(`/comments/${commentId}`);
}

export function getThreadSize(attachedComments: number[]) {
    return axios.get('/comments/threadsize', {
        params: {
            attachedComments,
        },
    });
}

export default {
    getRecording,
    getRecordingsMetaData,
    getCommentIds,
    resetCommentTries,
    deleteComment,
};
