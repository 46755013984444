import axios, { type AxiosResponse } from 'axios';
import type { ChatDataDto, ChatDataKey } from '@c1-ab/api-types/chat-data-types';

export function getChatStatus(caseId: string) {
    return axios.get(`/chats/${caseId}/status`);
}

export interface AnswerChatPayload {
    state: string;
    msgId: string;
    userId: string;
    user: unknown; // Stored user object from Auth.userObject
    queueId: string;
}

export function answerChat(payload: AnswerChatPayload) {
    return axios.post('/chatmanager/answer-case', payload);
}

export function getChatData(
    id: number,
    filter: ChatDataKey[] = ['chat', 'client', 'messages'],
): Promise<AxiosResponse<Partial<ChatDataDto>>> {
    return axios.get(`/chatmanager/chat/${id}`, {
        params: {
            filter,
        },
    });
}

export default {
    getChatData,
    getChatStatus,
    answerChat,
};
