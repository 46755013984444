<template>
    <div id="country-container" :class="{ disabled: disabled }">
        <standard-select v-model="modelValue" :items="filteredCountries" v-bind="$attrs" outlined searchable left />
    </div>
</template>

<script>
    import { countries } from '@/helpers/countries/countries.helper';

    export default {
        name: 'CountrySelect',
        props: {
            value: {
                type: String,
                default: null,
            },
            filter: {
                type: Array,
                default: null,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['input'],
        data() {
            return {
                countries,
            };
        },

        computed: {
            modelValue: {
                get() {
                    return {
                        id: this.value,
                        value: this.value,
                        text: this.value ? this.$t(`country.${this.value}`) : this.$t('countrySelect.noCountry'),
                        icon: this.value ? 'mdi-flag' : 'mdi-close',
                    };
                },
                set(value) {
                    this.$emit('input', value);
                },
            },
            filterSet() {
                return new Set(this.filter);
            },
            filteredCountries() {
                const countries = [{ code: null }, ...this.countries];

                if (!this.filter) {
                    return countries.map((country) => {
                        return {
                            id: country.code,
                            value: country.code,
                            text: country.code
                                ? this.$t(`country.${country.code}`)
                                : this.$t('countrySelect.noCountry'),
                            icon: country.code ? 'mdi-flag' : 'mdi-close',
                        };
                    });
                }

                return countries
                    .filter((country) => this.filterSet.has(country.code))
                    .map((country) => {
                        return {
                            id: country.code,
                            value: country.code,
                            text: country.code
                                ? this.$t(`country.${country.code}`)
                                : this.$t('countrySelect.noCountry'),
                            icon: country.code ? 'mdi-flag' : 'mdi-close',
                        };
                    });
            },
        },
    };
</script>

<style scoped>
    .disabled {
        pointer-events: none;
        opacity: 0.5;
    }
</style>

<i18n lang="json">
{
    "sv": {
        "countrySelect": {
            "noCountry": "Inget land"
        }
    },
    "en": {
        "countrySelect": {
            "noCountry": "No country"
        }
    }
}
</i18n>
