export const userRoles = {
    BASIC: 1,
    AGENT: 2,
    GROUP_ADMIN: 3,
    SUPER_ADMIN: 4,
    AI: 5,
    DEVELOPER: 99,
} as const;

// Type for userRoles
export type UserRole = keyof typeof userRoles;
