<template>
    <article>
        <!-- Queue icon -->
        <i :class="iconClass"></i>
        <!-- Queue name -->
        <span>
            {{ queue.name }}
        </span>
    </article>
</template>
<script lang="ts">
    import { defineComponent, PropType } from 'vue';
    import { TranslateResult } from 'vue-i18n';
    export default defineComponent({
        props: {
            queue: {
                type: Object as PropType<{ name: string | TranslateResult; icon: string }>,
                required: true,
            },
        },
        computed: {
            iconClass() {
                return `mdi ${this.queue.icon}`;
            },
        },
    });
</script>
<style scoped>
    article {
        background: white;
        border: 1px solid var(--v-gray5-base);
        i {
            margin-right: 4px;
        }
        padding: 4px;
        border-radius: 4px;
    }
</style>
