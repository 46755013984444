<template>
    <phone-number
        :defaultPhoneNumber="extendedAttrs.value"
        v-bind="extendedAttrs"
        :allowInternalNumbers="false"
        :showErrorMessage="false"
        @input="handleInput"
    />
</template>
<script>
    export default {
        /**
         * This is a wrapper component for the PhoneNumber component.
         * It is used by Creator.vue in order to abide to how components work there
         * This component does not allow internal numbers.
         * @emits input - Emitted when the phone number input inputs. the formatted number as a string.
         */

        computed: {
            extendedAttrs() {
                return {
                    ...this.$attrs,
                };
            },
        },
        methods: {
            handleInput(number) {
                this.$emit('input', number);
            },
        },
    };
</script>
