<template>
    <standard-select
        v-bind="buttonPropsComputed"
        :key="selectedQueue"
        :value="selectedSender"
        :items="defaultSenderItems"
        :single="true"
        @change="handleChange"
    />
</template>

<script>
    import { mapActions, mapState } from 'vuex';

    import { inputTypesKeys } from '@/enums/creator.enums';

    export default {
        name: 'EmailSystem',

        props: {
            value: {
                type: String,
                default: '',
            },
            icon: {
                type: String,
                default: '',
            },
            dense: {
                type: Boolean,
                default: true,
            },
        },

        data() {
            return {
                buttonProps: {},
                selectedQueue: null,
            };
        },

        computed: {
            ...mapState({
                companyEmails: (state) => state.Cases.systemEmails,
                queueFilters: (state) => state.Cases.caseFilters.queues,
                currentFormData: (state) => state.Creator.currentFormData,
                selectedCase: (state) => state.Cases.selectedCase,
            }),

            buttonPropsComputed() {
                return {
                    ...this.buttonProps,
                    ...this.$attrs,
                    rules: [],
                    dense: false,
                };
            },

            selectedSender() {
                return this.defaultSenderItems.find((item) => item.value === this.selectedQueue);
            },

            defaultSender() {
                // Email address based on queue
                if (this.queueSelect) {
                    const emailAddressFromQueue = this.queueFilters.find(
                        (queue) => queue.ID === this.queueSelect.value
                    ).defaultSenderEmail;

                    if (emailAddressFromQueue) {
                        return emailAddressFromQueue;
                    }
                }

                return this.defaultSenderItems.at(0);
            },

            emailBasedOnQueue() {
                if (!this.queueSelect || !this.queueFilters.length) {
                    return null;
                }

                return this.queueFilters.find((queue) => queue.ID === this.queueSelect.value).defaultSenderEmail;
            },

            queueSelect() {
                const queueSelect = this.currentFormData.form.inputs.find(
                    (input) => input.type === inputTypesKeys.EMAIL_QUEUE_SELECT
                );
                return queueSelect || null;
            },

            defaultSenderItems() {
                return this.companyEmails.map((item) => ({
                    text: item,
                    value: item,
                    name: item,
                    icon: 'mdi-email',
                    color: 'primary',
                }));
            },
        },

        watch: {
            currentFormData: {
                handler(newVal, oldVal) {
                    this.handleQueueChange(newVal, oldVal);
                },
                deep: true,
            },
        },

        created() {
            this.getAndSetDefault();
        },

        methods: {
            ...mapActions({
                getSystemEmails: 'Cases/getSystemEmails',
                getQueueIdentifierByQueueId: 'QueueManager/getQueueIdentifierByQueueId',
            }),

            /**
             * Handle the queue select input change by updating the email system select input if we can find a default email address on queue
             *
             * @param {object} newVal The new form data
             * @param {object} oldVal The old form data
             */
            handleQueueChange(newVal, oldVal) {
                // Check if the form data exists
                if (!this.currentFormData.form) return;

                if (!this.queueSelect) return;

                // Check if the old form data exists
                const oldQueueSelect = oldVal.form.inputs.find(
                    (input) => input.type === inputTypesKeys.EMAIL_QUEUE_SELECT
                );
                if (!oldQueueSelect) return;

                // Make sure queue values aren't the same and that they're not null
                if (this.queueSelect.value && this.queueSelect.value === oldQueueSelect.value) return;

                if (this.emailBasedOnQueue) {
                    // Set the default sender to the first identifier
                    this.selectedQueue = this.emailBasedOnQueue;
                    this.$emit('change', this.emailBasedOnQueue);
                    this.$emit('input', this.emailBasedOnQueue);
                }
            },

            async getAndSetDefault() {
                if (!this.companyEmails.length) await this.getSystemEmails();

                // If the current selected case has a queue, set the default sender to the queue's default sender
                if (this.selectedCase?.case?.queueId) {
                    const defaultSenderByQueueId = this.queueFilters.find(
                        (queue) => queue.ID === this.selectedCase.case.queueId
                    ).defaultSenderEmail;

                    if (defaultSenderByQueueId) {
                        this.$emit('change', defaultSenderByQueueId);
                        this.$emit('input', defaultSenderByQueueId);
                        return;
                    }
                }

                // If the current case have a case author on outgoing case
                if (
                    this.selectedCase?.case?.author &&
                    this.companyEmails.includes(this.selectedCase?.case?.author) &&
                    this.selectedCase?.case?.outgoing === 1
                ) {
                    this.$emit('change', this.selectedCase.case.author);
                    this.$emit('input', this.selectedCase.case.author);
                    return;
                }

                // If the current case have a case respondent on incoming case
                if (
                    this.selectedCase?.case?.respondent &&
                    this.companyEmails.includes(this.selectedCase?.case?.respondent) &&
                    this.selectedCase?.case?.outgoing === 0
                ) {
                    this.$emit('change', this.selectedCase.case.respondent);
                    this.$emit('input', this.selectedCase.case.respondent);
                    return;
                }

                if (this.value && this.companyEmails.includes(this.value)) {
                    this.selectedQueue = this.value;
                    this.$emit('change', this.value);
                    this.$emit('input', this.value);
                    return;
                }

                if (this.defaultSenderItems.length > 0) {
                    const defaultEmail = this.defaultSenderItems.at(0).value;
                    this.selectedQueue = defaultEmail;
                    this.$emit('change', defaultEmail);
                    this.$emit('input', defaultEmail);
                }
            },

            handleChange(value) {
                if (!value) {
                    this.selectedQueue = null;
                    this.$emit('change', '');
                    this.$emit('input', '');
                    return;
                }

                this.selectedQueue = value.at(0).value;
                this.$emit('change', value.at(0).value);
                this.$emit('input', value.at(0).value);
            },

            handleClick(event) {
                this.$emit('click', event);
            },
        },
    };
</script>

<style scoped>
    :deep(.v-btn__content) {
        letter-spacing: 0px !important;
        text-transform: none !important;
        font-weight: 400 !important;
    }

    .btn {
        color: var(--v-gray2-base);
        font-size: 1rem;
    }
</style>
