import axios from 'axios';

export interface CreateRolePayload {
    role: {
        name: string;
        description: string;
        groups: number[];
    };
    openHours: {
        id: number;
        active: boolean;
        times: [string, string];
    }[];
}

function createRole(payload: CreateRolePayload) {
    return axios.post('/v2/userroles', payload);
}

function deleteRole(id: number) {
    return axios.delete(`/userroles/${id}`);
}

export interface AddMembersToRolePayload {
    roleId: number;
    selectAll: boolean;
    members: number[];
    excludedMembers: number[];
    groups: number[];
}

function addMembersToRole(payload: AddMembersToRolePayload) {
    return axios.put(`/userroles/members/${payload.roleId}`, payload);
}

export interface SaveRoleDescriptionPayload {
    roleId: number;
    desc: string;
}

function saveRoleDescription(payload: SaveRoleDescriptionPayload) {
    return axios.put(`/userroles/${payload.roleId}/description`, payload);
}

export interface DeleteUserFromRolePayload {
    user: {
        RoleMembershipId: number;
    };
}

function deleteUserFromRole(payload: DeleteUserFromRolePayload) {
    return axios.delete(`/userroles/member/${payload.user.RoleMembershipId}`, { data: payload });
}

function deleteAllUsersFromRole(roleId: number) {
    return axios.delete(`/userroles/all/members/${roleId}`);
}

export interface AddRoleOwnershipPayload {
    roleId: number;
    groupId: number;
    data: {
        members: number[];
        groupId: number;
    };
}

function addRoleOwnership(payload: AddRoleOwnershipPayload) {
    return axios.post('/userroles/ownership', payload);
}

export interface DeleteRoleOwnershipPayload {
    roleId: number;
    groupId: number;
}

function deleteRoleOwnership(payload: DeleteRoleOwnershipPayload) {
    return axios.delete(`/userroles/ownership/${payload.roleId}`, { data: payload });
}

function getAllRoles() {
    return axios.get('/userroles');
}

function getRolesByUserId(userId: number) {
    return axios.get('/v2/userroles/users/' + userId);
}

function loginToRole(userId: number, roleId: number) {
    return axios.put(`/userroles/${roleId}/login/${userId}`);
}

function logoutOfRole(userId: number, roleId: number) {
    return axios.put(`/userroles/${roleId}/logout/${userId}`);
}

export interface RoleOpeningHoursPayload {
    roleId: number;
    openHours: {
        relId: number;
        active: boolean;
        times: [string, string];
    }[];
}

function roleOpeningHours(payload: RoleOpeningHoursPayload) {
    return axios.put('/userroles/openhours', payload);
}

export interface GetRoleMembersPayload {
    roleId: number;
    page: number;
    search: string;
}

function getRoleMembers(payload: GetRoleMembersPayload) {
    return axios.get(`/userroles/members/${payload.roleId}?page=${payload.page}&search=${payload.search}`);
}

export interface GetAvailableUsersPayload {
    roleId: number;
    page: number;
    search: string;
}

function getAvailableUsers(payload: GetAvailableUsersPayload) {
    return axios.get(`/userroles/available/users/${payload.roleId}?page=${payload.page}&search=${payload.search}`);
}

export default {
    createRole,
    getAllRoles,
    addMembersToRole,
    saveRoleDescription,
    deleteUserFromRole,
    deleteAllUsersFromRole,
    addRoleOwnership,
    deleteRoleOwnership,
    deleteRole,
    getRolesByUserId,
    roleOpeningHours,
    getRoleMembers,
    getAvailableUsers,
    loginToRole,
    logoutOfRole,
};
