import axios from 'axios';

export interface QueueCategoryRelationsPayload {
    queueId: number;
    categoryId: number;
    parent: boolean;
}

function getQueueCategoryRelations(payload: QueueCategoryRelationsPayload) {
    return axios.get('/categories/queues/relations', {
        params: {
            queueId: payload.queueId,
            categoryId: payload.categoryId,
            parent: payload.parent,
        },
    });
}

export interface UpdateQueueCategoryRelationsPayload {
    queueId: number[];
    categoryId: number;
    parent: string;
}

function updateQueueCategoryRelations(payload: UpdateQueueCategoryRelationsPayload) {
    return axios.put('/categories/queues/relations', payload);
}

export interface GetCategoriesPayload {
    queueId: number;
    sortBy: string;
}

function getCategories(payload: GetCategoriesPayload) {
    return axios.get('/categories', { params: payload });
}

export default {
    getQueueCategoryRelations,
    updateQueueCategoryRelations,
    getCategories,
};
