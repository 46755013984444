<template>
    <standard-button v-bind="extendedAttrs" class="primary-button" @click="handleClick"><slot></slot></standard-button>
</template>

<script>
    export default {
        name: 'PrimaryButton',
        computed: {
            extendedAttrs() {
                return {
                    ...this.$attrs,
                    color: 'var(--v-primary-base)',
                    rounded: false,
                };
            },
        },

        methods: {
            handleClick() {
                this.$emit('click');
            },
        },
    };
</script>

<style scoped>
    .primary-button {
        color: white !important;
    }
</style>
