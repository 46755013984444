import axios from 'axios';
import DedupeRequest from '../../entity/DedupeRequest';

const oneSecond = 1000;
const fiveSeconds = 5000;
const dedupeAPIs = {
    getQueues: new DedupeRequest((filters?: unknown) => {
        return axios.get('/queues', { params: filters });
    }, oneSecond),
    getQueuesV2: new DedupeRequest((filters?: unknown) => {
        return axios.get('/v2/queues', { params: filters });
    }, oneSecond),
    getQueueDefaultSignature: new DedupeRequest((id) => {
        return axios.get(`queues/${id}/signatures`);
    }, fiveSeconds),
};

// TODO: Not validated correctly in backend
function getPopupTriggerInfo(channelId: string) {
    return axios.get(`queue/notification/triggers/${channelId}`);
}

// TODO: Not validated correctly in backend
function savePopupTriggerInfo(data: unknown) {
    return axios.post('queue/notification/triggers', data);
}

function searchKeys() {
    return axios.get('queues/search/keys');
}

function getLiveQueueManagerStatistics({
    queueId,
    channelType,
    from,
    to,
}: { queueId: number; channelType: string; from: string; to: string }) {
    return axios.get(`/queue/current/statistics?queueId=${queueId}&channelType=${channelType}&from=${from}&to=${to}`);
}

function getQueueCDR({ queueId, channelType, type }: { queueId: number; channelType: string; type: string }) {
    return axios.get(`/queue/current/cdr/?queueId=${queueId}&channelType=${channelType}&type=${type}`);
}

function getQueues(filters?: {
    types: string[];
    isSystemQueue: boolean;
    users: number[];
    brands: number[];
    includes: string[];
}) {
    // @ts-ignore <This accepts only an object as a parameter>
    return dedupeAPIs.getQueues.send(filters);
}

function getQueuesV2(filters?: {
    types: string[];
    isSystemQueue: boolean;
    users: number[];
    brands: number[];
    includes: string[];
}) {
    // @ts-ignore <This accepts only an object as a parameter>
    return dedupeAPIs.getQueuesV2.send(filters);
}

function setQueuesOrder(payload: { ID: number; Position: number }[]) {
    return axios.put('/queues', payload);
}

function getAllQueuesWithAllInfo(channelType: string) {
    return axios.get(`/queues/explicit/${channelType}`);
}

function getUsersQueuesWithGroups(userId: number) {
    return axios.get(`/users/${userId}/queues/groups`);
}

function getQueueOnIdentifier(identifier: string) {
    return axios.get(`/queues/groups/identifier/${identifier}`);
}

function getAllQueuesChosenUser(userId: number) {
    return axios.get(`/queues/chosen/user/${userId}`);
}

function getAllQueuesByLoggedInUser() {
    return axios.get('/queues/user');
}

function getQueuesByUserId(userId: number) {
    return axios.get(`/queues/user/${userId}`);
}

function getQueueTooltipInfo(queueId: number) {
    return axios.get(`/queues/${queueId}/tooltip`);
}

function getQueuesInfo({ queueId, channelType }: { queueId: number; channelType: string }) {
    return axios.get(`/queue/${queueId}/info/${channelType}`);
}

function getQueueIdentityProvider(queueId: number) {
    return axios.get(`/queues/${queueId}/identity-provider`);
}

function queueById(id: number) {
    return axios.get(`/queues/${id}`);
}
function getQueueSettingsById(id: number) {
    return axios.get(`/queue/${id}/settings`);
}

function saveQueue(payload: {
    ID: number;
    Name: string;
    Created: string;
    Priority: number;
    Description: string;
    Active: boolean;
    IsSystemQueue: boolean;
    Position: number;
    CallByCase: boolean;
    Signature: string;
    DisplayNumber: string;
    DefaultSenderEmail: string;
    Language: string;
    BrandId: number;
    Icon: string;
    Categories: number[];
    AgentTimeoutDurationMs: number;
    Audiences: number[];
    IdentityProvider: number;
    allowIdentification: number;
}) {
    return axios.put(`/queues/${payload.ID}`, payload);
}

function createQueue(payload: {
    name: string;
    isSystemQueue: boolean;
    brandId: number;
    icon: string;
}) {
    return axios.post('/queues', payload);
}

function deleteQueue(payload: number) {
    return axios.delete(`/queues/${payload}`, { data: payload });
}

// Channels

function getAllChannels(queueId: number) {
    return axios.get(`/queues/${queueId}/channels`);
}

function getChannelsByQueues(payload: { queueIds: number[] }) {
    return axios.get(`/queues/${payload.queueIds}/channels`);
}

function getActiveChannelsInQueue(queueId: number) {
    return axios.get(`/queue/${queueId}/channels/active`);
}

function getSpecificChannel(payload: { qId: number; cId: number }) {
    return axios.get(`/queues/${payload.qId}/channels/${payload.cId}`);
}

function explicitlyGetAllChannelsByQueue(payload: number) {
    return axios.get(`/queues/${payload}/channelsExplicit`);
}

function getExternalSurveyUrl(payload: { qId: number; type: string }) {
    /// queues/:queueId/channels/survey/:type
    return axios.get(`/queues/${payload.qId}/channels/survey/${payload.type}`);
}

// TODO: This isnt validated correctly in backend
function updateChannel(payload: { queueId: number }) {
    return axios.put(`/queues/${payload.queueId}/channels`, payload);
}

function getQueuesByActiveChannelType(channelType: string) {
    return axios.get(`/queues/active-channel/${channelType}`);
}

function setSpecificChannelValue(payload: { id: number; value: { Active: boolean } }) {
    return axios.put(`/queues/channel/${payload.id}`, payload.value);
}

function deleteIdentifiers(ids: number[]) {
    return axios.delete('/queues/channel/identifiers', { data: ids });
}

function getAgentsInQueue(queueId: number) {
    return axios.get(`/queues/${queueId}/agents`);
}

function getAllAgents() {
    return axios.get('/queues/agents/all');
}

function addGroupsToQueue(payload: { queueId: number; groups: number[] }) {
    return axios.post(`/queues/${payload.queueId}/group`, payload);
}

// TOOD: Can't find this route in backend?
function updateAgents(payload: unknown) {
    return axios.put('/queues/agents', payload);
}

function getCompanyGroupsInQueue({ id, type }: { id: number; type: string }) {
    return axios.get(`/queues/${id}/groups?type=${type || ''}`);
}

// TODO: Not validated correctly in backend
function updateGroupChannelsInQueue(payload: { queueId: number }) {
    return axios.put(`/queues/${payload.queueId}/group`, payload);
}

function deleteCompanyGroupFromQueue(payload: { queueId: number; groupId: number }) {
    return axios.delete(`/queues/${payload.queueId}/group/${payload.groupId}`);
}

function loginLogoutToQueue(payload: {
    queueMemberships: { queueId: number; groupId: number; active: boolean }[];
    userId: number;
}) {
    return axios.put(`/queues/agent/${payload.userId}`, payload);
}

// TODO: Not validated correctly in backend
function channelLoginLogoutInQueue(payload: unknown) {
    return axios.put('/queues/channels/agent', payload);
}

// assign QIP

function getQueueIdentifierByQueueId(payload: { queueId: number; type: string }) {
    return axios.get(`/queues/${payload.queueId}/identifiers/?type=${payload.type}`);
}

function assignIdentifiersToQueue(payload: {
    queueId: number;
    channelType: string;
    identifierType: string;
    identifiers: string[];
}) {
    return axios.post(`/queues/${payload.queueId}/identifiers/${payload.channelType}/${payload.identifierType}`, {
        identifiers: payload.identifiers,
    });
}

function findIdentifiers({
    queueId,
    identifiers,
    type,
    identifierType,
}: { queueId: number; identifiers: string[]; type: string; identifierType: string }) {
    return axios.get(`/queues/${queueId}/identifiers/exist/`, {
        params: {
            identifiers,
            type,
            identifierType,
        },
    });
}

function getQueuesOverviewData(type: string) {
    return axios.get(`/queues/overview/${type}`);
}

// Social Media for Queues
function getSocialSettings(channelId: string) {
    return axios.get(`social/settings/${channelId}`);
}

function setSocialSettings(payload: { socialMediaChannels: unknown[]; tenant: { channelId: string } }) {
    return axios.post(`social/settings/${payload.tenant.channelId}`, payload);
}

function getEmailToReadArray() {
    return axios.get('/emailsToRead');
}

function getQueueDefaultSignature(id: number) {
    // @ts-ignore <This accepts only an object as a parameter>
    return dedupeAPIs.getQueueDefaultSignature.send(id);
}
// TODO: Can't find this in backend?
function addScheduledOverflow(payload: { queueId: number; body: { date: string; value: number } }) {
    return axios.post(`/queues/overflow/${payload.queueId}`, { body: payload });
}

function getGlobalSettings() {
    return axios.get('/queues/global-settings');
}

function updateGlobalSettings(payload: { id: number; value: number }[]) {
    return axios.put('/queues/global-settings', payload);
}

function getAudioFilesDeletion(queueId: number) {
    return axios.get(`/queues/${queueId}/audio-files/deletion`);
}

function setAudioFilesDeletion(payload: { active: boolean; days: number; queueId: number }) {
    return axios.post('/queues/audio-files/deletion', payload);
}

function getDisplayNumbers() {
    return axios.get('/queues/display-numbers');
}

function getLoggedInQueuesByUserId(userId: number) {
    return axios.get(`/queues/logged-in/${userId}`);
}

export default {
    getPopupTriggerInfo,
    savePopupTriggerInfo,
    searchKeys,
    getLiveQueueManagerStatistics,
    getQueueCDR,
    getQueues,
    getQueuesV2,
    setQueuesOrder,
    getAllQueuesWithAllInfo,
    getUsersQueuesWithGroups,
    getQueueOnIdentifier,
    getAllQueuesChosenUser,
    getAllQueuesByLoggedInUser,
    getQueuesByUserId,
    getQueueTooltipInfo,
    getQueuesInfo,
    getQueueIdentityProvider,
    queueById,
    getQueueSettingsById,
    saveQueue,
    createQueue,
    deleteQueue,
    getAllChannels,
    getChannelsByQueues,
    getActiveChannelsInQueue,
    getSpecificChannel,
    explicitlyGetAllChannelsByQueue,
    getExternalSurveyUrl,
    updateChannel,
    getQueuesByActiveChannelType,
    setSpecificChannelValue,
    deleteIdentifiers,
    getAgentsInQueue,
    getAllAgents,
    addGroupsToQueue,
    updateAgents,
    getCompanyGroupsInQueue,
    updateGroupChannelsInQueue,
    deleteCompanyGroupFromQueue,
    loginLogoutToQueue,
    channelLoginLogoutInQueue,
    getQueueIdentifierByQueueId,
    assignIdentifiersToQueue,
    findIdentifiers,
    getQueuesOverviewData,
    getSocialSettings,
    setSocialSettings,
    getEmailToReadArray,
    getQueueDefaultSignature,
    addScheduledOverflow,
    getGlobalSettings,
    updateGlobalSettings,
    getAudioFilesDeletion,
    setAudioFilesDeletion,
    getDisplayNumbers,
    getLoggedInQueuesByUserId,
};
