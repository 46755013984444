<template>
    <section>
        <v-textarea v-bind="extendedAttrs" @input="handleInput" @change="handleChange" @blur="handleBlur">
            <slot></slot>
        </v-textarea>
        <section v-if="extendedAttrs.counter" class="counter">
            {{ $attrs.value.length }}
        </section>
    </section>
</template>

<script>
    export default {
        name: 'TextArea',

        props: {
            validated: {
                type: Boolean,
                default: true,
            },
        },

        data() {
            return {
                buttonProps: {
                    elevation: 0,
                    color: 'white',
                    flat: true,
                    solo: true,
                    'hide-details': true,
                    outlined: true,
                },
            };
        },

        computed: {
            extendedAttrs() {
                return {
                    ...this.buttonProps,
                    ...this.$attrs,
                };
            },
        },

        methods: {
            handleClick() {
                this.$emit('click');
            },

            handleInput(value) {
                this.$emit('input', value);
            },

            handleChange(value) {
                this.$emit('change', value);
            },

            handleBlur(value) {
                this.$emit('blur', value);
            },
        },
    };
</script>

<style scoped>
    :deep(.v-input__control) {
        min-height: 40px !important;
    }

    .counter {
        display: flex;
        justify-content: flex-end;
        font-size: 12px;
        color: var(--v-gray2-base);
    }
</style>
