<template>
    <v-menu
        v-model="menuOpen"
        :close-on-content-click="false"
        transition="scale-transition"
        :top="top"
        :bottom="bottom"
        :left="left"
        :right="right"
        :offset-y="offset"
        :attach="menuAttach"
    >
        <template #activator="{}">
            <div v-if="slot" @click="menuOpen = !menuOpen"><slot></slot></div>

            <tertiary-action-button v-else v-bind="selectPropsComputed" @click="menuOpen = !menuOpen">
                <article v-if="selectedItems.length > 0" class="item-text item">
                    <div class="avatar-item">
                        <CommonAvatar
                            :key="selectedItem.UserID"
                            :userId="selectedItem.UserID"
                            :size="20"
                            class="mr-3"
                        />
                        <v-list-item-title class="item-text">{{ getName(selectedItem) }}</v-list-item-title>
                    </div>

                    <v-icon size="18" class="ml-2" color="var(--v-gray2-base)">mdi-chevron-down</v-icon>
                </article>
                <article v-else class="display-item item">
                    <div class="select-item">
                        {{ $t('selects.selectItems') }}
                    </div>
                    <v-icon size="18" class="ml-2" color="var(--v-gray2-base)">mdi-chevron-down</v-icon>
                </article>
            </tertiary-action-button>
        </template>

        <section class="action-item-container">
            <header>
                <search-input :key="menuOpen" :value="input" :focused="isFocused" input-radius @input="setSearch" />
            </header>

            <article class="user-list-wrapper">
                <section
                    ref="scrollContainer"
                    :key="input"
                    class="user-list"
                    :style="userListStyle"
                    @scroll="checkScroll"
                >
                    <article
                        v-for="(item, index) in users.data"
                        :key="item.UserID"
                        ref="menuItemRef"
                        class="user-list-item"
                        :class="[
                            listItemClass(item),
                            { 'selected-item': isSelected(item), 'highlighted-item': arrowCounter === index },
                        ]"
                        tabindex="-1"
                        @click="selectItem(item)"
                    >
                        <v-list-item-avatar class="avatar-user-list-item">
                            <CommonAvatar :key="item.UserID" :userId="item.UserID" :size="20" />
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title
                                cy-data="agent"
                                :cy-text="item.Name"
                                :cy-value="item.UserID"
                                :class="isSelected(item) ? 'item-text' : 'item-text-not-selected'"
                            >
                                {{ item.Name }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </article>

                    <v-progress-circular v-if="loading" color="primary" indeterminate size="28" />
                </section>
            </article>
        </section>
    </v-menu>
</template>

<script>
    import { mapActions } from 'vuex';
    import CommonAvatar from '@/components/Global/CommonAvatar.vue';
    import { debouncer } from '@/utils';
    import api from '../../../api/users/users';

    export default {
        name: 'UsersSelect',

        components: {
            CommonAvatar,
        },

        props: {
            value: {
                type: null,
                required: true,
            },
            single: {
                type: Boolean,
                default: false,
            },
            menuAttach: {
                type: null,
                default: undefined,
            },
            hideAllUsers: {
                type: Boolean,
                default: false,
            },
            top: {
                type: Boolean,
                default: false,
            },
            bottom: {
                type: Boolean,
                default: false,
            },
            left: {
                type: Boolean,
                default: false,
            },
            right: {
                type: Boolean,
                default: false,
            },
            offset: {
                type: Boolean,
                default: true,
            },
        },

        data() {
            return {
                selectProps: {
                    rounded: true,
                    color: 'var(--v-gray3-base)',
                    filled: true,
                    dense: true,
                    hideDetails: true,
                },

                loading: false,
                input: '',
                page: 1,
                users: {},
                menuOpen: false,
                selectedItems: [],
                hasMore: false,
                handleChangeDebouncer: null,
                isFocused: false,
                arrowCounter: 0,
                eventListener: null,
                firstMenuItemRef: null,
            };
        },

        computed: {
            selectPropsComputed() {
                return {
                    ...this.selectProps,
                    ...this.$attrs,
                };
            },

            selectedItem() {
                return this.selectedItems.at(0);
            },

            slot() {
                return this.$attrs.useSlot !== undefined;
            },

            userListStyle() {
                if (this.loading) {
                    return {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    };
                }
                return {};
            },
            isFirstItem() {
                return this.users.data.length > 0 && this.isSelected(this.users.data[0]);
            },
        },

        watch: {
            value(newVal) {
                this.selectedItems = [newVal];
            },

            menuOpen(newMenuOpen) {
                if (newMenuOpen) {
                    window.addEventListener('keydown', this.eventListener);
                    this.setSearch('');
                    this.$nextTick(() => {
                        this.isFocused = true;
                        this.$refs.scrollContainer.focus();
                    });
                } else {
                    window.removeEventListener('keydown', this.eventListener);
                    this.isFocused = false;
                }
            },
        },

        mounted() {
            this.firstMenuItemRef = this.$refs.menuItemRef;
        },

        beforeDestroy() {
            window.removeEventListener('keydown', this.eventListener);
        },

        created() {
            this.init();
            this.setSelectedItems();
            this.setEventListener();
        },

        methods: {
            ...mapActions('Users', {
                getUsersAndRoles: 'getUsersAndRoles',
            }),

            listItemClass(item) {
                if (this.isSelected(item)) {
                    return 'button-selected';
                }
                return 'button-not-selected';
            },

            setEventListener() {
                this.eventListener = (event) => {
                    if (event.key === 'ArrowDown' || event.key === 'ArrowUp' || event.key === 'Enter') {
                        this.handleKeyDown(event);
                    }
                };
            },

            getName(selectedItem) {
                const notDivertedUserId = -1;
                const allUsersUserId = 0;

                switch (selectedItem.UserID) {
                    case allUsersUserId: {
                        return this.$t('users.all');
                    }
                    case notDivertedUserId: {
                        return this.$t('users.unassigned');
                    }
                    default: {
                        return selectedItem.Name;
                    }
                }
            },

            setSelectedItems() {
                if (!this.value) return;
                this.selectedItems = [this.value];
            },

            setSearch(input) {
                this.input = input;
                this.fetchUsers(1).then((users) => {
                    this.users = users;
                    this.arrowCounter = 0;

                    // If the input is empty and the value is not empty, set the selected items to the value
                    if (this.value && !this.input) {
                        this.selectedItems = [this.value];
                        return;
                    }
                    const firstUser = this.users.data[0];
                    if (!firstUser) return;
                    const userId = firstUser.UserID;
                    // Else, set the selected items to the first user
                    this.selectedItems = [{ value: userId, Name: firstUser.Name, UserID: userId }];
                });
            },

            init() {
                this.debouncedSearch = debouncer(async () => {
                    this.page = 1;
                    await this.getData();
                }, 500);

                this.handleChangeDebouncer = debouncer(this.handleChange, 500);
            },

            checkScroll() {
                const container = this.$refs.scrollContainer;
                const { scrollHeight } = container;
                const { scrollTop } = container;
                const { clientHeight } = container;

                const PAGINATION_OFFSET = 5;

                if (Math.floor(scrollHeight - scrollTop) - PAGINATION_OFFSET > clientHeight || !this.hasMore) {
                    return;
                }

                this.page++;
                this.fetchUsers(this.page).then((users) => {
                    this.users.data = [...this.users.data, ...users.data];
                    this.hasMore = users.hasMore;
                });
            },

            async getData() {
                this.loading = true;
                const { data } = await this.fetchUsers(1);
                this.users = data;
                this.loading = false;
            },

            async fetchUsers(page = 1) {
                try {
                    const { data } = await api.getAllUsersV2({
                        search: this.input,
                        page,
                        itemsPerPage: 20,
                        selfFirst: true,
                        allowedUserStates: ['Enabled'],
                    });
                    const users = data;

                    // Add additional items for the first page
                    if (page === 1 && !this.input) {
                        if (this.$attrs && this.$attrs.hideUnassigned === undefined) {
                            users.data.unshift({ UserID: -1, Name: this.$t('users.unassigned') });
                        }

                        if (this.$attrs.hideAll === undefined) {
                            users.data.unshift({ UserID: 0, Name: this.$t('users.all') });
                        }

                        if (this.$attrs.hideNoUser === undefined) {
                            users.data.unshift({ UserID: 0, Name: this.$t('users.noUser') });
                        }
                    }

                    this.hasMore = users.hasMore;

                    return {
                        hasMore: users.hasMore,
                        data: users.data.map((user) => {
                            return {
                                ...user,
                                value: user.UserId || user.UserID,
                            };
                        }),
                    };
                } catch {
                    return {
                        hasMore: false,
                        data: [],
                    };
                }
            },

            handleKeyDown(event) {
                // prevent the default action (scroll / move caret)
                event.preventDefault();
                switch (event.key) {
                    case 'ArrowDown': {
                        if (this.arrowCounter >= this.users.data.length - 1) return;
                        this.arrowCounter++;

                        break;
                    }

                    case 'ArrowUp': {
                        if (this.arrowCounter <= 0) return;
                        this.arrowCounter--;

                        break;
                    }

                    case 'Enter': {
                        const selectedItem = this.users.data[this.arrowCounter];
                        if (selectedItem && selectedItem.item) {
                            this.selectItem(selectedItem.item);
                            break;
                        }
                        this.selectItem(this.users.data[this.arrowCounter]);
                        break;
                    }

                    default: {
                        break;
                    }
                }

                const selectedItem = this.$refs.menuItemRef[this.arrowCounter];
                if (selectedItem && selectedItem.$el) {
                    selectedItem.$el.scrollIntoView({
                        block: 'nearest',
                        inline: 'nearest',
                    });
                    // Loads more items as you scroll.
                    this.checkScroll();
                }
            },

            selectItem(item) {
                if (!item) return;
                this.selectedItems = [item];
                this.handleChangeDebouncer(item);
                if (this.single) {
                    this.menuOpen = false;
                }
            },

            isSelected(item) {
                return this.selectedItems.some((selectedItem) => selectedItem.value === item.value);
            },

            handleChange(value) {
                this.$emit('input', value);
                this.$emit('change', value);
            },
        },
    };
</script>

<style scoped>
    :deep(.v-input__prepend-inner) {
        align-self: center;
        margin: 0px !important;
        padding: 0px !important;
        white-space: nowrap !important;
    }

    div[role='menu'] {
        border-radius: 8px;
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2) !important;
        border: 1px solid #eee;
        padding: 4px;
        background-color: var(--v-gray3-base);
        min-width: 240px !important;
        max-width: 240px !important;
    }

    .select-item {
        display: flex;
        align-items: center;
        flex-direction: row;
    }

    .avatar-item {
        display: flex;
        align-items: center;
        flex-direction: row;
    }

    .user-list-wrapper {
        border-radius: 8px;
        max-height: 312px;
        min-height: 312px;
        background-color: var(--v-gray3-base);

        box-sizing: border-box;
        display: flex;
        overflow: hidden;
    }

    .user-list {
        max-height: 100%;
        width: 100%;
        overflow-y: auto;
        display: flex;
        gap: 4px;
        flex-direction: column;
        /** if there are less items than the height can we make the grid items go to the top */
        justify-content: flex-start;
    }
    .icon-container {
        padding-right: 16px;
    }

    .list-container {
        display: flex;
        align-items: center;
        flex-direction: row;
    }

    .item {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        flex: 1;
    }
    .item-text {
        font-size: 0.9rem !important;
        font-weight: 400 !important;
        color: var(--v-gray4-base);
    }

    .item-text-not-selected {
        font-size: 0.9rem !important;
        font-weight: 400 !important;
        color: var(--v-gray2-base);
    }

    .user-list-item {
        padding: 0 !important;
        padding-left: 8px !important;
        padding-right: 8px !important;
        height: 48px !important;
        margin-bottom: 0px;
        border-radius: 8px;
        background-color: var(--v-gray3-base);
        overflow: hidden;
        transition: all 0.1s ease-in-out;
        max-height: 48px !important;
        min-height: 48px !important;
        display: fleX;
        align-items: center;
    }

    .selected-item {
        background-color: white;
        box-sizing: border-box;
    }

    .display-item {
        display: flex;
        align-items: center;
        flex-direction: row;
        font-size: 0.9rem !important;
        font-weight: 400 !important;
        color: var(--v-gray2-base);
    }
    .action-item-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .highlighted-item {
        background-color: rgb(0, 0, 0, 0.1);
    }

    .avatar-users {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .avatar-user-list-item {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>

<i18n lang="json">
{
    "en": {
        "users": {
            "all": "All",
            "unassigned": "Unassigned",
            "noUser": "Unassign"
        },

        "selects": {
            "selectItems": "Select",
            "allItemsSelected": "All",
            "itemsSelected": "selected"
        }
    },
    "sv": {
        "users": {
            "all": "Alla",
            "unassigned": "Ej tilldelade",
            "noUser": "Ej tilldelad"
        },

        "selects": {
            "selectItems": "Välj",
            "allItemsSelected": "Alla",
            "itemsSelected": " valda"
        }
    }
}
</i18n>
