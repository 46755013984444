import axios from 'axios';

function getFavouriteEmails(queueId: number, limit: number, offset: number) {
    return axios.get(`/favourites/${queueId}/emails?limit=${limit}&offset=${offset}`);
}

function addFavouriteEmail(queueId: number, email: string) {
    return axios.post(`/favourites/${queueId}/emails`, { email });
}

function removeFavouriteEmail(queueId: number, email: string) {
    return axios.delete(`/favourites/${queueId}/emails/${email}`);
}

function getFrequentEmails(queueId: number, limit: number, offset: number) {
    return axios.get(`/favourites/${queueId}/frequent-emails?limit=${limit}&offset=${offset}`);
}

function getCombinedEmails(queueId: number, limit: number, offset: number, search: string) {
    return axios.get(
        `/favourites/${queueId}/combined-emails?limit=${limit}&offset=${offset}&search=${encodeURIComponent(search)}`,
    );
}

function getQueueEmails(queueId: number, limit: number, offset: number, search: string) {
    return axios.get(
        `/favourites/${queueId}/queue-emails?limit=${limit}&offset=${offset}&search=${encodeURIComponent(search)}`,
    );
}

export default {
    getFavouriteEmails,
    addFavouriteEmail,
    removeFavouriteEmail,
    getFrequentEmails,
    getCombinedEmails,
    getQueueEmails,
};
