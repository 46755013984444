export const channelTypes = {
    EMAIL: 'email',
    CALL: 'call',
    CHAT: 'chat',
    SMS: 'sms',
    SOCIAL: 'social',
    CALLBACK: 'callback',
    FORM: 'form',
} as const;

export type ChannelTypes = keyof typeof channelTypes;

export default {
    channelTypes,
};
