<template>
    <v-menu
        v-model="menuOpen"
        :close-on-content-click="false"
        transition="scale-transition"
        :top="top"
        :bottom="bottom"
        :left="left"
        :right="right"
        :offset-y="offset"
        :attach="menuAttach"
    >
        <template #activator="{}">
            <div v-if="slot" @click="open"><slot></slot></div>

            <slot v-else name="button" :open="open">
                <div class="d-flex">
                    <tertiary-action-button
                        :disabled="selectPropsComputed.error"
                        v-bind="selectPropsComputed"
                        @click="open"
                    >
                        <article v-if="selectPropsComputed.error" class="error-text">{{ $t('selects.error') }}</article>
                        <article v-else-if="selectedItems.length > 0" class="item-text item-button">
                            <article class="select-item-button">
                                <slot name="button-prepend-content" :selectedItems="selectedItems">
                                    <v-icon
                                        v-if="selectedItems.length !== items.length"
                                        size="18"
                                        class="mr-3"
                                        :color="selectedItems.at(0).color"
                                    >
                                        {{ selectedItems.at(0).icon }}
                                    </v-icon>
                                </slot>
                                <span>{{ generateCustomText }}</span>
                                <span>{{ extraGenerateCustomTextItems }}</span>
                            </article>

                            <v-icon size="18" class="ml-3" color="var(--v-gray2-base)">mdi-chevron-down</v-icon>
                        </article>
                        <article v-else class="display-item item">
                            <section class="select-item">
                                {{ $t('selects.selectItems') }}
                            </section>
                            <v-icon size="18" class="ml-3" color="var(--v-gray2-base)">mdi-chevron-down</v-icon>
                        </article>
                    </tertiary-action-button>
                </div>
            </slot>
        </template>

        <article class="action-item-container" :cy-data="collectionName">
            <header>
                <search-input input-radius :value="search" @input="setSearch" :autofocus="autoFocusSearch" />
            </header>
            <article class="item-list-wrapper">
                <section class="item-list">
                    <article v-for="(item, index) in itemsWithSearch" :key="index">
                        <slot name="selectable-item" :item="item" :selected="isSelected(item)" :select="selectItem">
                            <SimpleTooltip
                                :enabled="isDisabled(item)"
                                :tooltipText="$t('selects.itemUnavailable')"
                                right
                                white
                            >
                                <div class="list-item" :class="listItemClasses(item)" @click="selectItem(item)">
                                    <slot name="item-slot" :item="item" :selected="isSelected(item)">
                                        <slot name="list-prepend-icon" :item="item">
                                            <v-list-item-avatar>
                                                <v-icon size="18" :color="iconColor(item)">
                                                    {{ item.icon }}
                                                </v-icon>
                                            </v-list-item-avatar>
                                        </slot>
                                        <v-list-item-content>
                                            <v-list-item-title class="item-text-small">
                                                <span
                                                    :cy-text="itemText(item)"
                                                    :cy-value="item.value || 'none'"
                                                    class="text-truncate"
                                                >
                                                    <Truncate>{{ itemText(item) }}</Truncate>
                                                </span>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </slot>
                                </div>
                            </SimpleTooltip>
                        </slot>
                    </article>
                </section>
            </article>
            <footer v-if="showButtons" class="action-buttons-list">
                <standard-button
                    background-color="white"
                    :disabled="nonSelected"
                    cy-data="unselectAll"
                    @click="unselectAll"
                >
                    {{ $t('selects.unselectAll') }}
                </standard-button>
                <primary-button
                    background-color="var(--v-primary-base)"
                    fontColor="white"
                    cy-data="selectAll"
                    :disabled="allSelected"
                    @click="selectAll"
                >
                    {{ $t('selects.selectAll') }}
                </primary-button>
            </footer>
        </article>
    </v-menu>
</template>

<script>
    import { debouncer } from '@/utils';
    import Truncate from '../Functional/Truncate.vue';
    export default {
        name: 'MultiSelect',

        components: {
            SimpleTooltip: () => import('@/components/Global/SimpleTooltip.vue'),
            Truncate,
        },

        props: {
            value: {
                type: Array,
                default: () => [],
            },
            items: {
                type: Array,
                default: () => [],
            },
            textKey: {
                // can be used if the text key is not called text on the items
                type: String,
                default: 'text',
            },
            valueKey: {
                // can be used if the value key is not called value on the items
                type: String,
                default: 'value',
            },
            showSelectedFirst: {
                type: Boolean,
                default: false,
            },
            debouncerTime: {
                type: Number,
                default: 500,
            },
            menuAttach: {
                type: null,
                default: undefined,
            },
            top: {
                type: Boolean,
                default: false,
            },
            bottom: {
                type: Boolean,
                default: false,
            },
            left: {
                type: Boolean,
                default: false,
            },
            right: {
                type: Boolean,
                default: false,
            },
            offset: {
                type: Boolean,
                default: true,
            },
            collectionName: {
                type: String,
                default: '',
            },
            showButtons: {
                type: Boolean,
                default: true,
            },
            autoFocusSearch: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                selectProps: {
                    rounded: true,
                    color: 'var(--v-gray3-base)',
                    filled: true,
                    dense: true,
                    hideDetails: true,
                    multiple: true,
                },
                sortedItems: [],
                selectedItems: [],
                menuOpen: false,
                search: '',
            };
        },

        computed: {
            slot() {
                return this.$attrs.useSlot !== undefined;
            },

            nonSelected() {
                return this.selectedItems.length === 0;
            },
            allSelected() {
                return this.selectedItems.length === this.items.length;
            },
            selectPropsComputed() {
                return {
                    ...this.selectProps,
                    ...this.$attrs,
                    items: this.items,
                };
            },

            showChip() {
                return this.selectedItems.length !== 0 && this.selectedItems.length !== this.items.length;
            },

            itemsWithSearch() {
                if (!this.search) {
                    return this.sortedItems;
                }
                return this.sortedItems.filter((item) =>
                    item[this.textKey].toLowerCase().includes(this.search.toLowerCase())
                );
            },

            extraGenerateCustomTextItems() {
                if (this.selectedItems.length === 0 || this.selectedItems.length === this.items.length) {
                    return '';
                }

                switch (this.selectedItems.length) {
                    case 0:
                        return '';
                    case 1:
                        return '';
                    default:
                        return '+' + (this.selectedItems.length - 1);
                }
            },

            generateCustomText() {
                const selectedItemsLength = this.selectedItems?.length ?? 0;
                switch (selectedItemsLength) {
                    case 0: {
                        return this.$t('selects.selectItems');
                    }
                    case 1: {
                        return this.itemText(this.selectedItems.at(0));
                    }
                    case this.items.length: {
                        return this.$t('selects.allItemsSelected');
                    }
                    default: {
                        return this.itemText(this.selectedItems.at(0));
                    }
                }
            },
        },
        watch: {
            menuOpen: {
                immediate: true,
                handler(newVal) {
                    // sorts the items on selected when opening the component, i.e. the selected items will be on top
                    // the items will not change order when clicking on them
                    if (newVal === true) {
                        this.sortedItems = [...this.items].sort((a, b) => this.sortItems(a, b));
                    } else {
                        // resets the search when closing the component
                        this.search = '';
                    }
                },
            },
            value: {
                handler(value) {
                    if (value.length === this.selectedItems.length) {
                        this.setSelectedItems();
                        return;
                    }
                    this.setSelectedItems();
                },
                deep: true,
            },
        },

        created() {
            this.handleChangeDebouncer = debouncer(this.handleChange, this.debouncerTime);
            this.setSelectedItems();
        },

        methods: {
            listItemClasses(item) {
                const classes = [];

                if (this.isSelected(item)) {
                    classes.push('button-selected');
                } else {
                    classes.push('button-not-selected');
                }

                if (this.isDisabled(item)) {
                    classes.push('button-not-clickable');
                }

                return classes.join(' ');
            },

            sortItems(a, b) {
                if (!this.showSelectedFirst) {
                    return 0;
                }

                const aIsSelected = this.isSelected(a);
                const bIsSelected = this.isSelected(b);

                if (aIsSelected && !bIsSelected) {
                    return -1;
                }

                if (!aIsSelected && bIsSelected) {
                    return 1;
                }

                return 0;
            },
            open() {
                if (this.error) return;
                this.menuOpen = !this.menuOpen;
            },
            titleColor(item) {
                return this.isSelected(item) ? 'item-text' : 'item-text-not-selected';
            },
            listItem(item) {
                return this.isSelected(item) ? 'list-item' : 'list-item-not-selected';
            },

            iconColor(item) {
                return this.isSelected(item) ? item.color : 'var(--v-gray1-base)';
            },
            checkedColor(item) {
                return this.isSelected(item) ? 'var(--v-primary-base)' : 'var(--v-gray1-base)';
            },

            setSearch(input) {
                this.search = input;
            },
            selectAll() {
                this.selectedItems = this.items;
                this.handleChangeDebouncer(this.selectedItems);
            },

            unselectAll() {
                this.selectedItems = [];
                this.handleChangeDebouncer(this.selectedItems);
            },

            setSelectedItems() {
                if (!this.value) return;
                this.selectedItems = this.value;
            },

            selectItem(item) {
                if (this.isSelected(item)) {
                    this.selectedItems = this.selectedItems.filter(
                        (selectedItem) => selectedItem[this.valueKey] !== item[this.valueKey]
                    );
                } else {
                    this.selectedItems.push(item);
                }

                this.handleChangeDebouncer(this.selectedItems);
            },

            isSelected(item) {
                return this.selectedItems.some((selectedItem) => selectedItem[this.valueKey] === item[this.valueKey]);
            },

            handleChange(value) {
                this.$emit('change', value);
                this.$emit('input', value);
            },
            itemText(item) {
                if (item.textKey) {
                    return this.$t(item.textKey);
                }
                return item[this.textKey];
            },
            isDisabled(item) {
                return Boolean(item.disabled);
            },
        },
    };
</script>

<style scoped>
    .multi-container {
        display: flex;
        flex: 1;
    }
    .error-text {
        font-size: 0.8rem;
        color: var(--v-gray2-base);
    }
    :deep(.v-input__prepend-inner) {
        align-self: center;
        margin: 0px !important;
        padding: 0px !important;
        white-space: nowrap !important;
    }

    div[role='menu'] {
        border-radius: 8px;
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2) !important;
        border: 1px solid #eee;
        padding: 4px;
        background-color: var(--v-gray3-base);
        min-width: 240px !important;
        max-width: 240px !important;
    }

    .action-buttons-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        gap: 4px;
    }

    .item-list-wrapper {
        border-radius: 8px;
        max-height: 312px;
        background-color: var(--v-gray3-base);
        box-sizing: border-box;
        display: flex;
        overflow: hidden;
        gap: 4px;
    }

    .item-list {
        max-height: 100%;
        width: 100%;
        overflow-y: auto;
        gap: 4px;
        display: grid;
    }

    .select-item {
        display: flex;
        align-items: center;
        flex-direction: row;
    }

    .select-item-button {
        display: flex;
        align-items: center;
    }

    .select-item-button > *:nth-child(2) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 0px;
        flex: 1;
        text-align: left;
    }

    .icon-container {
        padding-right: 16px;
    }

    .list-container {
        display: flex;
        align-items: center;
        flex-direction: row;
    }

    .item {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        flex: 1;
    }

    .item-button {
        display: grid;
        grid-template-columns: 1fr auto auto;
        flex: 1;
    }
    .item-text {
        font-size: 0.9rem !important;
        font-weight: 400 !important;
        color: var(--v-gray4-base);
    }

    .item-text-small {
        font-size: 0.9rem !important;
        font-weight: 400 !important;
    }

    .item-text-not-selected {
        font-size: 0.9rem !important;
        opacity: 0.8;
        font-weight: 400 !important;
        color: var(--v-gray1-base);
    }

    .list-item {
        padding: 0 !important;
        padding-left: 8px !important;
        padding-right: 8px !important;
        height: 48px !important;
        margin-bottom: 0px;
        border-radius: 8px;
        background-color: var(--v-gray3-base);
        overflow: hidden;
        transition: all 0.1s ease-in-out;
    }

    .selected-item {
        background-color: white;
        box-sizing: border-box;
    }

    .display-item {
        display: flex;
        align-items: center;
        flex-direction: row;
        font-size: 0.9rem !important;
        font-weight: 400 !important;
        color: var(--v-gray2-base);
    }

    .action-item-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .button-not-clickable {
        cursor: default !important;
        pointer-events: none !important;
    }
</style>

<i18n lang="json">
{
    "en": {
        "selects": {
            "selectItems": "Select",
            "allItemsSelected": "All",
            "itemsSelected": "selected",
            "selectAll": "Select all",
            "unselectAll": "Unselect all",
            "error": "Could not load items",
            "itemUnavailable": "Item is unavailable"
        }
    },
    "sv": {
        "selects": {
            "selectItems": "Välj",
            "allItemsSelected": "Alla",
            "itemsSelected": " valda",
            "selectAll": "Välj alla",
            "unselectAll": "Avmarkera alla",
            "error": "Kunde inte ladda innehåll",
            "itemUnavailable": "Objektet är inte tillgängligt"
        }
    }
}
</i18n>
