<template>
    <div class="component-container">
        <VueTelInput
            ref="phoneInput"
            v-model="phoneNumber"
            :inputOptions="inputOptions"
            :allCountries="allCountriesList"
            :defaultCountry="defaultCountry"
            :autoDefaultCountry="false"
            class="phone-input"
            :key="defaultCountry"
            @input="handleInput"
        />
        <div class="error-message-container">
            <p v-if="showErrorMessage && !validPhoneNumber" class="error-message">
                {{ $t('phoneNumber.invalidPhoneNumber') }}
            </p>
        </div>
    </div>
</template>

<script>
    /**
     * PhoneNumber component is responsible for rendering a phone number input field using VueTelInput.
     * It allows the user to enter a phone number and validates the input.
     *
     * @component PhoneNumber
     *
     * @prop {Boolean} allowInternalNumbers - Determines whether internal numbers are allowed. Defaults to true.
     * @prop {String} defaultCountry - The default country code. Defaults to 'SE'.
     * @prop {String} placeholder - The placeholder text for the input field. Optional.
     * @prop {String} defaultPhoneNumber - The default phone number. Optional.
     * @prop {Boolean} showErrorMessage - Determines whether the error message is shown. Defaults to true.
     *
     * @emits input - Emitted when the phone number input changes. Returns the formatted number as a string.
     * @emits inputAndValidation - Emitted when the phone number input changes. Returns an object with the number and validity.
     *
     * @example
     * <PhoneNumber
     *   :allowInternalNumbers="true"
     *   defaultCountry="SE"
     *   placeholder="Enter phone number"
     *   @input="handlePhoneNumberInput"
     * />
     */

    import { mapActions } from 'vuex';
    import { VueTelInput } from 'vue-tel-input';
    import allCountries from '@/assets/PhoneNumber/allCountries';
    import internalNumbers from '@/enums/phoneNumber.enums';
    import { isPossiblePhoneNumber } from 'libphonenumber-js';

    export default {
        name: 'PhoneNumber',
        components: {
            VueTelInput,
        },
        props: {
            allowInternalNumbers: {
                type: Boolean,
                default: true,
            },
            defaultCountryProp: {
                type: String,
                default: 'SE',
            },
            placeholder: {
                type: String,
                required: false,
                default: '',
            },
            defaultPhoneNumber: {
                type: String,
                required: false,
                default: '',
            },
            showErrorMessage: {
                type: Boolean,
                default: true,
            },
        },

        data() {
            return {
                // copied from vue-tel-input default inputOptions
                inputOptions: {
                    autocomplete: 'on',
                    autofocus: false,
                    'aria-describedby': '',
                    id: '',
                    maxlength: 25,
                    name: 'telephone',
                    showDialCode: false,
                    placeholder: this.$t('phoneNumber.placeholder'),
                    readonly: false,
                    required: false,
                    tabindex: 0,
                    type: 'tel',
                    styleClasses: '',
                },
                internalNumberObject: {
                    name: 'Internal',
                    iso2: '00',
                    dialCode: '',
                    priority: 0,
                    areaCodes: null,
                },
                allCountries,
                allCountriesList: [],
                validPhoneNumber: true,
                phoneNumber: '',
                defaultCountry: 'SE',
            };
        },
        watch: {
            defaultCountryProp: {
                immediate: true,
                handler(newVal) {
                    this.defaultCountry = newVal;
                },
            },

            defaultPhoneNumber: {
                immediate: true,
                deep: true,
                handler(newVal) {
                    if (!newVal) return;

                    this.phoneNumber = newVal;

                    if (
                        this.phoneNumber &&
                        this.phoneNumber.length >= internalNumbers.MIN_LENGTH &&
                        this.phoneNumber.length <= internalNumbers.MAX_LENGTH
                    ) {
                        // sets the current country to internal if the number is an internal number
                        try {
                            this.$refs.phoneInput.choose('00');
                            // due to vue-tel-input not being made for internal numbers, the change method throws when we supply internal number
                            // eslint-disable-next-line no-empty
                        } catch {}
                    }
                },
            },
        },

        created() {
            this.init();
        },

        methods: {
            ...mapActions({
                searchPhoneNumbers: 'Cases/searchPhoneNumbers',
            }),
            init() {
                this.allCountriesList = structuredClone(this.allCountries);
                if (this.allowInternalNumbers) {
                    this.allCountriesList.unshift(this.internalNumberObject);
                }
                // const DEBOUNCE_TIME = 500;
                // this.debounceGetData = debouncer(this.getData, DEBOUNCE_TIME);
                if (this.placeholder) {
                    this.inputOptions.placeholder = this.placeholder;
                } else {
                    this.inputOptions.placeholder = this.$t('phoneNumber.placeholder');
                }
                if (
                    this.defaultPhoneNumber &&
                    this.defaultPhoneNumber.length >= internalNumbers.MIN_LENGTH &&
                    this.defaultPhoneNumber.length <= internalNumbers.MAX_LENGTH
                ) {
                    this.defaultCountry = '00';
                }
                this.phoneNumber = this.defaultPhoneNumber ?? '';
            },
            // this is a function that would be used if we want to implement a search function
            // async getData() {
            //     if (!this.nationalNumber || typeof this.nationalNumber === 'object') return;

            //     this.loading = true;
            //     const response = await this.searchPhoneNumbers(this.nationalNumber);

            //     const { data } = response;

            //     this.loading = false;
            // },

            handleInput(_, phoneObject) {
                let returnNumber = phoneObject.number;
                this.validatePhoneNumber(phoneObject);
                // if internal number, return the formatted number instead
                // phoneObject.number is undefined if inserted number does not follow format of regular numbers
                if (!returnNumber || phoneObject.country?.iso2 === '00') {
                    returnNumber = phoneObject.formatted;
                }
                this.$emit('input', returnNumber);
                this.$emit('inputAndValidation', { number: returnNumber, valid: this.validPhoneNumber });
            },
            validatePhoneNumber(phoneObject) {
                if (phoneObject.country?.iso2 === '00') {
                    this.validPhoneNumber =
                        phoneObject.formatted.length >= internalNumbers.MIN_LENGTH &&
                        phoneObject.formatted.length <= internalNumbers.MAX_LENGTH &&
                        !Number.isNaN(Number(phoneObject.formatted));
                    return;
                }

                this.validPhoneNumber = this.isPossiblePhoneNumber(
                    phoneObject.formatted + '',
                    phoneObject.country?.iso2
                );
            },
            isPossiblePhoneNumber,
        },
    };
</script>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>

<style scoped>
    .component-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-height: 140px;
    }
    .phone-input {
        border: 1px solid #eee;
        border-radius: 8px;
        padding: 0px;
        margin-bottom: 4px;
        height: 56px;
    }

    :deep(.vti__dropdown-list) {
        padding-left: 0px;
        padding-right: 0px;
        border: 1px solid rgb(0, 0, 0, 0.15);
        border-radius: 8px;
        max-width: 240px;
    }
    :deep(.vti__dropdown-item) {
        height: 42px;
        display: flex;
        flex-direction: row;
        padding: 0px 8px;
        justify-content: flex-start;
        align-items: center;
        font-size: 12px;
        font-weight: 400 !important;
        color: var(--v-gray2-base);
        font-family: 'Roboto', sans-serif;
        gap: 8px;
    }
    :deep(.vti__dropdown-item) > strong {
        font-weight: 400 !important;
        font-size: 12px;
        color: var(--v-gray4-base);
        /* Add truncate */
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .error-message-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 20px;
        margin-bottom: 3px;
    }
    .error-message {
        color: red;
        font-size: 12px;
        margin-top: 0px;
        margin-bottom: 0px;
    }
</style>
<i18n lang="json">
{
    "sv": {
        "phoneNumber": {
            "placeholder": "Skriv in telefonnummer",
            "invalidPhoneNumber": "Ogiltigt telefonnummer"
        }
    },
    "en": {
        "phoneNumber": {
            "placeholder": "Enter a phone number",
            "invalidPhoneNumber": "Invalid phone number"
        }
    }
}
</i18n>
