export function mapFilterPayload(filter, searchType, fixedFilterItems, brandsActive) {
    const brandIds = fixedFilterItems.brands.map((q) => q.id);

    const queues = brandsActive
        ? fixedFilterItems.queues.filter((q) => brandIds.includes(q.brand?.ID) || q.brand === null)
        : fixedFilterItems.queues;

    const newPayload = [];
    newPayload.page = filter.page;
    newPayload.items = filter.items;
    newPayload.queues = queues.map((q) => q.value).sort();
    newPayload.categories = fixedFilterItems.categories.map((q) => q.value).sort();
    newPayload.channels = fixedFilterItems.channels.map((q) => q.value).sort();

    newPayload.userIds = filter.users.map((q) => q.value).sort();
    newPayload.showUnansweredOnly = filter.showUnansweredOnly;
    newPayload.includeSubCases = filter.includeSubCases;
    newPayload.sortBy = filter.sortField;
    newPayload.direction = filter.sortDirection;
    newPayload.statuses = filter.statuses.map((q) => q.value).sort();
    newPayload.query = filter.searchQuery;

    if (newPayload.query === '') {
        newPayload.searchType = 1;
    } else {
        newPayload.searchType = searchType;
    }

    return newPayload;
}

/**
 /**
 * @typedef {Object} Brand
 * @property {number} id - The unique identifier.
 * @property {string} name - The name of the item.
 * @property {number} value - The numeric value.
 * @property {string} text - The text representation.
 */

/**
 * @typedef {Object} BrandInQueue
 * @property {number} ID - The unique identifier of the brand.
 * @property {string} Name - The name of the brand.
 */

/**
 * @typedef {Object} Queue
 * @property {string} name - The name of the item.
 * @property {number} ID - The unique identifier.
 * @property {string} color - The color associated with the item.
 * @property {number} value - The numeric value.
 * @property {string} icon - The icon reference.
 * @property {BrandInQueue} brand - The associated brand details.
 * @property {string|null} defaultSenderEmail - The default sender email (nullable).
 * @property {string} text - The text representation.
 */

/**
 * Get the correct queues based on the brandsActive flag.
 * An extra precaution to prevent subscribing to queues that are not available.
 * @param {{brands:Brand[], queues:Queue[]}} data
 * @param {boolean} brandsActive
 * @returns
 */
export function getCorrectQueuesForSubscription(data, brandsActive) {
    let queues = structuredClone(data.queues);

    if (brandsActive) {
        queues = queues.filter((item) => data.brands.find((brand) => brand.id === item.brand?.ID));
    }

    return queues.map((item) => item.value).sort((a, b) => a - b);
}
