import type { PluginFunction } from 'vue';

// Old based on Vuetify that should be deprecated
import StandardButton from '@/components/Main/Buttons/Standard.vue';
import PrimaryButton from '@/components/Main/Buttons/Primary.vue';
import ActionButton from '@/components/Main/Buttons/Action.vue';
import RoundIconButton from '@/components/Main/Buttons/RoundIcon.vue';
import StandardSelect from '@/components/Main/Selects/Standard.vue';
import TertiaryActionButton from '@/components/Main/Buttons/TertiaryAction.vue';
import SecondaryActionButton from '@/components/Main/Buttons/SecondaryAction.vue';
import PrimaryActionButton from '@/components/Main/Buttons/PrimaryAction.vue';
import MultiSelect from '@/components/Main/Selects/Multi.vue';
import UsersSelect from '@/components/Main/Selects/Users.vue';
import TertiaryButton from '@/components/Main/Buttons/Tertiary.vue';
import StandardInput from '@/components/Main/Inputs/Standard.vue';
import SearchInput from '@/components/Main/Inputs/Search.vue';
import SecondaryInput from '@/components/Main/Inputs/Secondary.vue';
import PhoneNumber from '@/components/Main/Inputs/PhoneNumber.vue';
import PhoneInput from '@/components/Main/Inputs/PhoneNumberPhoneWrapper.vue';
import SmsInput from '@/components/Main/Inputs/PhoneNumberSmsWrapper.vue';
import EmailInputSingle from '@/components/Main/Selects/EmailSingle.vue';
import EmailInputMulti from '@/components/Main/Selects/EmailMulti.vue';
import EmailInputSystem from '@/components/Main/Selects/EmailSystem.vue';
import StatusSelect from '@/components/Main/Selects/Status.vue';
import TimeSelect from '@/components/Main/Selects/Time.vue';
import SlaSelect from '@/components/Main/Selects/SLA.vue';
import QueueSelect from '@/components/Main/Selects/Queue.vue';
import QueuesSelect from '@/components/Main/Selects/Queues.vue';
import TemplateSelect from '@/components/Main/Selects/Template.vue';
import SignatureSelect from '@/components/Main/Selects/Signature.vue';
import UserSelect from '@/components/Main/Selects/User.vue';
import ClientSelect from '@/components/Main/Selects/Client.vue';
import TipTap from '@/components/Global/TiptapEditor.vue';
import AddressSingle from '@/components/Main/Inputs/AddressSingle.vue';
import SmsPreview from '@/components/Main/Previews/Sms.vue';
import SenderNumberSelect from '@/components/Main/Inputs/NumberSenderSelect.vue';
import MultiUsersSelect from '@/components/Main/Selects/MultiUsers.vue';
import BrandSelect from '@/components/Main/Selects/Brand.vue';
import BrandsSelect from '@/components/Main/Selects/Brands.vue';
import TextArea from '@/components/Main/Inputs/TextArea.vue';
import LoaderOverlay from '@/components/Main/Overlay/LoaderOverlay.vue';
import LanguageSelect from '@/components/Main/Selects/Language.vue';
import CountrySelect from '@/components/Main/Selects/Country.vue';
import DynamicSelect from '@/components/Main/Selects/Dynamic.vue';
import ClientTypeSelect from '@/components/Main/Selects/ClientTypeSelect.vue';

// Components in js that are not based on Vuetify
import Truncate from '@/components/Main/Functional/Truncate.vue';
import InfiniteList from '@/components/Main/List/InfiniteList.vue';
import Tooltip from '@/components/Main/Tooltips/BasicTooltip.vue';

// New C1 Components not based on Vuetify - TypeScript
// These components should be testable with Cypress
import C1Menu from '@/components/Main/C1/C1Menu.vue';
import C1List from '@/components/Main/C1/C1List.vue';
import C1MenuItem from '@/components/Main/C1/C1MenuItem.vue';
import C1Header from '@/components/Main/C1/C1Header.vue';
import C1Section from '@/components/Main/C1/C1Section.vue';
import C1InfiniteList from '@/components/Main/C1/C1InfiniteList.vue';
import C1Input from '@/components/Main/C1/C1Input.vue';

// Create a plugin to install all components globally
const components = {
    StandardButton,
    PrimaryButton,
    ActionButton,
    RoundIconButton,
    StandardSelect,
    TertiaryActionButton,
    SecondaryActionButton,
    PrimaryActionButton,
    MultiSelect,
    UsersSelect,
    TertiaryButton,
    StandardInput,
    SearchInput,
    SecondaryInput,
    PhoneNumber,
    PhoneInput,
    SmsInput,
    EmailInputSingle,
    EmailInputMulti,
    EmailInputSystem,
    StatusSelect,
    TimeSelect,
    SlaSelect,
    QueueSelect,
    QueuesSelect,
    TemplateSelect,
    SignatureSelect,
    UserSelect,
    ClientSelect,
    TipTap,
    SmsTipTap: TipTap,
    AddressSingle,
    SmsPreview,
    SenderNumberSelect,
    MultiUsersSelect,
    BrandSelect,
    BrandsSelect,
    Tooltip,
    TextArea,
    LoaderOverlay,
    InfiniteList,
    LanguageSelect,
    CountrySelect,
    DynamicSelect,
    Truncate,
    ClientTypeSelect,
    C1Menu,
    C1List,
    C1MenuItem,
    C1Header,
    C1Section,
    C1InfiniteList,
    C1Input,
};

const GlobalComponents: { install: PluginFunction<never> } = {
    install(Vue) {
        for (const [name, component] of Object.entries(components)) {
            Vue.component(name, component);
        }
    },
};

export default GlobalComponents;
