import axios from 'axios';

export interface SaveNewCategoryPayload {
    collectionId: number;
    name: string;
    icon: string;
}

// ** Categories ** //
export function saveNewCategory(category: SaveNewCategoryPayload) {
    return axios.post('/faqs/category', category);
}

export interface EditCategoryPayload {
    collectionId: number;
    name: string;
    icon: string;
    id: number;
}

export function editCategory(category: EditCategoryPayload) {
    return axios.put(`/faqs/category/${category.id}`, category);
}

export function getCategoriesByCollectionId(id: number) {
    return axios.get(`/faqs/category/${id}`);
}

export function deleteCategory(id: number) {
    return axios.delete(`/faqs/category/${id}`);
}

export interface EditCollectionPayload {
    id: number;
    body: {
        name: string;
        isActive: boolean;
    };
}

// ** Collections ** //
export function editCollection(collection: EditCollectionPayload) {
    return axios.put(`/faqs/collections/${collection.id}`, collection);
}

export function deleteCollection(id: number) {
    return axios.delete(`/faqs/collections/${id}`);
}

export interface SaveNewCollectionPayload {
    name: string;
    isActive: boolean;
}

export function saveNewCollection(collection: SaveNewCollectionPayload) {
    return axios.post('/faqs/collections', collection);
}

export function getAllFaqCollections() {
    return axios.get('/faqs/collections');
}

// ** Faqs ** //
export function getAllFaqs(id: number) {
    return axios.get(`/faqs/${id}`);
}

export interface CreateFaqPayload {
    categoryId: number;
    questionText: string;
    answerText: string;
}

export function createFaq(newFaq: CreateFaqPayload) {
    return axios.post('/faqs', newFaq);
}

export function deleteFaq(id: number) {
    return axios.delete(`/faqs/${id}`);
}

export interface UpdateFaqPayload {
    id: number;
    categoryId: number;
    questionText: string;
    answerText: string;
}

export function updateFaq(updFaq: UpdateFaqPayload) {
    return axios.put(`/faqs/${updFaq.id}`, updFaq);
}

// Widgets
export function getWidgetByCollection(collectionId: number) {
    return axios.get(`/faqs/collections/${collectionId}/widgets`);
}

export interface SaveWidgetPayload {
    faqCollection: number;
    id: number;
    primaryColor: string;
    name: string;
}

export function saveWidget(payload: SaveWidgetPayload) {
    return axios.put(`/faqs/collections/${payload.faqCollection}/widgets/${payload.id}`, payload);
}

export interface DeleteWidgetPayload {
    faqCollection: number;
    id: number;
}

export function deleteWidget(payload: DeleteWidgetPayload) {
    return axios.delete(`/faqs/collections/${payload.faqCollection}/widgets/${payload.id}`);
}

export interface CreateWidgetPayload {
    faqCollection: number;
    primaryColor: string;
    name: string;
}

export function createWidget(payload: CreateWidgetPayload) {
    return axios.post(`/faqs/collections/${payload.faqCollection}/widgets`, payload);
}

export default {
    getAllFaqs,
    createFaq,
    deleteFaq,
    updateFaq,

    getAllFaqCollections,
    saveNewCollection,
    deleteCollection,

    getCategoriesByCollectionId,
    saveNewCategory,
    deleteCategory,
    editCategory,
    editCollection,

    getWidgetByCollection,
};
