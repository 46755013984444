<template>
    <div>
        <c1-menu v-model="showMenu">
            <template #activator="{ toggle }">
                <tooltip :content="$t('rebindCase.connectClientToCase')">
                    <v-btn fab elevation="0" height="28" width="28" class="client-card-round-button" @click="toggle">
                        <v-icon size="18" color="var(--v-gray4-base)">mdi-account-plus</v-icon>
                    </v-btn>
                </tooltip>
            </template>
            <c1-section>
                <c1-header :size="4">{{ $t('rebindCase.contactPersons') }}</c1-header>
                <c1-infinite-list
                    v-if="!fetchClientsChildrenError"
                    :headers="[]"
                    :fetchItems="fetchClientsChildren"
                    :items="mappedClients"
                    :limit="16"
                    :emptyMessage="$t('rebindCase.emptyList')"
                    :emptyHeader="$t('rebindCase.emptyListHeader')"
                    emptyIcon="mdi-account"
                    minHeight="276px"
                >
                    <template #item="{ item }">
                        <c1-menu-item @click="clickItem(item)">
                            <template #icon>
                                <i class="mdi mdi-account"></i>
                            </template>
                            <truncate>{{ item.text }}</truncate>
                        </c1-menu-item>
                    </template>
                </c1-infinite-list>
                <c1-section v-else>
                    {{ fetchClientsChildrenError }}
                </c1-section>
            </c1-section>
            <c1-section borderTop>
                <c1-menu-item @click="showAllClients">
                    <span>{{ $t('rebindCase.showAllClients') }}</span>
                </c1-menu-item>
            </c1-section>
        </c1-menu>
    </div>
</template>

<script lang="ts">
    import { defineComponent, ref, computed, inject } from 'vue';
    import { getClientsChildren, bindClientToCase } from '@/api/client/client.api';
    import i18n from '@/i18n';

    import RebindCaseDialog from './RebindCaseDialog.vue';

    import type { Client } from '@/api/client/client.api';
    import type { Toasted } from 'vue-toasted';
    import type { Global } from '@/services/dialogs/dialog.service.types.d.ts';

    export default defineComponent({
        name: 'RebindCase',

        props: {
            caseId: {
                type: String,
                required: true,
            },

            clientId: {
                type: String,
                required: true,
            },
        },

        setup(props) {
            const toasted = inject('$toasted') as Toasted | undefined;

            if (!toasted) {
                throw new Error('No toasted found');
            }

            const global = inject('$global') as Global | undefined;

            if (!global) {
                throw new Error('No global found');
            }

            const clients = ref<Client[]>([]);

            const fetchClientsChildrenError = ref<string | null>(null);

            const mappedClients = computed(() => {
                return clients.value.map((client) => {
                    return {
                        id: client.id,
                        text: client.name,
                        icon: 'mdi-account',
                    };
                });
            });

            const clickItem = async (item: Client) => {
                try {
                    const { confirmed } = await global.dialogs.showConfirmationDialog({
                        title: i18n.t('rebindCase.bindCustomerTitle').toString(),
                        message: i18n.t('rebindCase.bindCustomerText').toString(),
                        confirmText: i18n.t('rebindCase.yes').toString(),
                        declineText: i18n.t('rebindCase.no').toString(),
                    });

                    if (!confirmed) {
                        return;
                    }

                    await bindClientToCase(item.id, props.caseId);

                    toasted.show(i18n.t('rebindCase.clientConnectedToCase').toString(), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                    showMenu.value = false;
                } catch (error) {
                    toasted.error(i18n.t('rebindCase.couldNotConnectClientToCase').toString(), {
                        icon: 'cancel',
                        type: 'error',
                    });

                    console.error('Error binding client to case:', error);
                }
            };

            const showAllClients = async () => {
                const { closed } = await global.dialogs.showDialog({
                    title: i18n.t('rebindCase.connectClientToCase').toString(),
                    component: RebindCaseDialog,
                    size: 'large',
                    closeble: true,
                    props: {
                        caseId: props.caseId,
                    },
                });
            };

            const showMenu = ref(false);

            const fetchClientsChildren = async ({
                limit,
                page,
                search,
            }: {
                limit: number;
                page: number;
                search: string;
            }) => {
                try {
                    const offset = (page - 1) * limit;
                    const response = await getClientsChildren(props.clientId, limit, offset, []);
                    if (page === 1) {
                        clients.value = response.data;
                    } else {
                        clients.value = clients.value.concat(response.data);
                    }

                    return response.data;
                } catch (error) {
                    console.error('Error fetching clients children:', error);
                    fetchClientsChildrenError.value = i18n.t('rebindCase.errorFetchingClients').toString();
                }
            };

            return {
                clickItem,
                showAllClients,
                showMenu,
                mappedClients,
                fetchClientsChildren,
                fetchClientsChildrenError,
            };
        },
    });
</script>

<style scoped>
    .client-card-round-button {
        background-color: white !important;
        border: 1px solid #ccc;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    }
</style>
