<template>
    <multi-select
        :id="uniqueId"
        :collectionName="collectionName"
        :items="items"
        :value="value"
        valueKey="id"
        :textKey="textKey"
        @change="$emit('change', $event)"
    >
        <template #list-prepend-icon="{ item }">
            <div class="prepend-icon-box">
                <BrandLogoTransparent :key="item.id" :brandId="item.id" size="20px" />
            </div>
        </template>
        <template #button-prepend-content="{ selectedItems }">
            <div>
                <BrandLogoRound
                    v-if="showButtonLogo(items, selectedItems)"
                    :key="selectedItems.at(0).id"
                    size="20px"
                    :brandId="selectedItems.at(0).id"
                    class="mr-3"
                />
            </div>
        </template>
    </multi-select>
</template>

<script>
    import BrandLogoRound from '@/components/Brands/BrandLogoRound.vue';
    import BrandLogoTransparent from '@/components/Brands/BrandLogoTransparent.vue';

    export default {
        name: 'BrandsSelect',

        components: {
            BrandLogoRound,
            BrandLogoTransparent,
        },

        props: {
            uniqueId: {
                type: String,
                default: '',
            },
            collectionName: {
                type: String,
                default: '',
            },
            items: {
                type: Array,
                required: true,
            },
            value: {
                type: null,
                required: true,
            },
            textKey: {
                type: String,
                default: 'text',
            },
        },
        methods: {
            showButtonLogo(items, selectedItems) {
                return items.length && (items.length !== selectedItems.length || items.length === 1);
            },
        },
    };
</script>

<style scoped>
    .prepend-icon-box {
        justify-self: center;
        width: 40px;
        height: 48px;
        margin-right: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>

<i18n lang="json">
{
    "en": {},
    "sv": {}
}
</i18n>
