<template>
    <div>
        <multi-select
            :id="uniqueId"
            :collectionName="collectionName"
            :items="items"
            :value="selectedValues"
            v-bind="selectPropsComputed"
            :showButtons="showButtons"
            @change="$emit('change', $event)"
        >
            <template #item-slot="{ item, selected }">
                <QueueSelectItem :item="item" :selected="selected" />
            </template>
        </multi-select>
    </div>
</template>

<script>
    import QueueSelectItem from '@/components/Main/Selects/Items/QueueSelectItem.vue';

    export default {
        name: 'QueuesSelect',

        components: {
            QueueSelectItem,
        },

        props: {
            uniqueId: {
                type: String,
                default: '',
            },
            collectionName: {
                type: String,
                default: '',
            },
            items: {
                type: Array,
                required: true,
            },
            value: {
                type: null,
                required: true,
            },
            showButtons: {
                type: Boolean,
                default: true,
            },
            selectedBrands: {
                type: Array,
                default: null,
            },
        },

        data() {
            return {
                selectProps: {
                    rounded: true,
                    dense: true,
                    color: 'var(--v-gray3-base)',
                },
            };
        },

        computed: {
            selectPropsComputed() {
                return {
                    ...this.selectProps,
                    ...this.$attrs,
                };
            },

            // Filter out queues that don't have a brand or have a brand that is not selected
            // Include no queue or queues that don't have a brand
            selectedValues() {
                if (!this.selectedBrands) {
                    return this.value;
                }

                return this.value.filter((queue) => {
                    return (
                        this.selectedBrands.some((brand) => {
                            return queue?.brand?.ID === brand.id;
                        }) || queue?.brand === null
                    );
                });
            },
        },
    };
</script>

<style scoped></style>

<i18n lang="json">
{
    "en": {},
    "sv": {}
}
</i18n>
