import axios from 'axios';

function searchClients(payload: string) {
    // '+' symbol == empty space in url-queries, thus we must do this replace to maintain it (phone numbers can have + symbols) -Teo 2022-06-14
    const formattedPayload = payload.replace('+', '%2B');
    return axios.get(`/clients?search=${formattedPayload}`);
}

export interface GetAllClientsDataPayload {
    limitOffSet: number;
    limitAmount: number;
    search: string;
    fields: string[];
}

function getAllClientsData(payload: GetAllClientsDataPayload) {
    return axios.get(
        `/clients/list/alldata?limit=${payload.limitOffSet || 0},${payload.limitAmount || 0}&search=${
            payload.search || 0
        }&fields=${payload.fields}`,
    );
}

function getClientsContacts(payload: string) {
    return axios.get(`/clients/${payload}`);
}

// @deprecated
function addNewClient(payload: unknown) {
    return axios.post('/clients', payload);
}

// @deprecated
function addNewContact(payload: unknown) {
    return axios.post('/contacts/add/new', payload);
}

// @deprecated
function deleteClient(payload: string) {
    return axios.delete(`/clients/${payload}`);
}

function getClientTemplate() {
    return axios.get('/clients/manifest/template');
}

function getContactTemplate() {
    return axios.get('/clients/contact/template');
}

// @deprecated
function updateContactInfo(payload: { id: string }) {
    return axios.put(`/contact/${payload.id}`, payload);
}

export interface SetDefaultCreateClientPayload {
    queue: number;
    topic: string;
    description: string;
    status: string;
    SLA: number;
    id: number;
}

function setDefaultCreateClient(payload: SetDefaultCreateClientPayload) {
    return axios.post('/clients/defaultData', payload);
}

function getDefaultCreateClient() {
    return axios.get('/clients/defaultData/all');
}

function getWallboardStatistics() {
    return axios.get('/wallboard');
}

function getWallboardUsers(limit: number) {
    return axios.get(`/wallboard/users?limit=${limit}`);
}
function getWallboardSettings() {
    return axios.get('/wallboard/settings');
}

export interface StoreWallboardCardPayload {
    arrayOfIds: number[];
    type: string;
    name: string;
    orderId: number;
}

function storeWallboardCard(payload: StoreWallboardCardPayload) {
    return axios.post('/wallboard/cards', payload);
}

export interface UpdateWallboardCardPayload {
    cardId: number;
    Name: string;
    Type: string;
    OnlyLoggedIn: number | boolean;
    Celebrations: number;
}

function updateWallboardCard(payload: UpdateWallboardCardPayload) {
    return axios.put(`/wallboard/cards/${payload.cardId}`, payload);
}

export interface UpdateWallboardOrderPayload {
    ID: number;
    OrderId: number;
}

function updateWallboardOrder(payload: UpdateWallboardOrderPayload) {
    return axios.put('/wallboard/cards/order', payload);
}
function deleteWallboardCard(id: number) {
    return axios.delete(`/wallboard/cards/${id}`);
}

export interface AddQueueToWallboardCardPayload {
    cardId: number;
    queueId: number;
}

function addQueueToWallboardCard(payload: AddQueueToWallboardCardPayload) {
    return axios.post(`/wallboard/cards/${payload.cardId}/queues/${payload.queueId}`);
}

export interface RemoveQueueFromWallboardCardPayload {
    cardId: number;
    queueId: number;
}

function removeQueueFromWallboardCard(payload: RemoveQueueFromWallboardCardPayload) {
    return axios.delete(`/wallboard/cards/${payload.cardId}/queues/${payload.queueId}`);
}

export interface UpdateCelebrationPayload {
    cardId: number;
    amount: number;
    particle: string;
    color: string;
}

function updateCelebration(payload: UpdateCelebrationPayload) {
    return axios.put(`/wallboard/cards/${payload.cardId}/celebrations`, payload);
}

export default {
    searchClients,
    getAllClientsData,
    getClientsContacts,
    addNewClient,
    addNewContact,
    deleteClient,
    getClientTemplate,
    getContactTemplate,
    updateContactInfo,
    setDefaultCreateClient,
    getDefaultCreateClient,
    getWallboardStatistics,
    getWallboardUsers,
    getWallboardSettings,
    storeWallboardCard,
    updateWallboardCard,
    updateWallboardOrder,
    deleteWallboardCard,
    addQueueToWallboardCard,
    removeQueueFromWallboardCard,
    updateCelebration,
};
