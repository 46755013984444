export const exceptionTypes = {
    CLOSED: 'CLOSED',
    OPEN: 'OPEN',
    MESSAGE: 'MESSAGE',
    TRANSFER: 'TRANSFER',
} as const;

export const calendarDisplayTypes = {
    DAY: 'day',
    WEEK: 'week',
    MONTH: 'month',
} as const;

export const openhoursQueueTypes = {
    C1: 'C1',
    OQ: 'OQ',
    IVR: 'IVR',
} as const;

export type ExceptionTypes = keyof typeof exceptionTypes;
export type CalendarDisplayTypes = keyof typeof calendarDisplayTypes;
export type OpenhoursQueueTypes = keyof typeof openhoursQueueTypes;

export default {
    exceptionTypes,
    calendarDisplayTypes,
    openhoursQueueTypes,
};
