import axios from 'axios';

export interface GetNumberToCallPayload {
    caseId: string;
    phoneNumber: string;
    userId: number;
    commentId: number;
    type: string;
}

function getNumberToCall(payload: GetNumberToCallPayload) {
    const params = {
        caseId: payload.caseId,
        userId: payload.userId,
        commentId: payload.commentId,
        type: payload.type,
    };
    return axios.get(`/queues/call/${payload.phoneNumber}`, { params });
}

export interface GetNumberToTransferPayload {
    phoneNumber: string;
    userId: number;
}

function getNumberToTransfer(payload: GetNumberToTransferPayload) {
    const params = {
        userId: payload.userId,
    };
    return axios.get(`/queues/transfer/${payload.phoneNumber}`, { params });
}

export default {
    getNumberToCall,
    getNumberToTransfer,
};
