import axios from 'axios';

export const audienceType = {
    ADMINS: 'ADMINS',
    VIP: 'VIP',
    USERS: 'USERS',
    CLIENTS: 'CLIENTS',
    ACTIVE_CLIENTS: 'ACTIVE-CLIENTS',
    NEW_CLIENTS: 'NEW-CLIENTS',
    COMPOSITE: 'COMPOSITE',
} as const;

export type AudienceType = (typeof audienceType)[keyof typeof audienceType];

export interface CreateSmsCampaignPayload {
    name: string;
    description: string;
    content: string;
    from: string;
    to: { id: number | null; type: AudienceType }[];
    queueId: number | null;
}

function createSMSCampaign(data: CreateSmsCampaignPayload) {
    return axios.post('/campaigns/sms', data);
}

export interface GetSMSCampaignsPayload {
    page: number;
    limit: number;
}

function getSMSCampaigns(data: GetSMSCampaignsPayload) {
    return axios.get('/campaigns/sms', { params: data });
}

function getCampignResults({ page, limit, campaignId }: { page: number; limit: number; campaignId: string }) {
    return axios.get(`/campaigns/${campaignId}/results`, { params: { page, limit } });
}

function getCampaignJobItems({
    campaignId,
    status,
    page,
    limit,
}: { campaignId: string; status: string; page: number; limit: number }) {
    return axios.get(`/campaigns/${campaignId}/job-items`, { params: { status, page, limit } });
}

export default {
    createSMSCampaign,
    getSMSCampaigns,
    getCampignResults,
    getCampaignJobItems,
};
