<template>
    <v-list-item
        class="pa-0"
        :cy-text="item.ID === null ? 'none' : item.text || item.name"
        :cy-data="item.ID === null ? 'none' : item.text || item.name"
    >
        <v-list-item-avatar>
            <v-icon size="16" :color="iconColor(item)">{{ item.icon }}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content :class="selected ? 'item-text' : 'item-text-not-selected'" class="content">
            <div>
                <truncate :align="'left'">{{ item.text || item.name }}</truncate>
            </div>
        </v-list-item-content>
        <div v-if="brandsActive && brandId" class="brand-container">
            <BrandLogoTransparent
                :key="brandId"
                :brandId="brandId"
                tooltip
                :tooltipText="item.brand?.Name"
                size="20px"
            />
        </div>
    </v-list-item>
</template>

<script>
    import BrandLogoTransparent from '@/components/Brands/BrandLogoTransparent.vue';
    import { mapState } from 'vuex';

    export default {
        components: {
            BrandLogoTransparent,
        },
        props: {
            item: {
                type: Object,
                required: true,
            },
            selected: {
                type: Boolean,
                required: true,
            },
        },
        computed: {
            ...mapState({
                brandsActive: (state) => state.Brands.active,
            }),
            brandId() {
                return this.item.brand?.ID || this.item.brandId;
            },
            cypressValue() {
                return this.item.id === null ? 'none' : this.item.text || this.item.name;
            },
        },
        methods: {
            iconColor(item) {
                return this.selected ? item.color : 'var(--v-gray1-base)';
            },
        },
    };
</script>

<style scoped>
    .item-text {
        font-size: 0.9rem !important;
        font-weight: 400 !important;
    }

    .item-text-not-selected {
        font-size: 0.9rem !important;
        font-weight: 400 !important;
        color: var(--v-gray2-base);
    }

    .brand-container {
        width: 40px;
        display: flex;
        justify-content: center;
    }
</style>
