<template>
    <dynamic-select
        :items="items"
        :fetchFunction="fetchFunction"
        :emptyHeader="$t('emptyHeader')"
        :emptyMessage="$t('emptyMessage')"
        :isSearchable="false"
        :displayIcon="displayIcon"
        :displayValue="displayValue"
        :dense="false"
        :createFunction="null"
        :label="label"
        :value="value"
        :background="background"
        :error="error"
        @input="handleChange"
    />
</template>

<script>
    import client from '@/api/client/client';

    export default {
        name: 'ClientTypeSelect',

        props: {
            background: {
                type: String,
                default: 'transparent',
            },

            label: {
                type: String,
                default: '',
            },

            value: {
                type: Number,
                default: null,
            },

            fetchDirectly: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                items: [],
                error: false,
            };
        },

        mounted() {
            if (this.fetchDirectly) {
                this.fetchFunction();
            }
        },

        methods: {
            async fetchFunction() {
                try {
                    this.error = false;
                    const response = await client.getClientTypes();
                    this.items = response.data.map((clientType) => ({
                        ...clientType,
                        value: clientType.id,
                    }));
                } catch (error) {
                    this.error = true;
                    console.error('Error fetching client types', error);
                }
            },

            handleChange(value) {
                this.$emit('input', value);
            },
        },

        computed: {
            displayIcon() {
                return this.items.find((item) => item.value === this.value)?.icon || '';
            },

            displayValue() {
                return this.items.find((item) => item.value === this.value)?.name || '';
            },
        },
    };
</script>

<i18n lang="json">
{
    "sv": {
        "emptyHeader": "Inga kundtyper",
        "emptyMessage": "Inga kundtyper"
    },
    "en": {
        "emptyHeader": "No client types",
        "emptyMessage": "No client types"
    }
}
</i18n>
