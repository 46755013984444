import axios from 'axios';

export interface AddOverflowPayload {
    name: string;
    queueId: number;
    overflowNumber: number;
    startTime: string;
    startDay: number;
    endTime: string;
    endDay: number;
}

function addOverflow(payload: AddOverflowPayload) {
    return axios.post(`/queues/${payload.queueId}/overflow`, payload);
}
function deleteAllOverflows(queueId: number) {
    return axios.delete(`/queues/${queueId}/overflows`);
}

export interface EditOverflowPayload {
    id: number;
    name: string;
    queueId: number;
    overflowNumber: number;
    startTime: string;
    startDay: number;
    endTime: string;
    endDay: number;
}

function editOverflow(payload: EditOverflowPayload) {
    return axios.put(`/queues/${payload.queueId}/overflow`, payload);
}

function getOverflows(queueId: number) {
    return axios.get(`/queues/${queueId}/overflows`);
}
function deleteOverflow(overflowId: number) {
    return axios.delete(`/queues/${overflowId}/overflow`);
}

export interface UpdateOverflowActivePayload {
    queueId: number;
    value: number;
}

function updateOverflowActive(payload: UpdateOverflowActivePayload) {
    return axios.put(`/queues/${payload.queueId}/overflowActive`, payload);
}

export interface UpdateKeepQueuePointsPayload {
    queueId: number;
    value: number;
}

function updateKeepQueuePoints(payload: UpdateKeepQueuePointsPayload) {
    return axios.put(`/queues/${payload.queueId}/keepQueuePoints`, payload);
}

export default {
    updateOverflowActive,
    addOverflow,
    deleteAllOverflows,
    editOverflow,
    deleteOverflow,
    getOverflows,
    updateKeepQueuePoints,
};
