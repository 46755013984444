import axios from 'axios';
import DedupeRequest from '../../entity/DedupeRequest';

export interface StartVideoMeetingPayload {
    guests: { name: string; role: string }[];
    host: { name: string };
}

function startVideoMeeting(payload: StartVideoMeetingPayload) {
    return axios.post('/cases/video-meeting', payload);
}

export interface GetCasesQuery {
    statuses: string[];
    items: number;
    page: number;
    userIds: string;
    queues: string[];
    channels: string[];
    categories: number[];
    showUnansweredOnly: boolean;
    includeSubCases: boolean;
    query: string;
    searchType: number;
    sortBy: string;
    direction: string;
}

function getCases(q: GetCasesQuery) {
    // Implementing this atm only on search, no not break anything else, to keep scope small
    if (q.query && q.query.length > 0) {
        return axios.get('/v2/cases', {
            params: {
                statuses: q.statuses,
                limit: q.items,
                page: q.page,
                userIds: q.userIds,
                queues: q.queues,
                channels: q.channels,
                categories: q.categories,
                showAnswered: q.showUnansweredOnly ? 0 : 1,
                includeSubCases: q.includeSubCases ? 1 : 0,
                sortBy: q.sortBy,
                direction: q.direction,
                query: encodeURIComponent(q.query),
                searchType: q.searchType,
            },
        });
    }

    return axios.get(
        `/v3/cases?statuses=${q.statuses}&userIds=${q.userIds}&queues=${q.queues.join(
            ',',
        )}&channels=${q.channels}&categories=${q.categories}&showAnswered=${q.showUnansweredOnly ? 0 : 1}
        &includeSubCases=${q.includeSubCases ? 1 : 0}
        &query=${encodeURIComponent(q.query)}&searchType=${q.searchType}&sortBy=${
            q.sortBy
        }&direction=${q.direction}&limit=${q.items}&page=${q.page}`,
    );
}

export interface GetCasesCountQuery {
    statuses: string[];
    userIds: string;
    queues: string[];
    channels: string;
    categories: number[];
    showUnansweredOnly: boolean;
    includeSubCases: boolean;
    query: string;
    searchType: number;
}

function getCasesCount(q: GetCasesCountQuery) {
    return axios.get(
        `/v3/cases/count?statuses=${q.statuses}&userIds=${q.userIds}&queues=${q.queues.join(',')}&channels=${q.channels}&categories=${q.categories}&showAnswered=${q.showUnansweredOnly ? 0 : 1}
        &includeSubCases=${q.includeSubCases ? 1 : 0}
        &query=${encodeURIComponent(q.query)}&searchType=${q.searchType}`,
    );
}

export interface SetDivertPayload {
    queueId: number;
    caseId: string;
    internalType: string;
}

function setDivert(q: SetDivertPayload) {
    return axios.post('/queue/diversion', q);
}

export interface GetSpecificCasePayload {
    caseId: string;
    commentId: number;
    search: string;
    itemsPerPage: number;
    dateTime: string;
    showSystemNotifications: boolean;
}

function getSpecificCase(payload: GetSpecificCasePayload) {
    return axios.get('/comments', {
        params: {
            caseId: payload.caseId,
            commentId: payload.commentId,
            search: payload.search,
            itemsPerPage: payload.itemsPerPage,
            dateTime: payload.dateTime,
            showSystemNotifications: payload.showSystemNotifications,
        },
    });
}

function getCaseAttachments(caseId: string) {
    return axios.get(`/cases/${caseId}/attachments`);
}

export interface SendCommentPayload {
    receiver: string | null;
    comment: string | null;
    caseId: string | null;
    from: string | null;
    outgoing: 0 | 1;
    attachments?: {
        file: {
            id?: number | null;
            contentBytes?: string;
            contentId?: string | null;
            contentType?: string | null;
            isInline: boolean;
            lastModifiedDateTime?: string | null;
            name: string;
            size?: number | null;
        };
        caseId: string | null;
        id?: string;
        type: string;
    }[];
    typeOfMessage: string;
    type: 0 | 1;
    subject: string | null;
    to: (string | null)[];
    ccTo?: (string | null)[];
    bccTo?: (string | null)[];
    attachConversation: 0 | 1;
    threadedAttaches: 0 | 1;
    signature?: string;
    userId: number | null;
    attachComments?: (number | null)[];
    closeCase: boolean;
    repliedComment: number | null;
    action?: 'CREATE_SUBCASE';
    sent?: 0 | 1;
    msgId: string | null;
}

function sendComment(q: SendCommentPayload) {
    return axios.post('/comments', q);
}

function getSystemEmails() {
    return axios.get('/cases/system/emails');
}

function getSystemPhoneNumbers() {
    return axios.get('/sms/numbers');
}

export interface AttemptPayload {
    attemptResultId: number;
    commentTypeId: number;
    startedOn: string;
    endedOn: string;
    comment: string;
}

export interface AttemptQuery {
    0: string | number;
    1: AttemptPayload;
}

function attempt(q: AttemptQuery) {
    return axios.post(`/cmot/callbacks/${q[0]}/attempt`, q[1]);
}

// @ts-ignore <DedupeRequest takes wrong type>
const clientCardDedupe = new DedupeRequest((q: string) => {
    return axios.get(`/client/${q}`);
}, 1000);

function clientCard(q: string) {
    // @ts-ignore <DedupeRequest takes wrong type>
    return clientCardDedupe.send(q);
}

export interface GetAllClientsPayload {
    itemsPerPage: number;
    page: number;
    search: string;
    getAll: boolean;
}

function getAllClients(payload: GetAllClientsPayload) {
    return axios.get(
        `/client/list/all?itemsPerPage=${payload?.itemsPerPage}&page=${payload?.page}&search=${payload?.search}&getAll=${payload?.getAll}`,
    );
}

export interface ChangeCaseOwnerAndClientConnectionPayload {
    oldClient: string;
    newClient: string;
}

function changeCaseOwnerAndClientConnection(payload: ChangeCaseOwnerAndClientConnectionPayload) {
    return axios.post('/client/change', payload);
}

export interface ChangeCaseOwnerPayload {
    ClientID: string;
    CaseID: string;
}

function changeCaseOwner(payload: ChangeCaseOwnerPayload) {
    return axios.put('/change/client', payload);
}

const getClientContactPersonsDedupe = new DedupeRequest((q) => {
    return axios.get(`/client/${q}/contacts`);
}, 1000);

function getClientContactPersons(id: string) {
    // @ts-ignore <DedupeRequest takes wrong type>
    return getClientContactPersonsDedupe.send(id);
}
function deleteContactPerson(id: string) {
    return axios.delete(`/client/${id}`);
}

function moveCase(payload: number) {
    return axios.put('/cases/move', payload);
}

export interface UpdateCaseFieldsPayload {
    caseId: string;
    data: {
        [key: string]: string;
    };
}

function updateCaseFields(q: UpdateCaseFieldsPayload) {
    return axios.put(`/cases/${q.caseId}/fields`, q.data);
}

export interface UpdateCasePayload {
    caseId: string;
    changed: {
        [key: string]: string;
    };
    closeSubCases?: boolean;
}

function updateCase(q: UpdateCasePayload) {
    let query = `/cases/${q.caseId}`;

    if (q.closeSubCases) {
        query += '?closeSubCases=true';
    }
    return axios.patch(query, q.changed);
}

export interface DeleteCasePayload {
    caseId: string;
}

function deleteCase(q: DeleteCasePayload) {
    return axios.delete(`/cases/${q.caseId}`);
}

export interface DeleteCasesPayload {
    caseIds: string[];
}

function deleteCases(q: DeleteCasesPayload) {
    return axios.delete('/cases', { data: q });
}

function getCaseTravel(caseId: string) {
    return axios.get(`/cases/case-travel/${caseId}`);
}

export interface ApplyActionToMultipleCasesPayload {
    value: {
        UserId: number;
        AgentName: string;
    };
    cases: string[];
}

function applyActionToMultipleCases(payload: ApplyActionToMultipleCasesPayload) {
    return axios.put('/cases/apply-multiple', payload);
}

function getTimelineItems({ limit, page, caseId }: { limit: number; page: number; caseId: string }) {
    return axios.get(`/v2/cases/${caseId}/timeline`, { params: { limit, page } });
}

export interface SearchPayload {
    status: string[];
    items: number;
    page: number;
    userId: number;
    queues: number[];
    channels: string[];
    categories: number[];
    showUnansweredOnly: boolean;
    includeSubCases: boolean;
    searchQuery: string;
    sortBy: string;
    direction: string;
}

function search(q: SearchPayload) {
    return axios.get(
        `/search?status=${q.status}&limit=${q.items}&page=${q.page}&userId=${q.userId}&queues=${q.queues.join(
            ',',
        )}&channels=${q.channels}&categories=${q.categories}&showAnswered=${q.showUnansweredOnly ? 0 : 1}
        &includeSubCases=${q.includeSubCases ? 1 : 0}
        &sortBy=${q.sortBy}&direction=${q.direction}&query=${encodeURIComponent(q.searchQuery)}`,
    );
}

export interface SoapGetUserLinkPayload {
    personNr: number;
}

// ***** SOAP integration for quicklinks ***** //
function soapGetUserLink(q: SoapGetUserLinkPayload) {
    return axios.get(`/soap/getPerson/${q.personNr}`);
}

function getActiveCasesByUser() {
    return axios.get('/cases/active/loggedInUser');
}

function getSoundComments(attachmentId: number) {
    return axios.get(`/cases/sound/comments/${attachmentId}`);
}

function getBackendTime() {
    return axios.get('/cases/queue/time');
}

function searchPhoneNumbers(search: string) {
    return axios.get('/cases/phone/numbers', { params: { search } });
}

export interface SendMultiCommuncationPayload {
    emails: string[];
    numbers: string[];
    userId: number;
    queueId: number;
    text: string;
    topic: string;
    to: string;
    from: string;
    smsSender: string;
    attachments: {
        id: number;
        name: string;
        size: number;
        type: string;
        contentBytes: string;
    }[];
}

function sendMultiCommuncation(payload: SendMultiCommuncationPayload) {
    return axios.post('/cases/multi/communication', payload);
}

export interface GetUnreadMessagesByStatusPayload {
    status: string;
    page: number;
    search: string;
}

const getUnreadMessagesByStatusDedupe = new DedupeRequest((payload: GetUnreadMessagesByStatusPayload) => {
    return axios.get(`/cases/unread?status=${payload.status}&page=${payload.page}&search=${payload.search}`);
}, 1000);

function getUnreadMessagesByStatus(payload: GetUnreadMessagesByStatusPayload) {
    return getUnreadMessagesByStatusDedupe.send(payload);
}

function getUnreadMessagesAmountByStatus(status: string) {
    return axios.get(`/cases/unread?status=${status}&$count`);
}

export interface CreateCaseCategoryPayload {
    name: string;
    icon: string;
    color: string;
    description: string;
}

function createCaseCategory(payload: CreateCaseCategoryPayload) {
    return axios.post('cases/categories', payload);
}

const getCaseCategoriesDedupe = new DedupeRequest(() => {
    return axios.get('cases/categories');
}, 1000);

function getCaseCategories() {
    // @ts-ignore <DedupeRequest takes wrong type>
    return getCaseCategoriesDedupe.send();
}

export interface EditCaseCategoryPayload {
    id: number;
    name: string;
    icon: string;
    color: string;
    description: string;
}

function editCaseCategory(payload: EditCaseCategoryPayload) {
    return axios.put(`cases/categories/${payload.id}`, payload);
}

export interface DeleteCaseCategoryPayload {
    categoryId: number;
    action: string;
}

function deleteCaseCategory(payload: DeleteCaseCategoryPayload) {
    return axios.delete(`cases/categories/${payload.categoryId}`, { data: payload });
}

export interface ReplaceCaseCategoryPayload {
    deleteCategory: number;
    replaceCategory: number;
    create: boolean;
}

function replaceCaseCategory(payload: ReplaceCaseCategoryPayload) {
    return axios.put('cases/categories', payload);
}

export interface GetIframePayload {
    type: string;
    query: {
        caseId: string;
    };
}

function getIframe({ type, query }: GetIframePayload) {
    return axios.get(`integrations/${type}/iframe`, { params: query });
}

function getIntegrationIframes() {
    return axios.get('integrations/iframes');
}

function isCaseInQueue(caseId: string) {
    return axios.get(`/cases/${caseId}/queue`);
}

function updateCaseCategories({ caseId, categoryIds }: { caseId: string; categoryIds: number[] }) {
    return axios.put(`cases/${caseId}/categories`, { categoryIds });
}

function giveWriteAccessToCaseOwner(caseId: string) {
    return axios.put(`cases/${caseId}/write-access`);
}

function resetUnreadMessages(caseId: string) {
    return axios.put(`/cases/${caseId}/unread/clear`);
}

function getExternalRelations(caseId: string) {
    return axios.get(`/cases/${caseId}/external-relations`);
}

function getNextCase(): Promise<{ caseId: string }> {
    return axios.post('/cases/get-next-case');
}

function getOrderIntegrations({ type, caseId }: { type: string; caseId: string }) {
    return axios.get(`integrations/${type}/orders`, { params: { caseId } });
}
function getCallbackNumbersFromCase(caseId: string) {
    return axios.get(`/cases/${caseId}/callbacks`);
}

function calculateAndUpdateSla(caseId: string, method: string) {
    return axios.put(`/cases/${caseId}/sla/${method}`);
}

export default {
    updateCaseCategories,
    getSystemEmails,
    getSystemPhoneNumbers,
    getSpecificCase,
    clientCard,
    getAllClients,
    getClientContactPersons,
    deleteContactPerson,
    changeCaseOwnerAndClientConnection,
    changeCaseOwner,
    updateCase,
    deleteCase,
    deleteCases,
    applyActionToMultipleCases,
    sendComment,
    search,
    soapGetUserLink,
    attempt,
    getActiveCasesByUser,
    getSoundComments,
    moveCase,
    searchPhoneNumbers,
    setDivert,
    sendMultiCommuncation,
    getCases,
    getCasesCount,
    getCaseCategories,
    getCaseTravel,
    updateCaseFields,
    getBackendTime,
    getUnreadMessagesByStatus,
    getUnreadMessagesAmountByStatus,
    startVideoMeeting,
    createCaseCategory,
    editCaseCategory,
    deleteCaseCategory,
    replaceCaseCategory,
    getIframe,
    getIntegrationIframes,
    isCaseInQueue,
    giveWriteAccessToCaseOwner,
    resetUnreadMessages,
    getCaseAttachments,
    getExternalRelations,
    getNextCase,
    getOrderIntegrations,
    getCallbackNumbersFromCase,
    getTimelineItems,
    calculateAndUpdateSla,
};
