import axios from 'axios';

function getAttachmentFile(id: number, meta: boolean) {
    try {
        return axios.get(`/attachment/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            params: {
                meta,
            },
        });
    } catch (error) {
        console.error(error);
        return error;
    }
}

function getAttachmentFileByContentId(contentId: string) {
    try {
        const encodedContentId = encodeURIComponent(contentId); // Encode the contentId
        return axios.get(`/attachment/content/${encodedContentId}`, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });
    } catch (error) {
        console.error(error);
        return Promise.reject(error); // Ensure consistent return type for error handling
    }
}

export interface UploadAttachmentsPayload {
    contentBytes: string;
    contentId?: string | null;
    contentType: string;
    name?: string;
    size?: number;
    isInline?: boolean;
    msgId?: string | null;
    context?: {
        id: string | number | null;
        resource: 'comment' | 'template';
    };
}

function uploadAttachments(attachments: UploadAttachmentsPayload) {
    return axios.post('/attachments', attachments, {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    });
}

export default {
    getAttachmentFile,
    getAttachmentFileByContentId,
    uploadAttachments,
};
