export const modalStates = {
    CASE_ASSIGN: 'CASE_ASSIGN',
    CONTACT_PERSON: 'CONTACT_PERSON',
    CLIENT_ASSIGN: 'CLIENT_ASSIGN',
    CRM: 'CRM',
    CONTENT: 'CONTENT',
    RECORDINGS: 'RECORDINGS',
    QUEUE_ASSIGN: 'QUEUE_ASSIGN',
    IFRAME: 'iframe',
} as const;

export type ModalStates = (typeof modalStates)[keyof typeof modalStates];

export const events = {
    UPDATE_CLIENT: 'UPDATE_CLIENT',
    NEW_CLIENT: 'NEW_CLIENT',
} as const;

export type Events = (typeof events)[keyof typeof events];

export const states = {
    OPEN: 'OPEN',
    CLOSED: 'CLOSED',
} as const;

export type States = (typeof states)[keyof typeof states];

export const searchFields = {
    NAME: 'name',
    EMAIL: 'email',
    TELEPHONE: 'telephone',
    ADDRESS: 'adress',
};

export type SearchFields = (typeof searchFields)[keyof typeof searchFields];

export const clientTypes = {
    CLIENT: 'client',
    CONTACT: 'contact',
    SHADOW: 'shadow',
} as const;

export type ClientTypes = (typeof clientTypes)[keyof typeof clientTypes];

// Order of fields in the client card
export const clientFieldOrder = new Set([
    'name',
    'clientId',
    'socialSecurityNumber',
    'primaryEmail',
    'secondaryEmail',
    'primaryTelephone',
    'secondaryTelephone',
    'address',
    'post',
    'city',
    'country',
    'language',
    'market',
    'customerType',
    'clientType',
    'tags',
    'confirmationEmail',
    'contactContract',
    'contactSupport',
    'parent',
    'priorityPoints',
    'gdprCleaned',
    'includedInSearch',
    'information',
    'locationInfo',
    'type',
    'externalId',
] as const);

export type ClientFieldOrder = typeof clientFieldOrder extends Set<infer T> ? T : never;
