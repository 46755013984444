import { z } from 'zod';

export const clientFieldsList = [
    'name',
    'primaryTelephone',
    'secondaryTelephone',
    'clientId',
    'primaryEmail',
    'secondaryEmail',
    'address',
    'post',
    'tags',
    'clientType',
    'customerType',
    'market',
    'city',
    'country',
    'language',
    'locationInfo',
    'information',
    'contactContract',
    'contactSupport',
    'confirmationEmail',
    'socialSecurityNumber',
    'gdprCleaned',
    'includedInSearch',
    'type',
    'parent',
    'priorityPoints',
    'externalIds',
    'swedishPersonalIdentityNumber',
    'norwegianNationalIdentityNumber',
] as const;

export type ClientFieldsType = (typeof clientFieldsList)[number];

export interface IClientFields {
    id: number;
    type: ClientFieldsType;
    visible: boolean;
    editable: boolean;
    position: number;
}

export const clientFieldSchema = z.object({
    id: z.coerce.number(),
    type: z.enum(clientFieldsList),
    visible: z.boolean(),
    editable: z.boolean(),
    position: z.coerce.number(),
});

export const clientFieldsSchema = z.array(clientFieldSchema);
