<template>
    <action-button v-bind="extendedAttrs" class="action-button" @click="handleClick"><slot></slot></action-button>
</template>

<script>
    export default {
        name: 'PrimaryAction',
        computed: {
            extendedAttrs() {
                return {
                    ...this.$attrs,
                    color: 'var(--v-primary-base)',
                };
            },
        },

        methods: {
            handleClick() {
                this.$emit('click');
            },
        },
    };
</script>

<style scoped>
    .action-button {
        color: white !important;
    }
</style>
