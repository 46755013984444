<template>
    <section :class="containerClass" class="timeline-item-container" @click="$emit('click')">
        <BusyLine :item="item" :isBusy="isBusy" :isSelected="isSelectedCase" />
        <section>
            <TimelineItemHeader :item="item" :isChild="isChild" />
        </section>
        <section class="timeline-round-icons">
            <TimelineItemCard :item="item" />
            <TimelineItemAvatar :item="item" />
        </section>
        <section>
            <TimelineItemChevron :active="isExpanded" @click="$emit('expand')" v-if="isParent" />
        </section>
    </section>
</template>

<script lang="ts">
    import { defineComponent, PropType } from 'vue';
    import TimelineItemHeader from '@/components/TimelineItemHeader.vue';
    import TimelineItemAvatar from '@/components/Cases/TimelineItemAvatar.vue';
    import TimelineItemCard from '@/components/Cases/TimelineItemCard.vue';
    import TimelineItemChevron from '@/components/TimelineItemChevron.vue';
    import BusyLine from '@/components/BusyLine.vue';
    import { ClientTimelineItem } from '@/types/cases.types';

    enum CardSize {
        BIG,
        SMALL,
    }

    export default defineComponent({
        name: 'TimelineItemSubCases',
        components: {
            TimelineItemHeader,
            TimelineItemAvatar,
            TimelineItemCard,
            TimelineItemChevron,
            BusyLine,
        },
        props: {
            item: {
                type: Object as PropType<ClientTimelineItem>,
                required: true,
            },
            isExpanded: {
                type: Boolean,
                default: false,
            },
            cardSize: {
                type: Number as PropType<CardSize>,
                default: CardSize.SMALL,
            },
        },
        computed: {
            busyCaseList() {
                // @ts-ignore <This is going to be converted to Pinia store in the future>
                return this.$store.state.Cases.busyCaseList as { id: string; activeUser: boolean; userName: string }[];
            },

            selectedCase() {
                // @ts-ignore <This is going to be converted to Pinia store in the future>
                return this.$store.state.Cases.selectedCase?.case as { caseId: string };
            },

            isParent() {
                return this.item.subCasesCount > 0;
            },

            isChild() {
                return Boolean(this.item.isChild);
            },

            isBusy() {
                return this.busyCaseList.some((e) => e.id === this.item.caseId);
            },

            isSelectedCase() {
                return this.selectedCase?.caseId === this.item.caseId;
            },

            baseClass() {
                return 'timeline-item-container';
            },

            stateClasses() {
                return {
                    selected: this.isSelectedCase,
                    'selected-expanded': this.isSelectedCase && (this.isExpanded || this.isChild),
                    disabled: !this.item.allowed,
                };
            },

            expansionClass() {
                return this.isExpanded ? 'timeline-item-container-expanded timeline-item-container-parent' : '';
            },
            bigCardClass() {
                return this.cardSize === CardSize.BIG ? 'big-card' : '';
            },

            containerClass() {
                const base = this.baseClass;
                const state = this.stateClasses;
                const expansion = this.expansionClass;
                const childParentClass = this.isChild
                    ? 'timeline-item-container-child'
                    : 'timeline-item-container-parent';
                const bigCardClass = this.bigCardClass;

                return [base, state, expansion, childParentClass, bigCardClass];
            },
        },
    });
</script>

<style scoped>
    .selected {
        background-color: var(--v-gray3-base);
    }

    .disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    .timeline-item-container-parent,
    .timeline-item-container-child {
        display: grid;
        grid-template-columns: 1fr auto 32px;
        padding-left: 16px;
        padding-right: 0;
        gap: 4px;
        position: relative;
        transition: background-color 0.2s;
        border-radius: 8px;
        user-select: none;
    }

    .timeline-item-container-child.big-card {
        grid-template-columns: 1fr auto 40px;
    }

    .timeline-item-container-parent {
        min-height: 64px;
        max-height: 64px;
    }

    .timeline-item-container-child {
        min-height: 40px;
        max-height: 40px;
    }

    .timeline-item-container-parent:hover {
        background-color: var(--v-gray3-base);
        cursor: pointer;
    }

    .timeline-item-container-child:hover {
        background-color: var(--v-gray5-darken1);
        cursor: pointer;
    }

    .timeline-item-container {
        position: relative;
    }

    .timeline-round-icons {
        display: flex;
        flex-direction: row;
        gap: 8px;
    }

    .timeline-item-container > * {
        align-self: center;
    }

    .selected-expanded {
        background-color: var(--v-gray5-darken1) !important;
    }
</style>
