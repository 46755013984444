import axios from 'axios';
import cfg from '@/app/config';
import DedupeRequest from '../../entity/DedupeRequest';

function loginClient(auth: { username: string; password: string }) {
    return axios.get('/auth', {
        headers: {
            Authorization: 'Basic ' + Buffer.from(`${auth.username}:${auth.password}`, 'utf8').toString('base64'),
        },
    });
}

function federatedLoginClient(path: string) {
    return axios.get('auth' + path);
}

const dedupeRefreshToken = new DedupeRequest(() => {
    return axios.get('auth/refresh');
}, 1000);

function refreshToken() {
    // @ts-ignore <Why does send accept an object but we send nothing?>
    return dedupeRefreshToken.send();
}

export interface ChangePasswordPayload {
    UserName: string;
    OldPassword: string;
    NewPassword: string;
}

function changePassword(payload: ChangePasswordPayload) {
    return axios.put('/credentials', payload);
}

function swyxGeneratePassword(email: string) {
    return axios.post('swyx/generate/password', email);
}

function resetPassword(username: string) {
    return axios.post('/auth/reset-password', username);
}

export type AuthenticationMethod = 'microsoft';

function logoutClient(authenticationMethod: AuthenticationMethod) {
    window.location.href = `${cfg.customerFirstUrl}auth/${authenticationMethod.toLowerCase()}/logout`;
}

function logout() {
    return axios.get('/logout');
}

export default {
    loginClient,
    federatedLoginClient,
    refreshToken,
    changePassword,
    swyxGeneratePassword,
    logoutClient,
    resetPassword,
    logout,
};
