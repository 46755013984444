export const mimeTypes = {
    TXT: 'text/plain',
    PDF: 'application/pdf',
    DOC: 'application/msword',
    DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    XLS: 'application/vnd.ms-excel',
    XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    PPT: 'application/vnd.ms-powerpoint',
    PPTX: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    JPG: 'image/jpg',
    JPEG: 'image/jpeg',
    PNG: 'image/png',
    GIF: 'image/gif',
    HEIC: 'image/heic',
    MP4: 'video/mp4',
    MP3: 'audio/mpeg',
    WEBP: 'image/webp',
    TIFF: 'image/tiff',
    SVG: 'image/svg+xml',
    BMP: 'image/bmp',
    ICO: 'image/x-icon',
    AVIF: 'image/avif',
} as const;

export const fileExtensions = {
    TXT: '.txt',
    PDF: '.pdf',
    DOC: '.doc',
    DOCX: '.docx',
    XLS: '.xls',
    XLSX: '.xlsx',
    PPT: '.ppt',
    PPTX: '.pptx',
    JPG: '.jpg',
    JPEG: '.jpeg',
    PNG: '.png',
    GIF: '.gif',
    HEIC: '.heic',
    MP4: '.mp4',
    MP3: '.mp3',
    WEBP: '.webp',
    TIFF: '.tiff',
    SVG: '.svg',
    BMP: '.bmp',
    ICO: '.ico',
    AVIF: '.avif',
} as const;

export const fileTypes = {
    JPG: 'jpg',
    JPEG: 'jpeg',
    PNG: 'png',
    GIF: 'gif',
    HEIC: 'heic',
    MP4: 'mp4',
    MP3: 'mp3',
    WEBP: 'webp',
    TIFF: 'tiff',
    SVG: 'svg',
    BMP: 'bmp',
    ICO: 'ico',
    AVIF: 'avif',
    PDF: 'pdf',
    DOC: 'doc',
    DOCX: 'docx',
    XLS: 'xls',
    XLSX: 'xlsx',
    PPT: 'ppt',
    PPTX: 'pptx',
    TXT: 'txt',
} as const;

export const imageMimeTypes = {
    JPG: mimeTypes.JPG,
    JPEG: mimeTypes.JPEG,
    PNG: mimeTypes.PNG,
    GIF: mimeTypes.GIF,
    HEIC: mimeTypes.HEIC,
    WEBP: mimeTypes.WEBP,
    TIFF: mimeTypes.TIFF,
    SVG: mimeTypes.SVG,
    BMP: mimeTypes.BMP,
    ICO: mimeTypes.ICO,
    AVIF: mimeTypes.AVIF,
} as const;

export const imageFileExtensions = {
    JPG: fileExtensions.JPG,
    JPEG: fileExtensions.JPEG,
    PNG: fileExtensions.PNG,
    GIF: fileExtensions.GIF,
    HEIC: fileExtensions.HEIC,
    WEBP: fileExtensions.WEBP,
    TIFF: fileExtensions.TIFF,
    SVG: fileExtensions.SVG,
    BMP: fileExtensions.BMP,
    ICO: fileExtensions.ICO,
    AVIF: fileExtensions.AVIF,
} as const;

export type MimeType = keyof typeof mimeTypes;
export type FileExtension = keyof typeof fileExtensions;
export type FileType = keyof typeof fileTypes;
export type ImageMimeType = keyof typeof imageMimeTypes;
export type ImageFileExtension = keyof typeof imageFileExtensions;
