import axios from 'axios';

export interface GetStatisticsPayload {
    from: string;
    to: string;
    queues: string;
    user: string;
    categories: string;
    channels: string;
    format: string;
}

/* C1 STATISTICS  */
function getAllStatistics({ from, to, queues, user, categories, channels, format }: GetStatisticsPayload) {
    return axios.get(
        `/statistics?from=${from}&to=${to}&queues=${queues}&user=${user}&categories=${categories}&channels=${channels}&format=${format}`,
    );
}

export interface GetCMOTStatisticsPayload {
    from: string;
    to: string;
    queueIds: string;
    timeFormat: string;
}

/* CMOT STATISTICS  */
function getCMOTStatistics({ from, to, queueIds, timeFormat }: GetCMOTStatisticsPayload) {
    return axios.get(`/statistics/cmot?from=${from}&to=${to}&queueIds=${queueIds}&timeFormat=${timeFormat}`);
}

/* Intervals  */
function getAllDateIntervals() {
    return axios.get('/statistics/intervals');
}

/* SWYX STATISTICS  */
function getActiveCalls() {
    return axios.get('/swyx/statistics/activecalls');
}

export interface GetTimelineStatisticsPayload {
    type: string;
    groupId: number;
    page: string;
    search: string;
    excludeEmptyValues: number;
    date: string;
}

function getTimelineStatistics({
    type,
    groupId,
    page,
    search,
    excludeEmptyValues,
    date,
}: GetTimelineStatisticsPayload) {
    return axios.get(
        `statistics/timeline?type=${type}&groupId=${groupId}&page=${page}&search=${search}&excludeEmptyValues=${excludeEmptyValues}&date=${date}`,
    );
}

export interface AggregatedActivityStatisticsPayload {
    type: string;
    id: number;
    from: string;
    to: string;
    dataType: string;
    format: string;
}

function aggregatedActivityStatistics({ type, id, from, to, dataType, format }: AggregatedActivityStatisticsPayload) {
    return axios.get(
        `statistics/calls/presence/column?type=${type}&id=${id}&from=${from}&to=${to}&datatype=${dataType}&format=${format}`,
    );
}

export interface GetCallPerformanceStatisticsPayload {
    id: number;
    type: string;
}

function getCallPerformanceStatistics({ id, type }: GetCallPerformanceStatisticsPayload) {
    return axios.get(`statistics/performance/calls?id=${id}&type=${type}`);
}

function getBigTableCallPerformanceStatistics() {
    return axios.get('statistics/performance/calls/table');
}

export interface CallCDRStatisticsPayload {
    from: string;
    to: string;
    channel: string;
    timeFormat: string;
    queueIds: string;
}

function callCDRStatistics({ from, to, channel, timeFormat, queueIds }: CallCDRStatisticsPayload) {
    return axios.get(
        `statistics/calls/cdr?from=${from}&to=${to}&channel=${channel}&format=${timeFormat}&queueIds=${queueIds}`,
    );
}

export interface ExportStatisticsPayload {
    statisticsType: number;
    recipient: string;
    period: number;
    identifiers: number[];
    name: string;
    timeInterval: number;
    time: string;
    day: number;
}

function exportStatistics(payload: ExportStatisticsPayload) {
    return axios.post('statistics/export', payload);
}

function getExportJobs() {
    return axios.get('statistics/export/jobs');
}

function deleteJob(id: number) {
    return axios.delete(`statistics/export/jobs/${id}`);
}

export interface GetUserStatisticsByGroupPayload {
    from: string;
    to: string;
    groupId: number;
    type: string;
    limit?: number;
    offset?: number;
}

function getUserStatisticsByGroup(payload: GetUserStatisticsByGroupPayload) {
    return axios.get('statistics/user/group', {
        params: payload,
    });
}

export interface GetUserGroupComparePayload {
    from: string;
    to: string;
    userIds: string;
    groupId: number;
    dataType: string;
}

function getUserGroupCompare({ from, to, userIds, groupId, dataType }: GetUserGroupComparePayload) {
    return axios.get(
        `statistics/user/group/compare?from=${from}&to=${to}&groupId=${groupId}&userIds=${userIds}&type=${dataType}`,
    );
}

export interface GetClientsCallStatisticsPayload {
    from: string;
    to: string;
    landingNumbers: string;
    name: string;
}

function getClientsCallStatistics({ from, to, landingNumbers, name }: GetClientsCallStatisticsPayload) {
    return axios.get(`statistics/client/calls/?from=${from}&to=${to}&name=${name}&landingNumbers=${landingNumbers}`);
}
function getJobData(jobId: number) {
    return axios.get(`statistics/jobs/${jobId}/data`);
}

export interface GetC1QueueCallsStatsPayload {
    groupId: number;
    queueId: number;
    from: string;
    to: string;
    format: string;
    period: { from: string; to: string };
}

function getC1QueueCallsStats({ groupId, queueId, from, to, format, period }: GetC1QueueCallsStatsPayload) {
    return axios.get(
        `statistics/calls/group/${groupId}/queue/${queueId}?from=${from}&to=${to}&format=${format}&periodStart=${period.from}&periodEnd=${period.to}`,
    );
}
function getUserC1LogIns(userId: number) {
    return axios.get(`statistics/timeline/c1queue/logins?userId=${userId}`);
}

export interface GetC1QueueServiceStatisticsPayload {
    queueIds: string;
    channelType: string;
    from: string;
    to: string;
}

function getC1QueueServiceStatistics({ queueIds, channelType, from, to }: GetC1QueueServiceStatisticsPayload) {
    return axios.get(`/statistics/service/queue?queueIds=${queueIds}&channelType=${channelType}&from=${from}&to=${to}`);
}

export interface GetC1WorkingTimeStatisticsPayload {
    type: string;
    typeId: number;
    compareType: string;
    compareTypeIds: string;
    channelType: string;
    from: string;
    to: string;
    accuracyMs: number;
}

function getC1WorkingTimeStatistics(payload: GetC1WorkingTimeStatisticsPayload) {
    return axios.get(
        `/statistics/service/cases?type=${payload.type}&typeId=${payload.typeId}&compareType=${payload.compareType}&compareTypeIds=${payload.compareTypeIds}&channelType=${payload.channelType}&from=${payload.from}&to=${payload.to}&accuracyMs=${payload.accuracyMs}`,
    );
}
function getQueuesAdvanced() {
    return axios.get('/queues?IsSystemQueue=1');
}

/** DYNAMIC EXPORTS */

/**
 * Fetches a list of dynamic export statistics.
 */
function getDynamicExports() {
    return axios.get('/statistics/dynamic-export');
}

export interface UpdateDynamicExportPayload {
    id: number;
    body: {
        name?: string;
        description?: string;
    };
}
/**
 * Updates a dynamic export with the given `id` and fields to update.
 *
 * @param {Object} payload - The payload for the update.
 * @param {number} payload.id - The ID of the dynamic export to update (must be a valid number).
 * @param {Object} payload.body - The fields to update.
 * @param {string} [payload.body.name] - The new name for the dynamic export (optional).
 * @param {string} [payload.body.description] - The new description for the dynamic export (optional).
 */
function updateDynamicExport({ id, body }: UpdateDynamicExportPayload) {
    return axios.patch(`/statistics/dynamic-export/${id}`, body);
}

export interface RunDynamicExportPayload {
    id: number;
    body: {
        start: string;
        end: string;
    };
}
/**
 * Runs a dynamic export for the given `id` and specified date range.
 *
 * @param {Object} payload - The payload for running the export.
 * @param {number} payload.id - The ID of the dynamic export to run (must be a valid number).
 * @param {Object} payload.body - The date range for the export.
 * @param {Date} payload.body.start - The start date of the export (must be a valid date).
 * @param {Date} payload.body.end - The end date of the export (must be a valid date).
 * @param {string} [payload.headers.accept='application/json'] - The format of the export ('application/json' or 'text/csv').
 */
function runDynamicExport({ id, body }: RunDynamicExportPayload) {
    return axios.post(`/statistics/dynamic-export/${id}/run`, body, {
        headers: { accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
        responseType: 'blob',
    });
}

export default {
    getAllStatistics,
    getCMOTStatistics,
    getAllDateIntervals,
    getActiveCalls,
    getTimelineStatistics,
    aggregatedActivityStatistics,
    getCallPerformanceStatistics,
    getBigTableCallPerformanceStatistics,
    callCDRStatistics,
    exportStatistics,
    getExportJobs,
    deleteJob,
    getUserStatisticsByGroup,
    getUserGroupCompare,
    getClientsCallStatistics,
    getJobData,
    getC1QueueCallsStats,
    getUserC1LogIns,
    getC1QueueServiceStatistics,
    getC1WorkingTimeStatistics,
    getQueuesAdvanced,
    getDynamicExports,
    updateDynamicExport,
    runDynamicExport,
};
