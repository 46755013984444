import axios from 'axios';

function getCreatorFormOptions() {
    return axios.get('/form/options/');
}

function getFormByOption(id: number, caseId: string) {
    let url = `/form/${id}`;

    if (caseId !== null) {
        url += `/${caseId}`;
    }

    return axios.get(url);
}

// TODO: This data is not typed cause its dynamically created
function postForm(data: unknown) {
    return axios.post('/form', data);
}

export default {
    getCreatorFormOptions,
    getFormByOption,
    postForm,
};
