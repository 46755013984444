<template>
    <div class="truncate-field">
        <span v-if="!isOverflowing" ref="textElementBase" class="truncate-text" :style="textStyles">
            <slot></slot>
        </span>
        <tooltip v-if="isOverflowing" :content="tooltipText" dynamic>
            <span ref="textElement" class="truncate-text" :style="textStyles">
                <slot></slot>
            </span>
        </tooltip>
    </div>
</template>

<script>
    export default {
        name: 'Truncate',
        props: {
            align: {
                type: String,
                default: 'inherit',
            },
        },

        data() {
            return {
                isOverflowing: false,
                tooltipText: '',
                resizeObserver: null,
            };
        },

        /**
         * This component only calculates overflow once.
         * As soon as it detects an overflow, it will stop observing the element.
         * If the element changes size after that, the overflow will not be recalculated.
         */
        mounted() {
            this.resizeObserver = new ResizeObserver(this.resizeFunction);
            this.resizeObserver.observe(this.$refs.textElementBase);
        },

        beforeDestroy() {
            this.resizeObserver.disconnect();
        },
        computed: {
            textStyles() {
                return {
                    'text-align': this.align,
                };
            },
        },

        methods: {
            resizeFunction(entries) {
                for (const entry of entries) {
                    if (entry.target === this.$refs.textElementBase || entry.target === this.$refs.textElement) {
                        this.checkOverflow(entry.target);
                        if (this.isOverflowing) {
                            this.resizeObserver.unobserve(this.$refs.textElementBase);
                        }
                    }
                }
            },
            checkOverflow(element) {
                this.isOverflowing = this.isElementOverflowing(element);
                this.tooltipText = this.isOverflowing ? element.textContent : '';
            },

            isElementOverflowing(element) {
                return element.scrollWidth > element.clientWidth;
            },
        },
    };
</script>

<style scoped>
    .truncate-field {
        display: flex;
        align-items: center;
        justify-content: stretch;
        flex: 1;
    }

    .truncate-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1;
        width: 0;
    }
</style>
