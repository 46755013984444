<template>
    <standard-select
        v-bind="selectPropsComputed"
        :items="items"
        :boolean="boolean"
        :dense="dense"
        :value="preSelected"
        :single="true"
        @click="handleClick"
        @change="handleChange"
    >
        <template #item-slot="{ item, selected }">
            <QueueSelectItem :item="item" :selected="selected" />
        </template>
        <template #button-append-content="{ selectedItems }">
            <div class="d-flex">
                <BrandLogoTransparent
                    v-if="selectedItems.at(0).brand?.ID"
                    :key="selectedItems.at(0).brand?.ID"
                    :brandId="selectedItems.at(0).brand?.ID"
                    size="20px"
                    tooltip
                    :tooltipText="selectedItems.at(0).brand?.Name"
                />
                <v-icon size="16" class="ml-3" color="var(--v-gray2-base)">mdi-chevron-down</v-icon>
            </div>
        </template>
    </standard-select>
</template>
<script>
    import { mapState, mapActions } from 'vuex';
    import { channelTypes } from '@/constants/enums/queues.enums';
    import BrandLogoTransparent from '@/components/Brands/BrandLogoTransparent.vue';
    import QueueSelectItem from './Items/QueueSelectItem.vue';

    export default {
        name: 'QueueSelect',
        components: {
            QueueSelectItem,
            BrandLogoTransparent,
        },

        props: {
            boolean: {
                type: Boolean,
                default: false,
            },
            dense: {
                type: Boolean,
                default: true,
            },
            queueTypes: {
                type: Array,
                default: () => null,
            },
        },

        data() {
            return {
                selectProps: {},
                queues: [],
                inputQueueTypes: {
                    EMAIL_QUEUE_SELECT: [channelTypes.EMAIL],
                    SMS_QUEUE_SELECT: [channelTypes.SMS],
                    CALL_QUEUE_SELECT: [channelTypes.CALL],
                    QUEUE_SELECT: null,
                },
                loggedInQueues: [],
            };
        },

        computed: {
            ...mapState({
                // Cases
                caseFilters: (state) => state.Cases.caseFilters,
                caseFiltersState: (state) => state.Cases.caseFiltersState,
                selectedCase: (state) => state.Cases.selectedCase,

                // Auth
                userObject: (state) => state.Auth.userObject,

                // Brands
                userSelectedBrands: (state) => state.Brands.userSelectedBrands,
                brandsActive: (state) => state.Brands.active,
            }),

            selectPropsComputed() {
                return {
                    ...this.selectProps,
                    ...this.$attrs,
                };
            },

            items() {
                if (!this.queues) {
                    return [];
                }
                return this.queues.map((queue) => ({
                    text: queue.Name,
                    value: queue.ID,
                    icon: queue.Icon,
                    color: 'var(--v-primary-base)',
                    brand: queue.Brand,
                    defaultSenderEmail: queue.DefaultSenderEmail,
                }));
            },

            preSelected() {
                try {
                    // 1. Check which case you are currently in
                    if (this.selectedCase) {
                        const selectedCaseQueue = this.queues.find(
                            (queue) => queue.ID === this.selectedCase.case.queueId
                        );
                        if (selectedCaseQueue) {
                            return this.getQueueInfo(
                                this.caseFilters.queues.find((queue) => queue.ID === selectedCaseQueue.ID)
                            );
                        }
                    }
                    // 2. Filter out all queues that the user is logged in to and sort by prio descending
                    const loggedInQueue = this.loggedInQueues
                        .filter((loggedInQueue) => this.queues.some((queue) => queue.ID === loggedInQueue.id))
                        .sort((a, b) => b.prio - a.prio)
                        .at(0);

                    if (loggedInQueue) {
                        return this.getQueueInfo(
                            this.caseFilters.queues.find((queue) => queue.ID === loggedInQueue.id)
                        );
                    }

                    // 3. If no logged-in are found, check caseFiltersState and caseFilters.queues
                    if (this.caseFiltersState && this.caseFilters.queues.length > 0) {
                        const queue = [...this.caseFilters.queues]
                            .sort((a, b) => b.Prio - a.Prio)
                            .find((queue) =>
                                this.caseFiltersState.queues.some((state) => {
                                    return queue.ID === state.id;
                                })
                            );

                        if (queue) {
                            return this.getQueueInfo(queue);
                        }
                    }

                    if (this.items.length) {
                        return this.items[0];
                    }

                    return null;
                } catch (error) {
                    console.error('Error auto selecting queue:', error);
                    return null;
                }
            },
        },
        watch: {
            // selectPropsComputed.type
            selectPropsComputed: {
                async handler(newVal, oldVal) {
                    // If the new value is empty, do not fetch new queues
                    if (!newVal) return;

                    // If the type is the same, do not fetch new queues
                    if (newVal.type === oldVal?.type) return;
                    await this.setAvailableQueues();
                    await this.setLoggedInQueues();

                    await this.$nextTick();
                    const queue = this.caseFilters.queues.find(
                        (queue) => queue.value === (this.preSelected?.value || null)
                    );
                    this.handleChange([this.getQueueInfo(queue)]);
                },
                deep: true,
                immediate: true,
            },
        },

        methods: {
            ...mapActions('QueueManager', ['getQueuesV2', 'getLoggedInQueuesByUserId']),
            getQueueInfo(queue) {
                return {
                    text: queue.name,
                    value: queue.value,
                    icon: queue.icon,
                    color: queue.color,
                    brand: queue.brand,
                };
            },

            handleClick(event) {
                this.$emit('click', event);
            },

            handleChange(queue) {
                this.$emit('input', queue.at(0).value);
                this.$emit('change', queue.at(0).value);
            },
            getQueueTypes() {
                if (this.queueTypes) {
                    return this.queueTypes;
                }
                if (!this.selectPropsComputed.type) {
                    return null;
                }
                return this.inputQueueTypes[this.selectPropsComputed.type] || null;
            },
            async setAvailableQueues() {
                const filters = {
                    types: this.getQueueTypes(),
                };
                if (this.brandsActive) {
                    filters.brands = this.userSelectedBrands;
                    filters.includes = ['brand'];
                }
                const queues = await this.getQueuesV2(filters);
                queues.unshift({
                    ID: null,
                    Name: this.$t('global.noQueue'),
                    Icon: 'mdi-account-multiple-remove',
                });
                this.queues = queues;
            },

            async setLoggedInQueues() {
                const { userId } = this.userObject;
                this.loggedInQueues = await this.getLoggedInQueuesByUserId(userId);
            },
        },
    };
</script>

<style scoped></style>

<i18n lang="json">
{}
</i18n>
