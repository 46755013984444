<template>
    <v-hover v-slot="{ hover }" open-delay="500">
        <v-list-item class="case-list-item" @click="$emit('selected', data.id)">
            <v-list-item-content>
                <v-list-item-title>({{ data.id }}) {{ data.description.split('\n')[0] }}</v-list-item-title>
                <v-list-item-subtitle>
                    Status: {{ translateStatus(data.status) }} | {{ $t('registered') }}:
                    {{ formatDate(data.registrationDate) }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                    {{ $t('notifier') }}: {{ data.notifier.name }} ({{ data.notifier.email }})
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="hover">
                <v-btn icon @click.stop="openInVitec">
                    <v-icon>mdi-open-in-new</v-icon>
                </v-btn>
            </v-list-item-action>
        </v-list-item>
    </v-hover>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    export default defineComponent({
        props: {
            data: {
                type: Object,
                required: true,
            },
        },
        computed: {},
        methods: {
            formatDate(date: string) {
                return new Date(date).toLocaleDateString();
            },
            openInVitec() {
                window.alert('Open in Vitec');
            },
            translateStatus(status: string) {
                switch (status) {
                    case 'Completed':
                        return this.$t('completed');
                    case 'Pending':
                        return this.$t('pending');
                    case 'Annulled':
                        return this.$t('annulled');
                    case 'Created':
                        return this.$t('created');
                    default:
                        return status;
                }
            },
        },
    });
</script>

<style scoped>
    .case-list-item {
        border-bottom: 1px solid #e0e0e0;
        padding: 16px;
    }
</style>

<i18n lang="json">
{
    "en": {
        "notifier": "Notifier",
        "registered": "Registered",
        "completed": "Completed",
        "pending": "Pending",
        "annulled": "Annulled",
        "created": "Created"
    },
    "sv": {
        "notifier": "Anmälare",
        "registered": "Registrerad",
        "completed": "Avslutad",
        "pending": "Pågående",
        "annulled": "Annullerad",
        "created": "Skapad"
    }
}
</i18n>
