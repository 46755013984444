import axios from 'axios';

function getAllIvrs() {
    return axios.get('/ivrs/user');
}
//= ===================================================//
//                  OPENHOURS CALLS                   //
//= ===================================================//

export interface OpeningHoursPayload {
    queueType: string;
    queueId: number;
    channelType: string;
}
function getOpenHours({ queueType, queueId, channelType }: OpeningHoursPayload) {
    return axios.get(`/openinghours?queueType=${queueType}&queueId=${queueId}&channelType=${channelType}`);
}

function setOpeningHours(payload: unknown) {
    return axios.post('/openinghours', payload);
}

export interface OpeningHoursExceptionsPayload {
    queueType: string;
    queueId: number;
    channelType: string;
    dateRange: {
        startDate: string;
        endDate: string;
    };
}

function getOpeningHoursExceptions({ queueType, queueId, channelType, dateRange }: OpeningHoursExceptionsPayload) {
    return axios.get('/openinghours/exceptions', {
        params: {
            queueType,
            queueId,
            channelType,
            start: dateRange.startDate,
            end: dateRange.endDate,
        },
    });
}

export interface OpeningHoursExceptionPayload {
    id: number;
    start: string;
    end: string;
    title: string;
    desc: string;
    exceptionType: string;
    queues: {
        queueId: number;
        queueType: string;
        status: string;
    }[];
    transferTo: string;
}

function setOpeningHoursException(payload: OpeningHoursExceptionPayload) {
    return axios.put('/openinghours/exception', payload);
}
function deleteOpeningHoursException(id: number) {
    return axios.delete(`/openinghours/exception/${id}`);
}
function getExceptionTemplates() {
    return axios.get('/openinghours/exception/templates');
}

function createExceptionTemplate(payload: unknown) {
    return axios.post('/openinghours/exception/templates', payload);
}

function deleteExceptionTemplate(id: number) {
    return axios.delete(`/openinghours/exception/template/${id}`);
}

export interface UploadFilePayload {
    formData: FormData;
}

function uploadFile({ formData }: UploadFilePayload) {
    return axios.post('/openinghours/files', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}

export interface UpdateFileOpeningHoursFileMetaDataPayload {
    id: number;
    title: string;
    desc: string;
}

function updateFileOpeningHoursFileMetaData(payload: UpdateFileOpeningHoursFileMetaDataPayload) {
    return axios.put('/openinghours/files', payload);
}

function getFilesLibraryByLoggedInUser(id: number) {
    return axios.get(`/files/user/${id}`);
}

function getAllFiles() {
    return axios.get('/files');
}

function getFile(name: string) {
    return axios.get(`/file/name/${name}`);
}
function removeFile(ohFileId: number) {
    return axios.delete(`openinghours/files/${ohFileId}`);
}

function getFileUsageInQueues(id: number) {
    return axios.get(`/file/${id}/queues`);
}
function getExceptionSettings() {
    return axios.get('/openinghours/settings');
}

export interface ExceptionSettingsPayload {
    purgeActive: number;
    exceptionLifespan: number;
}

function setExceptionSettings(payload: ExceptionSettingsPayload) {
    return axios.put('/openinghours/settings', payload);
}
function getQueueOpeninghours(queueId: number) {
    return axios.get(`/openinghours/${queueId}`);
}

export default {
    getOpenHours,
    setOpeningHours,
    getOpeningHoursExceptions,
    setOpeningHoursException,
    deleteOpeningHoursException,
    getExceptionTemplates,
    createExceptionTemplate,
    deleteExceptionTemplate,
    uploadFile,
    updateFileOpeningHoursFileMetaData,
    getFileUsageInQueues,
    getFilesLibraryByLoggedInUser,
    getFile,
    getAllFiles,
    removeFile,
    getQueueOpeninghours,
    getAllIvrs,
    getExceptionSettings,
    setExceptionSettings,
};
