<template>
    <button
        :style="paddingStyle"
        class="c1-menu-item"
        :class="{ 'c1-menu-item--active': isActive }"
        @click="handleClick"
    >
        <span v-if="$slots.icon" class="c1-menu-item__icon">
            <slot name="icon"></slot>
        </span>
        <span class="c1-menu-item__text">
            <slot></slot>
        </span>
    </button>
</template>

<script lang="ts">
    import { defineComponent, ref, watch, computed } from 'vue';

    export default defineComponent({
        name: 'C1Item',
        props: {
            active: {
                type: Boolean,
                default: false,
            },
        },
        setup(props, { emit, slots }) {
            const isActive = ref(props.active);

            watch(
                () => props.active,
                (newValue) => {
                    isActive.value = newValue;
                }
            );

            const padding = computed(() => {
                if (slots.icon) {
                    return '10px 10px';
                }

                return '10px 16px';
            });

            const paddingStyle = computed(() => {
                return {
                    padding: padding.value,
                };
            });

            const handleClick = () => {
                emit('click');
            };

            return {
                isActive,
                handleClick,
                paddingStyle,
            };
        },
    });
</script>

<style scoped>
    .c1-menu-item {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 10px 10px;
        background: transparent;
        border: none;
        cursor: pointer;
        width: 100%;
        text-align: left;
        transition:
            background 0.2s,
            color 0.2s;
        font-size: 14px;
        height: 46px;
    }

    .c1-menu-item:hover {
        background: #f0f0f0;
    }

    .c1-menu-item:active {
        background: #d0d0d0;
    }

    .c1-menu-item--active {
        background: #ccc;
        font-weight: bold;
    }

    .c1-menu-item__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
    }

    .c1-menu-item__text {
        flex-grow: 1;
    }
</style>
