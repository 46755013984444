<template>
    <i class="icon mdi" :class="caseTypeIcon"></i>
</template>
<script lang="ts">
    import { defineComponent, PropType } from 'vue';
    import { filterObjectsByKeys, getActiveFromSettings } from '@/utils';
    import { channelTypes } from '@/templates/cases.template';

    export default defineComponent({
        components: {},
        props: {
            internalType: {
                type: String as PropType<'call' | 'email' | 'chat' | 'sms' | 'callback' | 'form'>,
                required: true,
            },
        },
        data() {
            return {};
        },
        computed: {
            caseTypeIcon() {
                const obj = this.filteredChannelTypes.find((t) => t.value === this.internalType)?.icon;

                return obj || 'mdi-help-circle';
            },
            filteredChannelTypes() {
                const channelBooleans = getActiveFromSettings(this.channelConfig);
                return filterObjectsByKeys(channelBooleans, channelTypes, 'value') as { value: string; icon: string }[];
            },
            channelConfig(): object {
                // @ts-ignore <This is going to be converted to Pinia store in the future>
                return this.$store.state.System.userSettings.system.channels as object;
            },
        },
        watch: {},
        created() {},
        mounted() {},
        methods: {
            getChannelIcon(channel: string): string {
                // @ts-ignore <This is going to be converted to Pinia store in the future>
                return (this.$store.state.Social.social[channel]?.icon || 'mdi-help-circle') as string;
            },
        },
    });
</script>
<style scoped></style>
<i18n lang="json">
{
    "en": {},
    "sv": {}
}
</i18n>
