import axios, { type AxiosResponse } from 'axios';

export type ClientTypes = 'client' | 'shadow';

export interface Client {
    active: boolean;
    id: string;
    name: string;
    parent: string;
}

export interface ClientData {
    active: boolean;
    address: string | null;
    city: string | null;
    clientId: string;
    clientType: string | null;
    clientTypeIcon: string | null;
    clientTypeName: string | null;
    confirmationEmail: boolean;
    contactContract: string | null;
    contactSupport: string | null;
    country: string | null;
    customerType: string | null;
    gdprCleaned: boolean;
    includedInSearch: boolean;
    information: string | null;
    language: string | null;
    locationInfo: string | null;
    market: string | null;
    name: string;
    parent: string;
    post: string | null;
    primaryEmail: string | null;
    primaryTelephone: string | null;
    priorityPoints: number | null;
    secondaryEmail: string | null;
    secondaryTelephone: string | null;
    socialSecurityNumber: string | null;
    tags: string[] | null;
    type: 'client';
}

export function getClientsChildren(
    id: string,
    limit: number,
    offset: number,
    clientTypeIds: ClientTypes[] = [],
): Promise<AxiosResponse<Client[]>> {
    return axios.get<Client[]>(`/clients/${id}/children`, { params: { limit, offset, clientTypeIds } });
}

export function getClients(limit: number, offset: number, search: string): Promise<AxiosResponse<Client[]>> {
    return axios.get<Client[]>('/v3/clients', { params: { limit, offset, search } });
}

export function bindClientToCase(clientId: string, caseId: string): Promise<AxiosResponse<void>> {
    return axios.post(`/client/${clientId}/case/${caseId}`);
}

export function getClient(clientId: string): Promise<AxiosResponse<ClientData>> {
    return axios.get<ClientData>(`/v2/clients/${clientId}`);
}
