<template>
    <standard-button v-bind="buttonPropsComputed" class="outlined-btn" @click="handleClick">
        <slot></slot>
    </standard-button>
</template>

<script>
    export default {
        name: 'TertiaryButton',

        data() {
            return {
                buttonProps: {
                    color: 'var(--v-gray3-base)',
                },
            };
        },

        computed: {
            buttonPropsComputed() {
                return {
                    ...this.buttonProps,
                    ...this.$attrs,
                };
            },
        },

        methods: {
            handleClick() {
                this.$emit('click');
            },
        },
    };
</script>

<style scoped>
    :deep(.v-btn__content) {
        letter-spacing: 0px !important;
        text-transform: none !important;
        font-weight: 400 !important;
        color: var(--v-gray2-base) !important;
    }
</style>
