export const campaignStrategyType = {
    MASS_EMAIL: 'MASS_EMAIL',
    MASS_SMS: 'MASS_SMS',
    COMPOSITE: 'COMPOSITE',
} as const;

export type CampaignStrategyType = (typeof campaignStrategyType)[keyof typeof campaignStrategyType];

export const audienceType = {
    ADMINS: 'ADMINS',
    VIP: 'VIP',
    USERS: 'USERS',
    CLIENTS: 'CLIENTS',
    ACTIVE_CLIENTS: 'ACTIVE_CLIENTS',
    NEW_CLIENTS: 'NEW_CLIENTS',
    COMPOSITE: 'COMPOSITE',
} as const;

export type AudienceType = (typeof audienceType)[keyof typeof audienceType];
