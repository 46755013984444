const languageToCountryMap: Record<string, string> = {
    aar: 'DJ', // Afar -> Djibouti
    abk: 'GE', // Abkhazian -> Georgia
    afr: 'ZA', // Afrikaans -> South Africa
    aka: 'GH', // Akan -> Ghana
    amh: 'ET', // Amharic -> Ethiopia
    ara: 'SA', // Arabic -> Saudi Arabia
    arg: 'ES', // Aragonese -> Spain
    asm: 'IN', // Assamese -> India
    ava: 'RU', // Avaric -> Russia
    ave: 'IR', // Avestan -> Iran
    aym: 'BO', // Aymara -> Bolivia
    aze: 'AZ', // Azerbaijani -> Azerbaijan
    bak: 'RU', // Bashkir -> Russia
    bam: 'ML', // Bambara -> Mali
    bel: 'BY', // Belarusian -> Belarus
    ben: 'BD', // Bengali -> Bangladesh
    bih: 'IN', // Bihari -> India
    bis: 'VU', // Bislama -> Vanuatu
    bos: 'BA', // Bosnian -> Bosnia and Herzegovina
    bre: 'FR', // Breton -> France
    bul: 'BG', // Bulgarian -> Bulgaria
    bur: 'MM', // Burmese -> Myanmar
    cat: 'ES', // Catalan -> Spain
    cha: 'GU', // Chamorro -> Guam
    che: 'RU', // Chechen -> Russia
    chi: 'CN', // Chinese -> China
    chu: 'RU', // Church Slavic -> Russia
    chv: 'RU', // Chuvash -> Russia
    cor: 'GB', // Cornish -> United Kingdom
    cos: 'FR', // Corsican -> France
    cre: 'CA', // Cree -> Canada
    cze: 'CZ', // Czech -> Czech Republic
    dan: 'DK', // Danish -> Denmark
    div: 'MV', // Divehi -> Maldives
    dut: 'NL', // Dutch -> Netherlands
    dzo: 'BT', // Dzongkha -> Bhutan
    eng: 'GB', // English -> United Kingdom
    epo: 'PL', // Esperanto -> Poland
    est: 'EE', // Estonian -> Estonia
    ewe: 'TG', // Ewe -> Togo
    fao: 'FO', // Faroese -> Faroe Islands
    fij: 'FJ', // Fijian -> Fiji
    fin: 'FI', // Finnish -> Finland
    fra: 'FR', // French -> France
    fry: 'NL', // Frisian -> Netherlands
    ful: 'SN', // Fula -> Senegal
    geo: 'GE', // Georgian -> Georgia
    ger: 'DE', // German -> Germany
    gla: 'GB', // Gaelic -> United Kingdom
    gle: 'IE', // Irish -> Ireland
    glg: 'ES', // Galician -> Spain
    guj: 'IN', // Gujarati -> India
    hat: 'HT', // Haitian -> Haiti
    hau: 'NG', // Hausa -> Nigeria
    heb: 'IL', // Hebrew -> Israel
    hin: 'IN', // Hindi -> India
    hrv: 'HR', // Croatian -> Croatia
    hun: 'HU', // Hungarian -> Hungary
    ibo: 'NG', // Igbo -> Nigeria
    ice: 'IS', // Icelandic -> Iceland
    ido: 'FR', // Ido -> France
    ind: 'ID', // Indonesian -> Indonesia
    ita: 'IT', // Italian -> Italy
    jpn: 'JP', // Japanese -> Japan
    kal: 'GL', // Kalaallisut -> Greenland
    kan: 'IN', // Kannada -> India
    kas: 'IN', // Kashmiri -> India
    kaz: 'KZ', // Kazakh -> Kazakhstan
    khm: 'KH', // Khmer -> Cambodia
    kik: 'KE', // Kikuyu -> Kenya
    kin: 'RW', // Kinyarwanda -> Rwanda
    kir: 'KG', // Kyrgyz -> Kyrgyzstan
    kor: 'KR', // Korean -> South Korea
    kur: 'IQ', // Kurdish -> Iraq
    lao: 'LA', // Lao -> Laos
    lat: 'VA', // Latin -> Vatican
    lav: 'LV', // Latvian -> Latvia
    lit: 'LT', // Lithuanian -> Lithuania
    mac: 'MK', // Macedonian -> North Macedonia
    mal: 'IN', // Malayalam -> India
    mao: 'NZ', // Maori -> New Zealand
    mar: 'IN', // Marathi -> India
    may: 'MY', // Malay -> Malaysia
    mon: 'MN', // Mongolian -> Mongolia
    nau: 'NR', // Nauru -> Nauru
    nav: 'US', // Navajo -> United States
    nep: 'NP', // Nepali -> Nepal
    nor: 'NO', // Norwegian -> Norway
    ori: 'IN', // Oriya -> India
    pan: 'IN', // Punjabi -> India
    per: 'IR', // Persian -> Iran
    pol: 'PL', // Polish -> Poland
    por: 'PT', // Portuguese -> Portugal
    pus: 'AF', // Pashto -> Afghanistan
    que: 'PE', // Quechua -> Peru
    rum: 'RO', // Romanian -> Romania
    rus: 'RU', // Russian -> Russia
    san: 'IN', // Sanskrit -> India
    sin: 'LK', // Sinhala -> Sri Lanka
    slo: 'SK', // Slovak -> Slovakia
    slv: 'SI', // Slovenian -> Slovenia
    sme: 'FI', // Sami -> Finland
    sna: 'ZW', // Shona -> Zimbabwe
    som: 'SO', // Somali -> Somalia
    spa: 'ES', // Spanish -> Spain
    srp: 'RS', // Serbian -> Serbia
    ssw: 'SZ', // Swati -> Eswatini
    swa: 'TZ', // Swahili -> Tanzania
    swe: 'SE', // Swedish -> Sweden
    tam: 'LK', // Tamil -> Sri Lanka
    tel: 'IN', // Telugu -> India
    tgk: 'TJ', // Tajik -> Tajikistan
    tha: 'TH', // Thai -> Thailand
    tur: 'TR', // Turkish -> Turkey
    ukr: 'UA', // Ukrainian -> Ukraine
    urd: 'PK', // Urdu -> Pakistan
    uzb: 'UZ', // Uzbek -> Uzbekistan
    vie: 'VN', // Vietnamese -> Vietnam
    xho: 'ZA', // Xhosa -> South Africa
    yor: 'NG', // Yoruba -> Nigeria
    zul: 'ZA', // Zulu -> South Africa
};

export function mapLanguageToCountry(threeLetterCode: string): string | undefined {
    return languageToCountryMap[threeLetterCode.toLowerCase()];
}
