<template>
    <section class="infinite-list-container" :style="minMaxStyles">
        <header v-if="items.length && headers.length" class="infinite-list-headers" :style="headerStyles">
            <slot name="header">
                <section v-for="header in headers" :key="header.value" class="header-item">
                    {{ header.text }}
                </section>
            </slot>
        </header>

        <section ref="list" class="infinite-list" :class="listClass" @scroll="onScroll">
            <div ref="listContent">
                <TransitionGroup name="move" tag="div" :css="false">
                    <div v-for="item in orderedItems" :key="item.id">
                        <slot name="divider" :item="item"></slot>
                        <slot name="item" :item="item"></slot>
                    </div>
                </TransitionGroup>
            </div>

            <section v-if="orderedItems.length === 0 && !loading" class="infinite-list-empty">
                <EmptyState :text="emptyMessage" :header="emptyHeader" :icon="emptyIcon" />
            </section>
        </section>
    </section>
</template>

<script lang="ts">
    import { ref, computed, watch, onMounted, nextTick, defineComponent, PropType } from 'vue';
    import EmptyState from '@/components/Main/List/EmptyState.vue';
    import { debouncer } from '@/utils';

    interface Header {
        value: string;
        text: string;
        width?: string;
    }

    interface Item {
        id: string | number;
    }

    export default defineComponent({
        components: { EmptyState },
        props: {
            headers: { type: Array as PropType<Header[]>, default: () => [] },
            fetchItems: { type: Function, required: true },
            fetchProps: { type: Object, default: () => ({}) },
            limit: { type: Number, default: 30 },
            emptyMessage: { type: String, default: 'There are no items to display' },
            emptyHeader: { type: String, default: 'No data found' },
            emptyIcon: { type: String, default: 'mdi-information' },
            items: { type: Array as PropType<Item[]>, default: () => [] },
            search: { type: String, default: '' },
            size: { type: String, default: 'medium' },
            reverse: { type: Boolean, default: false },
            fixed: { type: Boolean, default: false },
            minHeight: { type: String, default: '0' },
            maxHeight: { type: String, default: '100%' },
        },
        setup(props) {
            const page = ref(1);
            const loading = ref(false);
            const loaded = ref(false);
            const list = ref<HTMLDivElement | null>(null);
            const listContent = ref<HTMLDivElement | null>(null);
            const fetchDebouncer = ref(debouncer(loadItems, 500));
            const minHeight = computed(() => ({ minHeight: props.minHeight }));
            const maxHeight = computed(() => ({ maxHeight: props.maxHeight }));

            const minMaxStyles = computed(() => ({
                minHeight: props.minHeight,
                maxHeight: props.maxHeight,
            }));

            const headerStyles = computed(() => ({
                'grid-template-columns': props.headers.map((header) => header.width).join(' '),
            }));

            const listClass = computed(() => ({ 'infinite-list-fixed': props.fixed }));

            const orderedItems = computed(() => (props.reverse ? [...props.items].reverse() : props.items));

            watch(
                () => props.search,
                async () => {
                    page.value = 1;
                    await loadItems();
                    if (list.value) list.value.scrollTop = 0;
                }
            );

            async function loadItems() {
                if (loading.value) return;
                loading.value = true;
                await props.fetchItems({
                    page: page.value,
                    limit: props.limit,
                    search: props.search,
                    ...props.fetchProps,
                });
                page.value += 1;
                loading.value = false;

                if (props.reverse && !loaded.value) {
                    loaded.value = true;
                    await nextTick();
                    if (list.value) list.value.scrollTop = list.value.scrollHeight;
                }
            }

            function onScroll(e: Event) {
                const target = e.target as HTMLDivElement;
                if (!target) return;

                const { scrollTop, scrollHeight, clientHeight } = target;
                const isNearTop = scrollTop <= 50;
                const isNearBottom = (scrollTop + clientHeight) / scrollHeight >= 0.8;

                if ((props.reverse && isNearTop) || (!props.reverse && isNearBottom)) {
                    fetchDebouncer.value();
                }
            }

            onMounted(async () => {
                await loadItems();

                if (props.reverse) {
                    await nextTick();
                    if (list.value) list.value.scrollTop = list.value.scrollHeight;
                }

                let count = 0;
                const MAX_ITERATIONS = 5;

                while (
                    list.value?.clientHeight &&
                    listContent.value?.clientHeight &&
                    list.value.clientHeight >= listContent.value.clientHeight &&
                    !loading.value
                ) {
                    count += 1;
                    if (count > MAX_ITERATIONS) {
                        console.error('InfiniteList: Too many iterations to fill the list');
                        break;
                    }
                    const numberOfItems = props.items.length;
                    await loadItems();
                    if (numberOfItems === props.items.length) break;
                    await nextTick();
                }
            });

            return {
                page,
                loading,
                loaded,
                list,
                listContent,
                fetchDebouncer,
                headerStyles,
                listClass,
                orderedItems,
                loadItems,
                onScroll,
                minHeight,
                maxHeight,
                minMaxStyles,
            };
        },
    });
</script>

<style scoped>
    .infinite-list-container {
        display: flex;
        flex-direction: column;
        height: 0;
        flex: 1;
    }

    .infinite-list {
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        flex: 1;
    }

    .infinite-list-headers {
        display: grid;
        gap: 16px;
        border-bottom: 1px solid #e0e0e0;
        padding: 16px 32px;
        position: relative;
    }

    .header-item {
        color: #666;
    }

    .header-item:last-child {
        justify-self: end;
    }

    .infinite-list-empty {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-size: 0.8em;
        color: #666;
        padding: 16px 0;
        flex: 1;
    }

    .move-enter-active,
    .move-leave-active,
    .move-move {
        transition: opacity 0.5s;
    }

    .move-enter,
    .move-leave-to {
        opacity: 0;
    }

    .infinite-list-fixed {
        overflow-y: auto;
        scroll-behavior: smooth;
    }

    .infinite-list-fixed::-webkit-scrollbar {
        display: none;
    }
</style>
