<template>
    <section v-if="!clientInfo">
        <c1-section>
            <c1-input :debounce="500" :placeholder="$t('rebindCase.searchClients')" v-model="search" />
        </c1-section>
        <c1-section>
            <c1-infinite-list
                v-if="!fetchClientsError"
                :headers="[]"
                :fetchItems="fetchClients"
                :items="mappedClients"
                :limit="16"
                :search="search"
                :emptyMessage="$t('rebindCase.emptyListClients')"
                :emptyHeader="$t('rebindCase.emptyListClientsHeader')"
                emptyIcon="mdi-account"
                minHeight="368px"
                maxHeight="368px"
            >
                <template #item="{ item }">
                    <c1-menu-item @click="clickItem(item)">
                        <template #icon>
                            <i class="mdi mdi-account"></i>
                        </template>
                        <div class="client-information">
                            <truncate>{{ item.text }}</truncate>
                            <tooltip :content="$t('rebindCase.showClientInfo')">
                                <CreateCommentsRoundButton
                                    iconOnly
                                    icon="mdi-format-list-bulleted"
                                    @click="(event) => showClientInfo(event, item.id)"
                                />
                            </tooltip>
                        </div>
                    </c1-menu-item>
                </template>
            </c1-infinite-list>
            <c1-section v-else>
                {{ fetchClientsError }}
            </c1-section>
        </c1-section>

        <c1-section class="rebind-case-action-menu">
            <div>
                <primary-action-button @click="createClient">{{ $t('rebindCase.createClient') }}</primary-action-button>
            </div>
        </c1-section>
    </section>
    <section v-else>
        <c1-section v-if="!fetchClientError && !fetchClientsFieldListError" class="client-fields-container">
            <c1-section class="disabled">
                <ClientFamilySearch v-if="client" :key="client.clientId" :clientId="client.clientId" />
            </c1-section>
            <c1-section cols="2">
                <div v-for="(value, key) in filteredClientData" :key="key" class="client-field">
                    <label :for="key">{{ $t(`label.${key}`) }}</label>
                    <div class="client-field-value" :id="key">{{ value }}</div>
                </div>
            </c1-section>
        </c1-section>
        <c1-section v-else>
            {{ fetchClientError || fetchClientsFieldListError }}
        </c1-section>

        <c1-section class="rebind-case-action-menu">
            <div>
                <secondary-action-button @click="back">{{ $t('rebindCase.back') }}</secondary-action-button>
            </div>
        </c1-section>
    </section>
</template>

<script lang="ts">
    import { defineComponent, ref, computed, inject, getCurrentInstance, onMounted } from 'vue';
    import { getClients, getClient, bindClientToCase } from '@/api/client/client.api';
    import { getClientFieldsList } from '@/api/client/client.fields';

    import i18n from '@/i18n';

    import type { Client, ClientData } from '@/api/client/client.api';
    import type { Toasted } from 'vue-toasted';
    import type { Global } from '@/services/dialogs/dialog.service.types.d.ts';

    import CreateCommentsRoundButton from '@/components/Cases/CreateComments/CreateCommentsRoundButton.vue';
    import ClientFamilySearch from '@/components/Search/Clients/ClientFamilySearch.vue';

    export default defineComponent({
        name: 'RebindCase',

        props: {
            caseId: {
                type: String,
                required: true,
            },
        },

        components: {
            CreateCommentsRoundButton,
            ClientFamilySearch,
        },

        setup(props, { emit }) {
            const toasted = inject('$toasted') as Toasted | undefined;

            if (!toasted) {
                throw new Error('No toasted found');
            }

            const global = inject('$global') as Global | undefined;

            if (!global) {
                throw new Error('No global found');
            }

            const currentInstance = getCurrentInstance();

            if (!currentInstance) {
                throw new Error('No current instance found');
            }

            const search = ref('');
            const clientInfo = ref<boolean>(false);
            const clients = ref<Client[]>([]);
            const client = ref<ClientData | null>(null);

            // Errors
            const fetchClientsError = ref<string | null>(null);
            const fetchClientError = ref<string | null>(null);
            const fetchClientsFieldListError = ref<string | null>(null);

            const clientFieldList = ref<
                { editable: boolean; id: number; position: number; type: string; visible: boolean }[]
            >([]);

            const mappedClients = computed(() => {
                return clients.value.map((client) => {
                    return {
                        id: client.id,
                        text: client.name,
                        icon: 'mdi-account',
                    };
                });
            });

            const clickItem = async (item: Client) => {
                try {
                    const { confirmed } = await global.dialogs.showConfirmationDialog({
                        title: i18n.t('rebindCase.bindClientTitle').toString(),
                        message: i18n.t('rebindCase.bindClientText').toString(),
                        confirmText: i18n.t('rebindCase.yes').toString(),
                        declineText: i18n.t('rebindCase.no').toString(),
                    });

                    if (!confirmed) {
                        return;
                    }

                    await bindClientToCase(item.id, props.caseId);

                    toasted.show(i18n.t('rebindCase.clientConnectedToCase').toString(), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                    emit('close');
                } catch (error) {
                    toasted.error(i18n.t('rebindCase.couldNotConnectClientToCase').toString(), {
                        icon: 'cancel',
                        type: 'error',
                    });

                    console.error('Error binding client to case:', error);
                }
            };

            const fetchClients = async ({ limit, page, search }: { limit: number; page: number; search: string }) => {
                try {
                    const offset = (page - 1) * limit;
                    const response = await getClients(limit, offset, search);

                    if (page === 1) {
                        clients.value = response.data;
                    } else {
                        clients.value = clients.value.concat(response.data);
                    }

                    return response.data;
                } catch (error) {
                    console.error('Error fetching clients children:', error);
                    fetchClientsError.value = i18n.t('rebindCase.errorFetchingClients').toString();
                }
            };

            const fetchClient = async (clientId: string) => {
                try {
                    const response = await getClient(clientId);
                    return response.data;
                } catch (error) {
                    console.error('Error fetching client:', error);
                    fetchClientError.value = i18n.t('rebindCase.errorFetchingClient').toString();
                }
            };

            const createClient = async () => {
                // @ts-ignore
                await currentInstance.proxy.$store.dispatch(
                    'Creator/openForm',
                    {
                        id: 3,
                    },
                    { root: true }
                );
            };

            const fetchClientFieldList = async () => {
                try {
                    const response = await getClientFieldsList();
                    clientFieldList.value = response.data;
                } catch (error) {
                    console.error('Error fetching client fields:', error);
                    fetchClientsFieldListError.value = i18n.t('rebindCase.errorFetchingClientFields').toString();
                }
            };

            const showClientInfo = async (event: MouseEvent, clientId: string) => {
                event.stopPropagation();
                event.preventDefault();
                clientInfo.value = true;
                const result = await fetchClient(clientId);

                if (result) {
                    client.value = result;
                }
            };

            const filteredClientData = computed(() => {
                if (!client.value) {
                    return {};
                }

                return clientFieldList.value
                    .filter((field) => field.visible && client.value && field.type in client.value)
                    .sort((a, b) => a.position - b.position)
                    .reduce((acc: Record<string, any>, field) => {
                        if (client.value && field.type in client.value) {
                            acc[field.type] = (client.value as Record<string, any>)[field.type];
                        }
                        return acc;
                    }, {});
            });

            const back = () => {
                clientInfo.value = false;
            };

            onMounted(() => {
                fetchClientFieldList();
            });

            return {
                createClient,
                mappedClients,
                fetchClients,
                fetchClient,
                clickItem,
                search,
                showClientInfo,
                clientInfo,
                client,
                clientFieldList,
                filteredClientData,
                back,

                fetchClientsError,
                fetchClientError,
                fetchClientsFieldListError,
            };
        },
    });
</script>

<style scoped>
    .rebind-case-action-menu {
        display: flex;
        flex: 1;
        flex-direction: row;
    }

    .rebind-case-action-menu > div {
        justify-self: flex-end;
    }

    .client-information {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 8px;
    }

    .client-field {
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin-bottom: 4px;
    }

    .client-field-value {
        border: 1px solid #ececec;
        padding: 8px;
        min-height: 42px;
        border-radius: 4px;
        align-items: center;
        display: flex;
    }

    .client-fields-container {
        max-height: 435px;
        overflow-y: auto;
        gap: 8px;
        display: flex;
    }

    .disabled {
        pointer-events: none;
    }
</style>
