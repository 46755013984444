<template>
    <div class="main-container">
        <v-menu v-model="menuOpen" :close-on-content-click="false" transition="scale-transition" offset-y>
            <template #activator="{}">
                <div v-if="icon" :style="brandBoxStyle">
                    <BrandLogoRound
                        :key="selectedItem?.id"
                        :brandId="selectedItem?.id || null"
                        :size="size"
                        borderWidth="3px"
                        tooltipText="Select brand"
                        @click="activatorClicked"
                    />
                </div>
                <div v-else style="flex: 1; display: flex">
                    <tertiary-action-button
                        v-if="items && items.length"
                        v-bind="selectPropsComputed"
                        @click="activatorClicked"
                    >
                        <article v-if="!noSelectedItems" class="item-text item">
                            <div class="select-item">
                                <BrandLogoTransparent
                                    :key="selectedItem.id"
                                    size="24px"
                                    class="mr-3"
                                    :brandId="selectedItem.id"
                                />
                                <span v-if="!$attrs.minimal">
                                    {{ itemText(selectedItem) }}
                                </span>
                            </div>
                            <v-icon size="16" class="ml-3" color="var(--v-gray2-base)">mdi-chevron-down</v-icon>
                        </article>
                        <article v-else class="display-item item">
                            <div class="select-item" :style="itemStyle">
                                {{ customText ?? $t('selects.selectItems') }}
                            </div>
                            <v-icon size="16" class="ml-3" color="var(--v-gray2-base)">mdi-chevron-down</v-icon>
                        </article>
                    </tertiary-action-button>

                    <tertiary-action-button v-else v-bind="selectPropsComputed">
                        <article class="item-text item center">
                            <div class="select-item">
                                {{ $t('selects.noItems') }}
                            </div>
                        </article>
                    </tertiary-action-button>
                </div>
            </template>

            <div>
                <section ref="scrollContainer" class="user-list">
                    <v-list-item
                        v-for="item of items"
                        :key="item.id"
                        :class="['list-item', { 'selected-item': isSelected(item) }]"
                        @click="selectItem(item)"
                    >
                        <div style="width: 40px; display: flex; justify-content: center; align-items: center">
                            <BrandLogoTransparent size="24px" :brandId="item.id" />
                        </div>

                        <v-list-item-content>
                            <v-list-item-title :class="isSelected(item) ? 'item-text' : 'item-text-not-selected'">
                                {{ item.name ? item.name : $t('users.noName') }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </section>
            </div>
        </v-menu>
    </div>
</template>

<script>
    import BrandLogoRound from '@/components/Brands/BrandLogoRound.vue';
    import BrandLogoTransparent from '@/components/Brands/BrandLogoTransparent.vue';

    export default {
        name: 'BrandSelect',

        components: {
            BrandLogoRound,
            BrandLogoTransparent,
        },

        props: {
            showExtra: {
                type: Boolean,
                default: false,
            },
            size: {
                type: String,
                default: '60px',
            },
            value: {
                type: null,
                required: true,
            },
            icon: {
                type: Boolean,
                default: false,
            },
            textKey: {
                type: String,
                default: 'text',
            },
            valueKey: {
                // can be used if the value key is not called value on the items
                type: String,
                default: 'value',
            },
            items: {
                type: Array,
                default: () => [],
            },
        },

        data() {
            return {
                selectProps: {
                    rounded: true,
                    color: 'var(--v-gray3-base)',
                    filled: true,
                    dense: true,
                    hideDetails: true,
                },

                menuOpen: false,
                selectedItems: [],
            };
        },

        computed: {
            selectPropsComputed() {
                return {
                    ...this.selectProps,
                    ...this.$attrs,
                };
            },

            selectedItem() {
                return this.selectedItems.at(0);
            },
            brandBoxStyle() {
                return {
                    width: this.size,
                    height: this.size,
                    cursor: 'pointer',
                };
            },
            itemStyle() {
                return { height: this.dense ? '40px' : '50px' };
            },
            noSelectedItems() {
                if (this.selectedItems[0]?.value === '') {
                    return true;
                }
                return this.selectedItems.length === 0;
            },
        },

        watch: {
            value() {
                this.setSelectedFromValue();
            },
        },

        created() {
            this.init();
        },

        methods: {
            init() {
                this.setSelectedFromValue();
            },
            selectItem(item) {
                this.selectedItems = [item];
                this.handleChange(item);

                this.menuOpen = false;
            },

            isSelected(item) {
                const selected = this.selectedItems.some(
                    (selectedItem) => selectedItem[this.valueKey] === item[this.valueKey]
                );
                return selected;
            },

            handleChange(value) {
                this.$emit('input', [value]);
                this.$emit('change', [value]);
            },

            setSelectedFromValue() {
                if (!this.value) {
                    return;
                }

                if (typeof this.value === 'number') {
                    if (!this.items?.length) {
                        return;
                    }

                    this.selectedItems = [this.items.find((item) => item.id === this.value)];
                    return;
                }
                this.selectedItems = [this.value];
            },
            itemText(item) {
                if (item.textKey) {
                    return this.$t(item.textKey);
                }
                return item[this.textKey];
            },
            activatorClicked() {
                this.menuOpen = !this.menuOpen;
            },
        },
    };
</script>

<style scoped lang="scss">
    :deep(.v-input__prepend-inner) {
        align-self: center;
        margin: 0px !important;
        padding: 0px !important;
        white-space: nowrap !important;
    }

    div[role='menu'] {
        border-radius: 8px;
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2) !important;
        border: 1px solid #eee;
        padding: 4px;
        background-color: var(--v-gray3-base);
        min-width: 240px !important;
        max-width: 240px !important;
    }

    .main-container {
        flex: 1;
        display: flex;
    }

    .select-item {
        display: flex;
        align-items: center;
        flex-direction: row;
    }

    .avatar-item {
        display: flex;
        align-items: center;
        flex-direction: row;
    }

    .user-list {
        max-height: 295px;
        overflow-y: auto;
        padding: 4px 0;
    }

    .list-container {
        display: flex;
        align-items: center;
        flex-direction: row;
    }

    .item {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        flex: 1;
    }
    .item-text {
        font-size: 0.9rem !important;
        font-weight: 400 !important;
        color: var(--v-gray2-base);
    }

    .item-text-not-selected {
        font-size: 0.9rem !important;
        font-weight: 400 !important;
        color: var(--v-gray2-base);
    }
    .list-item {
        padding: 0 !important;
        padding-left: 8px !important;
        padding-right: 8px !important;
        height: 48px !important;
        background-color: var(--v-gray3-base);
        margin-bottom: 1px;
        display: flex;
        gap: 8px;
        border-radius: 8px;
        &:hover::before {
            border-radius: 8px;
        }
    }

    .tooltip-button {
        justify-self: end;
    }
    .selected-item {
        background-color: white;
    }

    .display-item {
        display: flex;
        align-items: center;
        flex-direction: row;
        font-size: 0.9rem !important;
        font-weight: 400 !important;
        color: var(--v-gray2-base);
    }
</style>

<i18n lang="json">
{
    "en": {
        "users": {
            "all": "All",
            "unassigned": "Unassigned",
            "noName": "No name"
        },

        "selects": {
            "selectItems": "Select",
            "allItemsSelected": "All",
            "itemsSelected": "selected",
            "noItems": "No items"
        }
    },
    "sv": {
        "users": {
            "all": "Alla",
            "unassigned": "Ej tilldelade",
            "noName": "Inget namn"
        },

        "selects": {
            "selectItems": "Välj",
            "allItemsSelected": "Alla",
            "itemsSelected": " valda",
            "noItems": "Inga valda"
        }
    }
}
</i18n>
