// Full list of all countries in the world, used in PhoneNumber.js to define available country codes

const allCountries = [
    { name: 'Afghanistan (‫افغانستان‬‎)', iso2: 'AF', dialCode: '93', priority: 0, areaCodes: null },
    { name: 'Albania (Shqipëri)', iso2: 'AL', dialCode: '355', priority: 0, areaCodes: null },
    { name: 'Algeria (‫الجزائر‬‎)', iso2: 'DZ', dialCode: '213', priority: 0, areaCodes: null },
    { name: 'American Samoa', iso2: 'AS', dialCode: '1', priority: 5, areaCodes: ['684'] },
    { name: 'Andorra', iso2: 'AD', dialCode: '376', priority: 0, areaCodes: null },
    { name: 'Angola', iso2: 'AO', dialCode: '244', priority: 0, areaCodes: null },
    { name: 'Anguilla', iso2: 'AI', dialCode: '1', priority: 6, areaCodes: ['264'] },
    { name: 'Antigua and Barbuda', iso2: 'AG', dialCode: '1', priority: 7, areaCodes: ['268'] },
    { name: 'Argentina', iso2: 'AR', dialCode: '54', priority: 0, areaCodes: null },
    { name: 'Armenia (Հայաստան)', iso2: 'AM', dialCode: '374', priority: 0, areaCodes: null },
    { name: 'Aruba', iso2: 'AW', dialCode: '297', priority: 0, areaCodes: null },
    { name: 'Ascension Island', iso2: 'AC', dialCode: '247', priority: 0, areaCodes: null },
    { name: 'Australia', iso2: 'AU', dialCode: '61', priority: 0, areaCodes: null },
    { name: 'Austria (Österreich)', iso2: 'AT', dialCode: '43', priority: 0, areaCodes: null },
    { name: 'Azerbaijan (Azərbaycan)', iso2: 'AZ', dialCode: '994', priority: 0, areaCodes: null },
    { name: 'Bahamas', iso2: 'BS', dialCode: '1', priority: 8, areaCodes: ['242'] },
    { name: 'Bahrain (‫البحرين‬‎)', iso2: 'BH', dialCode: '973', priority: 0, areaCodes: null },
    { name: 'Bangladesh (বাংলাদেশ)', iso2: 'BD', dialCode: '880', priority: 0, areaCodes: null },
    { name: 'Barbados', iso2: 'BB', dialCode: '1', priority: 9, areaCodes: ['246'] },
    { name: 'Belarus (Беларусь)', iso2: 'BY', dialCode: '375', priority: 0, areaCodes: null },
    { name: 'Belgium (België)', iso2: 'BE', dialCode: '32', priority: 0, areaCodes: null },
    { name: 'Belize', iso2: 'BZ', dialCode: '501', priority: 0, areaCodes: null },
    { name: 'Benin (Bénin)', iso2: 'BJ', dialCode: '229', priority: 0, areaCodes: null },
    { name: 'Bermuda', iso2: 'BM', dialCode: '1', priority: 10, areaCodes: ['441'] },
    { name: 'Bhutan (འབྲུག)', iso2: 'BT', dialCode: '975', priority: 0, areaCodes: null },
    { name: 'Bolivia', iso2: 'BO', dialCode: '591', priority: 0, areaCodes: null },
    {
        name: 'Bosnia and Herzegovina (Босна и Херцеговина)',
        iso2: 'BA',
        dialCode: '387',
        priority: 0,
        areaCodes: null,
    },
    { name: 'Botswana', iso2: 'BW', dialCode: '267', priority: 0, areaCodes: null },
    { name: 'Brazil (Brasil)', iso2: 'BR', dialCode: '55', priority: 0, areaCodes: null },
    { name: 'British Indian Ocean Territory', iso2: 'IO', dialCode: '246', priority: 0, areaCodes: null },
    { name: 'British Virgin Islands', iso2: 'VG', dialCode: '1', priority: 11, areaCodes: ['284'] },
    { name: 'Brunei', iso2: 'BN', dialCode: '673', priority: 0, areaCodes: null },
    { name: 'Bulgaria (България)', iso2: 'BG', dialCode: '359', priority: 0, areaCodes: null },
    { name: 'Burkina Faso', iso2: 'BF', dialCode: '226', priority: 0, areaCodes: null },
    { name: 'Burundi (Uburundi)', iso2: 'BI', dialCode: '257', priority: 0, areaCodes: null },
    { name: 'Cambodia (កម្ពុជា)', iso2: 'KH', dialCode: '855', priority: 0, areaCodes: null },
    { name: 'Cameroon (Cameroun)', iso2: 'CM', dialCode: '237', priority: 0, areaCodes: null },
    {
        name: 'Canada',
        iso2: 'CA',
        dialCode: '1',
        priority: 1,
        areaCodes: [
            '204',
            '226',
            '236',
            '249',
            '250',
            '263',
            '289',
            '306',
            '343',
            '354',
            '365',
            '367',
            '368',
            '382',
            '387',
            '403',
            '416',
            '418',
            '428',
            '431',
            '437',
            '438',
            '450',
            '584',
            '468',
            '474',
            '506',
            '514',
            '519',
            '548',
            '579',
            '581',
            '584',
            '587',
            '604',
            '613',
            '639',
            '647',
            '672',
            '683',
            '705',
            '709',
            '742',
            '753',
            '778',
            '780',
            '782',
            '807',
            '819',
            '825',
            '867',
            '873',
            '902',
            '905',
        ],
    },
    { name: 'Cape Verde (Kabu Verdi)', iso2: 'CV', dialCode: '238', priority: 0, areaCodes: null },
    { name: 'Caribbean Netherlands', iso2: 'BQ', dialCode: '599', priority: 1, areaCodes: ['3', '4', '7'] },
    { name: 'Cayman Islands', iso2: 'KY', dialCode: '1', priority: 12, areaCodes: ['345'] },
    {
        name: 'Central African Republic (République centrafricaine)',
        iso2: 'CF',
        dialCode: '236',
        priority: 0,
        areaCodes: null,
    },
    { name: 'Chad (Tchad)', iso2: 'TD', dialCode: '235', priority: 0, areaCodes: null },
    { name: 'Chile', iso2: 'CL', dialCode: '56', priority: 0, areaCodes: null },
    { name: 'China (中国)', iso2: 'CN', dialCode: '86', priority: 0, areaCodes: null },
    { name: 'Christmas Island', iso2: 'CX', dialCode: '61', priority: 2, areaCodes: ['89164'] },
    { name: 'Cocos (Keeling) Islands', iso2: 'CC', dialCode: '61', priority: 1, areaCodes: ['89162'] },
    { name: 'Colombia', iso2: 'CO', dialCode: '57', priority: 0, areaCodes: null },
    { name: 'Comoros (‫جزر القمر‬‎)', iso2: 'KM', dialCode: '269', priority: 0, areaCodes: null },
    {
        name: 'Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)',
        iso2: 'CD',
        dialCode: '243',
        priority: 0,
        areaCodes: null,
    },
    { name: 'Congo (Republic) (Congo-Brazzaville)', iso2: 'CG', dialCode: '242', priority: 0, areaCodes: null },
    { name: 'Cook Islands', iso2: 'CK', dialCode: '682', priority: 0, areaCodes: null },
    { name: 'Costa Rica', iso2: 'CR', dialCode: '506', priority: 0, areaCodes: null },
    { name: 'Côte d’Ivoire', iso2: 'CI', dialCode: '225', priority: 0, areaCodes: null },
    { name: 'Croatia (Hrvatska)', iso2: 'HR', dialCode: '385', priority: 0, areaCodes: null },
    { name: 'Cuba', iso2: 'CU', dialCode: '53', priority: 0, areaCodes: null },
    { name: 'Curaçao', iso2: 'CW', dialCode: '599', priority: 0, areaCodes: null },
    { name: 'Cyprus (Κύπρος)', iso2: 'CY', dialCode: '357', priority: 0, areaCodes: null },
    { name: 'Czech Republic (Česká republika)', iso2: 'CZ', dialCode: '420', priority: 0, areaCodes: null },
    { name: 'Denmark (Danmark)', iso2: 'DK', dialCode: '45', priority: 0, areaCodes: null },
    { name: 'Djibouti', iso2: 'DJ', dialCode: '253', priority: 0, areaCodes: null },
    { name: 'Dominica', iso2: 'DM', dialCode: '1', priority: 13, areaCodes: ['767'] },
    {
        name: 'Dominican Republic (República Dominicana)',
        iso2: 'DO',
        dialCode: '1',
        priority: 2,
        areaCodes: ['809', '829', '849'],
    },
    { name: 'Ecuador', iso2: 'EC', dialCode: '593', priority: 0, areaCodes: null },
    { name: 'Egypt (‫مصر‬‎)', iso2: 'EG', dialCode: '20', priority: 0, areaCodes: null },
    { name: 'El Salvador', iso2: 'SV', dialCode: '503', priority: 0, areaCodes: null },
    { name: 'Equatorial Guinea (Guinea Ecuatorial)', iso2: 'GQ', dialCode: '240', priority: 0, areaCodes: null },
    { name: 'Eritrea', iso2: 'ER', dialCode: '291', priority: 0, areaCodes: null },
    { name: 'Estonia (Eesti)', iso2: 'EE', dialCode: '372', priority: 0, areaCodes: null },
    { name: 'Eswatini', iso2: 'SZ', dialCode: '268', priority: 0, areaCodes: null },
    { name: 'Ethiopia', iso2: 'ET', dialCode: '251', priority: 0, areaCodes: null },
    { name: 'Falkland Islands (Islas Malvinas)', iso2: 'FK', dialCode: '500', priority: 0, areaCodes: null },
    { name: 'Faroe Islands (Føroyar)', iso2: 'FO', dialCode: '298', priority: 0, areaCodes: null },
    { name: 'Fiji', iso2: 'FJ', dialCode: '679', priority: 0, areaCodes: null },
    { name: 'Finland (Suomi)', iso2: 'FI', dialCode: '358', priority: 0, areaCodes: null },
    { name: 'France', iso2: 'FR', dialCode: '33', priority: 0, areaCodes: null },
    { name: 'French Guiana (Guyane française)', iso2: 'GF', dialCode: '594', priority: 0, areaCodes: null },
    { name: 'French Polynesia (Polynésie française)', iso2: 'PF', dialCode: '689', priority: 0, areaCodes: null },
    { name: 'Gabon', iso2: 'GA', dialCode: '241', priority: 0, areaCodes: null },
    { name: 'Gambia', iso2: 'GM', dialCode: '220', priority: 0, areaCodes: null },
    { name: 'Georgia (საქართველო)', iso2: 'GE', dialCode: '995', priority: 0, areaCodes: null },
    { name: 'Germany (Deutschland)', iso2: 'DE', dialCode: '49', priority: 0, areaCodes: null },
    { name: 'Ghana (Gaana)', iso2: 'GH', dialCode: '233', priority: 0, areaCodes: null },
    { name: 'Gibraltar', iso2: 'GI', dialCode: '350', priority: 0, areaCodes: null },
    { name: 'Greece (Ελλάδα)', iso2: 'GR', dialCode: '30', priority: 0, areaCodes: null },
    { name: 'Greenland (Kalaallit Nunaat)', iso2: 'GL', dialCode: '299', priority: 0, areaCodes: null },
    { name: 'Grenada', iso2: 'GD', dialCode: '1', priority: 14, areaCodes: ['473'] },
    { name: 'Guadeloupe', iso2: 'GP', dialCode: '590', priority: 0, areaCodes: null },
    { name: 'Guam', iso2: 'GU', dialCode: '1', priority: 15, areaCodes: ['671'] },
    { name: 'Guatemala', iso2: 'GT', dialCode: '502', priority: 0, areaCodes: null },
    { name: 'Guernsey', iso2: 'GG', dialCode: '44', priority: 1, areaCodes: ['1481', '7781', '7839', '7911'] },
    { name: 'Guinea (Guinée)', iso2: 'GN', dialCode: '224', priority: 0, areaCodes: null },
    { name: 'Guinea-Bissau (Guiné Bissau)', iso2: 'GW', dialCode: '245', priority: 0, areaCodes: null },
    { name: 'Guyana', iso2: 'GY', dialCode: '592', priority: 0, areaCodes: null },
    { name: 'Haiti', iso2: 'HT', dialCode: '509', priority: 0, areaCodes: null },
    { name: 'Honduras', iso2: 'HN', dialCode: '504', priority: 0, areaCodes: null },
    { name: 'Hong Kong (香港)', iso2: 'HK', dialCode: '852', priority: 0, areaCodes: null },
    { name: 'Hungary (Magyarország)', iso2: 'HU', dialCode: '36', priority: 0, areaCodes: null },
    { name: 'Iceland (Ísland)', iso2: 'IS', dialCode: '354', priority: 0, areaCodes: null },
    { name: 'India (भारत)', iso2: 'IN', dialCode: '91', priority: 0, areaCodes: null },
    { name: 'Indonesia', iso2: 'ID', dialCode: '62', priority: 0, areaCodes: null },
    { name: 'Iran (‫ایران‬‎)', iso2: 'IR', dialCode: '98', priority: 0, areaCodes: null },
    { name: 'Iraq (‫العراق‬‎)', iso2: 'IQ', dialCode: '964', priority: 0, areaCodes: null },
    { name: 'Ireland', iso2: 'IE', dialCode: '353', priority: 0, areaCodes: null },
    {
        name: 'Isle of Man',
        iso2: 'IM',
        dialCode: '44',
        priority: 2,
        areaCodes: ['1624', '74576', '7524', '7924', '7624'],
    },
    { name: 'Israel (‫ישראל‬‎)', iso2: 'IL', dialCode: '972', priority: 0, areaCodes: null },
    { name: 'Italy (Italia)', iso2: 'IT', dialCode: '39', priority: 0, areaCodes: null },
    { name: 'Jamaica', iso2: 'JM', dialCode: '1', priority: 4, areaCodes: ['876', '658'] },
    { name: 'Japan (日本)', iso2: 'JP', dialCode: '81', priority: 0, areaCodes: null },
    {
        name: 'Jersey',
        iso2: 'JE',
        dialCode: '44',
        priority: 3,
        areaCodes: ['1534', '7509', '7700', '7797', '7829', '7937'],
    },
    { name: 'Jordan (‫الأردن‬‎)', iso2: 'JO', dialCode: '962', priority: 0, areaCodes: null },
    { name: 'Kazakhstan (Казахстан)', iso2: 'KZ', dialCode: '7', priority: 1, areaCodes: ['33', '7'] },
    { name: 'Kenya', iso2: 'KE', dialCode: '254', priority: 0, areaCodes: null },
    { name: 'Kiribati', iso2: 'KI', dialCode: '686', priority: 0, areaCodes: null },
    { name: 'Kosovo', iso2: 'XK', dialCode: '383', priority: 0, areaCodes: null },
    { name: 'Kuwait (‫الكويت‬‎)', iso2: 'KW', dialCode: '965', priority: 0, areaCodes: null },
    { name: 'Kyrgyzstan (Кыргызстан)', iso2: 'KG', dialCode: '996', priority: 0, areaCodes: null },
    { name: 'Laos (ລາວ)', iso2: 'LA', dialCode: '856', priority: 0, areaCodes: null },
    { name: 'Latvia (Latvija)', iso2: 'LV', dialCode: '371', priority: 0, areaCodes: null },
    { name: 'Lebanon (‫لبنان‬‎)', iso2: 'LB', dialCode: '961', priority: 0, areaCodes: null },
    { name: 'Lesotho', iso2: 'LS', dialCode: '266', priority: 0, areaCodes: null },
    { name: 'Liberia', iso2: 'LR', dialCode: '231', priority: 0, areaCodes: null },
    { name: 'Libya (‫ليبيا‬‎)', iso2: 'LY', dialCode: '218', priority: 0, areaCodes: null },
    { name: 'Liechtenstein', iso2: 'LI', dialCode: '423', priority: 0, areaCodes: null },
    { name: 'Lithuania (Lietuva)', iso2: 'LT', dialCode: '370', priority: 0, areaCodes: null },
    { name: 'Luxembourg', iso2: 'LU', dialCode: '352', priority: 0, areaCodes: null },
    { name: 'Macau (澳門)', iso2: 'MO', dialCode: '853', priority: 0, areaCodes: null },
    { name: 'Madagascar (Madagasikara)', iso2: 'MG', dialCode: '261', priority: 0, areaCodes: null },
    { name: 'Malawi', iso2: 'MW', dialCode: '265', priority: 0, areaCodes: null },
    { name: 'Malaysia', iso2: 'MY', dialCode: '60', priority: 0, areaCodes: null },
    { name: 'Maldives', iso2: 'MV', dialCode: '960', priority: 0, areaCodes: null },
    { name: 'Mali', iso2: 'ML', dialCode: '223', priority: 0, areaCodes: null },
    { name: 'Malta', iso2: 'MT', dialCode: '356', priority: 0, areaCodes: null },
    { name: 'Marshall Islands', iso2: 'MH', dialCode: '692', priority: 0, areaCodes: null },
    { name: 'Martinique', iso2: 'MQ', dialCode: '596', priority: 0, areaCodes: null },
    { name: 'Mauritania (‫موريتانيا‬‎)', iso2: 'MR', dialCode: '222', priority: 0, areaCodes: null },
    { name: 'Mauritius (Moris)', iso2: 'MU', dialCode: '230', priority: 0, areaCodes: null },
    { name: 'Mayotte', iso2: 'YT', dialCode: '262', priority: 1, areaCodes: ['269', '639'] },
    { name: 'Mexico (México)', iso2: 'MX', dialCode: '52', priority: 0, areaCodes: null },
    { name: 'Micronesia', iso2: 'FM', dialCode: '691', priority: 0, areaCodes: null },
    { name: 'Moldova (Republica Moldova)', iso2: 'MD', dialCode: '373', priority: 0, areaCodes: null },
    { name: 'Monaco', iso2: 'MC', dialCode: '377', priority: 0, areaCodes: null },
    { name: 'Mongolia (Монгол)', iso2: 'MN', dialCode: '976', priority: 0, areaCodes: null },
    { name: 'Montenegro (Crna Gora)', iso2: 'ME', dialCode: '382', priority: 0, areaCodes: null },
    { name: 'Montserrat', iso2: 'MS', dialCode: '1', priority: 16, areaCodes: ['664'] },
    { name: 'Morocco (‫المغرب‬‎)', iso2: 'MA', dialCode: '212', priority: 0, areaCodes: null },
    { name: 'Mozambique (Moçambique)', iso2: 'MZ', dialCode: '258', priority: 0, areaCodes: null },
    { name: 'Myanmar (Burma) (မြန်မာ)', iso2: 'MM', dialCode: '95', priority: 0, areaCodes: null },
    { name: 'Namibia (Namibië)', iso2: 'NA', dialCode: '264', priority: 0, areaCodes: null },
    { name: 'Nauru', iso2: 'NR', dialCode: '674', priority: 0, areaCodes: null },
    { name: 'Nepal (नेपाल)', iso2: 'NP', dialCode: '977', priority: 0, areaCodes: null },
    { name: 'Netherlands (Nederland)', iso2: 'NL', dialCode: '31', priority: 0, areaCodes: null },
    { name: 'New Caledonia (Nouvelle-Calédonie)', iso2: 'NC', dialCode: '687', priority: 0, areaCodes: null },
    { name: 'New Zealand', iso2: 'NZ', dialCode: '64', priority: 0, areaCodes: null },
    { name: 'Nicaragua', iso2: 'NI', dialCode: '505', priority: 0, areaCodes: null },
    { name: 'Niger (Nijar)', iso2: 'NE', dialCode: '227', priority: 0, areaCodes: null },
    { name: 'Nigeria', iso2: 'NG', dialCode: '234', priority: 0, areaCodes: null },
    { name: 'Niue', iso2: 'NU', dialCode: '683', priority: 0, areaCodes: null },
    { name: 'Norfolk Island', iso2: 'NF', dialCode: '672', priority: 0, areaCodes: null },
    { name: 'North Korea (조선 민주주의 인민 공화국)', iso2: 'KP', dialCode: '850', priority: 0, areaCodes: null },
    { name: 'North Macedonia (Северна Македонија)', iso2: 'MK', dialCode: '389', priority: 0, areaCodes: null },
    { name: 'Northern Mariana Islands', iso2: 'MP', dialCode: '1', priority: 17, areaCodes: ['670'] },
    { name: 'Norway (Norge)', iso2: 'NO', dialCode: '47', priority: 0, areaCodes: null },
    { name: 'Oman (‫عُمان‬‎)', iso2: 'OM', dialCode: '968', priority: 0, areaCodes: null },
    { name: 'Pakistan (‫پاکستان‬‎)', iso2: 'PK', dialCode: '92', priority: 0, areaCodes: null },
    { name: 'Palau', iso2: 'PW', dialCode: '680', priority: 0, areaCodes: null },
    { name: 'Palestine (‫فلسطين‬‎)', iso2: 'PS', dialCode: '970', priority: 0, areaCodes: null },
    { name: 'Panama (Panamá)', iso2: 'PA', dialCode: '507', priority: 0, areaCodes: null },
    { name: 'Papua New Guinea', iso2: 'PG', dialCode: '675', priority: 0, areaCodes: null },
    { name: 'Paraguay', iso2: 'PY', dialCode: '595', priority: 0, areaCodes: null },
    { name: 'Peru (Perú)', iso2: 'PE', dialCode: '51', priority: 0, areaCodes: null },
    { name: 'Philippines', iso2: 'PH', dialCode: '63', priority: 0, areaCodes: null },
    { name: 'Poland (Polska)', iso2: 'PL', dialCode: '48', priority: 0, areaCodes: null },
    { name: 'Portugal', iso2: 'PT', dialCode: '351', priority: 0, areaCodes: null },
    { name: 'Puerto Rico', iso2: 'PR', dialCode: '1', priority: 3, areaCodes: ['787', '939'] },
    { name: 'Qatar (‫قطر‬‎)', iso2: 'QA', dialCode: '974', priority: 0, areaCodes: null },
    { name: 'Réunion (La Réunion)', iso2: 'RE', dialCode: '262', priority: 0, areaCodes: null },
    { name: 'Romania (România)', iso2: 'RO', dialCode: '40', priority: 0, areaCodes: null },
    { name: 'Russia (Россия)', iso2: 'RU', dialCode: '7', priority: 0, areaCodes: null },
    { name: 'Rwanda', iso2: 'RW', dialCode: '250', priority: 0, areaCodes: null },
    { name: 'Saint Barthélemy', iso2: 'BL', dialCode: '590', priority: 1, areaCodes: null },
    { name: 'Saint Helena', iso2: 'SH', dialCode: '290', priority: 0, areaCodes: null },
    { name: 'Saint Kitts and Nevis', iso2: 'KN', dialCode: '1', priority: 18, areaCodes: ['869'] },
    { name: 'Saint Lucia', iso2: 'LC', dialCode: '1', priority: 19, areaCodes: ['758'] },
    {
        name: 'Saint Martin (Saint-Martin (partie française))',
        iso2: 'MF',
        dialCode: '590',
        priority: 2,
        areaCodes: null,
    },
    {
        name: 'Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)',
        iso2: 'PM',
        dialCode: '508',
        priority: 0,
        areaCodes: null,
    },
    { name: 'Saint Vincent and the Grenadines', iso2: 'VC', dialCode: '1', priority: 20, areaCodes: ['784'] },
    { name: 'Samoa', iso2: 'WS', dialCode: '685', priority: 0, areaCodes: null },
    { name: 'San Marino', iso2: 'SM', dialCode: '378', priority: 0, areaCodes: null },
    {
        name: 'São Tomé and Príncipe (São Tomé e Príncipe)',
        iso2: 'ST',
        dialCode: '239',
        priority: 0,
        areaCodes: null,
    },
    {
        name: 'Saudi Arabia (‫المملكة العربية السعودية‬‎)',
        iso2: 'SA',
        dialCode: '966',
        priority: 0,
        areaCodes: null,
    },
    { name: 'Senegal (Sénégal)', iso2: 'SN', dialCode: '221', priority: 0, areaCodes: null },
    { name: 'Serbia (Србија)', iso2: 'RS', dialCode: '381', priority: 0, areaCodes: null },
    { name: 'Seychelles', iso2: 'SC', dialCode: '248', priority: 0, areaCodes: null },
    { name: 'Sierra Leone', iso2: 'SL', dialCode: '232', priority: 0, areaCodes: null },
    { name: 'Singapore', iso2: 'SG', dialCode: '65', priority: 0, areaCodes: null },
    { name: 'Sint Maarten', iso2: 'SX', dialCode: '1', priority: 21, areaCodes: ['721'] },
    { name: 'Slovakia (Slovensko)', iso2: 'SK', dialCode: '421', priority: 0, areaCodes: null },
    { name: 'Slovenia (Slovenija)', iso2: 'SI', dialCode: '386', priority: 0, areaCodes: null },
    { name: 'Solomon Islands', iso2: 'SB', dialCode: '677', priority: 0, areaCodes: null },
    { name: 'Somalia (Soomaaliya)', iso2: 'SO', dialCode: '252', priority: 0, areaCodes: null },
    { name: 'South Africa', iso2: 'ZA', dialCode: '27', priority: 0, areaCodes: null },
    { name: 'South Korea (대한민국)', iso2: 'KR', dialCode: '82', priority: 0, areaCodes: null },
    { name: 'South Sudan (‫جنوب السودان‬‎)', iso2: 'SS', dialCode: '211', priority: 0, areaCodes: null },
    { name: 'Spain (España)', iso2: 'ES', dialCode: '34', priority: 0, areaCodes: null },
    { name: 'Sri Lanka (ශ්‍රී ලංකාව)', iso2: 'LK', dialCode: '94', priority: 0, areaCodes: null },
    { name: 'Sudan (‫السودان‬‎)', iso2: 'SD', dialCode: '249', priority: 0, areaCodes: null },
    { name: 'Suriname', iso2: 'SR', dialCode: '597', priority: 0, areaCodes: null },
    { name: 'Svalbard and Jan Mayen', iso2: 'SJ', dialCode: '47', priority: 1, areaCodes: ['79'] },
    { name: 'Sweden (Sverige)', iso2: 'SE', dialCode: '46', priority: 0, areaCodes: null },
    { name: 'Switzerland (Schweiz)', iso2: 'CH', dialCode: '41', priority: 0, areaCodes: null },
    { name: 'Syria (‫سوريا‬‎)', iso2: 'SY', dialCode: '963', priority: 0, areaCodes: null },
    { name: 'Taiwan (台灣)', iso2: 'TW', dialCode: '886', priority: 0, areaCodes: null },
    { name: 'Tajikistan', iso2: 'TJ', dialCode: '992', priority: 0, areaCodes: null },
    { name: 'Tanzania', iso2: 'TZ', dialCode: '255', priority: 0, areaCodes: null },
    { name: 'Thailand (ไทย)', iso2: 'TH', dialCode: '66', priority: 0, areaCodes: null },
    { name: 'Timor-Leste', iso2: 'TL', dialCode: '670', priority: 0, areaCodes: null },
    { name: 'Togo', iso2: 'TG', dialCode: '228', priority: 0, areaCodes: null },
    { name: 'Tokelau', iso2: 'TK', dialCode: '690', priority: 0, areaCodes: null },
    { name: 'Tonga', iso2: 'TO', dialCode: '676', priority: 0, areaCodes: null },
    { name: 'Trinidad and Tobago', iso2: 'TT', dialCode: '1', priority: 22, areaCodes: ['868'] },
    { name: 'Tunisia (‫تونس‬‎)', iso2: 'TN', dialCode: '216', priority: 0, areaCodes: null },
    { name: 'Turkey (Türkiye)', iso2: 'TR', dialCode: '90', priority: 0, areaCodes: null },
    { name: 'Turkmenistan', iso2: 'TM', dialCode: '993', priority: 0, areaCodes: null },
    { name: 'Turks and Caicos Islands', iso2: 'TC', dialCode: '1', priority: 23, areaCodes: ['649'] },
    { name: 'Tuvalu', iso2: 'TV', dialCode: '688', priority: 0, areaCodes: null },
    { name: 'U.S. Virgin Islands', iso2: 'VI', dialCode: '1', priority: 24, areaCodes: ['340'] },
    { name: 'Uganda', iso2: 'UG', dialCode: '256', priority: 0, areaCodes: null },
    { name: 'Ukraine (Україна)', iso2: 'UA', dialCode: '380', priority: 0, areaCodes: null },
    {
        name: 'United Arab Emirates (‫الإمارات العربية المتحدة‬‎)',
        iso2: 'AE',
        dialCode: '971',
        priority: 0,
        areaCodes: null,
    },
    { name: 'United Kingdom', iso2: 'GB', dialCode: '44', priority: 0, areaCodes: null },
    { name: 'United States', iso2: 'US', dialCode: '1', priority: 0, areaCodes: null },
    { name: 'Uruguay', iso2: 'UY', dialCode: '598', priority: 0, areaCodes: null },
    { name: 'Uzbekistan (Oʻzbekiston)', iso2: 'UZ', dialCode: '998', priority: 0, areaCodes: null },
    { name: 'Vanuatu', iso2: 'VU', dialCode: '678', priority: 0, areaCodes: null },
    { name: 'Vatican City (Città del Vaticano)', iso2: 'VA', dialCode: '39', priority: 1, areaCodes: ['06698'] },
    { name: 'Venezuela', iso2: 'VE', dialCode: '58', priority: 0, areaCodes: null },
    { name: 'Vietnam (Việt Nam)', iso2: 'VN', dialCode: '84', priority: 0, areaCodes: null },
    { name: 'Wallis and Futuna (Wallis-et-Futuna)', iso2: 'WF', dialCode: '681', priority: 0, areaCodes: null },
    {
        name: 'Western Sahara (‫الصحراء الغربية‬‎)',
        iso2: 'EH',
        dialCode: '212',
        priority: 1,
        areaCodes: ['5288', '5289'],
    },
    { name: 'Yemen (‫اليمن‬‎)', iso2: 'YE', dialCode: '967', priority: 0, areaCodes: null },
    { name: 'Zambia', iso2: 'ZM', dialCode: '260', priority: 0, areaCodes: null },
    { name: 'Zimbabwe', iso2: 'ZW', dialCode: '263', priority: 0, areaCodes: null },
    { name: 'Åland Islands', iso2: 'AX', dialCode: '358', priority: 1, areaCodes: ['18'] },
];

export default allCountries;
