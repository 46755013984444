import axios from 'axios';

export interface SetStatusPayload {
    replace: boolean;
    isActive: boolean;
    activeClientId: string;
    status: string;
}

export interface SetInternalNumberRangePayload {
    start: number;
    end: number;
}

export interface SetUserSettingsPayload {
    device?: string;
    inputDevice: string;
    outputDevice: string;
    ringtone: string;
    numberToShow: string;
    maxConcurrentCalls: string;
}

export interface AddGatewayPayload {
    name: string;
    realm: string;
    username: string;
    password: string;
    proxy: string;
    register: boolean;
    context: string;
    callerIdInFrom: boolean;
}

export default {
    checkInternalNumberValidation(params: string) {
        return axios.get('/pbx/validate?type=internal', {
            params,
        });
    },
    checkPublicNumberValidation(params: string) {
        return axios.get('/pbx/validate?type=external', {
            params,
        });
    },
    getNextInternalNumber() {
        return axios.get('/pbx/next?type=internal');
    },
    getPublicNumberSuggestions() {
        return axios.get('/pbx/next?type=external');
    },
    getCredentials() {
        return axios.get('/pbx/credentials/', {
            params: {
                device: 'Browser',
            },
        });
    },
    getStatus() {
        return axios.get('/pbx/status');
    },

    setStatus(payload: SetStatusPayload) {
        return axios.post('/pbx/status', payload);
    },
    getTransferUserData({ page = 1, search = '' }) {
        return axios.get('/pbx/transfer', {
            params: { page, search },
        });
    },
    findUserNumber({ name = '', userId = '' }) {
        return axios.get('/pbx/userdata', {
            params: { name, userId },
        });
    },
    getCallHistory({ page = 1, userId }: { page: number; userId: string }) {
        return axios.get(`/pbx/cdr/${userId}`, { params: { page } });
    },
    setInternalNumberRange(payload: SetInternalNumberRangePayload) {
        return axios.post('pbx/range', payload, {
            params: {
                type: 'internal',
            },
        });
    },
    addNumberRange(payload: SetInternalNumberRangePayload) {
        return axios.post('pbx/range', payload, {
            params: {
                type: 'external',
            },
        });
    },
    updateAvailableNumbers() {
        return axios.post('/pbx/update-available');
    },
    getSettings() {
        return axios.get('/pbx/settings', {
            params: {
                device: 'Browser',
            },
        });
    },
    setSettings(payload: SetUserSettingsPayload) {
        const newPayload = {
            ...payload,
            device: payload.device || 'Browser',
        } as SetUserSettingsPayload;

        return axios.post('/pbx/settings', newPayload);
    },
    addGateway(payload: AddGatewayPayload) {
        return axios.post('/pbx/gateway', payload);
    },
};
