<template>
    <v-menu
        v-model="menuOpen"
        :close-on-content-click="false"
        transition="scale-transition"
        :top="top"
        :bottom="bottom"
        :left="left"
        :right="right"
        :offset-y="offset"
        :attach="menuAttach"
        :disabled="disabled"
    >
        <template #activator="{}">
            <div @click="handleClick" ref="activator" class="wrapper-div">
                <slot>
                    <div class="tertiary-action-button" cy-data="dropDownButton">
                        <tertiary-action-button v-if="items && items.length" v-bind="selectPropsComputed">
                            <article v-if="!noSelectedItems" class="item-text item">
                                <div class="select-item">
                                    <i
                                        class="icon mdi"
                                        :class="[iconClass, selectedItems.at(0).icon]"
                                        :style="{ color: selectedItems.at(0).color }"
                                    ></i>
                                    <span v-if="!$attrs.minimal">
                                        {{ itemText(selectedItems.at(0)) }}
                                    </span>
                                </div>
                                <div>
                                    <slot name="button-append-content" :selectedItems="selectedItems">
                                        <v-icon size="16" class="ml-3" color="var(--v-gray2-base)">
                                            mdi-chevron-down
                                        </v-icon>
                                    </slot>
                                </div>
                            </article>
                            <article v-else class="display-item item">
                                <div class="select-item" :style="itemStyle">
                                    {{ customText ?? $t('selects.selectItems') }}
                                </div>
                                <div>
                                    <slot name="button-append-content" :selectedItems="selectedItems">
                                        <v-icon size="16" class="ml-3" color="var(--v-gray2-base)">
                                            mdi-chevron-down
                                        </v-icon>
                                    </slot>
                                </div>
                            </article>
                            <article v-if="!items || items.length === 0" class="display-item item">
                                <div class="select-item">
                                    {{ $t('selects.noItems') }}
                                </div>
                            </article>
                        </tertiary-action-button>
                    </div>
                </slot>
            </div>
        </template>

        <article class="action-item-container">
            <header v-if="searchable">
                <search-input input-radius :value="search" color="white" @input="setSearch" />
            </header>

            <article class="item-list-wrapper" :style="listWrapperStyle">
                <section class="item-list" :cy-data="nameOfItems" ref="itemList">
                    <component
                        :is="clickableItems ? 'button' : 'div'"
                        v-for="(item, index) in itemsWithSearch"
                        :key="index"
                        class="list-item"
                        :cy-value="item.value"
                        :cy-text="itemText(item)"
                        :cy-tkey="item.translationKey"
                        :class="listItemClass(item)"
                        :style="listItemStyle(item)"
                        @click="selectItem(item)"
                        tabindex="0"
                    >
                        <slot name="item-slot" :item="item" :selected="isSelected(item)">
                            <div>
                                <i class="icon mdi" :class="[iconClass, item.icon]" :style="{ color: item.color }"></i>
                            </div>
                            <div>
                                <div :class="isSelected(item) ? 'item-text' : 'item-text-not-selected'">
                                    <span>
                                        {{ itemText(item) }}
                                    </span>
                                </div>
                            </div>
                        </slot>
                    </component>
                </section>
            </article>
        </article>
    </v-menu>
</template>

<script>
    export default {
        name: 'StandardSelect',

        props: {
            clickableItems: {
                type: Boolean,
                default: true,
            },

            top: {
                type: Boolean,
                default: false,
            },

            bottom: {
                type: Boolean,
                default: false,
            },

            left: {
                type: Boolean,
                default: false,
            },
            right: {
                type: Boolean,
                default: false,
            },

            offset: {
                type: Boolean,
                default: true,
            },
            customText: {
                type: String,
                default: null,
            },

            items: {
                type: Array,
                default: () => [],
            },

            boolean: {
                type: Boolean,
                default: false,
            },

            single: {
                type: Boolean,
                default: false,
            },

            value: {
                type: null,
                default: null,
            },

            visibleAmount: {
                type: Number,
                default: 6,
            },

            resetOnClick: {
                type: Boolean,
                default: false,
            },

            searchable: {
                type: Boolean,
                default: false,
            },
            textKey: {
                type: String,
                default: 'text',
            },
            menuAttach: {
                type: null,
                default: undefined,
            },
            closeOnSelect: {
                type: Boolean,
                default: false,
            },

            hoverEnable: {
                type: Boolean,
                default: false,
            },

            // Used for testing with cypress
            nameOfItems: {
                type: String,
                default: 'default',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                selectProps: {
                    rounded: true,
                    color: 'var(--v-gray3-base)',
                    filled: true,
                    dense: true,
                    items: [],
                    hideDetails: true,
                },
                selectedItems: [],
                menuOpen: false,
                itemHeight: 48,
                itemMarginBottom: 4,
                search: '',
                children: [],
            };
        },

        computed: {
            itemStyle() {
                return { height: this.dense ? '40px' : '50px' };
            },

            iconClass() {
                return this.$attrs.minimal ? '' : 'mr-3';
            },

            selectPropsComputed() {
                return {
                    ...this.selectProps,
                    ...this.$attrs,
                };
            },

            noSelectedItems() {
                if (this.selectedItems[0]?.value === '') {
                    return true;
                }
                return this.selectedItems.length === 0;
            },

            listWrapperStyle() {
                const itemHeight = this.itemHeight + this.itemMarginBottom;
                const wrapperHeight = this.visibleAmount * itemHeight;
                return { maxHeight: wrapperHeight + 'px' };
            },
            itemsWithSearch() {
                if (!this.search) {
                    return this.items;
                }
                return this.items.filter((item) => {
                    return item[this.textKey].toLowerCase().includes(this.search.toLowerCase());
                });
            },
        },

        watch: {
            value: {
                handler() {
                    this.setSelectedItems();
                },
                deep: true,
            },
            menuOpen: {
                immediate: true,
                handler(newVal) {
                    if (!newVal) {
                        this.search = '';
                        this.removeEventListeners();
                        const el = this.$refs.activator;
                        if (el) {
                            const newEl = el.querySelector('[tabindex], button');
                            if (newEl) {
                                newEl.focus();
                            }
                        }
                        return;
                    }
                    this.addEventListeners();
                },
            },
        },

        created() {
            this.setSelectedItems();
        },

        methods: {
            addEventListeners() {
                document.addEventListener('keydown', this.handleKeyDown);
                this.$nextTick(() => {
                    const ref = this.$refs.itemList;
                    if (!ref) return;
                    this.children = Array.from(ref.children).filter(
                        (child) => !child.classList.contains('button-disabled')
                    );
                });
            },
            removeEventListeners() {
                document.removeEventListener('keydown', this.handleKeyDown);
                this.children = [];
            },
            handleKeyDown(event) {
                if (event.key === 'Escape') {
                    this.menuOpen = false;
                    return;
                }

                const events = ['ArrowDown', 'ArrowUp', 'Tab'];
                if (!events.includes(event.key)) {
                    return;
                }
                const data = this.children;
                const focused = document.activeElement;
                const focusIndex = data.indexOf(focused);

                if (event.key === 'ArrowDown' || event.key === 'Tab') {
                    if (focusIndex < data.length - 1) {
                        data[focusIndex + 1].focus();
                        event.preventDefault();
                    } else {
                        data[0].focus();
                        event.preventDefault();
                    }
                }
                if (event.key === 'ArrowUp' || (event.key === 'Tab' && event.shiftKey)) {
                    if (focusIndex > 0) {
                        data[focusIndex - 1].focus();
                        event.preventDefault();
                    } else {
                        data[data.length - 1].focus();
                        event.preventDefault();
                    }
                }
            },
            listItemClass(item) {
                if (!this.clickableItems) {
                    return 'not-clickable-items';
                }

                let baseClass = 'standard-button';

                if (this.isSelected(item)) {
                    baseClass = 'button-selected';
                } else {
                    baseClass = 'button-not-selected';
                }

                if (item.selectable === false) {
                    baseClass += ' button-disabled';
                }

                return baseClass;
            },

            listItemStyle() {
                return { height: this.itemHeight + 'px', marginBottom: this.itemMarginBottom + 'px' };
            },

            iconColor(item) {
                return this.isSelected(item) ? item.color : 'var(--v-gray1-base)';
            },

            handleClick(event) {
                this.menuOpen = !this.menuOpen;
                this.$emit('click', event);
            },

            handleChange(value) {
                this.$emit('input', value);
                this.$emit('change', value);
                if (this.closeOnSelect) {
                    this.menuOpen = false;
                }
            },

            setSelectedItems() {
                if (!this.value) return;
                this.selectedItems = [this.value];
            },

            selectItem(item) {
                if (!this.clickableItems) return;
                if (this.single) {
                    this.selectedItems = [item];
                    this.handleChange(this.selectedItems);
                    this.menuOpen = false;
                    if (this.resetOnClick) {
                        this.selectedItems = [];
                    }
                    return;
                }
                this.selectedItems = [item];
                this.handleChange(this.selectedItems);
            },

            isSelected(item) {
                return this.selectedItems.some((selectedItem) => selectedItem.value === item.value);
            },

            itemText(item) {
                if (item.icon === 'mdi-check' && this.boolean) {
                    return this.$t('selects.true');
                }

                if (item.icon === 'mdi-close' && this.boolean) {
                    return this.$t('selects.false');
                }

                if (item.textKey) {
                    return this.$t(item.textKey);
                }
                return item[this.textKey];
            },

            setSearch(input) {
                this.search = input;
            },
        },
    };
</script>

<style scoped>
    .icon {
        font-size: 16px;
        margin: 0px 8px;
    }

    .action-item-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    .tertiary-action-button {
        display: flex;
        flex: 1;
    }
    :deep(.v-input__prepend-inner) {
        align-self: center;
        margin: 0px !important;
        padding: 0px !important;
        white-space: nowrap !important;
    }

    div[role='menu'] {
        border-radius: 8px;
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2) !important;
        border: 1px solid #eee;
        padding: 4px;
        background-color: var(--v-gray3-base);
        min-width: 240px !important;
        max-width: 240px !important;
        z-index: 99999 !important;
    }

    .item {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        flex: 1;
    }

    .item-list-wrapper {
        border-radius: 8px;
        background-color: var(--v-gray3-base);
        box-sizing: border-box;
        display: flex;
        overflow: hidden;
    }

    .item-list {
        max-height: 100%;
        width: 100%;
        overflow-y: auto;
        gap: 4px;
        display: grid;
    }

    .select-item {
        display: flex;
        align-items: center;
        flex-direction: row;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 1px;
        flex: 1;
        color: var(--v-gray4-base);
        font-weight: 400;
    }

    .display-item {
        display: flex;
        align-items: center;
        flex-direction: row;
        font-size: 0.9rem !important;
        font-weight: 400 !important;
        color: var(--v-gray2-base);
    }

    .item-text {
        font-size: 0.9rem !important;
        font-weight: 400 !important;
    }

    .item-text-not-selected {
        font-size: 0.9rem !important;
        font-weight: 400 !important;
        color: var(--v-gray2-base);
    }

    .list-item {
        padding: 0 !important;
        padding-left: 8px !important;
        padding-right: 8px !important;
        margin-bottom: 0px !important;
        border-radius: 8px;
        background-color: var(--v-gray3-base);
        overflow: hidden;
        transition: all 0.1s ease-in-out;
        display: flex;
        align-items: center;
        gap: 16px;
    }

    .selected-item {
        background-color: white;
        box-sizing: border-box;
    }

    .icon-container {
        padding-right: 16px;
    }

    .center {
        justify-content: center;
    }

    standard-button:focus {
        border: 1px solid var(--v-primary-base) !important;
    }
    .wrapper-div {
        flex: 1;
    }
</style>

<i18n lang="json">
{
    "en": {
        "selects": {
            "true": "Yes",
            "false": "No",
            "selectItems": "Select",
            "noItems": "No choices"
        }
    },
    "sv": {
        "selects": {
            "true": "Ja",
            "false": "Nej",
            "selectItems": "Välj",
            "noItems": "Inga val"
        }
    }
}
</i18n>
