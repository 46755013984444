<template>
    <div
        class="c1-section"
        v-bind="$attrs"
        :style="{
            paddingTop: paddingTop,
            paddingBottom: paddingBottom,
            borderTop: borderTop ? '1px solid #ccc' : 'none',
            borderBottom: borderBottom ? '1px solid #ccc' : 'none',
            display: 'grid',
            gridTemplateColumns: `repeat(${cols}, 1fr)`,
            gap: gap,
        }"
    >
        <slot></slot>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'C1Section',

        props: {
            paddingTop: {
                type: String,
                default: '6px',
            },
            paddingBottom: {
                type: String,
                default: '6px',
            },
            borderTop: {
                type: Boolean,
                default: false,
            },
            borderBottom: {
                type: Boolean,
                default: false,
            },
            cols: {
                type: Number,
                default: 1,
            },
            gap: {
                type: String,
                default: '10px',
            },
        },
    });
</script>

<style scoped>
    .c1-section {
        display: block;
    }
</style>
