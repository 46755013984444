<template>
    <phone-number v-bind="extendedAttrs" :showErrorMessage="true" @input="handleInput" />
</template>
<script>
    /**
     * This is a wrapper component for the PhoneNumber component.
     * It is used by Creator.vue in order to abide to how components work there
     * This component allows internal numbers.
     * @emits input - Emitted when the phone number input inputs. the formatted number as a string.
     */
    export default {
        computed: {
            extendedAttrs() {
                return {
                    ...this.$attrs,
                };
            },
        },
        methods: {
            handleInput(number) {
                this.$emit('input', number);
            },
        },
    };
</script>
