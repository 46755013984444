import axios from 'axios';

export interface PublishPayload {
    text: string;
    caseId: string;
    userId: number;
}

function publish(q: PublishPayload) {
    return axios.post('/v1/social-networks/publish/', {
        text: q.text,
        caseId: q.caseId,
        userId: q.userId,
    });
}

export interface UpdateCommentPayload {
    isHidden: boolean;
}

function updateComment(itemId: string, data: UpdateCommentPayload) {
    return axios.put(`/v1/social-networks/comment/${itemId}`, data);
}

export default {
    publish,
    updateComment,
};
