<template>
    <div>
        <slot name="select" :numbers="mappedNumbers">
            <standard-select
                v-bind="buttonPropsComputed"
                :items="mappedNumbers"
                closeOnSelect
                :value="selectedItem"
                @change="([item]) => handleChange(item)"
            />
        </slot>
    </div>
</template>
<script>
    import { mapState, mapActions } from 'vuex';

    export default {
        name: 'SenderNumberSelect',
        props: {
            value: {
                type: String,
                default: null,
            },
            queueId: {
                type: Number,
                default: null,
            },
        },
        data() {
            return {
                selectedItem: this.value,
            };
        },
        computed: {
            ...mapState({
                numbers: (state) => state.Cases.systemSmsNumbers,
            }),

            buttonPropsComputed() {
                return {
                    ...this.buttonProps,
                    ...this.$attrs,
                    rules: [],
                    dense: false,
                };
            },

            mappedNumbers() {
                return this.numbers.map((number) => ({
                    text: number.number,
                    value: number.number,
                    queueIds: number.queueIds,
                    icon: 'mdi-cellphone-message',
                }));
            },
            defaultSender() {
                return this.mappedNumbers.at(0);
            },
        },
        watch: {
            queueId(newVal) {
                // set sender based on selected queue
                if (newVal) {
                    const item = this.mappedNumbers.find((item) => item.queueIds.includes(this.queueId));
                    item && this.handleChange(item);
                }
            },
        },
        created() {
            this.init();
        },
        methods: {
            ...mapActions({
                getSystemSmsNumbers: 'Cases/getSystemSmsNumbers',
            }),
            handleChange(item) {
                if (!item) return;

                this.selectedItem = item;
                this.$emit('input', item.value);
            },
            async init() {
                if (!this.numbers?.length) await this.getSystemSmsNumbers();
                this.handleChange(this.defaultSender);
            },
        },
    };
</script>
<style scoped lang="scss"></style>
<i18n lang="json">
{
    "en": {},
    "sv": {}
}
</i18n>
