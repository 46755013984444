import axios from 'axios';

function getPromptsByContext(context: string) {
    return axios.get('/integrations/chatgpt/prompts', {
        params: {
            context,
        },
    });
}

/**
 * Fetches conversations from the smart search API.
 */
function getConversations(offset: number, limit: number, _search: string | undefined) {
    return axios.get('/integrations/chatgpt/smart-search/conversations', {
        params: {
            offset,
            limit,
        },
    });
}

export interface CreateConversationPayload {
    promptId: number;
    topic: string;
}

/**
 * Creates a new conversation by sending a POST request to the specified endpoint.
 */
function createConversation(data: CreateConversationPayload) {
    return axios.post('/integrations/chatgpt/smart-search/conversation', data);
}

/**
 * Fetches a conversation by its ID from the smart search integration.
 */
function getConversation(id: string) {
    return axios.get(`/integrations/chatgpt/smart-search/conversation/${id}`);
}

/**
 * Asynchronously saves a conversation item by sending a POST request to the specified endpoint.
 * The function yields chunks of the response stream as they are received.
 */
async function* saveConversationItem(id: string, text: string, promptId: number) {
    try {
        const { data: stream } = await axios.post(
            `/integrations/chatgpt/smart-search/conversation/${id}`,
            { text, promptId },
            {
                responseType: 'stream',
                adapter: 'fetch',
            },
        );

        const reader = stream.pipeThrough(new TextDecoderStream()).getReader();
        // biome-ignore lint/suspicious/noAssignInExpressions: <Subjective: I prefer the code to be short>
        for (let chunk: { value: unknown }; !(chunk = await reader.read()).done; ) {
            yield chunk.value;
        }
    } catch (error) {
        console.error('Error during request:', error);
        return error;
    }
}

export default {
    getConversations,
    createConversation,

    getConversation,
    saveConversationItem,

    getPromptsByContext,
};
