import axios from 'axios';

const reportTypes = {
    IN_CALL: 'inCall',
    OUT_CALL: 'outCall',
    EMAIL: 'email',
};

export interface GetInvoiceDocumentsPayload {
    from: string;
    to: string;
    queue: number | null;
    channel: string | null;
    timeFormat: string;
    reportType: (typeof reportTypes)[keyof typeof reportTypes];
}

function getInvoiceDocuments({ from, to, queue, channel, timeFormat, reportType }: GetInvoiceDocumentsPayload) {
    return axios.get(
        `/statistics/report/invoices?from=${from}&to=${to}&queueId=${queue}&channel=${channel}&timeFormat=${timeFormat}&reportType=${reportType}`,
    );
}

export default {
    getInvoiceDocuments,
};
