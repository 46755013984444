import axios from 'axios';

function authorizeIntegration() {
    return axios.get('/billing/integration');
}

function checkIfIntegrationIsActive() {
    return axios.get('/billing/integration/active');
}

function syncExternals() {
    return axios.post('/billing/externals/sync');
}

function getCustomers() {
    return axios.get('/billing/customers');
}
function getCustomer(customerId: number) {
    return axios.get(`/billing/customers/${customerId}`);
}

// TODO: Body isnt validated in the backend? Casting to unknown for now
function updateCustomer(payload: unknown) {
    return axios.put('/billing/customers', payload);
}

function deleteCustomer(id: number) {
    return axios.delete(`/billing/customers/${id}`);
}

export interface BillableCustomersQuery {
    from: string;
    to: string;
    queueId: number;
}

function getBillableCustomers(query: BillableCustomersQuery) {
    return axios.get('billing/billable-customers', {
        params: {
            ...query,
        },
    });
}

export interface GetBillableCustomerPayload {
    customerId: number;
    query: {
        from: string;
        to: string;
    };
}
function getBillableCustomer(payload: GetBillableCustomerPayload) {
    return axios.get(`billing/billable-customers/${payload.customerId}`, {
        params: {
            ...payload.query,
        },
    });
}

export interface InvoiceQuery {
    from: string;
    to: string;
    customerId: number;
}

function getInvoices(query: InvoiceQuery) {
    return axios.get('billing/invoices', {
        params: {
            ...query,
        },
    });
}

export interface CreateInvoicePayload {
    customers: {
        customerId: number;
        comments: string;
    }[];
    period: {
        from: string;
        to: string;
    };
    recipients: string[];
}

function createInvoice(payload: CreateInvoicePayload) {
    return axios.post('billing/invoices', payload);
}

export interface AddArticlesToCustomerPayload {
    customerId: number;
    articles: {
        id: number;
        quantity: number;
    }[];
}

function addArticlesToCustomer(payload: AddArticlesToCustomerPayload) {
    const { customerId, articles } = payload;
    return axios.post(`billing/customers/${customerId}/articles`, {
        articles,
    });
}

export interface DeleteArticleFromCustomerPayload {
    customerId: number;
    articleId: number;
}

function deleteArticleFromCustomer(payload: DeleteArticleFromCustomerPayload) {
    const { customerId, articleId } = payload;
    return axios.delete(`billing/customers/${customerId}/articles/${articleId}`);
}

function getArticlesByCustomer(id: number) {
    return axios.get(`billing/customer/${id}/articles`);
}

function getArticles() {
    return axios.get('billing/articles');
}

export interface CreateArticlePayload {
    name: string;
    price: number;
}

function createArticle(payload: CreateArticlePayload) {
    return axios.post('/billing/articles', payload);
}

function deleteArticle(id: number) {
    return axios.delete(`/billing/articles/${id}`);
}

export interface UpdateArticlePayload {
    id: number;
    name: string;
    price: number;
}

function updateArticle(article: UpdateArticlePayload) {
    return axios.put(`/billing/articles/${article.id}`, article);
}

function getBillingPeriods() {
    return axios.get('/billing/periods');
}

export interface CreatePeriodPayload {
    name: string;
    startTime: string;
    endTime: string;
    type: string;
}

function createPeriod(payload: CreatePeriodPayload) {
    return axios.post('/billing/periods', payload);
}

function deletePeriod(id: number) {
    return axios.delete(`/billing/periods/${id}`);
}

export interface UpdatePeriodPayload {
    params: {
        id: number;
    };
    data: {
        name: string;
        startTime: string;
        endTime: string;
        type: string;
    };
}

function updatePeriod(payload: UpdatePeriodPayload) {
    return axios.put(`/billing/periods/${payload.params.id}`, payload.data);
}

export interface AddPeriodToCustomerPayload {
    customerId: number;
    periodId: number;
    articleId: number;
}

function addPeriodToCustomer(payload: AddPeriodToCustomerPayload) {
    const { customerId, periodId, articleId } = payload;
    return axios.post(`/billing/customers/${customerId}/periods/${periodId}`, {
        articleId,
    });
}

export interface DeleteCustomerPeriodPayload {
    customerId: number;
    periodId: number;
}

function deleteCustomerPeriod(payload: DeleteCustomerPeriodPayload) {
    const { customerId, periodId } = payload;
    return axios.delete(`/billing/customers/${customerId}/periods/${periodId}`);
}

function getPeriodTypes() {
    return axios.get('/billing/periods/types');
}

type DateString = string; // Represents 'YYYY-MM-DD' or 'YYYY-MM-DD HH:mm:ss'

function generateInvoiceBase({ from, to }: { from: DateString; to: DateString }) {
    return axios.get(`/billing/invoicebase/generate?from=${from}&to=${to}`);
}

function getInvoiceSummary({ from, to, customerId }: { from: DateString; to: DateString; customerId: number }) {
    return axios.get(`/billing/customers/${customerId}/invoicebase/summary?from=${from}&to=${to}`);
}

export default {
    authorizeIntegration,
    checkIfIntegrationIsActive,
    getInvoiceSummary,
    syncExternals,
    getCustomers,
    getCustomer,
    updateCustomer,
    deleteCustomer,
    getBillableCustomers,
    getBillableCustomer,
    createInvoice,
    getInvoices,
    addArticlesToCustomer,
    getArticlesByCustomer,
    deleteArticleFromCustomer,
    getArticles,
    createArticle,
    updateArticle,
    deleteArticle,
    getBillingPeriods,
    createPeriod,
    deletePeriod,
    updatePeriod,
    addPeriodToCustomer,
    deleteCustomerPeriod,
    getPeriodTypes,
    generateInvoiceBase,
};
