import axios from 'axios';

export interface SendSMSPayload {
    reciever: string;
    text: string;
    caseId: string;
}

function sendSMS(payload: SendSMSPayload) {
    return axios.post('/video/send-sms', payload);
}

export interface SendEmailPayload {
    reciever: string;
    text: string;
    caseId: string;
    from: string;
    signature: string;
}

function sendEmail(payload: SendEmailPayload) {
    return axios.post('/video/send-email', payload);
}

export default {
    sendSMS,
    sendEmail,
};
