<template>
    <v-text-field
        v-bind="extendedAttrs"
        cy-data="smallTextInput"
        @input="handleInput"
        @change="handleChange"
        @keydown.enter="handleEnter"
        @blur="handleBlur"
    >
        <slot></slot>
    </v-text-field>
</template>

<script>
    export default {
        name: 'StandardInput',

        props: {
            validated: {
                type: Boolean,
                default: true,
            },
        },

        data() {
            return {
                buttonProps: {
                    elevation: 0,
                    color: 'white',
                    flat: true,
                    solo: true,
                    'hide-details': true,
                },
            };
        },

        computed: {
            extendedAttrs() {
                return {
                    ...this.buttonProps,
                    ...this.$attrs,
                };
            },
        },

        methods: {
            handleClick() {
                this.$emit('click');
            },

            handleEnter() {
                this.$emit('enter');
            },

            handleInput(value) {
                this.$emit('input', value);
            },

            handleChange(value) {
                this.$emit('change', value);
            },

            handleBlur(value) {
                this.$emit('blur', value);
            },
        },
    };
</script>

<style scoped>
    .search-input {
        border: 1px solid #eee;
        border-radius: 8px;
        padding: 0px;
        margin-bottom: 4px;
    }

    :deep(.v-input__control) {
        min-height: 40px !important;
    }
</style>
