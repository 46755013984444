<template>
    <section
        class="clients-family-item outlined-btn"
        :class="{ selected: isSelectedClient }"
        @click="$emit('click', item)"
    >
        <section class="clients-family-item-inner-container">
            <i class="mdi mdi-account icon-left"></i>

            <truncate class="truncate-wrapper">
                {{ item.name || $t('clientFamilyItem.noName') }}
            </truncate>
        </section>
    </section>
</template>

<script>
    export default {
        name: 'ClientFamilyItem',

        props: {
            item: {
                type: Object,
                required: true,
            },
            selectedClientId: {
                type: String,
                required: true,
            },
        },

        computed: {
            isSelectedClient() {
                return this.item.id === this.selectedClientId;
            },
        },
    };
</script>

<style scoped>
    .clients-family-item {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;
        position: relative;
    }

    .outlined-btn {
        background-color: white;
        border-bottom: 1px solid #ccc;
        color: var(--v-gray2-base);
        transition: all 0.2s;
    }

    .outlined-btn:hover {
        background-color: rgba(0, 0, 0, 0.1);
        color: black;
        cursor: pointer;
    }

    .outlined-btn:active {
        background-color: rgba(0, 0, 0, 0.2);
    }

    .icon-left {
        position: absolute;
        left: 24px;
    }

    .icon-right {
        position: absolute;
        right: 24px;
    }

    .selected {
        background-color: var(--v-primary-base);
        color: white;
    }

    .clients-family-item-inner-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .truncate {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 0px;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .truncate-wrapper {
        text-align: center;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: stretch;
        padding: 0 64px;
    }
</style>

<i18n lang="json">
{
    "en": {
        "clientFamilyItem": {
            "noName": "Customer name missing"
        }
    },
    "sv": {
        "clientFamilyItem": {
            "noName": "Kundnamn saknas"
        }
    }
}
</i18n>
