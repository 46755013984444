<template>
    <v-text-field
        v-bind="extendedAttrs"
        ref="input"
        :value="value"
        :loading="false"
        :class="[{ 'select-radius': inputRadius, 'outlined-btn': extendedAttrs.outlined === '' }]"
        :style="backgroundColor"
        @input="handleInput"
        @focusin="(event) => handleFocus(true, event)"
        @focusout="(event) => handleFocus(false, event)"
        @keydown.escape="handleInput('')"
        @paste="onPaste"
    >
        <template #append>
            <div v-if="extendedAttrs.loading" class="loading-dots">
                <div class="dot dot-1"></div>
                <div class="dot dot-2"></div>
                <div class="dot dot-3"></div>
            </div>
            <i class="icon mdi mdi-magnify"></i>
        </template>
    </v-text-field>
</template>
<script>
    import { events } from '@/enums/events.enums';
    import { debouncer } from '@/utils';

    export default {
        name: 'SearchInput',
        props: {
            value: {
                type: String,
                default: '',
            },
            inputRadius: {
                type: Boolean,
                default: false,
            },
            minCharacters: {
                type: Number,
                default: 0,
            },
            debounceTime: {
                type: Number,
                default: 500,
            },
            focused: {
                type: Boolean,
                default: false,
            },
            color: {
                type: String,
                default: 'white',
            },
            autofocus: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                buttonProps: {
                    elevation: 0,
                    color: 'white',
                    flat: true,
                    solo: true,
                    'hide-details': true,
                    placeholder: this.$t('search.searchHere'),
                },
                debouncedSearch: null,
            };
        },

        computed: {
            placeholder() {
                const minCharactersText = ' ' + this.$t(`search.minCharacters`, { minLetters: this.minCharacters });
                const appendText = this.minCharacters > 0 ? minCharactersText : '';

                if (!this.minCharacters) {
                    return this.$attrs.placeholder || this.$t('search.searchHere');
                }

                return this.$t('global.search') + appendText;
            },
            extendedAttrs() {
                return {
                    ...this.buttonProps,
                    ...this.$attrs,
                    placeholder: this.placeholder,
                };
            },
            backgroundColor() {
                return 'background-color:' + this.color;
            },
        },

        watch: {
            async focused() {
                const TIMEOUT_TO_FOCUS = 100;
                await new Promise((resolve) => setTimeout(resolve, TIMEOUT_TO_FOCUS));
                this.$refs.input.focus();
            },
        },

        created() {
            this.debouncedSearch = debouncer(this.emitInput, this.debounceTime);
        },
        mounted() {
            this.addEventListeners();
        },
        beforeDestroy() {
            this.removeEventListeners();
        },

        methods: {
            handleFocus(value, event) {
                if (value) {
                    this.$emit(events.FOCUS_IN, event);
                } else {
                    this.$emit(events.FOCUS_OUT, event);
                }
            },

            handleInput(value) {
                let searchValue = value;
                if (this.minCharacters > 0 && searchValue.length < this.minCharacters) {
                    searchValue = '';
                }
                this.debouncedSearch(searchValue);
            },
            emitInput(value) {
                this.$emit('input', value);
            },

            onPaste(event) {
                this.$nextTick(() => {
                    this.emitInput(event.srcElement._value);
                });
            },
            addEventListeners() {
                if (!this.autofocus) {
                    return;
                }
                document.addEventListener('keydown', this.handleKey);
            },
            removeEventListeners() {
                document.removeEventListener('keydown', this.handleKey);
            },
            handleKey(event) {
                if (event.key.length === 1) {
                    const el = this.$refs.input.$el.querySelector('input');
                    if (!el) return;
                    el.focus();
                }
            },
        },
    };
</script>

<style scoped>
    .icon {
        font-size: 16px;
        color: var(--v-gray1-base);
    }

    .outlined-btn {
        background-color: transparent;
        border: 1px solid #ccc;
        color: var(--v-gray2-base);
        flex: 1;
    }

    .outlined-btn i {
        color: var(--v-gray2-base);
    }

    .outlined-btn:focus {
        border-color: red !important;
        outline: none !important;
    }

    .search-input {
        border: 1px solid #eee;
        border-radius: 8px;
        padding: 0px;
        margin-bottom: 4px;
    }

    :deep(.v-input__control) {
        min-height: 40px !important;
    }

    :deep(.v-text-field__slot) {
        font-size: 0.95rem !important;
    }

    .loading-dots {
        display: flex;
        align-items: center;
        height: 16px; /* Adjust the height as needed */
        margin-right: 8px;
    }

    .dot {
        width: 4px;
        height: 4px;
        background-color: var(--v-gray2-base);
        border-radius: 50%;
        margin-right: 4px;
        animation: showHide 1.5s infinite;
        opacity: 0; /* Initially hide the dots */
    }

    .dot-1 {
        animation-delay: 0.5s; /* Delay the first dot */
    }

    .dot-2 {
        animation-delay: 1s; /* Delay the second dot */
    }

    .select-radius {
        border-radius: 8px !important;
    }

    @keyframes showHide {
        0%,
        100% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
    }
</style>

<i18n lang="json">
{
    "en": {
        "search": {
            "minCharacters": "(min {minLetters} chars)"
        }
    },
    "sv": {
        "search": {
            "minCharacters": "(minst {minLetters} tecken)"
        }
    }
}
</i18n>
