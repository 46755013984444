import axios from 'axios';
//= ===================================================//
//       CALLBACKBOOKING TEMPLATES                    //
//= ===================================================//

export interface AddTemplatePayload {
    name: string;
    queueIdentifier: number;
    isActive: number;
    // TODO: Which type is this?
    timetableTemplates: unknown[];
}

function addTemplate(data: AddTemplatePayload) {
    return axios.post('/cmot/templates', data);
}

export interface UpdateTemplatePayload {
    id: number;
    timetableTemplates: {
        dayOfWeek: number;
        startOn: string;
        endOn: string;
        slotGenerateTypeId: number;
    }[];
}

function updateTemplate({ id, data }: { id: number; data: UpdateTemplatePayload }) {
    return axios.put(`/cmot/templates/${id}`, data);
}
function deleteTemplate(id: number) {
    return axios.delete(`/cmot/templates/${id}`);
}
function getTemplateById(id: number) {
    return axios.get(`/cmot/templates/${id}`);
}
function getTemplateByQueueIdentifier(id: number) {
    return axios.get(`/cmot/templates/by-queue-identifier/${id}`);
}

function getTemplateFrequencies() {
    return axios.get('/cmot/templates-frequencies');
}

export interface ApplyTemplateData {
    templateId: number | string;
    queueIdentifier: number | string;
    startOn: string;
    weeksToRepeat: number;
    weeksToApply: 'odd' | 'even';
}

function setPeriod(payload: ApplyTemplateData) {
    return axios.post('/cmot/templates/apply-template', payload);
}

// -----------------------------------------------------------

//= ===================================================//
//                  CALLBACK AGENTS                   //
//= ===================================================//

export interface AddAgentPayload {
    userIdentifier: number;
    queueIdentifier: number;
    nrOfMinPerCall: string;
}

function addAgent(data: AddAgentPayload) {
    return axios.post('/cmot/agents', data);
}

export interface UpdateAgentPayload {
    userIdentifier: number;
    queueIdentifier: number;
    nrOfMinPerCall: string;
}

function updateAgent(data: UpdateAgentPayload, id: number) {
    return axios.put(`/cmot/agents/${id}`, data);
}

function deleteAgent(id: number) {
    return axios.delete(`/cmot/agents/${id}`);
}

function getAgentsByQueueId(id: number) {
    return axios.get(`/cmot/agents/by-queue-identifier/${id}`);
}
// -----------------------------------------------------------

export interface UpdateBookingPayload {
    StartOn: string;
    EndOn: string;
    UserIdentifiers: number[];
    QueueIdentifier: number;
    slotGenerateTypeId: number; // This is not PascalCase because it is the name of the property in the backend in update validation
}

function updateBooking(data: UpdateBookingPayload, selectedBooking: number) {
    return axios.put(`/cmot/timetables/${selectedBooking}`, data);
}

export interface AddGeneratedSlotTimesPayload {
    StartOn: string;
    EndOn: string;
    UserIdentifiers: number[];
    QueueIdentifier: number;
    SlotGenerateTypeId: number;
}

function insertBooking(data: AddGeneratedSlotTimesPayload) {
    return axios.post('/cmot/timetables', data);
}

function getClickedBooking(id: number) {
    return axios.get(`/cmot/timetables/${id}`);
}

function getTimeGenereateType() {
    return axios.get('/cmot/callbacks/init');
}

export interface GetPeriodPayload {
    startDate: string;
    endDate: string;
    id: number;
}

function getPeriod(data: GetPeriodPayload) {
    return axios.get(`/cmot/search/timetables?from=${data.startDate}&to=${data.endDate}&qid=${data.id}`);
}

function getAllUsersInGroup(id: number) {
    return axios.get(`/cmot/groups/${id}/info`);
}

function getAllGroups(userId: number) {
    return axios.get(`/cmot/users/${userId}/groups`);
}

function deleteRecord(selectedBooking: number) {
    return axios.delete(`/cmot/timetables/${selectedBooking}`);
}

function deleteAllRecords(selectedBooking: number) {
    return axios.delete('/cmot/multiple/timetables/1', { data: selectedBooking });
}
function deleteAllRecordsWithTemplate(selectedBooking: number) {
    return axios.delete('/cmot/multiple/timetables/2', { data: selectedBooking });
}

function deleteMarkedSlots(selectedSlotId: number) {
    return axios.post('/cmot/slots/delete', selectedSlotId);
}

export interface DeleteSlotPayload {
    id: number;
}

function deleteSlot(item: DeleteSlotPayload) {
    return axios.delete(`/cmot/slots/${item.id}`);
}

export interface PostponeBookingPayload {
    slaTime: string;
    callbackId: number;
}

function postponeBooking(payload: PostponeBookingPayload) {
    return axios.put(`/cmot/callback/${payload.callbackId}`, payload);
}

function getPhonenumber(callbackId: number) {
    return axios.get(`/cmot/phonenumber/${callbackId}`);
}

function generateNewSlots(selectedBooking: number) {
    return axios.post(`/cmot/booking/slots/${selectedBooking}`);
}

export {
    updateBooking,
    deleteRecord,
    deleteAllRecords,
    deleteAllRecordsWithTemplate,
    insertBooking,
    getPeriod,
    getAllUsersInGroup,
    getAllGroups,
    getClickedBooking,
    getTimeGenereateType,
    deleteMarkedSlots,
    deleteSlot,
    postponeBooking,
    // templates
    addTemplate,
    updateTemplate,
    deleteTemplate,
    getTemplateById,
    getTemplateByQueueIdentifier,
    getTemplateFrequencies,
    // agents
    addAgent,
    updateAgent,
    deleteAgent,
    getAgentsByQueueId,
    setPeriod,
    getPhonenumber,
    generateNewSlots,
};
