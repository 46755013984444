export const dataTypes = {
    DATE: 'DATE',
    NUMBER: 'NUMBER',
    STRING: 'STRING',
    TIME: 'TIME',
} as const;

export const directionTypes = {
    INBOUND: 'INBOUND',
    OUTBOUND: 'OUTBOUND',
} as const;

export const layoutTypes = {
    MIX: 'MIX',
    PODIUM: 'PODIUM',
    LIST: 'LIST',
} as const;

export const sourceTypes = {
    C1: 'C1',
    S2: 'S2',
    SWITCH: 'SWITCH',
} as const;

export const trendTypes = {
    NEGATIVE: 'NEGATIVE',
    NEUTRAL: 'NEUTRAL',
    POSITIVE: 'POSITIVE',
} as const;

export const stateValues = {
    DEFAULT: 'DEFAULT',
    INITIATED: 'INITIATED',
    FETCHING: 'FETCHING',
    LOADED: 'LOADED',
    ERROR: 'ERROR',
} as const;

export const sortingTypes = {
    ASC: 'ASC',
    DESC: 'DESC',
} as const;

export const timePeriodTypes = {
    TODAY: 1,
    YESTERDAY: -1,
    THIS_WEEK: 7,
    LAST_WEEK: -7,
    THIS_MONTH: 30,
    LAST_MONTH: -30,
} as const;

// Types for each constant group
export type DataType = keyof typeof dataTypes;
export type DirectionType = keyof typeof directionTypes;
export type LayoutType = keyof typeof layoutTypes;
export type SourceType = keyof typeof sourceTypes;
export type TrendType = keyof typeof trendTypes;
export type StateValue = keyof typeof stateValues;
export type SortingType = keyof typeof sortingTypes;
export type TimePeriodType = keyof typeof timePeriodTypes;
