<template>
    <main class="sms-container">
        <div class="smartphone">
            <div class="smartphone-content">
                <div v-if="hasContent" class="chat">
                    <div class="mine messages">
                        <div class="message last">{{ text }}</div>
                    </div>
                </div>
                <div v-else class="no-content">
                    <span>{{ $t('smsPreview.empty') }}</span>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
    export default {
        computed: {
            hasContent() {
                return this.text !== '';
            },

            text() {
                let content = this.$attrs.value?.content;

                if (!content) {
                    return '';
                }

                // Replace <p> tags with an empty string
                content = content.replaceAll('<p>', '');

                // Replace </p> tags with <br/> between the text
                content = content.replaceAll('</p>', '<br/>');

                content = content.trim();

                // if content is only <br/> tags, return empty string
                if (content === '<br/>' || content === '<br>') {
                    return '';
                }

                return content;
            },
        },
    };
</script>
<style lang="scss" scoped>
    .no-content {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        color: var(--v-gray2-base);
    }
    .sms-container {
        display: relative;
    }

    .smartphone {
        position: relative;
        width: 360px;
        height: 640px;
        margin: auto;
        border: 16px var(--v-gray3-base) solid;
        border-top-width: 60px;
        border-bottom-width: 60px;
        border-radius: 36px;
    }

    .smartphone:before {
        content: '';
        display: block;
        width: 60px;
        height: 5px;
        position: absolute;
        top: -30px;
        left: 50%;
        transform: translate(-50%, -50%);
        background: white;
        border-radius: 10px;
    }

    .smartphone:after {
        content: '';
        display: block;
        width: 35px;
        height: 35px;
        position: absolute;
        left: 50%;
        bottom: -65px;
        transform: translate(-50%, -50%);
        background: white;
        border-radius: 50%;
    }

    .smartphone .smartphone-content {
        background: white;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
    }

    // ** Messages ** //

    .chat {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 10px;
    }

    .messages {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
    }

    .message {
        border-radius: 20px;
        padding: 8px 15px;
        margin-top: 5px;
        margin-bottom: 5px;
        display: inline-block;
        max-width: 80%;
    }

    .yours {
        align-items: flex-start;
    }

    .yours .message {
        margin-right: 25%;
        background-color: #eee;
        position: relative;
    }

    .yours .message.last:before {
        content: '';
        position: absolute;
        z-index: 0;
        bottom: 0;
        left: -7px;
        height: 20px;
        width: 20px;
        background: #eee;
        border-bottom-right-radius: 15px;
    }
    .yours .message.last:after {
        content: '';
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: -10px;
        width: 10px;
        height: 20px;
        background: white;
        border-bottom-right-radius: 10px;
    }

    .mine {
        align-items: flex-end;
    }

    .mine .message {
        color: white;
        margin-left: 25%;
        background: linear-gradient(to bottom, #00d0ea 0%, #0085d1 100%);
        background-attachment: fixed;
        position: relative;
    }

    .mine .message.last:before {
        content: '';
        position: absolute;
        z-index: 0;
        bottom: 0;
        right: -8px;
        height: 20px;
        width: 20px;
        background: linear-gradient(to bottom, #00d0ea 0%, #0085d1 100%);
        background-attachment: fixed;
        border-bottom-left-radius: 15px;
    }

    .mine .message.last:after {
        content: '';
        position: absolute;
        z-index: 1;
        bottom: 0;
        right: -10px;
        width: 10px;
        height: 20px;
        background: white;
        border-bottom-left-radius: 10px;
    }
</style>
<i18n lang="json">
{
    "en": {
        "smsPreview": {
            "empty": "No content"
        }
    },
    "sv": {
        "smsPreview": {
            "empty": "Inget innehåll"
        }
    }
}
</i18n>
