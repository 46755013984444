import axios from 'axios';

function getCaseActivity(caseId: string, limit: number, offset: number) {
    return axios.get(`/activity/case/${caseId}`, {
        params: {
            limit,
            offset,
        },
    });
}

function getCaseActivityStatistics(caseId: string) {
    return axios.get(`/activity/case/${caseId}/statistics`);
}

export default {
    getCaseActivity,
    getCaseActivityStatistics,
};
