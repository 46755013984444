import axios from 'axios';

export const audienceType = {
    ADMINS: 'ADMINS',
    VIP: 'VIP',
    USERS: 'USERS',
    CLIENTS: 'CLIENTS',
    ACTIVE_CLIENTS: 'ACTIVE-CLIENTS',
    NEW_CLIENTS: 'NEW-CLIENTS',
    COMPOSITE: 'COMPOSITE',
} as const;

export type AudienceType = (typeof audienceType)[keyof typeof audienceType];

function getAudiences(data: { page: number; limit: number }) {
    return axios.get('/campaigns/audiences/', { params: data });
}

function getAudienceItems(type: AudienceType, id: number, limit: number, page: number, search: string) {
    return axios.get('/campaigns/audiences/items/', { params: { type, id, limit, page, search } });
}

function getAudienceItemsClients(id: number, limit: number, page: number, search: string) {
    return axios.get('/campaigns/audiences/items/clients', { params: { id, limit, page, search } });
}

export interface CreateAudiencePayload {
    name: string;
    type: AudienceType;
}

function createAudience(data: CreateAudiencePayload) {
    return axios.post('/campaigns/audiences/', data);
}

function deleteAudience(audienceId: number) {
    return axios.delete(`/campaigns/audiences/${audienceId}`);
}

function bulkInsertClients(data: { id: number; file: File }) {
    return axios.post(`/campaigns/audiences/${data.id}/clients/bulk`, data.file, {
        headers: {
            'Content-Type': 'text/csv',
        },
    });
}

function deleteAudienceItem(audienceId: number, clientId: number) {
    return axios.delete(`/campaigns/audiences/${audienceId}/clients/${clientId}`);
}

function addAudienceItem(audienceId: number, clientId: number) {
    return axios.post(`/campaigns/audiences/${audienceId}/clients/${clientId}`);
}

export default {
    getAudiences,
    getAudienceItems,
    getAudienceItemsClients,
    createAudience,
    deleteAudience,
    bulkInsertClients,
    deleteAudienceItem,
    addAudienceItem,
};
