<template>
    <v-menu v-model="menuOpen" :close-on-content-click="false" transition="scale-transition" offset-y>
        <template #activator="{}">
            <div v-if="slot" @click="open"><slot></slot></div>

            <slot v-else name="button" :open="open">
                <tertiary-action-button
                    :disabled="selectPropsComputed.error"
                    v-bind="selectPropsComputed"
                    @click="open"
                >
                    <article v-if="selectPropsComputed.error" class="error-text">{{ $t('selects.error') }}</article>
                    <article v-else class="item-text item">
                        <article class="select-item">
                            <div v-if="allItems === false" class="all-items-spacer">
                                <CommonAvatar
                                    v-if="selectedItems.length !== items.length || items.length === 1"
                                    :key="selectUserImage"
                                    class="mr-3"
                                    :userId="selectUserImage"
                                    :size="20"
                                />
                            </div>
                            {{ generateCustomText }}
                        </article>
                        <v-icon size="18" class="ml-3" color="var(--v-gray2-base)">mdi-chevron-down</v-icon>
                    </article>
                </tertiary-action-button>
            </slot>
        </template>

        <article class="action-item-container" cy-data="userList">
            <header>
                <search-input input-radius :value="search" @input="setSearch" />
            </header>
            <article class="item-list-wrapper">
                <section v-if="!loading" ref="scrollContainer" class="item-list" @scroll="checkScroll">
                    <article
                        v-for="(item, index) in itemsWithSearch"
                        :key="index"
                        class="list-item"
                        :class="listItemClass(item)"
                        :cy-value="item.value === -2 ? 'none' : itemText(item)"
                        @click="selectItem(item)"
                    >
                        <v-list-item-avatar class="list-item-avatar">
                            <CommonAvatar :key="item.value" :userId="item.value" :size="20" />
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title class="item-text-small">
                                <Truncate>{{ itemText(item) }}</Truncate>
                            </v-list-item-title>
                        </v-list-item-content>
                    </article>
                    <div v-if="loadingMoreUsers === true && hasMore === true" class="user-loading-bar">
                        <v-progress-circular :size="25" indeterminate color="primary" />
                    </div>
                </section>
            </article>
            <footer class="action-buttons-list">
                <standard-button
                    background-color="white"
                    cy-data="unselectAll"
                    :disabled="nonSelected"
                    @click="unselectAll"
                >
                    {{ $t('selects.unselectAll') }}
                </standard-button>
                <primary-button
                    background-color="var(--v-primary-base)"
                    fontColor="white"
                    cy-data="selectAll"
                    :disabled="allSelected"
                    @click="selectAll"
                >
                    {{ $t('selects.selectAll') }}
                </primary-button>
            </footer>
        </article>
    </v-menu>
</template>

<script>
    import { mapActions } from 'vuex';
    import { debouncer } from '@/utils';
    import CommonAvatar from '@/components/Global/CommonAvatar.vue';
    import Truncate from '../Functional/Truncate.vue';

    export default {
        name: 'MultiSelect',
        components: {
            CommonAvatar,
            Truncate,
        },
        props: {
            value: {
                type: Array,
                default: () => [],
            },
            // I dont use them. Still works i think. However is not tested. textKey and valueKey.
            //----------------------------------------
            textKey: {
                // can be used, if the text key is not called text on the items
                type: String,
                default: 'text',
            },
            valueKey: {
                // can be used if the value key is not called value on the items
                type: String,
                default: 'value',
            },
            //----------------------------------------
            debouncerTime: {
                type: Number,
                default: 500,
            },
        },
        data() {
            return {
                selectProps: {
                    rounded: true,
                    filled: true,
                    dense: true,
                    hideDetails: true,
                    multiple: true,
                },
                sortedItems: [],
                selectedItems: [],
                menuOpen: false,
                search: '',
                items: [],
                hasMore: true,
                page: 1,
                // Makes i so not all user get loaded when scrolling down
                pageLoadStopper: false,
                // Are true when all items are selected
                allItems: false,
                // When true items will be added after -1 , ... , ... and the backend will SELECT WHERE NOT *nubers after -1*.
                allMinusItems: false,
                unselectedItems: [],
                // This dose not count unassined
                userCount: 0,
                searchItems: [],
                searchItemsSave: [],
                itemSearchStopper: true,
                loading: true,
                loadingMoreUsers: false,
            };
        },
        computed: {
            slot() {
                return this.$attrs.useSlot !== undefined;
            },
            nonSelected() {
                return this.selectedItems.length === 0;
            },
            allSelected() {
                if (this.allMinusItems === true) {
                    return this.userCount + 1 === this.userCount - (this.unselectedItems.length - 1);
                }
                return this.selectedItems.length === this.items.length;
            },
            selectPropsComputed() {
                return {
                    ...this.selectProps,
                    ...this.$attrs,
                    items: this.items,
                };
            },
            selectUserImage() {
                if (this.selectedItems.length <= 0) {
                    return 0;
                }
                return this.selectedItems[0].value;
            },
            itemsWithSearch() {
                if (!this.search) {
                    return this.sortedItems;
                }
                return this.searchItems.filter((item) =>
                    item[this.textKey].toLowerCase().includes(this.search.toLowerCase())
                );
            },
            generateCustomText() {
                if (this.allItems === true) {
                    return this.$t('selects.allItemsSelected');
                }
                const remainingCount = this.userCount - this.unselectedItems.length;
                switch (this.selectedItems.length) {
                    case 0: {
                        if (this.allMinusItems === true) {
                            return '';
                        }
                        return this.$t('selects.selectItems');
                    }
                    case 1: {
                        if (this.allMinusItems === true) {
                            // If only one user is load in all Minus Item mode
                            if (remainingCount === 0) {
                                return this.itemText(this.selectedItems.at(0));
                            }
                            return this.itemText(this.selectedItems.at(0)) + ' + ' + remainingCount;
                        }
                        return this.itemText(this.selectedItems.at(0));
                    }
                    case this.items.length: {
                        if (this.allMinusItems === true && this.userCount >= this.items.length) {
                            return this.userNameCount;
                        }
                        return this.$t('selects.allItemsSelected');
                    }
                    default: {
                        if (this.allMinusItems === true) {
                            return this.userNameCount;
                        }
                        return this.itemText(this.selectedItems.at(0)) + ' + ' + (this.selectedItems.length - 1);
                    }
                }
            },
            userNameCount() {
                return this.itemText(this.selectedItems.at(0)) + ' + fler ';
            },
        },
        watch: {
            menuOpen: {
                immediate: true,
                handler(newVal) {
                    if (newVal === false) {
                        this.search = '';
                    } else {
                        this.setUserCount();
                    }
                },
            },
            value: {
                handler() {
                    this.setSelectedItems();
                },
                deep: true,
            },
            search() {
                this.fetchUsersSearch(1);
            },
        },
        created() {
            // Adds not assigend is -2 and the backend has a exception for it
            this.addNotAssigned();
            this.setExceptions();
            this.handleFirstFetch();
            this.setSelectedItems();
            this.handleChangeDebouncer = debouncer(this.handleChange, this.debouncerTime);
        },
        methods: {
            ...mapActions('Users', {
                getUsersAndRoles: 'getUsersAndRoles',
                getUserCount: 'getUserCount',
            }),
            async fetchUsersSearch(page) {
                this.itemSearchStopper = true;
                const users = await this.fetchUsers(page);
                this.searchItems = users.data.map((item) => {
                    return {
                        text: item.Name,
                        value: item.UserID,
                        icon: item.ProfilePicture,
                    };
                });
                // hadel exeptions for selecting all and all - ...
                this.searchExceptionAllItems();
                this.searchExceptionAllMinusItems();
                this.itemSearchStopper = false;
            },
            searchExceptionAllItems() {
                // Makes the selected items be right
                if (this.allItems !== true) return;
                for (const item of this.searchItems) {
                    this.searchItemsSave.push(item.value);
                    this.selectedItems.push(item);
                }
            },
            searchExceptionAllMinusItems() {
                if (this.allMinusItems !== true) return;
                for (const item of this.searchItems) {
                    let foundValue = false;
                    for (const unselectedItem of this.unselectedItems) {
                        if (item.value === unselectedItem.value) {
                            foundValue = true;
                            break;
                        }
                    }
                    if (foundValue === false) {
                        this.searchItemsSave.push(item.value);
                        this.selectedItems.push(item);
                    }
                }
            },
            async fetchUsersAndSet() {
                const users = await this.fetchUsers(this.page);
                if (users.hasMore === false) {
                    this.pageLoadStopper = false;
                    this.loadingMoreUsers = false;
                    return;
                }

                // Check if users.data exists and Map data
                if (Array.isArray(users.data)) {
                    const usersMapped = users.data.map((item) => ({
                        text: item.Name,
                        value: item.UserID,
                        icon: item.ProfilePicture,
                    }));

                    // Removes items that get duplicated from using search
                    const removeIndex = [];
                    for (const item of this.searchItemsSave) {
                        for (const index in usersMapped) {
                            if (item === usersMapped[index].value) {
                                removeIndex.push(index);
                            }
                        }
                    }
                    for (const remove of removeIndex) {
                        usersMapped.splice(remove, 1);
                    }
                    // Adds items
                    for (const user of usersMapped) {
                        this.items.push(user);
                    }
                    if (this.items.length > 1) {
                        for (const user of usersMapped) {
                            this.sortedItems.push(user);
                        }
                    }
                    // Handle Exception for
                    // -1 === all. and
                    // -1, ... , ... === all except , ... , ...
                    await this.allItemsException(usersMapped);
                    await this.allMinusItemsException(usersMapped);

                    this.pageLoadStopper = false;
                    this.loadingMoreUsers = false;
                } else {
                    console.error('users.data is undefined or not an array');
                }
            },
            async fetchUsers(page = 1) {
                const users = await this.getUsersAndRoles({
                    search: this.search,
                    page,
                    selfFirst: true,
                });
                this.hasMore = users.hasMore;
                return users;
            },
            allMinusItemsException(usersMaped) {
                if (this.allMinusItems !== true) return;
                for (const user of usersMaped) {
                    let sameValue = false;
                    for (const item of this.unselectedItems) {
                        if (user.value === item.value) {
                            sameValue = true;
                        }
                    }
                    if (sameValue === false) {
                        this.selectedItems.push(user);
                    }
                }
            },
            allItemsException(usersMaped) {
                if (this.allItems !== true) return;
                // If all items are selected
                for (const user of usersMaped) {
                    this.selectedItems.push(user);
                }
            },
            checkScroll() {
                const container = this.$refs.scrollContainer;
                const { scrollHeight } = container;
                const { scrollTop } = container;
                const { clientHeight } = container;
                const PAGINATION_OFFSET = 30;
                if (Math.floor(scrollHeight - scrollTop) - PAGINATION_OFFSET > clientHeight || !this.hasMore) {
                    return;
                }
                if (this.pageLoadStopper === true) {
                    return;
                }
                this.pageLoadStopper = true;
                this.page++;
                this.loadingMoreUsers = true;
                this.fetchUsersAndSet(this.page);
            },
            listItemClass(item) {
                if (this.isSelected(item)) {
                    return 'button-selected';
                }
                return 'button-not-selected';
            },
            open() {
                if (this.error) return;
                this.menuOpen = !this.menuOpen;
            },
            listItem(item) {
                return this.isSelected(item) ? 'list-item' : 'list-item-not-selected';
            },
            setSearch(input) {
                this.search = input;
            },
            selectAll() {
                this.allItems = true;
                this.allMinusItems = false;
                this.unselectedItems = [];
                this.selectedItems = this.items;
                this.handleChangeDebouncer(this.selectedItems);
            },
            unselectAll() {
                this.allItems = false;
                this.allMinusItems = false;
                this.unselectedItems = [];
                this.selectedItems = [];
                this.handleChangeDebouncer(this.selectedItems);
            },
            setSelectedItems() {
                if (!this.value) return;
                if (this.allItems === true || this.allMinusItems === true) return;
                this.selectedItems = this.value;
            },
            async handleFirstFetch() {
                await this.fetchUsersAndSet();
                await this.loadtoFirstSelectedUser();
                this.loading = false;
            },
            async loadtoFirstSelectedUser() {
                while (this.selectedItems.length < 1 && this.allMinusItems === true) {
                    this.page++;
                    await this.fetchUsersAndSet(this.page);
                }
            },
            setExceptions() {
                if (!this.value) return;
                // -1 is all users and if length is more than 1 it means all except ... ... ...
                if (this.value.length < 1 || this.value[0].value !== -1) return;
                // If we count from all items down
                if (this.value.length > 1) {
                    const unassignedUserId = -2;
                    let selectUnassinedId = true;
                    for (let i = 1; i < this.value.length; i++) {
                        if (this.value[i].value === unassignedUserId) {
                            selectUnassinedId = false;
                        }
                        this.unselectedItems.push(this.value[i]);
                    }
                    if (selectUnassinedId === true) {
                        this.selectedItems.push(this.items[0]);
                    }
                    this.allMinusItems = true;
                } else {
                    // select Unassigned
                    this.selectedItems.push(this.items[0]);
                    this.allItems = true;
                }
            },
            addNotAssigned() {
                const addNotAssignedObj = {
                    value: -2,
                    text: this.$t('selects.unassigned'),
                };
                this.items.push(addNotAssignedObj);
                this.sortedItems.push(addNotAssignedObj);
            },
            selectItem(item) {
                if (this.isSelected(item)) {
                    this.selectedItems = this.selectedItems.filter(
                        (selectedItem) => selectedItem[this.valueKey] !== item[this.valueKey]
                    );
                    if (this.allItems === true) {
                        this.allItems = false;
                        this.allMinusItems = true;
                    }
                    if (this.allMinusItems === true) {
                        this.unselectedItems.push(item);
                        // Resets to normal mode
                        if (this.userCount + 1 - this.unselectedItems.length <= 0) {
                            this.unselectAll();
                        }
                        // If non is loaded
                        this.loadtoFirstSelectedUser();
                    }
                } else {
                    this.selectedItems.push(item);
                    if (this.allMinusItems === true) {
                        this.unselectedItems = this.unselectedItems.filter(
                            (selectedItem) => selectedItem[this.valueKey] !== item[this.valueKey]
                        );
                        if (this.unselectedItems.length === 0) {
                            this.allMinusItems = false;
                            this.allItems = true;
                        }
                    } else if (this.selectedItems.length === this.items.length) {
                        this.allItems = true;
                    }
                }
                this.handleChangeDebouncer(this.selectedItems);
            },
            isSelected(item) {
                return this.selectedItems.some((selectedItem) => selectedItem[this.valueKey] === item[this.valueKey]);
            },
            async setUserCount() {
                const result = await this.getUserCount();
                this.userCount = result.count;
            },
            handleChange(value) {
                if (this.allItems === true) {
                    value = [];
                    value.push({
                        value: -1,
                    });
                }
                if (this.allMinusItems === true) {
                    value = [];
                    value.push({
                        value: -1,
                    });
                    for (const item of this.unselectedItems) {
                        value.push(item);
                    }
                }
                this.$emit('change', value);
            },
            itemText(item) {
                if (item.textKey) {
                    return this.$t(item.textKey);
                }
                return item[this.textKey];
            },
        },
    };
</script>

<style scoped>
    .error-text {
        font-size: 0.8rem;
        color: var(--v-gray2-base);
    }
    :deep(.v-input__prepend-inner) {
        align-self: center;
        margin: 0px !important;
        padding: 0px !important;
        white-space: nowrap !important;
    }
    div[role='menu'] {
        border-radius: 8px;
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2) !important;
        border: 1px solid #eee;
        padding: 4px;
        background-color: var(--v-gray3-base);
        min-width: 240px !important;
        max-width: 240px !important;
    }
    .action-buttons-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        gap: 4px;
    }
    .item-list-wrapper {
        border-radius: 8px;
        max-height: 312px;
        background-color: var(--v-gray3-base);
        box-sizing: border-box;
        display: flex;
        overflow: hidden;
        gap: 4px;
    }
    .item-list {
        max-height: 100%;
        width: 100%;
        overflow-y: auto;
        gap: 4px;
        display: grid;
    }
    .select-item {
        display: flex;
        align-items: center;
        flex-direction: row;
    }
    .icon-container {
        padding-right: 16px;
    }
    .list-container {
        display: flex;
        align-items: center;
        flex-direction: row;
    }
    .item {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        flex: 1;
    }
    .item-text {
        font-size: 0.9rem !important;
        font-weight: 400 !important;
        color: var(--v-gray4-base);
    }
    .item-text-small {
        font-size: 0.9rem !important;
        font-weight: 400 !important;
    }
    .item-text-not-selected {
        font-size: 0.9rem !important;
        opacity: 0.8;
        font-weight: 400 !important;
        color: var(--v-gray1-base);
    }
    .list-item {
        padding: 0 !important;
        padding-left: 8px !important;
        padding-right: 8px !important;
        height: 48px !important;
        margin-bottom: 0px;
        border-radius: 8px;
        background-color: var(--v-gray3-base);
        overflow: hidden;
        transition: all 0.1s ease-in-out;
    }
    .selected-item {
        background-color: white;
        box-sizing: border-box;
    }
    .display-item {
        display: flex;
        align-items: center;
        flex-direction: row;
        font-size: 0.9rem !important;
        font-weight: 400 !important;
        color: var(--v-gray2-base);
    }
    .action-item-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    .all-items-spacer {
        width: 20px;
        height: 20px;
        min-width: 20px;
        max-width: 20px;
        margin-right: 12px;
    }
    .user-loading-bar {
        text-align: center !important;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .list-item-avatar {
        display: flex !important;
        justify-content: center !important;
    }
</style>

<i18n lang="json">
{
    "en": {
        "selects": {
            "selectItems": "Select",
            "allItemsSelected": "All",
            "selectAll": "Select all",
            "unselectAll": "Unselect all",
            "error": "Could not load items",
            "unassigned": "Unassigned"
        }
    },
    "sv": {
        "selects": {
            "selectItems": "Välj",
            "allItemsSelected": "Alla",
            "selectAll": "Välj alla",
            "unselectAll": "Avmarkera alla",
            "error": "Kunde inte ladda innehåll",
            "unassigned": "Otilldelad"
        }
    }
}
</i18n>
