<template>
    <standard-button v-bind="extendedAttrs" @click="handleClick" @on-hover="setHover">
        <v-icon v-if="isActive" :size="iconSize" :color="colorComputed">mdi-close</v-icon>
        <v-icon v-else :size="iconSize" :color="colorComputed"><slot></slot></v-icon>
    </standard-button>
</template>
<script>
    export default {
        name: 'RoundIconButton',

        props: {
            large: {
                type: Boolean,
                default: false,
            },
            iconColor: {
                type: String,
                default: 'var(--v-gray2-base)',
            },
        },
        data() {
            return {
                color: 'white',
                hover: false,
            };
        },

        computed: {
            extendedAttrs() {
                return {
                    fab: true,
                    height: this.large ? '48px' : '36px',
                    width: this.large ? '48px' : '36px',
                    ...this.$attrs,
                    style: {
                        ...this.outlined,
                    },
                };
            },
            outlined() {
                const outlined = this.$attrs.hasOwnProperty('outlined') && this.$attrs.outlined !== false;
                return {
                    border: outlined ? '1px solid #ccc' : '',
                };
            },

            iconSize() {
                return this.large ? 24 : 16;
            },

            isActive() {
                return this.$attrs.active;
            },

            colorComputed() {
                return this.iconColor;
            },
        },

        methods: {
            setHover(value) {
                this.hover = value;
            },
            handleClick() {
                this.$emit('click');
            },
        },
    };
</script>

<style scoped>
    :focus {
        border: 1px solid var(--v-primary-base) !important;
    }
</style>
