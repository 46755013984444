<template>
    <div>
        <v-combobox
            v-bind="buttonPropsComputed"
            :items="availableEmails"
            :search-input.sync="search"
            :loading="loading"
            @input="handleChange"
        />
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import { debouncer } from '@/utils';
    import { searchFields, clientTypes } from '../../../enums/client.enums';

    export default {
        name: 'EmailSingle',

        props: {
            icon: {
                type: String,
                default: '',
            },
            dense: {
                type: Boolean,
                default: true,
            },
            autocomplete: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                buttonProps: {
                    elevation: 0,
                    ripple: false,
                    color: 'white',
                    height: this.dense ? 40 : 50,
                    flat: true,
                    solo: true,
                    'hide-details': true,
                    outlined: true,
                    chips: true,
                },

                search: null,
                ruleStatus: '',
                contactPersons: [],
                loading: false,
                searchData: [],
                clientData: null,
                debounceGetData: null,
            };
        },

        computed: {
            ...mapState({
                clientContactPersons: (state) => state.Cases.clientContactPersons,
                currentFormData: (state) => state.Creator.currentFormData,
            }),

            appendProps() {
                if (!this.autocomplete) {
                    return {
                        appendIcon: '',
                    };
                }
                return {};
            },

            availableEmails() {
                try {
                    if (!this.autoComplete) {
                        return [];
                    }
                    const clientEmails = this.getClientEmails();
                    const availableEmails = new Set(clientEmails);

                    for (const email of this.searchData) {
                        availableEmails.add(email);
                    }

                    return Array.from(availableEmails);
                } catch (error) {
                    return [];
                }
            },

            buttonPropsComputed() {
                return {
                    ...this.buttonProps,
                    ...this.$attrs,
                    ...this.appendProps,
                    rules: [],
                };
            },
        },

        watch: {
            search: {
                handler() {
                    this.debounceGetData();
                },
            },
        },

        mounted() {
            const DEBOUNCE_TIME = 500;
            this.debounceGetData = debouncer(this.getData, DEBOUNCE_TIME);
        },

        methods: {
            ...mapActions({
                getClientContactPersons: 'Cases/getClientContactPersons',
                searchClients: 'Client/getClients',
            }),

            async getData() {
                if (!this.search) {
                    this.searchData = [];
                    this.clientData = null;
                    return;
                }

                this.loading = true;
                const data = await this.searchClients({
                    search: this.search,
                    searchFields: [searchFields.NAME, searchFields.EMAIL],
                    types: Object.values(clientTypes),
                });

                if (!data) {
                    this.searchData = [];
                    this.clientData = null;
                    this.loading = false;
                    return;
                }

                this.clientData = data;

                const allEmails = data.clients
                    .flatMap((client) => [client.data.primaryEmail, client.data.secondaryEmail])
                    .filter(Boolean);

                this.searchData = allEmails;
                this.loading = false;
            },

            handleChange(value) {
                if (value?.length === 0) {
                    this.$emit('change', null);
                    return;
                }

                this.input = value;

                this.$emit('change', this.input);
            },

            getClientEmails() {
                const clientEmails = new Set();
                const client = this.currentFormData?.client?.data;

                if (client?.primaryEmail) {
                    clientEmails.add(client.primaryEmail);
                }
                if (client?.secondaryEmail) {
                    clientEmails.add(client.secondaryEmail);
                }
                if (client?.contacts) {
                    for (const contact of client.contacts) {
                        const { primaryEmail, secondaryEmail } = contact;
                        if (primaryEmail) {
                            clientEmails.add(primaryEmail);
                        }
                        if (secondaryEmail) {
                            clientEmails.add(secondaryEmail);
                        }
                    }
                }

                return [...clientEmails].filter((email) => email.includes(this.search));
            },

            getClientNameFromCard(email) {
                const client = this.currentFormData?.client?.data;

                if (client?.primaryEmail === email) {
                    return client.name;
                }

                if (client?.secondaryEmail === email) {
                    return client.name;
                }

                if (client?.contacts) {
                    for (const contact of client.contacts) {
                        const { primaryEmail, secondaryEmail, name } = contact;
                        if (primaryEmail === email) {
                            return name;
                        }
                        if (secondaryEmail === email) {
                            return name;
                        }
                    }
                }

                return '';
            },
        },
    };
</script>

<style scoped>
    :deep(.v-btn__content) {
        letter-spacing: 0px !important;
        text-transform: none !important;
        font-weight: 400 !important;
    }

    .btn {
        color: var(--v-gray2-base);
        font-size: 1rem;
    }
</style>
