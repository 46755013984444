<template>
    <v-btn
        v-bind="buttonPropsComputed"
        :style="itemStyle"
        class="btn"
        @mouseover="emitHover(true)"
        @mouseout="emitHover(false)"
        @click="handleClick"
        @mouseleave="(e) => e.target.blur()"
    >
        <i v-if="icon" class="icon mdi" :class="icon"></i>
        <div class="standard-button-content"><slot></slot></div>
    </v-btn>
</template>

<script>
    export default {
        name: 'StandardButton',

        props: {
            icon: {
                type: String,
                default: '',
            },
            dense: {
                type: Boolean,
                default: true,
            },
            small: {
                type: Boolean,
                default: false,
            },
            backgroundColor: {
                type: String,
                default: 'var(--v-gray3-base)',
            },
            fontColor: {
                type: String,
                default: '',
            },
            hoverEffect: {
                type: Boolean,
                default: false,
            },
            hoverBackgroundColor: {
                type: String,
                default: 'var(--v-gray3-base)',
            },
        },

        data() {
            return {
                buttonProps: {
                    elevation: 0,
                    ripple: false,
                    color: 'white',
                    height: this.dense ? 40 : 50,
                },
                hover: false,
            };
        },

        computed: {
            buttonPropsComputed() {
                return {
                    ...this.buttonProps,
                    ...this.$attrs,
                };
            },

            itemStyle() {
                return {
                    minHeight: this.dense ? '40px ' : '50px',
                    minWidth: this.dense ? '40px ' : '50px',
                    color: this.fontColor,
                    transition: this.hoverEffect ? 'background-color 0.3s ease-out' : '',
                };
            },
        },

        methods: {
            handleClick(event) {
                this.$emit('click', event);
            },

            emitHover(value) {
                this.hover = value;
                this.$emit('on-hover', value);
            },
        },
    };
</script>

<style scoped>
    .icon {
        font-size: 16px;
        margin-right: 4px;
    }

    :deep(.v-btn__content) {
        letter-spacing: 0px !important;
        text-transform: none !important;
        font-weight: 400 !important;
    }

    .btn {
        color: var(--v-gray2-base);
        font-size: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .standard-button-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex: 1;
    }

    :deep(.standard-button-content > span:first-child) {
        overflow: hidden;
        width: 0px;
        flex: 1;
        text-align: left;
        white-space: nowrap;
        justify-content: center;
        text-overflow: ellipsis;
        display: inline-block;
        text-align: center;
    }

    .btn::before {
        content: none !important;
    }
</style>
