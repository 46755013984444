import axios from 'axios';
import type { VitecTask } from '@/types/integrations/vitec.types';
export function getVitecTasks(clientId: string) {
    return axios.get<VitecTask[]>('integrations/vitec/tasks/', { params: { clientId } });
}

export function getVitecTask(taskId: number) {
    return axios.get<VitecTask>(`integrations/vitec/tasks/${taskId}`);
}

export default {
    getVitecTasks,
    getVitecTask,
};
