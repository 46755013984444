import axios from 'axios';

function getTokenUsage(dateFrom: string, dateTo: string, context: string) {
    return axios.get('/integrations/chatgpt/token-usage', {
        params: {
            dateTo,
            dateFrom,
            context,
        },
    });
}

function getLaws() {
    return axios.get('/integrations/chatgpt/laws');
}

export interface LayPayload {
    lawText: string;
}

function saveLaws(payload: LayPayload) {
    return axios.put('/integrations/chatgpt/laws', payload);
}

//* Functions for AI */
function getFunctions() {
    return axios.get('/integrations/chatgpt/functions');
}

function getFunction(id: number) {
    return axios.get(`/integrations/chatgpt/functions/${id}`);
}

// TODO: Add type for payload
function saveFunction(payload: unknown) {
    return axios.post('/integrations/chatgpt/functions', payload);
}

function removeFunction(id: number) {
    return axios.delete(`/integrations/chatgpt/functions/${id}`);
}

function getAvailableIntegrations() {
    return axios.get('/integrations/chatgpt/functions/integrations');
}

export interface AutoDialogSettings {
    active: boolean;
    promptId: number;
    queueId: number;
    channel: string;
}

// * Activate AI on channel
function activateAIOnChannel(payload: AutoDialogSettings) {
    return axios.post('/integrations/chatgpt/auto-dialog-settings', payload);
}

function getAutoDialogSettings(queueId: number, channel: string) {
    return axios.get(`/integrations/chatgpt/auto-dialog-settings/queue/${queueId}/${channel}`);
}

// Datasources
function getSources() {
    return axios.get('integrations/chatgpt/data-sources');
}
function deleteSources(id: number, fileId: number) {
    return axios.delete(`integrations/chatgpt/data-source/${id}/file-id/${fileId}`);
}
export interface DataSourcePayload {
    file: File[];
}

function saveSource(payload: DataSourcePayload) {
    return axios.post('integrations/chatgpt/data-source', payload, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}

// Auto categorization
function getAutoCategorization() {
    return axios.get('/integrations/chatgpt/text-utilizer/auto-categorization');
}

// TODO: Add type for payload
function saveAutoCategorization(payload: unknown) {
    return axios.post('/integrations/chatgpt/text-utilizer/auto-categorization', payload);
}

// Manage instructions
function getInstructions() {
    return axios.get('/integrations/chatgpt/instructions');
}

// TODO: Add type for payload
function saveInstruction(payload: unknown) {
    return axios.post('/integrations/chatgpt/instruction', payload);
}

function deleteInstruction(id: number) {
    return axios.delete(`/integrations/chatgpt/instruction/${id}`);
}

function getProviders() {
    return axios.get('/integrations/chatgpt/llm/providers');
}

function getProviderLLMSById(id: number) {
    return axios.get(`/integrations/chatgpt/llm/providers/${id}`);
}

// Manage prompts
function getAllDataSources() {
    return axios.get('/integrations/chatgpt/prompts/data-sources');
}

function deletePrompt(id: number) {
    return axios.delete(`/integrations/chatgpt/prompt/${id}`);
}
// TODO: Add type for payload
function savePrompt(payload: unknown) {
    return axios.post('/integrations/chatgpt/prompt', payload);
}

function getAllContexts() {
    return axios.get('/integrations/chatgpt/context');
}

function getSpecificPrompt(id: number) {
    return axios.get(`/integrations/chatgpt/prompt/${id}`);
}

function getAvailablePrompts(context: string) {
    return axios.get(`/integrations/chatgpt/prompts?context=${context}`);
}

// TODO: Add type for payload
function command(payload: unknown) {
    return axios.post('/integrations/chatgpt/v2/command', payload);
}

export default {
    getAvailablePrompts,
    command,
    getAllContexts,
    getSpecificPrompt,
    savePrompt,
    deletePrompt,
    saveInstruction,
    getInstructions,
    deleteInstruction,
    getAutoCategorization,
    saveAutoCategorization,
    saveSource,
    deleteSources,
    getSources,

    getAllDataSources,

    activateAIOnChannel,
    getAutoDialogSettings,

    getFunctions,
    getFunction,
    saveFunction,
    removeFunction,
    getAvailableIntegrations,

    getProviders,
    getProviderLLMSById,

    getLaws,
    saveLaws,
    getTokenUsage,
};
