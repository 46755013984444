<template>
    <standard-select
        v-bind="selectPropsComputed"
        :items="items"
        :boolean="boolean"
        :dense="dense"
        :value="preSelected"
        :single="true"
        @click="handleClick"
        @change="handleChange"
    />
</template>
<script>
    import { mapState } from 'vuex';
    import { caseStatuses } from '@/enums/cases.enums';

    export default {
        name: 'StatusSelect',

        props: {
            boolean: {
                type: Boolean,
                default: false,
            },
            dense: {
                type: Boolean,
                default: true,
            },
            value: {
                type: String,
                default: caseStatuses.OPEN,
            },
        },

        data() {
            return {
                selectProps: {},
            };
        },

        computed: {
            ...mapState('Cases', ['caseFilters']),

            selectPropsComputed() {
                return {
                    ...this.selectProps,
                    ...this.$attrs,
                };
            },

            items() {
                return this.caseFilters.statuses
                    .filter((status) => !status.isCreatable)
                    .map((status) => ({
                        text: status.nameTranslate[this.$i18n.locale],
                        value: status.value,
                        icon: status.icon,
                        color: status.color,
                    }));
            },

            preSelected() {
                try {
                    let valueToSet = this.value;
                    if (!valueToSet) {
                        valueToSet = caseStatuses.OPEN;
                    }
                    return this.items.find((status) => status.value === valueToSet);
                } catch (error) {
                    console.error('Error auto selecting status:', error);

                    return null;
                }
            },
        },

        created() {
            const status = this.caseFilters.statuses.find((status) => status.value === this.preSelected.value);
            this.$emit('input', status.value);
            this.$emit('change', status.value);
        },

        methods: {
            handleClick(event) {
                this.$emit('click', event);
            },

            handleChange(status) {
                this.$emit('input', status.at(0).value);
                this.$emit('change', status.at(0).value);
            },
        },
    };
</script>

<style scoped></style>

<i18n lang="json">
{}
</i18n>
