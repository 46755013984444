import axios from 'axios';

function getConnectedClientData(userId: number, type: string) {
    return axios.get(`search/${type}/${userId}/client-data`);
}

export interface SearchPayload {
    type: string;
    q: string;
    filterQuestion: string;
    page: number;
    listViewItems: string;
}

function search(payload: SearchPayload) {
    return axios.get(
        `search/${payload.type}/?q=${encodeURIComponent(payload.q)}&filterQuestion=${encodeURIComponent(
            payload.filterQuestion,
        )}&page=${payload.page}&listViewItems=${payload.listViewItems}`,
    );
}

export interface UpdateSearchObjectPayload {
    IsExternal: number;
    Type: string;
    UserId: number | string;
    Name: string;
}

function updateSearchObject(payload: UpdateSearchObjectPayload) {
    return axios.put('/search/object', payload);
}

function getUserData({ userId, type }: { userId: string; type: string }) {
    const urlSearch = `search/getUser/?q=${encodeURIComponent(userId)}&type=${type}`;
    return axios.get(`${urlSearch}`);
}

function getUniqueSearch(searchWord: string) {
    return axios.get('search/?q=' + searchWord);
}

function getSearchHandlerUsers({ search, page }: { search: string; page: number }) {
    return axios.get(`search/settings/users?search=${search}&page=${page}`);
}
function getSearchHandlerQueues({ search, page }: { search: string; page: number }) {
    return axios.get(`search/settings/queues?search=${search}&page=${page}`);
}

export interface UpdateUserShowInSearchPayload {
    id: number;
    showInSearch: boolean;
}

function updateUserShowInSearch(payload: UpdateUserShowInSearchPayload) {
    return axios.put(`search/settings/users/${payload.id}`, payload);
}

export default {
    getConnectedClientData,
    search,
    updateSearchObject,
    getUniqueSearch,
    getUserData,
    getSearchHandlerUsers,
    getSearchHandlerQueues,
    updateUserShowInSearch,
};
