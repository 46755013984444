import axios from 'axios';

export interface GetBlacklistedEmailsPayload {
    page: number;
    order: string;
    itemsPerPage: number;
    searchQuery: string;
    direction: string;
}

function getBlacklistedEmails(payload: GetBlacklistedEmailsPayload) {
    return axios.get('/emails/blacklisted/', { params: payload });
}

export interface GetBlacklistedEmailsPagePayload {
    id: number;
    itemsPerPage: number;
    order: string;
    direction: string;
}

function getBlacklistedEmailsPage(payload: GetBlacklistedEmailsPagePayload) {
    const { id, ...parameters } = payload;
    return axios.get(`/emails/blacklisted/${id}/page`, { params: parameters });
}

export interface AddBlacklistedEmailPayload {
    identifier: string;
}

function addBlacklistedEmail(payload: AddBlacklistedEmailPayload) {
    return axios.post('/emails/blacklisted/', payload);
}

export interface UpdateBlacklistedEmailPayload {
    id: number;
    identifier: string;
}

function updateBlacklistedEmail(payload: UpdateBlacklistedEmailPayload) {
    const { id, ...body } = payload;
    return axios.put(`/emails/blacklisted/${id}`, body);
}

export interface DeleteBlacklistedEmailPayload {
    id: number;
}

function deleteBlacklistedEmail(payload: DeleteBlacklistedEmailPayload) {
    return axios.delete(`/emails/blacklisted/${payload.id}`);
}

export default {
    getBlacklistedEmails,
    getBlacklistedEmailsPage,
    addBlacklistedEmail,
    updateBlacklistedEmail,
    deleteBlacklistedEmail,
};
