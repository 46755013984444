import { mapLanguageToCountry } from '@/helpers/queueLanguage.helper';

/**
 * Partial structure of the root state.
 */
export interface RootState {
    Cases: {
        changeQueues: {
            ID: string;
            language: string;
        }[];
    };
}

/**
 * Partial structure of the current form data.
 */
export interface CurrentFormData {
    form: {
        inputs: {
            type: string;
            defaultCountryProp?: string;
            value: string;
        }[];
    };
}

/**
 * Updates the default country property of the telephone input field based on the selected call queue's language.
 */
export function handleTelephoneQueueLanguage(
    currentFormData: CurrentFormData,
    payload: { item: { type: string } },
    rootState: RootState,
): CurrentFormData {
    const { form } = currentFormData;
    const telephoneInput = form.inputs.find((input) => input.type === 'TELEPHONE_INPUT');
    const queueSelect = form.inputs.find((input) => input.type === 'CALL_QUEUE_SELECT');

    // Exit early if necessary inputs are missing or the event is not from CALL_QUEUE_SELECT
    if (!telephoneInput || !queueSelect || payload.item.type !== 'CALL_QUEUE_SELECT') {
        return currentFormData;
    }

    // Find the selected queue's language
    const queueData = rootState.Cases.changeQueues.find((queue) => queue.ID === queueSelect.value);
    if (!queueData?.language) {
        return currentFormData;
    }

    // Update the telephone input with the mapped country code
    telephoneInput.defaultCountryProp = mapLanguageToCountry(queueData.language);

    return { ...currentFormData };
}
