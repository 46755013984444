import axios from 'axios';

export interface GetCalendarDataPayload {
    user: string;
    isExternal?: number;
}

function getCalendarData(payload: GetCalendarDataPayload) {
    return axios.get(
        '/calendar/?user=' +
            payload.user +
            '&date=' +
            new Date().toISOString().slice(0, 10) +
            `&isExternal=${payload.isExternal || ''}`,
    );
}

export interface GetUserCreatedEventsPayload {
    user: string;
    page: number;
    limit: number;
}

function getUserCreatedEvents(payload: GetUserCreatedEventsPayload) {
    return axios.get(`/calendar/user/${payload.user}?page=${payload.page}&limit=${payload.limit}`);
}

export interface AddCalendarEventPayload {
    title: string;
    description: string;
    from: string;
    to: string;
    user: number;
    isExternal: number;
}

function addCalendarEvent(payload: AddCalendarEventPayload) {
    return axios.post('/calendar/event', payload);
}

export interface UpdateCalendarEventPayload {
    id: number;
    title: string;
    description: string;
    from: string;
    to: string;
    user: number;
    isExternal: number;
}

function updateCalendarEvent(payload: UpdateCalendarEventPayload) {
    return axios.put('/calendar/event', payload);
}

export interface DeleteCalendarEventPayload {
    event: number;
}

function deleteCalendarEvent(payload: DeleteCalendarEventPayload) {
    return axios.delete(`/calendar/event/${payload.event}`);
}
export default {
    getCalendarData,
    addCalendarEvent,
    updateCalendarEvent,
    deleteCalendarEvent,
    getUserCreatedEvents,
};
