import axios from 'axios';

const language = {
    eng: 'English',
    swe: 'Swedish',
    nor: 'Norwegian',
    dan: 'Danish',
    fin: 'Finnish',
} as const;

function getSurveys() {
    return axios.get('/surveys');
}

function getSurveyById(id: number) {
    return axios.get(`/surveys/${id}`);
}

export interface CreateSurveyPayload {
    name: string;
    description: string;
    active: boolean;
    language: keyof typeof language;
}

function createSurvey(newSurvey: CreateSurveyPayload) {
    return axios.post('/surveys', newSurvey);
}

function deleteSurvey(id: number) {
    return axios.delete(`/surveys/${id}`);
}

function getTriggersBySurveyId(surveyId: number) {
    return axios.get(`/surveys/${surveyId}/triggers`);
}

export interface TriggerPayload {
    id: number;
    surveyRef: number;
    queues: number[];
    delay: number;
    active: boolean;
    manual: boolean;
    sendType: {
        sendType: 'EMAIL' | 'SMS';
        noReplySenderMail?: string;
        emailContent?: string;
        emailTitle?: string;
        emailSubject?: string;
        emailLogoLink?: string;
        emailContactEmail?: string;
        smsContent?: string;
    };
}

function updateTrigger(trigger: TriggerPayload) {
    return axios.put(`/surveys/${trigger.surveyRef}/triggers/${trigger.id}`, trigger);
}

export interface CreateTriggerPayload {
    surveyRef: number;
    queues: number[];
    delay: number;
    active: boolean;
    manual: boolean;
    sendType: {
        sendType: 'EMAIL' | 'SMS';
        noReplySenderMail?: string;
        emailContent?: string;
        emailTitle?: string;
        emailSubject?: string;
        emailLogoLink?: string;
        emailContactEmail?: string;
        smsContent?: string;
    };
}

function createTrigger(surveyId: number, trigger: CreateTriggerPayload) {
    return axios.post(`/surveys/${surveyId}/triggers`, trigger);
}

function deleteTrigger(surveyId: number, triggerId: number) {
    return axios.delete(`/surveys/${surveyId}/triggers/${triggerId}`);
}

function getQuestionsBySurvey(id: number) {
    return axios.get(`/surveys/${id}/questions`);
}

function getAnswersCsv(surveyId: number) {
    return axios.get(`/surveys/${surveyId}/answers`, {
        params: {
            type: 'csv',
        },
    });
}

export interface Question {
    text: string;
    type: 'TEXT' | 'RADIO' | 'CHECKBOX';
    required: boolean;
    alternatives: string[];
}

export interface Survey {
    id: number;
    name: string;
    description: string;
    active: boolean;
}

function putSurveyAndQuestions(survey: Survey, questions: Question[]) {
    return axios.put(`/surveys/${survey.id}`, {
        ...survey,
        questions,
    });
}

function setSurveyActive(surveyId: number, active: boolean) {
    return axios.put(`/surveys/${surveyId}/active`, {
        active,
    });
}

function getAnswersBySurvey(surveyId: number) {
    return axios.get(`/surveys/${surveyId}/answers`, {
        params: {
            type: 'summary',
        },
    });
}

function sendPendingManualSurvey(commentId: number) {
    return axios.post(`/pending-surveys/${commentId}/send`);
}

export default {
    getSurveys,
    getSurveyById,
    createSurvey,
    deleteSurvey,
    getQuestionsBySurvey,
    getAnswersBySurvey,
    putSurveyAndQuestions,
    setSurveyActive,
    getTriggersBySurveyId,
    updateTrigger,
    createTrigger,
    deleteTrigger,
    getAnswersCsv,
    sendPendingManualSurvey,
};
