<template>
    <section v-if="errorState === ErrorState.NONE">
        <section class="create-comments" :class="createCommentsClass" :style="{ minHeight }">
            <CreateCommentsToolbar v-if="!isFullScreen" cy-data="create-comments-toolbar-tab" />
            <CreateCommentsHeader cy-data="create-comments-header-tab" />
            <CreatecommentsRichTextEditor cy-data="create-comments-rich-text-editor-tab" />
            <CreateCommentsAction cy-data="create-comments-action-tab" />
        </section>
    </section>
    <section v-else>
        <section
            :class="isFullScreen ? 'create-comments-error-fullscreen' : 'create-comments-error'"
            cy-data="create-comments-error-tab"
        >
            <i class="mdi mdi-close-circle"></i>
            <h1>{{ errorMessage }}</h1>
        </section>
    </section>
</template>

<script lang="ts">
    /**
     * @component CreateCommentsTab
     *
     * @description
     * The `CreateCommentsTab` component provides a shared state for managing comment creation
     * within a case. It injects and provides a reactive state that persists across all child
     * components under it. The state is automatically destroyed when the component is unmounted.
     *
     * This component listens for socket events to update the current case and subscribes to
     * relevant services. It handles different error states and dynamically renders the appropriate UI.
     *
     * @prop {string} [caseId] - The ID of the case to associate comments with.
     * @prop {number[]} attachedConversations - An array of conversation IDs attached to the case.
     *
     * @injects {Socket} $socket - Injected socket instance for real-time updates.
     * @injects {Toasted} $toasted - Injected toast notifications for user feedback.
     * @injects {Global} $global - Injected global configuration object.
     * @injects {StoreDispatch} storeDispatch - Injected Vuex store dispatcher.
     *
     * @provides {CreateCommentsState} commentsState - A reactive state object shared with child components.
     *
     * @event attachConversation - Emits when a conversation is attached.
     * @event uncheckAllConversation - Emits when all conversations should be unchecked.
     * @event close - Emits when the tab should be closed.
     * @event checkAllConversations - Emits when all conversations should be checked.
     * @event send - Emits when a comment should be sent.
     * @event sendClose - Emits when a comment should be sent and the case closed.
     * @event dockWindow - Emits when the window should be docked.
     * @event createPDF - Emits when a PDF should be generated.
     * @event forward - Emits when a case should be forwarded.
     * @event getSignatureTemplate - Emits when a signature template should be fetched.
     * @event uploadNewFiles - Emits when new files should be uploaded.
     *
     * @socketEvent changeInCurrentCase - Listens for updates to the current case.
     * @socketEvent connect - Handles socket connection events.
     *
     * @computed {boolean} inCase - Checks if the current user is active in the case.
     * @computed {boolean} isActiveUser - Determines if the current user is the active user in the case.
     * @computed {string} errorMessage - Displays an error message based on the current error state.
     * @computed {string} createCommentsClass - Dynamically assigns classes based on the current state.
     * @computed {string} minHeight - Sets the minimum height of the component based on the current state.
     * @computed {string} caseId - Retrieves the case ID from the route or props.
     * @computed {UserSettings} userSettings - Retrieves the user settings from the store.
     * @computed {BusyCaseListItem[]} busyCaseList - Retrieves the busy case list from the store.
     * @computed {UserData} userData - Retrieves the user data from the store.
     * @computed {ErrorState} errorState - Retrieves the current error state.
     * @computed {boolean} isFullScreen - Determines if the component is in full-screen mode.
     * @computed {boolean} readOnly - Determines if the component is read-only.
     *
     *
     * @author Viktor Blomstergren
     * @date 2025-02-13 - Initial version
     * @updated 2025-02-22 - Refactor new collapsible view
     */

    import {
        defineComponent,
        getCurrentInstance,
        onMounted,
        reactive,
        provide,
        computed,
        ref,
        inject,
        onBeforeUnmount,
    } from 'vue';

    import CreateCommentsToolbar from '@/components/Cases/CreateComments/CreateCommentsToolbar.vue';
    import CreateCommentsHeader from '@/components/Cases/CreateComments/CreateCommentsHeader.vue';
    import CreateCommentsAction from '@/components/Cases/CreateComments/CreateCommentsAction.vue';
    import CreatecommentsRichTextEditor from '@/components/Cases/CreateComments/CreateCommentsRichTextEditor.vue';

    import { CreateCommentsService } from '@/services/createComments/createComments';
    import { useCommentsStore } from '@/store/pinia/comments.store';

    import { useLocalStorage, useLocalStorageContent } from '@/composables/useLocalStorage';
    import { changeImgSrcToDataSrc } from '@/helpers/comments/comments.helper';

    import type { Socket } from 'socket.io-client';

    import type {
        BusyCaseListItem,
        UserData,
        CommentData,
        CreateCommentsState,
        FileData,
        UserSettings,
        Global,
        StoreDispatch,
    } from '@/types/createComments.state';
    import type { Toasted } from 'vue-toasted';

    import * as api from '@/api/createComments/createComments.api';
    import * as shadowDom from '@/directives/shadowDom';

    import i18n from '@/i18n';

    enum ErrorState {
        NONE = 'NONE',
        CASE_ID_MISSING = 'CASE_ID_MISSING',
        LOAD_FAILED = 'LOAD_FAILED',
        USER_SETTINGS_MISSING = 'USER_SETTINGS_MISSING',
        BUSY_CASE_LIST_MISSING = 'BUSY_CASE_LIST_MISSING',
        USER_DATA_MISSING = 'USER_DATA_MISSING',
    }

    export default defineComponent({
        name: 'CreateCommentsTab',

        components: {
            CreateCommentsToolbar,
            CreateCommentsHeader,
            CreateCommentsAction,
            CreatecommentsRichTextEditor,
        },

        props: {
            caseId: {
                type: String,
                required: false,
            },

            attachedConversations: {
                type: Array<number>,
                required: false,
                default: () => [],
            },
        },

        sockets: {
            changeInCurrentCase(data: {
                type: string;
                data: { case: { status: String; queueId: number; clientId: string } };
            }) {
                if (data.type === 'UPDATE_CASE') {
                    // @ts-ignore - Use socket.io later should fix this problem
                    this.commentsState.status = data.data.case.status;
                    // @ts-ignore - Use socket.io later should fix this problem
                    this.commentsState.queueId = data.data.case.queueId;
                    // @ts-ignore - Use socket.io later should fix this problem
                    this.commentsState.clientId = data.data.case.clientId;
                }
            },
        },

        setup(props, { emit }) {
            // Store
            const commentsStore = useCommentsStore();

            // Is Full Screen
            const isFullScreen = ref(commentsStore.isFullScreenView);

            // State
            const loading = ref(false);
            const readOnly = ref(false);
            const errorState = ref<ErrorState>(ErrorState.NONE);

            // Case id from props or route
            const caseId = computed(() => props.caseId || proxy.$route.params.caseId);

            // Current instance
            const currentInstance = getCurrentInstance();

            if (!currentInstance) {
                throw new Error('Current instance is missing');
            }

            // Extract data from store
            const userSettings = computed(() => proxy.$store.state.System.userSettings as UserSettings);
            const busyCaseList = computed(() => proxy.$store.state.Cases.busyCaseList as BusyCaseListItem[]);
            const userData = computed(() => proxy.$store.state.Auth.userObject as UserData);

            // Instance
            const proxy = currentInstance.proxy as unknown as {
                $store: any;
                $route: any;
            };

            // Injected dependencies
            const socket = inject('$socket') as Socket | undefined;
            const toasted = inject('$toasted') as Toasted | undefined;
            const global = inject('$global') as Global | undefined;
            const storeDispatch = proxy.$store.dispatch as StoreDispatch | undefined;

            // Check error state
            const checkErrorState = () => {
                if (!userSettings) return ErrorState.USER_SETTINGS_MISSING;
                if (!busyCaseList.value) return ErrorState.BUSY_CASE_LIST_MISSING;
                if (!userData) return ErrorState.USER_DATA_MISSING;
                if (!caseId) return ErrorState.CASE_ID_MISSING;
                if (!socket) return ErrorState.LOAD_FAILED;
                if (!toasted) return ErrorState.LOAD_FAILED;
                if (!global) return ErrorState.LOAD_FAILED;
                if (!storeDispatch) return ErrorState.LOAD_FAILED;
                return ErrorState.NONE;
            };

            const errorMessage = computed(() => {
                switch (errorState.value) {
                    case ErrorState.CASE_ID_MISSING:
                        return i18n.t('error.caseIdMissing');
                    case ErrorState.LOAD_FAILED:
                        return i18n.t('error.loadFailed');
                    case ErrorState.USER_SETTINGS_MISSING:
                        return i18n.t('error.userSettingsMissing');
                    case ErrorState.BUSY_CASE_LIST_MISSING:
                        return i18n.t('error.busyCaseListMissing');
                    case ErrorState.USER_DATA_MISSING:
                        return i18n.t('error.userDataMissing');
                    default:
                        return '';
                }
            });

            errorState.value = checkErrorState();

            if (errorState.value !== ErrorState.NONE) {
                return {
                    errorMessage,
                    errorState,
                    ErrorState,
                    isFullScreen,
                };
            }

            // Assert dependencies
            if (!storeDispatch || !socket || !toasted || !global) {
                throw new Error('Missing dependencies');
            }

            // Services
            const service = new CreateCommentsService(
                global,
                toasted,
                i18n,
                socket,
                api,
                shadowDom,
                document,
                localStorage,
                window,
                changeImgSrcToDataSrc,
                useLocalStorage,
                useLocalStorageContent,
                storeDispatch
            );

            // Computed user states
            const inCase = computed(() =>
                busyCaseList.value.some((item) => item.userId === userData.value.userId && caseId.value === item.id)
            );

            const isActiveUser = computed(() => {
                const caseItem = busyCaseList.value.find((item) => caseId.value === item.id);
                return caseItem ? (caseItem.activeUser ? caseItem.userId === userData.value.userId : true) : true;
            });

            // Check if the user is in the tab
            if (proxy.$route.name === 'CaseTab') {
                commentsStore.setFullScreenView();
            }

            const getReactiveCommentsState = () => {
                return reactive<Partial<CreateCommentsState>>({
                    ...service.resetCommentState({}),
                    ...service.useCreateCommentState(caseId.value),

                    // Fixed states
                    inCase: computed(() => inCase.value),
                    caseId: computed(() => caseId.value),
                    isFullScreen: computed(() => isFullScreen.value),
                    attachedConversations: computed(() => props.attachedConversations),

                    // User settings
                    showSignature: computed(() => userSettings.value.texteditor.showSignature.active),
                    editSubject: computed(() => userSettings.value.cases.editSubject.active),
                    closeOnSendClose: computed(() => userSettings.value.cases.closeOnSendClose.active),
                    allwaysAttachThreadedFiles: computed(
                        () => userSettings.value.cases.attachConversationSettings?.allwaysAttachThreadedFiles?.active
                    ),
                    attachConversationDefault: computed(
                        () => userSettings.value.cases.attachConversationSettings?.attachConversation?.active
                    ),
                    shortCutsSend: computed(() => userSettings.value.cases.shortCuts.send.active),
                    shortCutsSendClose: computed(() => userSettings.value.cases.shortCuts.sendAndClose.active),
                    maxFileSize: computed(() => 24_000_000), // Should be a setting
                    collapseEditor: computed(() => userSettings.value.texteditor.collapseEditor.active ?? false),
                } as unknown as CreateCommentsState); // Can cast this here cause VUE will automatically unwrap the Refs
            };

            // Shared state across components
            let commentsState = getReactiveCommentsState();

            // Assign methods to shared state
            Object.assign(commentsState, {
                emitSendClose: async () => {
                    sendComment(true);
                },

                emitSend: async () => {
                    sendComment();
                },

                emitCreatePDF: async () => {
                    service.generatePDF(caseId.value, commentsState.subject);
                },

                emitDockWindow: () => {
                    service.dockWindow(caseId.value, 1000, 600, 100, 50);
                    emit('close');
                },

                emitClose: () => {
                    emit('close');
                },

                emitGetSignatureTemplate: async (id: number) => {
                    return await service.getSignatureTemplate(id);
                },

                emitUploadNewFiles: async (files: FileData[], currentFiles: number[]) => {
                    return await service.uploadNewFiles(files, currentFiles);
                },

                emitForward: async () => {
                    service.forwardCase(commentsState, caseId.value);
                },

                emitAttachConversation: async (value: boolean) => {
                    commentsState.attachConversation = value;
                    emit('attachConversation', value);
                },

                emitUncheckAllConversation: async () => {
                    emit('uncheckAllConversation');
                },

                emitCheckAllConversations: async () => {
                    emit('checkAllConversations');
                },
            });

            // Methods
            const sendComment = async (closeCaseInDB = false) => {
                commentsState.disabled = true;
                commentsState[closeCaseInDB ? 'sendingClose' : 'sending'] = true;

                try {
                    const result = await service.postComment(
                        commentsState,
                        caseId.value,
                        userData.value,
                        closeCaseInDB
                    );

                    if (result === null) {
                        service.showToast('error', 'createComments.failed');
                        return;
                    }

                    if ('validationFailed' in result && result.validationFailed) {
                        return;
                    }

                    if ('confirmed' in result && result.confirmed === false) {
                        return;
                    }

                    if ('status' in result && result.status !== 200) {
                        service.showToast('error', 'createComments.failed');
                        return;
                    }

                    // reset the comment state
                    commentsState = service.resetCommentState(commentsState);
                    service.showToast('success', 'createComments.success');

                    if (commentsState.closeOnSendClose && closeCaseInDB) {
                        service.closeCase();
                        emit('close');
                    } else {
                        emit('close');
                    }

                    if (isFullScreen.value) {
                        service.closeWindow();
                    }
                } catch (error) {
                    console.error('Error in sendComment', error);
                    service.showToast('error', 'createComments.failed');
                } finally {
                    commentsState[closeCaseInDB ? 'sendingClose' : 'sending'] = false;
                    commentsState.disabled = false;
                    await getData();
                }
            };

            const getData = async () => {
                loading.value = true;
                const result = await service.getCreateCommentsData(caseId.value);

                if (!result) {
                    errorState.value = ErrorState.LOAD_FAILED;
                    return {
                        errorState,
                        ErrorState,
                    };
                }

                commentsState = service.setDefaultCommentState(commentsState, result);

                loading.value = false;
            };

            const insert = async (content: string) => {
                commentsState.content += '<br>' + content;
            };

            const answerAll = async (item: CommentData) => {
                await handleAnswer(item, 'all');
            };

            const answer = async (item: CommentData) => {
                await handleAnswer(item, 'one');
            };

            const handleAnswer = async (item: CommentData, answerType: 'one' | 'all') => {
                await getData();
                const copyItem = structuredClone(item);
                commentsState.repliedComment = item.id;

                const { toRecipients, ccRecipients, bccRecipients, from } = service.getAnswerEmailAdresses(
                    copyItem,
                    commentsState.systemEmails,
                    answerType
                );

                commentsState.subject = item.subject;
                commentsState.toRecipients = toRecipients;
                commentsState.ccRecipients = ccRecipients;
                commentsState.bccRecipients = bccRecipients;
                commentsState.from = from;
                commentsState.external = true;
                commentsState.showCcAndBcc = true;
            };

            // Computed classes for styling
            const createCommentsClass = computed(() => {
                const classMap = {
                    'create-comments-full-screen': isFullScreen.value,
                    'create-comments-collapsed': !isFullScreen.value,
                    'read-only': !isActiveUser.value || commentsState.status === 'InQueue',
                    'in-case': inCase.value && isFullScreen.value,
                    'not-in-case': !inCase.value && isFullScreen.value,
                };

                return Object.entries(classMap)
                    .filter(([, condition]) => condition)
                    .map(([className]) => className)
                    .join(' ');
            });

            // Key down event
            const handleKeyDown = (event: KeyboardEvent) => {
                service.handleKeyDown(commentsState, sendComment, event);
            };

            // Lifecycle hooks
            onMounted(async () => {
                if (commentsState.isFullScreen) {
                    service.subscribeToCase(caseId.value, userData.value, busyCaseList.value);
                }

                await getData();

                if (commentsState.attachConversationDefault && commentsState.attachConversation === null) {
                    commentsState.attachConversation = true;
                    emit('attachConversation', true);

                    proxy.$store.dispatch('Forwarder/setShowAttachCheckBoxes', true);
                }

                if (commentsState.attachConversation) {
                    emit('attachConversation', commentsState.attachConversation);

                    proxy.$store.dispatch('Forwarder/setShowAttachCheckBoxes', commentsState.attachConversation);
                }

                if (commentsState.repliedComment) {
                    emit('repliedComment', commentsState.repliedComment);
                }

                window.addEventListener('keydown', handleKeyDown);
            });

            onBeforeUnmount(() => {
                if (commentsState.isFullScreen) {
                    service.unsubscribeFromCase(caseId.value);
                }

                window.removeEventListener('keydown', handleKeyDown);
            });

            // Min height for the component
            const minHeight = computed(() => {
                if (commentsState.collapseEditor && !isFullScreen.value) {
                    return 'auto';
                }

                return isFullScreen.value ? '100vh' : commentsState.minHeight + 'px';
            });

            // Provide shared state
            provide('commentsState', commentsState);

            return {
                errorMessage,
                commentsState,
                createCommentsClass,
                isFullScreen,
                readOnly,
                ErrorState,
                errorState,
                busyCaseList,
                inCase,
                minHeight,
                answerAll,
                answer,
                insert,
            };
        },
    });
</script>

<style scoped>
    .create-comments {
        background-color: white;
        position: relative;
    }

    .read-only {
        position: relative;
        pointer-events: none;
        opacity: 1;
    }

    .read-only::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(128, 128, 128, 0.25);
        z-index: 1;
    }

    .create-comments-full-screen {
        display: grid;
        grid-template-rows: auto 1fr auto;
        height: 100vh;
    }

    .create-comments-collapsed {
        display: grid;
        grid-template-rows: auto auto 1fr auto;
    }

    .create-comments-error {
        display: flex;
        place-items: center;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 300px;
        background-color: white;
        border-top: 1px solid var(--v-gray3-base);
    }

    .create-comments-error-fullscreen {
        display: flex;
        place-items: center;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        background-color: white;
    }

    .create-comments-error h1 {
        color: black;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
    }

    .create-comments-error p {
        color: #6c6c6c;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
    }

    .mdi {
        font-size: 40px;
        color: #aaa;
    }

    .loading {
        display: flex;
        place-items: center;
        justify-content: center;
    }

    .in-case {
        border: 4px solid var(--v-primary-base);
    }

    .not-in-case {
        border: 4px solid var(--v-color1-base);
    }
</style>
