import axios from 'axios';

function divertOptions() {
    return axios.get('tapi/presence/hvd');
}

export interface SetPresencePayload {
    userId: number;
    hvdId: number;
    validUntil: string;
}

function setPresence(data: SetPresencePayload) {
    return axios.put(`tapi/users/${data.userId}/hvd`, data);
}

function getPresence(userId: number) {
    return axios.get(`tapi/users/${userId}/presence`);
}

export default {
    divertOptions,
    setPresence,
    getPresence,
};
