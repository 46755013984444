export const states = {
    INIT: 'init',
    LOADING: 'loading',
    LOADED: 'loaded',
    ERROR: 'error',
    NOT_FOUND: 'not_found',
    NOT_ALLOWED: 'not_allowed',
} as const;

export type State = (typeof states)[keyof typeof states];

export const caseStatuses = {
    OPEN: 'Open',
    ONGOING: 'Ongoing',
    CLOSED: 'Closed',
    IN_QUEUE: 'InQueue',
    DELETED: 'Deleted',
    SNOOZED: 'Snoozed',
} as const;

export type CaseStatus = (typeof caseStatuses)[keyof typeof caseStatuses];

export const statusIcons = {
    Closed: 'mdi-tray-minus',
    Open: 'mdi-tray',
    Ongoing: 'mdi-tray-full',
    Deleted: 'mdi-tray-remove',
    InQueue: 'mdi-arrow-down',
    Snoozed: 'mdi-timer-alert-outline',
} as const;

export const statusColors = {
    Closed: 'color2',
    Open: 'color4',
    Ongoing: 'color1',
    Deleted: 'gray1',
    InQueue: 'color3',
    Snoozed: 'accent7',
} as const;

export const events = {
    NEW_CASE: 'NEW_CASE',
    UPDATE_CASE: 'UPDATE_CASE',
    NEW_COMMENT: 'NEW_COMMENT',
    UPDATE_COMMENT: 'UPDATE_COMMENT',
    OTHER_USER_UPDATED_CASE: 'OTHER_USER_UPDATED_CASE',
    DELETE_COMMENT: 'DELETE_COMMENT',
} as const;

export type Event = (typeof events)[keyof typeof events];

export const caseKeys = {
    CATEGORIES: 'categories',
    STATUS: 'status',
    QUEUE: 'queueId',
    USER: 'userId',
    SLA: 'sla',
    ORDER: 'textField',
    SNOOZE: 'snooze',
    TEXT: 'textField',
} as const;

export type CaseKey = (typeof caseKeys)[keyof typeof caseKeys];

export const positionTypes = {
    LEFT: 'left',
    RIGHT: 'right',
} as const;

export type PositionType = (typeof positionTypes)[keyof typeof positionTypes];

export const categories = {
    NO_CATEGORY: 0,
} as const;

export const callbackTypes = {
    CALLBACK_MANUAL: 'callback_manual',
    CALLBACK_AUTO: 'callback_auto',
} as const;

export type CallbackType = (typeof callbackTypes)[keyof typeof callbackTypes];

export const caseTypes = {
    CALL: 'call',
    CHAT: 'chat',
    EMAIL: 'email',
    SMS: 'sms',
    SOCIAL: 'social',
    CALLBACK: 'callback',
} as const;

export type CaseType = (typeof caseTypes)[keyof typeof caseTypes];

export const icons = {
    CALL: {
        totalCalls: 'mdi-phone-outline',
        missedCalls: 'mdi-phone-hangup-outline',
        answeredCalls: 'mdi-phone-check-outline',
        transferedCalls: 'mdi-phone-outgoing',
        offeredCallbacks: 'mdi-phone-outgoing',
        numberInQueue: 'mdi-account-outline',
        longestWaiting: 'mdi-clock-outline',
        averageTimeInQueue: 'mdi-clock-outline',
        answeredCallsPercentage: 'mdi-phone-outline',
    },
    CHAT: {
        totalCalls: 'mdi-chat-outline',
        missedCalls: 'mdi-chat-minus-outline',
        answeredCalls: 'mdi-chat-plus-outline',
        numberInQueue: 'mdi-account-outline',
        longestWaiting: 'mdi-clock-outline',
        averageTimeInQueue: 'mdi-clock-outline',
        answeredCallsPercentage: 'mdi-chat-outline',
    },
    EMAIL: {
        totalCalls: 'mdi-email-outline',
        missedCalls: 'mdi-email-minus-outline',
        answeredCalls: 'mdi-email-plus-outline',
        numberInQueue: 'mdi-account-outline',
        longestWaiting: 'mdi-clock-outline',
        averageTimeInQueue: 'mdi-clock-outline',
        answeredCallsPercentage: 'mdi-email-outline',
    },
    SMS: {
        totalCalls: 'mdi-message-text-outline',
        missedCalls: 'mdi-message-outline',
        answeredCalls: 'mdi-forum-outline',
        numberInQueue: 'mdi-account-outline',
        longestWaiting: 'mdi-clock-outline',
        averageTimeInQueue: 'mdi-clock-outline',
        answeredCallsPercentage: 'mdi-message-text-outline',
    },
    CALLBACK: {
        totalCalls: 'mdi-phone-outline',
        missedCalls: 'mdi-phone-hangup-outline',
        answeredCalls: 'mdi-phone-check-outline',
        offeredCallbacks: 'mdi-phone-outgoing',
        numberInQueue: 'mdi-account-outline',
        longestWaiting: 'mdi-clock-outline',
        averageTimeInQueue: 'mdi-clock-outline',
        answeredCallsPercentage: 'mdi-phone-outline',
    },
    SOCIAL: {
        totalCalls: 'mdi-chat-outline',
        missedCalls: 'mdi-chat-minus-outline',
        answeredCalls: 'mdi-chat-plus-outline',
        numberInQueue: 'mdi-account-outline',
        longestWaiting: 'mdi-clock-outline',
        averageTimeInQueue: 'mdi-clock-outline',
        answeredCallsPercentage: 'mdi-chat-outline',
    },
} as const;

export const communicationTypes = {
    EMAIL: 'email',
    SMS: 'sms',
    CALL: 'call',
    VIDEO: 'video',
    FORM: 'form',
} as const;

export type CommunicationType = (typeof communicationTypes)[keyof typeof communicationTypes];

export const usersSpecialCases = {
    ALLUSERS: -1,
    UNASSIGNED: -2,
} as const;

export const deletionDialogResponses = {
    CANCEL: 'CANCEL',
    DELETE_LATER: 'DELETE_LATER',
    DELETE_NOW: 'DELETE_NOW',
} as const;

export type DeletionDialogResponse = (typeof deletionDialogResponses)[keyof typeof deletionDialogResponses];

export const changeMultipleCasesOptions = {
    USER: 'user',
    STATUS: 'status',
    CATEGORY: 'category',
    QUEUE: 'queue',
} as const;

export type ChangeMultipleCasesOption = (typeof changeMultipleCasesOptions)[keyof typeof changeMultipleCasesOptions];

export const numberOfDeletions = {
    MIN: 1,
    MAX: 250,
} as const;
