import axios from 'axios';

function getExternalData() {
    return axios.get('integration/synchronization/1337');
}

export interface DeploySyncPayload {
    settings: {
        deleteOld: boolean;
    };
    dataToSynk: {
        OCR: string;
        avieringssatt?: string | null;
        telefon?: string | null;
        email?: string | null;
        contactid?: string | null;
        efternamn?: string | null;
        fornamn?: string | null;
        mobil?: string | null;
        personnummer?: string | null;
        saldo?: string | null;
        senastbetalt?: string | null;
        url?: string | null;
    }[];
    sms?: {
        active: boolean;
        smsText?: string | null;
    };
}

function deploySync(payload: DeploySyncPayload) {
    return axios.post('integration/synchronization/1337', payload);
}
function getHistory() {
    return axios.get('integration/history/1337');
}
/**
 * BANKID
 */
function bankidAuthenticate(data: unknown) {
    return axios.post('integrations/bankid/authenticate', data);
}

function isBankIdActive() {
    return axios.get('integrations/bankid/active');
}

function getActiveStates() {
    return axios.get('system/integrations/active-state');
}

/**
 * IDENTITYPROVIDER
 */
function getIdentityProviderInstances(type: string) {
    return axios.get(`/system/integrations/${type}/instances`);
}

/**
 * DEDU
 */

export interface GetDeduTaskPayload {
    search?: string;
    clientId: string;
    page?: number;
    pageSize?: number;
    status?: string[];
}

function getDeduTasks(payload: GetDeduTaskPayload) {
    return axios.get('integrations/dedu/tasks', { params: payload });
}

function getDeduTask(id: string) {
    return axios.get(`integrations/dedu/tasks/${id}`);
}

export interface CreateDeduTaskPayload {
    buildingEntity: {
        id: number;
        type: string;
    };
    professionId: number;
    description: string;
    reportedByEmail?: string;
    reportedByName: string;
    reportedByPhone?: string;
    taskPriorityId?: number;
    taskTypeId?: number;
    taskSubCategoryId?: number;
    sourceId?: number;
    placement?: string;
}

function createDeduTask(data: CreateDeduTaskPayload) {
    return axios.post('integrations/dedu/tasks', data);
}

export interface UpdateDeduTaskPayload {
    taskId: string;
    buildingEntity: {
        id: number;
        type: string;
    };
    professionId: number;
    description: string;
    reportedByEmail?: string;
    reportedByName: string;
    reportedByPhone?: string;
    taskPriorityId?: number;
    taskTypeId?: number;
    taskSubCategoryId?: number;
    sourceId?: number;
    placement?: string;
}

function updateDeduTask(data: UpdateDeduTaskPayload) {
    return axios.put(`integrations/dedu/tasks/${data.taskId}`, data);
}

export interface GetDeduBuildingEntityItemsPayload {
    type: string[];
    page?: number;
    pageSize?: number;
    search?: string;
    structureId?: number;
    spatiSystemId?: number;
    elementId?: number;
    spaceId?: number;
}

function getDeduBuildingEntityItems(payload: GetDeduBuildingEntityItemsPayload) {
    return axios.get('integrations/dedu/building-entity-items', {
        params: {
            ...payload,
            type: payload.type.toString(), // toString or else its an array *shrug*
        },
    });
}

export interface GetDeduProfessionsPayload {
    structureId: number;
    page?: number;
    pageSize?: number;
    search?: string;
}

function getDeduProfessions({ structureId }: Partial<GetDeduProfessionsPayload>) {
    return axios.get('integrations/dedu/professions', {
        params: {
            structureId,
        },
    });
}

function getDeduTaskTypes() {
    return axios.get('integrations/dedu/task-types');
}

function getDeduTaskCategories() {
    return axios.get('integrations/dedu/task-categories');
}

function getDeduTaskSubCategories() {
    return axios.get('integrations/dedu/task-subcategories');
}
function getDeduTaskPriorities() {
    return axios.get('integrations/dedu/task-priorities');
}
function getDeduTaskSources() {
    return axios.get('integrations/dedu/task-sources');
}

// MADHAT
export interface GetOrdersPayload {
    type: string;
    caseId: string;
}

function getOrders({ type, caseId }: GetOrdersPayload) {
    return axios.get(`integrations/${type}/orders`, { params: { caseId } });
}

export interface GetOrderPayload {
    type: string;
    caseId: string;
    vismaOrderNo: string;
    webOrderNo: string;
}

function getOrder(payload: GetOrderPayload) {
    const { type, ...args } = payload;
    return axios.get(`integrations/${type}/order`, { params: { ...args } });
}

function getPortals(type: string) {
    return axios.get(`integrations/${type}/portals`);
}

export interface AddPortalPayload {
    portalId: string;
    queueId: string;
    name: string;
    siteId: string;
}

function addPortal(payload: AddPortalPayload) {
    return axios.post('integrations/madhat/portals', payload);
}

export interface UpdatePortalPayload {
    id: string;
    portalId: string;
    queueId: string;
    name: string;
    siteId: string;
}

function updatePortal(payload: UpdatePortalPayload) {
    return axios.put(`integrations/madhat/portals/${payload.id}`, payload);
}

function deletePortal(id: string) {
    return axios.delete(`integrations/madhat/portals/${id}`);
}
function getOrderIntegrations() {
    return axios.get('integrations/orders');
}

/* PINDELIVERY */
function getPindeliveryReports(orderId: string) {
    return axios.get(`integrations/pindelivery/reports?orderId=${orderId}`);
}
/**
 * LIME
 */
function getQueues() {
    return axios.get('/integration/lime/queues');
}

function setSelectedQueue(payload: unknown) {
    return axios.post('/integration/lime/queues', payload);
}

// TODO: Unknown is this in use? Not validated correctly in backend
function createCaseInIntegration(payload: { source: string }) {
    const { source, ...rest } = payload;
    return axios.post(`/integration/${source}/case`, rest);
}

/**
 * SYSTEM INTEGRATIOMS
 */

function getSystemIntegrations() {
    return axios.get('system/integrations');
}
function getCaseButtonIntegrations() {
    return axios.get('system/integrations/case-buttons');
}

function getSystemIntegrationKeys() {
    return axios.get('system/integrations/keys');
}

// TODO: Unknown is this in use? Not validated correctly in backend
function addKeyToIntegration(payload: { integrationId: string }) {
    return axios.put(`system/integrations/${payload.integrationId}/key`, payload);
}

function getSystemIntegrationData(id: string) {
    return axios.get(`system/integrations/${id}`);
}

// TODO: Unknown is this in use? Not validated correctly in backend
function updateSystemIntegration(payload: { integrationId: string }) {
    const { integrationId, ...rest } = payload;
    return axios.put(`system/integrations/${integrationId}`, rest);
}

// TODO: Unknown is this in use? Not validated correctly in backend
function updateSystemIntegrationInstance(payload: { instanceId: string }) {
    const { instanceId, ...rest } = payload;
    return axios.put(`system/integrations/instances/${instanceId}`, rest);
}

export interface SaveSystemIntegrationInstanceSettingsPayload {
    instanceId: string;
    values: Record<string, unknown>;
}

function saveSystemIntegrationInstanceSettings(payload: SaveSystemIntegrationInstanceSettingsPayload) {
    return axios.put(`system/integrations/instances/${payload.instanceId}/settings`, { settings: payload.values });
}

export interface CreateInstancePayload {
    integrationId: string;
    name: string;
}

function createInstance(payload: CreateInstancePayload) {
    return axios.post(`system/integrations/${payload.integrationId}/instances`, payload);
}

function deleteInstance(instanceId: string) {
    return axios.delete(`system/integrations/instances/${instanceId}`);
}

export interface ChangeSystemIntegrationPayload {
    id: string;
    active: boolean;
    settings: Record<string, unknown>;
}

function changeSystemIntegration(payload: ChangeSystemIntegrationPayload) {
    return axios.put(`system/integrations/${payload.id}`, payload);
}

/* Sveriges A-kassor */
function sverigesAkassorGetMember(id: string) {
    return axios.get(`integrations/sveriges-akassor/member?ssn=${id}`);
}
function sverigesAkassorOpenMember(id: string) {
    return axios.get(`integrations/sveriges-akassor/open/member?ssn=${id}`);
}
// * Kuralink
function initBooking() {
    return axios.post('integrations/kuralink/init-booking');
}

function getI2IResourceUrl(clientId: unknown) {
    return axios.get('integrations/i2i/resource-url', { params: { clientId } });
}

function getI2IPolicy(id: unknown) {
    return axios.get(`integrations/i2i/policy/${id}`);
}
function getI2ICustomerClaims(id: unknown) {
    return axios.get(`integrations/i2i/customer/${id}/claims`);
}
function getI2ICustomerQuotes(id: unknown) {
    return axios.get(`integrations/i2i/customer/${id}/quotes`);
}

export default {
    getExternalData,
    deploySync,
    getHistory,
    bankidAuthenticate,
    isBankIdActive,
    getSystemIntegrations,
    getCaseButtonIntegrations,
    getSystemIntegrationData,
    updateSystemIntegration,
    updateSystemIntegrationInstance,
    changeSystemIntegration,
    getSystemIntegrationKeys,
    addKeyToIntegration,
    getDeduTasks,
    getDeduTask,
    createDeduTask,
    updateDeduTask,
    getDeduBuildingEntityItems,
    getDeduProfessions,
    getDeduTaskTypes,
    getDeduTaskCategories,
    getDeduTaskSubCategories,
    getDeduTaskPriorities,
    getDeduTaskSources,
    getQueues,
    getIdentityProviderInstances,
    setSelectedQueue,
    createCaseInIntegration,
    getOrders,
    getOrder,
    getPortals,
    addPortal,
    updatePortal,
    deletePortal,
    getOrderIntegrations,
    getActiveStates,
    saveSystemIntegrationInstanceSettings,
    createInstance,
    deleteInstance,
    getPindeliveryReports,
    sverigesAkassorGetMember,
    sverigesAkassorOpenMember,
    initBooking,
    getI2IResourceUrl,
    getI2IPolicy,
    getI2ICustomerClaims,
    getI2ICustomerQuotes,
};
