import axios from 'axios';

function getSettings(configKeys: string) {
    return axios.get(`/system/configurations/?configKeys=${configKeys}`);
}

// TODO: This should be refactored, the api isnt clear and static, depending on if you want to update user data config or not the api changes.
function updateDoc(payload: unknown) {
    return axios.put('/system/settings', payload);
}

export interface UpdateSingleValuePayload {
    keysToChange: {
        key: string;
        value: string | number | boolean;
    }[];
    route: string;
}

function updateSingleValue(payload: UpdateSingleValuePayload) {
    const { keysToChange, route } = payload;
    return axios.put(`/system/settings/${route}`, { keysToChange });
}

export default {
    getSettings,
    updateDoc,
    updateSingleValue,
};
