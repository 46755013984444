<template>
    <div class="c-input" :class="inputClass" :style="inputStyle">
        <label v-if="label" :for="inputId" class="c-input__label">{{ label }}</label>
        <input
            v-bind="inputProps"
            :id="inputId"
            v-model="internalValue"
            class="c-input__field"
            :type="type"
            :placeholder="placeholder"
            @input="debouncedHandleInput"
        />
        <span v-if="errorMessage" class="c-input__error">{{ errorMessage }}</span>
    </div>
</template>

<script lang="ts">
    import { defineComponent, computed, ref, watch } from 'vue';
    import { debouncer } from '@/utils/debouncer';

    export default defineComponent({
        name: 'CInput',
        props: {
            value: {
                type: [String, Number],
                default: '',
            },
            label: {
                type: String,
                default: '',
            },
            type: {
                type: String,
                default: 'text',
            },
            placeholder: {
                type: String,
                default: '',
            },
            errorMessage: {
                type: String,
                default: '',
            },
            inputClass: {
                type: String,
                default: '',
            },
            inputStyle: {
                type: Object,
                default: () => ({}),
            },
            id: {
                type: String,
                default: () => `input-${Math.random().toString(36).substring(2, 9)}`,
            },
            debounce: {
                type: Number,
                default: 0,
            },
        },
        setup(props, { emit }) {
            const inputId = ref(props.id);
            const internalValue = ref(props.value);

            watch(
                () => props.value,
                (newValue) => {
                    internalValue.value = newValue;
                }
            );

            const handleInput = (...args: unknown[]) => {
                const event = args[0] as Event;
                const newValue = (event.target as HTMLInputElement).value;
                internalValue.value = newValue;
                emit('input', newValue);
            };

            const debouncedHandleInput = debouncer(handleInput, props.debounce);

            const inputProps = computed(() => ({
                class: 'c-input__field',
                placeholder: props.placeholder,
            }));

            return {
                inputProps,
                internalValue,
                inputId,
                debouncedHandleInput,
            };
        },
    });
</script>

<style scoped>
    .c-input {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .c-input__label {
        font-size: 14px;
        color: #333;
    }

    .c-input__field {
        padding: 10px;
        font-size: 14px;
        border: 1px solid #ccc;
        border-radius: 4px;
        transition: border-color 0.2s;
    }

    .c-input__field:focus {
        border-color: #007bff;
        outline: none;
    }

    .c-input__error {
        font-size: 12px;
        color: red;
    }
</style>
