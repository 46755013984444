import axios from 'axios';
import DedupeRequest from '../../entity/DedupeRequest';

function getUserCompanyGroups(userId: number) {
    return axios.get(`groups/user/${userId}`);
}
function getGroupWithUsers(groupId: number) {
    return axios.get(`/groups/${groupId}/extended-users`);
}

const getAllCompanyGroupsDedupe = new DedupeRequest(() => {
    return axios.get('/groups');
}, 1000);

function getAllCompanyGroups() {
    // @ts-ignore <This accepts an object but we just send empty?>
    return getAllCompanyGroupsDedupe.send();
}

function getGroupTooltipInfo(groupId: number) {
    return axios.get(`/groups/${groupId}/tooltip`);
}

export interface CreateCompanyGroupPayload {
    userId: number;
    groupName: string;
    description: string;
}

function createCompanyGroup(payload: CreateCompanyGroupPayload) {
    return axios.post('/groups', payload);
}

export interface AddMembersToCompanyGroupPayload {
    groupId: number;
    members: { userId: number }[];
}

function addMembersToCompanyGroup(payload: AddMembersToCompanyGroupPayload) {
    return axios.put(`groups/${payload.groupId}`, payload);
}

export interface SaveGroupDescriptionPayload {
    groupId: number;
    description: string;
}

function saveGroupDescription(payload: SaveGroupDescriptionPayload) {
    return axios.put(`groups/${payload.groupId}/description`, payload);
}

function deleteCompanyGroup(groupId: number) {
    return axios.delete(`groups/${groupId}`);
}
function deleteUserFromGroup({ groupId, userId }: { groupId: number; userId: number }) {
    return axios.delete(`/groups/${groupId}/users/${userId}`);
}
function deleteAllUsersFromGroup(groupId: number) {
    return axios.delete(`/groups/all/${groupId}/users`);
}

export interface EditGroupNamePayload {
    groupId: number;
    name: string;
}

function editGroupName(payload: EditGroupNamePayload) {
    return axios.put(`groups/${payload.groupId}/name`, payload);
}
function getGroupsBasics() {
    return axios.get('/groups/basics');
}
function getGroupSettings(groupId: number) {
    return axios.get(`/groups/${groupId}/settings`);
}

export interface GetUsersInGroupPayload {
    groupId: number;
    page: number;
    search: string;
}

function getUsersInGroup(payload: GetUsersInGroupPayload) {
    return axios.get(`/groups/${payload.groupId}/users?page=${payload.page}&search=${payload.search}`);
}

export interface GetAvailableUsersPayload {
    groupId: number;
    page: number;
    search: string;
}

function getAvailableUsers(payload: GetAvailableUsersPayload) {
    return axios.get(`/groups/available/users/${payload.groupId}?page=${payload.page}&search=${payload.search}`);
}

export interface AddMembersToGroupPayload {
    groupId: number;
    selectAll: boolean;
    excludedMembers: number[];
    members: number[];
}

function addMembersToGroup(payload: AddMembersToGroupPayload) {
    return axios.put(`/groups/members/${payload.groupId}`, payload);
}

function getGroups() {
    return axios.get('/v2/groups');
}

function getGroupsWithMemberCount() {
    return axios.get('/v2/groups/member-count');
}

export default {
    getAllCompanyGroups,
    getUserCompanyGroups,
    getGroupWithUsers,

    getGroupTooltipInfo,
    createCompanyGroup,
    saveGroupDescription,
    // updateCompanyGroup,
    deleteCompanyGroup,
    deleteUserFromGroup,
    deleteAllUsersFromGroup,
    addMembersToCompanyGroup,
    editGroupName,
    getGroupsBasics,
    getGroupSettings,
    getUsersInGroup,
    getAvailableUsers,
    addMembersToGroup,
    getGroups,
    getGroupsWithMemberCount,
};
