<template>
    <section :class="headerContainerClass">
        <v-select
            :class="{ disabled: !commentsState.external }"
            :items="commentsState.systemEmails"
            :value="commentsState.from"
            :bind="selectProps"
            :validate="true"
            class="header-from"
            v-bind="selectProps"
            @input="updateFrom"
            cy-data="create-comments-from"
        >
            <template slot="prepend-inner">
                <span class="prepend-text">{{ $t('createComments.from') }}</span>
            </template>
        </v-select>

        <section class="header-dock-window">
            <CreateCommentsRoundButton
                :iconOnly="Boolean(commentsState.collapseEditor)"
                :disabled="Boolean(commentsState.isFullScreen)"
                icon="mdi-dock-window"
                :tooltip="$t('createComments.dockWindow')"
                @click="dockWindow"
                cy-data="create-comments-dock-window"
            />
        </section>

        <section class="header-close">
            <CreateCommentsRoundButton
                :iconOnly="Boolean(commentsState.collapseEditor)"
                :disabled="Boolean(commentsState.isFullScreen)"
                icon="mdi-close"
                :tooltip="$t('createComments.close')"
                @click="close"
                cy-data="create-comments-close"
            />
        </section>

        <EmailMulti
            class="to-recipients"
            :disabled="!commentsState.external"
            :value="commentsState.toRecipients"
            :dense="true"
            :bind="comboProps"
            :validate="true"
            :queueId="commentsState.queueId"
            :class="{ 'to-recipients-cc-bcc': commentsState.showCcAndBcc }"
            @input="updateToRecipients"
            cy-data="create-comments-to"
        >
            <template slot="prepend-inner">
                <span class="prepend-text">{{ $t('createComments.to') }}</span>
            </template>

            <template slot="append-inner">
                <span v-if="showAppendInner" class="prepend-text">
                    {{ numberOfCCAndBCC + ' ' }}{{ $t('createComments.ccBccCount') }}
                </span>
            </template>
        </EmailMulti>

        <EmailMulti
            v-if="commentsState.showCcAndBcc"
            class="ccRecipients"
            :disabled="!commentsState.external"
            :value="commentsState.ccRecipients"
            :dense="true"
            :bind="comboProps"
            :validate="true"
            :queueId="commentsState.queueId"
            @input="updateCcRecipients"
            cy-data="create-comments-cc"
        >
            <template slot="prepend-inner">
                <span class="prepend-text">{{ $t('createComments.cc') }}</span>
            </template>
        </EmailMulti>

        <EmailMulti
            v-if="commentsState.showCcAndBcc"
            class="bccRecipients"
            :disabled="!commentsState.external"
            :value="commentsState.bccRecipients"
            :dense="true"
            :bind="comboProps"
            :validate="true"
            :queueId="commentsState.queueId"
            @input="updateBccRecipients"
            cy-data="create-comments-bcc"
        >
            <template slot="prepend-inner">
                <span class="prepend-text">{{ $t('createComments.bcc') }}</span>
            </template>
        </EmailMulti>

        <CreateCommentsRoundButton
            class="header-show-cc-bcc"
            :iconOnly="Boolean(commentsState.collapseEditor)"
            :disabled="!commentsState.external"
            icon="mdi-file-send-outline"
            :tooltip="
                commentsState.showCcAndBcc ? $t('createComments.hideCcAndBcc') : $t('createComments.showCcAndBcc')
            "
            :active="commentsState.showCcAndBcc"
            toggle
            @update:active="updateCCAndBCC"
            cy-data="create-comments-cc-bcc"
        />

        <CreateCommentsRoundButton
            class="header-external"
            :iconOnly="Boolean(commentsState.collapseEditor)"
            :active="commentsState.external"
            icon="mdi-comment-text-outline"
            activeIcon="mdi-email"
            :tooltip="
                commentsState.external
                    ? $t('createComments.toggleToInternalCommunication')
                    : $t('createComments.toggleToExternalCommunication')
            "
            toggle
            @update:active="(value) => (commentsState.external = value)"
            cy-data="create-comments-external"
        />

        <v-text-field
            :class="{ disabled: !commentsState.editSubject }"
            :value="commentsState.subject"
            v-bind="textFieldProps"
            dense
            class="header-subject"
            :placeholder="$t('createComments.addSubject')"
            @input="updateSubject"
            cy-data="create-comments-subject"
        />
    </section>
</template>

<script lang="ts">
    import { computed, defineComponent, inject } from 'vue';
    import EmailMulti from '@/components/Main/Selects/EmailMulti.vue';
    import CreateCommentsRoundButton from '@/components/Cases/CreateComments/CreateCommentsRoundButton.vue';
    import { CreateCommentsState } from '@/types/createComments.state';

    export default defineComponent({
        name: 'CreateCommentsToolbar',

        components: {
            EmailMulti,
            CreateCommentsRoundButton,
        },

        setup() {
            const commentsState = inject<CreateCommentsState>('commentsState');

            if (!commentsState) {
                throw new Error('commentsState is not provided');
            }

            const updateExternal = (value: boolean) => {
                commentsState.external = value;
            };

            const updateFrom = (value: string) => {
                commentsState.from = value;
            };

            const updateToRecipients = (value: string[]) => {
                commentsState.toRecipients = value;
            };

            const updateCcRecipients = (value: string[]) => {
                commentsState.ccRecipients = value;
            };

            const updateBccRecipients = (value: string[]) => {
                commentsState.bccRecipients = value;
            };

            const updateCCAndBCC = (value: boolean) => {
                commentsState.showCcAndBcc = value;
            };

            const updateSubject = (value: string) => {
                commentsState.subject = value;
            };

            const dockWindow = () => {
                commentsState.emitDockWindow();
            };

            const close = () => {
                commentsState.emitClose();
            };

            const textFieldProps = {
                elevation: 0,
                ripple: false,
                color: 'white',
                solo: true,
                'hide-details': true,
                outlined: true,
                dense: true,
                flat: true,
            };

            const comboProps = {
                elevation: 0,
                ripple: false,
                color: 'white',
                solo: true,
                'hide-details': true,
                outlined: true,
                dense: true,
                multiple: true,
                chips: true,
                flat: true,
            };

            const selectProps = {
                elevation: 0,
                ripple: false,
                color: 'white',
                solo: true,
                'hide-details': true,
                outlined: true,
                dense: true,
                chips: true,
                flat: true,
            };

            const showAppendInner = computed(() => {
                return (
                    (commentsState.ccRecipients.length > 0 || commentsState.bccRecipients.length > 0) &&
                    !commentsState.showCcAndBcc
                );
            });

            const numberOfCCAndBCC = computed(() => {
                return commentsState.ccRecipients.length + commentsState.bccRecipients.length;
            });

            const headerContainerClass = computed(() => {
                return {
                    'header-container': !commentsState.collapseEditor,
                    'header-container-collapsed': commentsState.collapseEditor,
                    disabled: commentsState.disabled,
                };
            });

            return {
                headerContainerClass,
                numberOfCCAndBCC,
                showAppendInner,
                commentsState,
                updateExternal,
                updateFrom,
                updateToRecipients,
                updateCcRecipients,
                updateBccRecipients,
                updateCCAndBCC,
                updateSubject,
                dockWindow,
                close,
                textFieldProps,
                comboProps,
                selectProps,
            };
        },
    });
</script>

<style scoped>
    .disabled {
        pointer-events: none;
        opacity: 0.5;
    }

    .prepend-text {
        font-size: 0.75rem;
        color: black;
        white-space: nowrap;
    }

    .header-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 32px 32px;
        grid-template-rows: repeat(3, auto);
        gap: 4px;
        padding: 16px;
        padding-bottom: 8px;
    }

    .header-container > * {
        align-self: center;
    }

    .header-from {
        grid-column: 1 / span 3;
    }

    .header-subject {
        grid-column: 1 / span 5;
    }

    .to-recipients {
        grid-column: 1 / span 3;
    }

    .to-recipients-cc-bcc {
        grid-column: 1 / span 1;
    }

    .header-container-collapsed {
        display: grid;
        grid-template-columns: 1fr 1fr auto auto auto auto;
        gap: 4px;
        padding: 8px 8px;
        padding-bottom: 0px;
    }

    .header-container-collapsed > * {
        align-self: center;
    }

    .header-container-collapsed > .header-from {
        grid-column: 1 / span 1;
    }

    .header-container-collapsed > .header-subject {
        grid-column: 1 / span 6;
    }

    .header-container-collapsed > .to-recipients {
        grid-row: 1;
        grid-column: 2;
    }

    .header-container-collapsed > .header-show-cc-bcc {
        grid-row: 1;
        grid-column: 3;
    }

    .header-container-collapsed > .header-external {
        grid-row: 1;
        grid-column: 4;
    }

    .header-container-collapsed > .header-dock-window {
        grid-row: 1;
        grid-column: 5;
    }

    .header-container-collapsed > .header-close {
        grid-row: 1;
        grid-column: 6;
    }

    .header-container-collapsed > .ccRecipients {
        grid-row: 2;
        grid-column: 1 / span 1;
    }

    .header-container-collapsed > .bccRecipients {
        grid-row: 2;
        grid-column: 2 / span 1;
    }

    .header-container-collapsed > .to-recipients-cc-bcc {
        grid-row: 1;
        grid-column: 2;
    }
</style>
