<template>
    <section class="button-section">
        <!-- Expanded button with text when expanded -->
        <button v-if="expanded" class="expanded-button" @click="createSubCase">
            <i class="mdi mdi-plus"></i>
            <span>
                {{ $t('create') }}
            </span>
        </button>

        <!-- Minimized button with tooltip when not expanded -->
        <tooltip v-else :content="$t('create')">
            <button class="minimized-button" @click="createSubCase">
                <i class="mdi mdi-plus"></i>
            </button>
        </tooltip>
    </section>
</template>

<script lang="ts">
    import { defineComponent, inject, getCurrentInstance } from 'vue';
    import { formTypes } from '@/enums/creator.enums';
    import i18n from '@/i18n';

    import type { Toasted } from 'vue-toasted';
    import { getCreateCommentsData } from '@/api/createComments/createComments.api';
    import type { StoreDispatch } from '@/types/createComments.state';

    export default defineComponent({
        name: 'CreateSubCaseButton',

        /**
         * Component props
         */
        props: {
            /**
             * Determines whether the button is in an expanded state.
             * When expanded, it shows text along with the icon.
             * When minimized, it only shows the icon with a tooltip.
             */
            expanded: {
                type: Boolean,
                default: false,
            },

            /**
             * The ID of the case for which a sub-case is created.
             */
            caseId: {
                type: String,
                required: true,
            },
        },

        setup(props) {
            /**
             * Inject the `toasted` instance for displaying notifications.
             * Throws an error if not provided.
             */
            const toasted = inject('$toasted') as Toasted | undefined;
            if (!toasted) throw new Error('Toasted should be provided from app');

            /**
             * Get the current Vue instance.
             * Throws an error if unavailable.
             */
            const currentInstance = getCurrentInstance();
            if (!currentInstance) throw new Error('Current instance is missing');

            const proxy = currentInstance.proxy as unknown as { $store: any; $route: any };

            /**
             * Get the Vuex store's dispatch function.
             * Throws an error if missing.
             */
            const storeDispatch = proxy.$store.dispatch as StoreDispatch | undefined;
            if (!storeDispatch) throw new Error('Store dispatch is missing');

            /**
             * Fetches comment-related data for a given case.
             */
            const getData = async (caseId: string) => {
                try {
                    return (await getCreateCommentsData(caseId)).data;
                } catch (error) {
                    console.error(error);
                    return null;
                }
            };

            /**
             * Handles creating a sub-case based on the existing case data.
             * Fetches data, then dispatches an action to open the form.
             */
            const createSubCase = async () => {
                const data = await getData(props.caseId);
                if (!data) {
                    toasted.error(i18n.t('createComments.failed').toString());
                    return;
                }

                storeDispatch(
                    'Creator/openForm',
                    {
                        id: formTypes.CREATE_SUBCASE,
                        keys: {
                            caseId: { value: props.caseId },
                            status: { value: 'Closed' },
                            from: { value: data.from },
                        },
                    },
                    { root: true }
                );
            };

            return { createSubCase };
        },
    });
</script>

<style scoped>
    i {
        font-size: 20px;
    }

    button {
        background-color: transparent;
        border: none;
        color: #000;
        cursor: pointer;
        min-height: 32px;
        transition: background-color 0.3s;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    span {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-wrap: nowrap;
    }

    .minimized-button {
        border-radius: 50%;
        width: 32px;
    }

    .expanded-button {
        border-radius: 16px;
        padding: 0 8px;
        gap: 4px;
    }

    button:hover {
        background-color: var(--Color-Neutrals-60, #d9d9d9);
    }

    button:active {
        background-color: var(--Color-Neutrals-70, #c0c0c0);
    }

    .button-section {
        display: flex;
        justify-content: center;
    }
</style>

<i18n lang="json">
{
    "en": {
        "create": "Create subcase"
    },
    "sv": {
        "create": "Skapa underärende"
    }
}
</i18n>
