import axios from 'axios';
import DedupeRequest from '@/entity/DedupeRequest';

const oneSecond = 1000;
const fiveSeconds = 5000;

function getAdminList() {
    return axios.get('admin');
}
function getSidebarContent() {
    return axios.get('app/nav');
}

function saveChatMessage(payload: { queueId: number; message: string; type: string }) {
    return axios.post('/admin/chat/messages', payload);
}

function getChatMessages(queueId: number) {
    return axios.get(`/admin/chat/messages/${queueId}`);
}

//= ===================================================//
//                  TEMPLATE CALLS                    //
//= ===================================================//

function getTemplatesByIdentifier(query: string) {
    return axios.get(`/templates/identifier/${query}`);
}

const getTemplatesDedupe = new DedupeRequest((id) => {
    return axios.get(`templates/${id}`);
}, oneSecond);

function getTemplates(id = 0) {
    // @ts-ignore <Why does this take in an object but sends a number?>
    return getTemplatesDedupe.send(id);
}

export interface Attachment {
    type: string;
    file: {
        id?: number | null;
        contentBytes?: string;
        name?: string;
        contentType: string;
        contentId?: string | null;
        size: number;
        msgId?: string | null;
        isInline?: boolean | number;
        context?: {
            id?: number | string | null;
            resource: 'comment' | 'template';
        } | null;
    }[];
}

export interface TemplatePayload {
    name: string;
    content: string;
    id: number;
    categoryId: number;
    isActive: boolean;
    identifier: string;
    language: string;
    subject: string;
    attachments: Attachment;
}

function addOrChangeTemplate(payload: TemplatePayload) {
    return axios.post('/templates', payload);
}
function deleteTemplate(id: number) {
    return axios.delete(`/templates/${id}`);
}

function getGlobalSignature() {
    // @ts-ignore <Why does this should take an object but we send nothing?>
    return getGlobalSignatureDedupe.send();
}

const getGlobalSignatureDedupe = new DedupeRequest(() => {
    return axios.get('/templates/signature/global');
}, fiveSeconds);

function setGlobalSignature(id: number) {
    return axios.put(`/templates/signature/global/${id}`);
}

function removeGlobalSignature() {
    return axios.delete('/templates/signature/global');
}

export interface ChatTemplateFilters {
    categoryIds?: number[];
    languages?: string[];
    isActive?: boolean;
    search?: string;
    attachments?: boolean;
}

function getChatTemplates(filters: ChatTemplateFilters) {
    return axios.get('/templates', {
        params: filters,
    });
}

//= ===================================================//
//                        ROLES                       //
//= ===================================================//

function getRoles() {
    return axios.get('/roles');
}

export interface RoleChangePayload {
    name: string;
    userId: number;
    roleId: number;
    roleValue: boolean;
}

function saveRoleChange(payload: RoleChangePayload) {
    return axios.put('/roles', payload);
}

//= ===================================================//
//                       Config                       //
//= ===================================================//
function getIntegrationConfig() {
    return axios.get('/admin/config');
}

export interface IntegrationTopicPayload {
    configId: number;
    key: string;
    parentKey: string;
}

function getSpecificIntegrationTopic(payload: IntegrationTopicPayload) {
    return axios.get(
        `/admin/config/specific?configId=${payload.configId}&key=${payload.key}&parentKey=${payload.parentKey}`,
    );
}

// TODO: Can't find this route in backend is this in use!
function saveIntegrationConfig(payload: unknown) {
    return axios.post('/admin/config', payload);
}

//= ===================================================//
//                       SNOOZE                       //
//= ===================================================//

function getAllTimeAlternatives() {
    return axios.get('/admin/all/times');
}

function getSnoozeOptions(caseId: string) {
    return axios.get(`/admin/snoozes/${caseId}`);
}

export interface GetSettingsSnoozesPayload {
    ChannelType: string;
    QueueId: number;
}

function getSettingsSnoozes(payload: GetSettingsSnoozesPayload) {
    return axios.get(`/admin/settings/snoozes?ChannelType=${payload.ChannelType}&QueueId=${payload.QueueId}`);
}

export interface AddSLASnoozePayload {
    SnoozeId: number;
    ChannelType: string;
    QueueId: number;
}

function addSLASnooze(payload: AddSLASnoozePayload) {
    return axios.post('/admin/settings/snoozes', payload);
}

function deleteSLASnooze(id: number) {
    return axios.delete(`/admin/settings/snoozes/${id}`);
}

export default {
    getAdminList,
    getSidebarContent,
    getTemplates,
    getTemplatesByIdentifier,
    addOrChangeTemplate,
    deleteTemplate,
    getChatTemplates,
    getIntegrationConfig,
    saveIntegrationConfig,
    getSpecificIntegrationTopic,
    saveRoleChange,
    getRoles,
    saveChatMessage,
    getChatMessages,
    getSnoozeOptions,
    getSettingsSnoozes,
    addSLASnooze,
    deleteSLASnooze,
    getAllTimeAlternatives,
    getGlobalSignature,
    setGlobalSignature,
    removeGlobalSignature,
};
