/* Vue engine */
import Vue from 'vue';
import Router from 'vue-router';

/* Application config */
import cfg from '@/app/config';

// **  Styling to thirdparty apps

// ** Neccessery third party applications
import axios from 'axios';
import VueSocketIO from 'vue-socket.io';
import { io } from 'socket.io-client';

import { provide } from 'vue';

import { createPinia, PiniaVuePlugin } from 'pinia';

// ** Third party applications that are "nice to have"
import 'vue-dnd-zone/vue-dnd-zone.css';
import './scss/slider/slider-default.css';

// ** Global components end
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';

// Add the store to window to allow cypress to access it (eg. for accesstoken for doing requests)
if (window.Cypress) {
    window.__store__ = store;
    // TODO: maybe add pinia aswell later?
}

import initSentry from './sentry';

// ** Important for authentication, please do not move.
import AuthHandler from './auth/auth.handler';
import AuthHelper from './auth/auth.helper';
import AxiosInterceptor from './interceptors/axiosClient';
import i18n from './i18n';
import App from './App.vue';

import { global } from './services/global/global.service';

import shadowDirective from './directives/shadowDom';
import linkifyDirective from './directives/linkifyDom';
// ** Import global styles
import './css/global.css';

// ** Global components
import GlobalComponents from '@/plugins/globalComponents';

Vue.use(GlobalComponents);

(async () => {
    // this removes the extremely long error warning when trying to navigate to same adress
    const originalPush = Router.prototype.push;
    Router.prototype.push = function push(location) {
        return originalPush.call(this, location).catch((error) => error);
    };

    /* Axios defaults */
    axios.defaults.baseURL = cfg.customerFirstUrl + 'api/';

    /* Options for Vue Toasted Globally */
    const options = {
        router,
        position: 'bottom-center',
        duration: 5000,
        iconPack: 'mdi',
        theme: 'bubble',
        type: 'success',
        keepOnHover: true,
        singleton: true,
    };

    /** Register imported third party applications */
    async function loadModules() {
        await Promise.all([
            import('vue-confetti').then((module) => Vue.use(module.default)),
            import('vue-toasted').then((module) => Vue.use(module.default, options)),
            import('vue-audio-visual').then((module) => Vue.use(module.default)),
            import('vue-dnd-zone').then((module) => Vue.use(module.default)),
            import('vue-slider-component').then((module) => Vue.component('VueSlider', module.default)),
            import('vue-notification').then((module) => Vue.use(module.default)),
            import('vue-dompurify-html').then((module) =>
                Vue.use(module.default, {
                    default: {
                        USE_PROFILES: { html: true },
                        FORBID_TAGS: ['style'],
                        ADD_ATTR: ['target'],
                    },
                }),
            ),
        ]);
    }

    await loadModules();

    Vue.directive('add-target-blank', {
        inserted(el) {
            const links = el.querySelectorAll('a');
            for (const link of links) {
                link.setAttribute('target', '_blank');
            }
        },
        update(el) {
            const links = el.querySelectorAll('a');
            for (const link of links) {
                link.setAttribute('target', '_blank');
            }
        },
    });

    // ** !Important Vuetify **/
    Vue.config.productionTip = false;

    axios.interceptors.request.use(
        (req) => {
            // console.log(`${req.method.toUpperCase()} Request made to ${req.url} with data:`, req.data);
            return req;
        },
        (error) => error,
    );

    // Handles auth interception logic
    AxiosInterceptor(axios);

    if (localStorage.getItem('loggedIn')) {
        try {
            const tokenResponse = await AuthHandler.refreshTokens();
            if (tokenResponse) {
                await AuthHelper.saveTokens(tokenResponse);
            }
        } catch (_) {
            console.error('Error refreshing initial token');
        }
    }

    // Get settings
    try {
        //* Gets configuration for the system
        await store.dispatch('System/getSettings', ['admin_config', 'search_config', 'userdata_config']);
        await store.dispatch('System/setVuetifyColors');
    } catch (error) {
        console.error('Error could not get systemSettings -> Error - >', error, new Date());
    }

    const accessToken = store.state.Auth.accessToken || 0;

    // Socket stuff very last to ensure we have token
    const eventSocket = io(cfg.customerFirstUrl + 'casemanagement/events', {
        transports: ['websocket'],
        query: {
            token: accessToken,
        },
        path: '/api/socket.io',
        autoConnect: false,
        useConnectionNamespace: false,
    });

    Vue.use(PiniaVuePlugin);
    const pinia = createPinia();

    Vue.use(
        new VueSocketIO({
            debug: false,
            connection: eventSocket,
            vuex: {
                store,
                actionPrefix: 'SOCKET_',
                mutationPrefix: 'SOCKET_',
            },
        }),
    );

    initSentry(Vue, router);

    router.beforeEach((_1, _2, next) => {
        // Show loader
        store.dispatch('Loader/showLoader');
        next();
    });

    router.afterEach(() => {
        // Hide loader
        store.dispatch('Loader/hideLoader');
    });

    /**
     * Global object to be used in the application
     *
     * @param {object} global
     * @param {object} global.dialogs
     * @param {function} global.dialogs.showConfirmationDialog
     * @param {function} global.dialogs.showDialog
     */
    Vue.prototype.$global = global;

    new Vue({
        router,
        store,
        vuetify,
        i18n,
        directives: {
            'shadow-dom': shadowDirective,
            'linkify-dom': linkifyDirective,
        },
        mounted() {
            // Assume your app has finished loading once the root component is mounted
            window.dispatchEvent(new Event('c1-loading-end'));
        },
        render: (h) => h(App),
        setup() {
            provide('$socket', Vue.prototype.$socket);
            provide('$toasted', Vue.prototype.$toasted);
            provide('$i18n', i18n);
            provide('$global', global);
        },
        pinia,
    }).$mount('#app');
})();

window.addEventListener('error', (e) => {
    const regex = /Loading chunk \d+ failed/;
    // prompt user to confirm refresh
    if (regex.test(e.message)) {
        window.location.reload();
    }
});
