import axios from 'axios';

export interface GetLeaderboardPayload {
    from: Date;
    to: Date;
    types: string[];
    search: string;
    groups: string[];
    source: string;
    fields: string[];
    sorting: string;
}

function getLeaderboard({ from, to, types, search, groups, source, fields, sorting }: GetLeaderboardPayload) {
    return axios.get('/leaderboard', {
        params: {
            source,
            from,
            to,
            types,
            search,
            sorting,
            groups: groups.join(','),
            fields: fields.join(','),
        },
    });
}

export interface GetIndividualLeaderboardPayload {
    userId: string;
    source: string;
    from: Date;
    to: Date;
    search: string;
    groups: string[];
}

function getIndividualLeaderboard({ userId, source, from, to, search, groups }: GetIndividualLeaderboardPayload) {
    return axios.get(`/leaderboard/${userId}`, {
        params: {
            source,
            from,
            to,
            search,
            groups: groups.join(','),
        },
    });
}

function getLeaderboardSources() {
    return axios.get('/leaderboard/sources');
}

export interface GetLeaderboardIntervalPayload {
    source: string;
}

function getLeaderboardInterval({ source }: GetLeaderboardIntervalPayload) {
    return axios.get('/leaderboard/interval', {
        params: {
            source,
        },
    });
}

export default {
    getLeaderboard,
    getIndividualLeaderboard,
    getLeaderboardSources,
    getLeaderboardInterval,
};
