<template>
    <v-menu v-model="menuOpen" :close-on-content-click="false" transition="scale-transition" offset-y>
        <template #activator="{}">
            <tertiary-action-button :dense="false" @click="menuOpen = !menuOpen">
                <article v-if="selectedItems.length > 0" class="item-text item">
                    <div class="avatar-item">
                        <v-list-item-avatar>
                            <v-icon size="18" color="var(--v-gray2-base)">{{ getIcon(selectedItem) }}</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-title class="item-text">
                            {{ selectedItem.data.name ? selectedItem.data.name : $t('users.noName') }}
                        </v-list-item-title>
                    </div>

                    <v-icon size="18" class="ml-2" color="var(--v-gray2-base)">mdi-chevron-down</v-icon>
                </article>
                <article v-else class="display-item item">
                    <div class="select-item">
                        {{ $t('selects.selectItems') }}
                    </div>
                    <v-icon size="18" class="ml-2" color="var(--v-gray2-base)">mdi-chevron-down</v-icon>
                </article>
            </tertiary-action-button>
        </template>

        <div>
            <header>
                <search-input :value="input" @input="setSearch" />
            </header>

            <section :key="input" ref="scrollContainer" class="user-list" @scroll="checkScroll">
                <v-list-item
                    v-for="(item, index) in clients.data"
                    :key="index"
                    :class="['list-item', { 'selected-item': isSelected(item) }]"
                    @click="selectItem(item)"
                >
                    <v-list-item-avatar>
                        <v-icon size="18" :color="iconColor(item)">{{ getIcon(item) }}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title :class="isSelected(item) ? 'item-text' : 'item-text-not-selected'">
                            {{ item.data.name ? item.data.name : $t('users.noName') }}
                        </v-list-item-title>
                    </v-list-item-content>

                    <!-- INACTIVE CHIP -->
                    <InactiveIndicator v-if="!item.data.active" />

                    <article class="tooltip-button">
                        <ClientTooltip :item="item.data" :visibleFields="item.visibleFields" />
                    </article>
                </v-list-item>
            </section>
            <footer class="action-buttons-list">
                <standard-button background-color="var(--v-primary-base)" @click="createClient">
                    {{ $t('selects.createClient') }}
                </standard-button>
            </footer>
        </div>
    </v-menu>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import { debouncer } from '@/utils';
    import ClientTooltip from '@/components/Main/Tooltips/ClientTooltip.vue';
    import { formTypes } from '../../../enums/creator.enums';

    export default {
        name: 'ClientSelect',

        components: {
            ClientTooltip,
            InactiveIndicator: () => import('@/components/Contacts/InactiveIndicator.vue'),
        },
        props: {
            showExtra: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                selectProps: {
                    rounded: true,
                    color: 'var(--v-gray3-base)',
                    filled: true,
                    dense: true,
                    hideDetails: true,
                },

                loading: false,
                input: '',
                page: 1,
                clients: {},
                menuOpen: false,
                selectedItems: [],
                hasMore: false,
            };
        },

        computed: {
            ...mapState('Creator', ['currentFormData', 'currentForm']),
            selectPropsComputed() {
                return {
                    ...this.selectProps,
                    ...this.$attrs,
                };
            },

            selectedItem() {
                return this.selectedItems.at(0);
            },
        },

        watch: {
            menuOpen() {
                if (this.menuOpen) {
                    this.setSearch('');
                }
            },
        },

        created() {
            this.init();
        },

        methods: {
            ...mapActions('Client', {
                getClients: 'getClients',
            }),

            ...mapActions('Creator', {
                openForm: 'openForm',
            }),

            getIcon(item) {
                if (item.data.type === 'client') {
                    return 'mdi-link';
                }
                return 'mdi-account';
            },

            iconColor(item) {
                return this.isSelected(item) ? 'var(--v-primary-base)' : 'var(--v-gray1-base)';
            },

            setSearch(input) {
                this.input = input;
                this.fetchClients(1).then((clients) => {
                    this.clients = clients;
                });
            },

            async init() {
                // eslint-disable-next-line new-cap
                this.debouncedSearch = new debouncer(async () => {
                    this.page = 1;
                    await this.getData();
                }, 500);

                this.clients = await this.fetchClients();
            },

            checkScroll() {
                const container = this.$refs.scrollContainer;
                const { scrollHeight, scrollTop, clientHeight } = container;

                if (Math.floor(scrollHeight - scrollTop) > clientHeight || !this.hasMore) {
                    return;
                }

                this.page++;
                this.fetchClients(this.page).then((clients) => {
                    this.clients.data = [...this.clients.data, ...clients.data];
                    this.hasMore = clients.hasMore;
                });
            },

            async getData() {
                this.loading = true;
                const { data } = await this.fetchClients(1);
                this.clients = data;
                this.loading = false;
            },

            async fetchClients(page = 1) {
                // If client id is supplied we will recieve the clients and contacts that matches the provided client id best.
                const { clientId } = this.selectPropsComputed;

                // Standard state of component is to only show clients, if client id is provided we will also show contacts for merging purposes.
                const types = ['client'];
                const data = await this.getClients({
                    search: this.input,
                    page,
                    clientId,
                    types,
                    // * if client id is provided we will not search for all clients, but simply return all related clients and contacts to said client. This makes it so the initial fetch returns related clients, then when we start searching we will search for all clients.
                    // ! IMPORTANT FOR CLIENT MERGE FUNCTIONALITY IN CLIENT CARD
                    alwaysSearch: !this.$attrs.clientId,
                });

                this.hasMore = data.hasMore;

                return {
                    hasMore: data.hasMore,
                    data: data.clients.map((data) => {
                        return {
                            ...data,
                            value: data.data.clientId,
                        };
                    }),
                };
            },

            selectItem(item) {
                this.selectedItems = [item];
                this.handleChange(item);
                this.menuOpen = false;
            },

            isSelected(item) {
                return this.selectedItems.some((selectedItem) => selectedItem.value === item.value);
            },

            handleChange(value) {
                this.$emit('input', [value]);
                this.$emit('change', [value]);
            },

            createClient() {
                if (this.currentForm.id === formTypes.MERGE_CLIENT) {
                    this.openForm({
                        id: formTypes.CREATE_CLIENT,
                        keys: {
                            return: formTypes.MERGE_CLIENT,
                            returnKeys: this.currentFormData.form.keys,
                        },
                    });
                    return;
                }

                this.openForm({
                    id: formTypes.CREATE_CLIENT,
                });
            },
        },
    };
</script>

<style scoped lang="scss">
    :deep(.v-input__prepend-inner) {
        align-self: center;
        margin: 0px !important;
        padding: 0px !important;
        white-space: nowrap !important;
    }

    div[role='menu'] {
        border-radius: 8px;
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2) !important;
        border: 1px solid #eee;
        padding: 4px;
        background-color: var(--v-gray3-base);
    }

    .select-item {
        display: flex;
        align-items: center;
        flex-direction: row;
    }

    .avatar-item {
        display: flex;
        align-items: center;
        flex-direction: row;
    }

    .user-list {
        max-height: 295px;
        overflow-y: auto;
        padding: 4px 0;
    }
    .icon-container {
        padding-right: 16px;
    }

    .list-container {
        display: flex;
        align-items: center;
        flex-direction: row;
    }

    .item {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        flex: 1;
    }
    .item-text {
        font-size: 0.9rem !important;
        font-weight: 400 !important;
        color: var(--v-gray2-base);
    }

    .item-text-not-selected {
        font-size: 0.9rem !important;
        font-weight: 400 !important;
        color: var(--v-gray2-base);
    }
    .list-item {
        padding: 0 !important;
        padding-left: 8px !important;
        padding-right: 8px !important;
        height: 48px !important;
        background-color: var(--v-gray3-base);
        margin-bottom: 1px;
        display: flex;
        gap: 8px;
        border-radius: 8px;
        &:hover::before {
            border-radius: 8px;
        }
    }

    .tooltip-button {
        justify-self: end;
    }
    .selected-item {
        background-color: white;
    }

    .display-item {
        display: flex;
        align-items: center;
        flex-direction: row;
        font-size: 0.9rem !important;
        font-weight: 400 !important;
        color: var(--v-gray2-base);
    }

    .action-buttons-list {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        gap: 4px;
    }
</style>

<i18n lang="json">
{
    "en": {
        "users": {
            "all": "All",
            "unassigned": "Unassigned",
            "noName": "No name"
        },

        "selects": {
            "selectItems": "Select",
            "allItemsSelected": "All",
            "itemsSelected": "selected",
            "createClient": "Create client"
        }
    },
    "sv": {
        "users": {
            "all": "Alla",
            "unassigned": "Ej tilldelade",
            "noName": "Inget namn"
        },

        "selects": {
            "selectItems": "Välj",
            "allItemsSelected": "Alla",
            "itemsSelected": " valda",
            "createClient": "Skapa klient"
        }
    }
}
</i18n>
