<template>
    <component :is="headerTag" class="c1-header">
        <slot></slot>
    </component>
</template>

<script lang="ts">
    import { defineComponent, computed } from 'vue';

    export default defineComponent({
        name: 'C1Header',
        props: {
            size: {
                type: Number,
                default: 1,
                validator: (value: number) => value >= 1 && value <= 6,
            },
        },
        setup(props) {
            const headerTag = computed(() => `h${props.size}`);

            return {
                headerTag,
            };
        },
    });
</script>

<style scoped>
    .c1-header {
        font-weight: bold;
        margin: 0;
        padding: 16px 12px 8px 16px;
        color: var(--Color-Neutrals-50, #333);
        font-weight: 500;
    }

    h1 {
        font-size: 18px;
    }

    h2 {
        font-size: 16px;
    }

    h3 {
        font-size: 14px;
    }

    h4 {
        font-size: 12px;
    }

    h5 {
        font-size: 10px;
    }

    h6 {
        font-size: 8px;
    }
</style>
