<template>
    <section class="heading">
        <truncate>{{ title || $t('timelineItemHeader.noTopic') }}</truncate>
    </section>
</template>
<script lang="ts">
    import { defineComponent } from 'vue';

    export default defineComponent({
        components: {},
        props: {
            title: {
                type: String,
                required: true,
            },
        },
    });
</script>
<style scoped>
    .heading {
        font-weight: 700;
    }
</style>
<i18n lang="json">
{
    "en": {
        "timelineItemHeader": {
            "noTopic": "No topic"
        }
    },

    "sv": {
        "timelineItemHeader": {
            "noTopic": "Inget ämne"
        }
    }
}
</i18n>
