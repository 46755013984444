import axios from 'axios';

export interface DeploySmsBatchRequest {
    smsFrom: string;
    smsToBatchArray: string[];
    smsText: string;
    smsToBatchString: string;
    smsTotal: number;
}

function deploySmsBatch(payload: DeploySmsBatchRequest) {
    return axios.post('marketing/sms/batch', payload);
}
function getHistory() {
    return axios.get('marketing/sms/history');
}

export default {
    deploySmsBatch,
    getHistory,
};
