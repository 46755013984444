<template>
    <div class="c1-menu">
        <slot name="activator" :toggle="toggleMenu"></slot>
        <transition name="c1-fade">
            <div v-if="isOpen" class="c1-menu-content" :style="{ width: menuWidth }" @click.stop>
                <slot></slot>
            </div>
        </transition>
    </div>
</template>

<script lang="ts">
    import { defineComponent, ref, computed, watch, onMounted, onBeforeUnmount } from 'vue';

    export default defineComponent({
        name: 'C1Menu',
        props: {
            value: {
                type: Boolean,
                default: false,
            },
            width: {
                type: [String, Number],
                default: 300,
            },
        },
        setup(props, { emit }) {
            const isOpen = ref(props.value);

            watch(
                () => props.value,
                (newValue) => {
                    isOpen.value = newValue;
                }
            );

            const toggleMenu = () => {
                isOpen.value = !isOpen.value;
                emit('input', isOpen.value);
            };

            const closeMenu = (event: Event) => {
                if (!(event.target as HTMLElement).closest('.c1-menu')) {
                    isOpen.value = false;
                    emit('input', isOpen.value);
                }
            };

            onMounted(() => {
                document.addEventListener('click', closeMenu);
            });

            onBeforeUnmount(() => {
                document.removeEventListener('click', closeMenu);
            });

            const menuWidth = computed(() => (typeof props.width === 'number' ? `${props.width}px` : props.width));

            return {
                isOpen,
                toggleMenu,
                menuWidth,
            };
        },
    });
</script>

<style scoped>
    .c1-menu {
        position: relative;
        display: inline-block;
    }

    .c1-menu-content {
        margin-top: 4px;
        position: absolute;
        z-index: 50;
        background: white;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }

    .c1-fade-enter-active,
    .c1-fade-leave-active {
        transition: opacity 0.2s;
    }
    .c1-fade-enter-from,
    .c1-fade-leave-to {
        opacity: 0;
    }
</style>
