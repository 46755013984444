<template>
    <section :class="createCommentsActionsClass">
        <CreateCommentsFunctions cy-data="create-comments-functions" />
        <CreateCommentsSendButtons cy-data="create-comments-send-buttons" />
    </section>
</template>

<script lang="ts">
    import { defineComponent, computed, inject } from 'vue';
    import { CreateCommentsState } from '@/types/createComments.state';

    import CreateCommentsFunctions from '@/components/Cases/CreateComments/CreateCommentsFunctions.vue';
    import CreateCommentsSendButtons from '@/components/Cases/CreateComments/CreateCommentsSendButtons.vue';

    export default defineComponent({
        name: 'CreateCommentsAction',

        components: {
            CreateCommentsFunctions,
            CreateCommentsSendButtons,
        },

        setup() {
            const commentsState = inject<CreateCommentsState>('commentsState');

            if (!commentsState) {
                throw new Error('commentsState is not provided');
            }

            const createCommentsActionsClass = computed(() => {
                return {
                    'create-comments-actions': !commentsState.collapseEditor,
                    'create-comments-actions-collapsed': commentsState.collapseEditor,
                };
            });

            return {
                commentsState,
                createCommentsActionsClass,
            };
        },
    });
</script>

<style scoped>
    .create-comments-actions {
        display: flex;
        gap: 8px;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        padding-top: 8px;
    }

    .create-comments-actions-collapsed {
        display: flex;
        gap: 8px;
        justify-content: space-between;
        align-items: center;
        padding: 8px;
        padding-top: 0px;
    }
</style>
