<template>
    <section class="clients-family-container" :style="containerHeightStyle">
        <infinite-list
            ref="list"
            id="family-list"
            :headers="[]"
            :fetchItems="fetchClientFamily"
            :items="clientFamilyArr"
            :limit="itemsToShow + 1"
            :reverse="true"
            :fixed="true"
            :emptyHeader="$t('clientFamily.empty')"
            :emptyMessage="$t('clientFamily.emptyMessage')"
            emptyIcon="mdi-account-group"
        >
            <template #item="{ item }">
                <ClientFamilyItemSearch :item="item" :selectedClientId="clientId" />
            </template>
        </infinite-list>
    </section>
</template>

<script>
    import ClientFamilyItemSearch from './ClientFamilyItemSearch.vue';
    import api from '@/api/client/client';

    export default {
        name: 'ClientFamilySearch',

        components: {
            ClientFamilyItemSearch,
        },

        props: {
            clientId: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                itemHeight: 50,
                itemsToShow: 4,
                clientFamilyArr: [],
                api,
            };
        },

        computed: {
            containerHeight() {
                return `${Math.min(this.clientFamilyArr.length * this.itemHeight, this.itemHeight * this.itemsToShow)}px`;
            },

            containerHeightStyle() {
                return {
                    minHeight: this.containerHeight,
                    maxHeight: this.containerHeight,
                };
            },
        },

        sockets: {
            clientUpdate(payload) {
                this.fetchClientFamily({ limit: 5, page: 1 });
            },
        },

        created() {
            console.log('created');
        },
        mounted() {
            this.$socket.emit('subscribeToClient', this.clientId);
        },

        beforeDestroy() {
            this.$socket.emit('unsubscribeToClient', this.clientId);
        },

        methods: {
            async fetchClientFamily({ limit = 5, page = 1 }) {
                try {
                    const offset = (page - 1) * limit;
                    const { data } = await api.getClientsFamily(this.clientId, limit, offset);
                    this.clientFamilyArr = page === 1 ? data : this.clientFamilyArr.concat(data);
                } catch (error) {
                    console.error(
                        `Error fetching client family with clientId ${this.clientId}, limit ${limit} and offset ${offset}`
                    );
                    this.$toasted.error(this.$t('clientFamily.fetchFail'));
                }
            },
        },
    };
</script>

<style scoped>
    .clients-family-container {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
    }

    :deep(#family-list > .infinite-list) {
        overflow-y: visible;
    }
</style>

<i18n lang="json">
{
    "en": {
        "clientFamily": {
            "empty": "No Family",
            "emptyMessage": "This client has no family",
            "fetchFail": "Could not fetch client family"
        }
    },
    "sv": {
        "clientFamily": {
            "empty": "Ingan familj",
            "emptyMessage": "Denna klient har ingen familj",
            "fetchFail": "Kunde inte hämta familj"
        }
    }
}
</i18n>
